import React, { useState, useEffect } from 'react';
import {
  renderDoubleInputRow,
  transformAlertsBackToUIType,
} from '../../helpers/ClientHelper';

const AlertsComponent = (props) => {
  const {
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    setLastStepInParent,
  } = props;

  const { moduleSettings: { alerts = {} } = {} } = localPlanDetailsByPlanId;
  const [alertsDetails, setAlertsDetails] = useState(alerts);

  useEffect(() => {
    if (alerts && Object.keys(alerts).length > 0) {
      let tempAlerts = transformAlertsBackToUIType(alerts);
      setAlertsDetails(tempAlerts);
    }
  }, [localPlanDetailsByPlanId]);

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  return (
    <>
      <div className="insured_main_outer">
        {renderDoubleInputRow(
          'text',
          'tldebtRateYellow',
          'debtRateYellowThreshold',
          'tldebtRateRed',
          'debtRateRedThreshold',
          'Debt rate',
          'percentage',
          alertsDetails,
          'alerts',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          setAlertsDetails
        )}
        {renderDoubleInputRow(
          'text',
          'tlInvReturnYellowHigh',
          'invReturnYellowHigh',
          'tlInvReturnRed',
          'invReturnRedHigh',
          'Investment return (upper bracket)',
          'percentage',
          alertsDetails,
          'alerts',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          setAlertsDetails
        )}
        {renderDoubleInputRow(
          'text',
          'tlInvReturnYellowLow',
          'invReturnYellowLow',
          'tlInvReturnRedLow',
          'invReturnRedLow',
          'Investment return (lower bracket)',
          'percentage',
          alertsDetails,
          'alerts',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          setAlertsDetails
        )}
        {renderDoubleInputRow(
          'text',
          'tlInsuranceYellow',
          'insuranceYellowThreshold',
          'tlInsuranceRed',
          'insuranceRedThreshold',
          'Insurance sufficiency',
          'percentage',
          alertsDetails,
          'alerts',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          setAlertsDetails
        )}
      </div>
    </>
  );
};

export default AlertsComponent;
