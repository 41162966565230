export const getOutputResponseSelector = (state) => state.OutputResponseReducer;

export const outputResponseLoadingSelector = (state) => {
  return getOutputResponseSelector(state).isOutputResponseLoading;
};

export const outputResponseChronoDetailsByPlanIdSelector = (state) => {
  if (isOutputResponseDataAvailable(state)) {
    return getOutputResponseSelector(state).outputResponse.chrono || {};
  }
  return {};
};

export const outputResponseSimulateBeforeSelector = (state) => {
  if (isOutputResponseDataAvailable(state)) {
    return getOutputResponseSelector(state).simulateBeforeData || {};
  }
  return {};
};

export const outputResponseDetailsSelector = (state) => {
  return getOutputResponseSelector(state).outputResponse || {};
};

export const outputResponseDashboardSummaryDetailsByPlanIdSelector = (
  state
) => {
  if (isOutputResponseDataAvailable(state)) {
    return getOutputResponseSelector(state).outputResponse.dashboard || {};
  }
  return {};
};

export const outputResponsePlannerDetailsByPlanIdSelector = (state) => {
  if (isOutputResponseDataAvailable(state)) {
    return getOutputResponseSelector(state).outputResponse.planner || {};
  }
  return {};
};

export const outputResponseChartsByPlanIdSelector = (state) => {
  if (
    isOutputResponseDataAvailable(state) &&
    getOutputResponseSelector(state).outputResponse.results
  ) {
    return getOutputResponseSelector(state).outputResponse.results.charts || [];
  } else if (
    isOutputResponseDataAvailable(state) &&
    getOutputResponseSelector(state).outputResponse.charts
  ) {
    return getOutputResponseSelector(state).outputResponse.charts || {};
  }
  return {};
};

export const outputResponseTablesByPlanIdSelector = (state) => {
  if (
    isOutputResponseDataAvailable(state) &&
    getOutputResponseSelector(state).outputResponse.results
  ) {
    return getOutputResponseSelector(state).outputResponse.results.tables || [];
  }
  return [];
};

export const outputResponseDataByPlanIdSelector = (state) => {
  if (
    isOutputResponseDataAvailable(state) &&
    getOutputResponseSelector(state).outputResponse.results
  ) {
    return getOutputResponseSelector(state).outputResponse.results.data || [];
  }
  return [];
};

export const outputResponseNotesByPlanIdSelector = (state) => {
  if (
    isOutputResponseDataAvailable(state) &&
    getOutputResponseSelector(state).outputResponse.results
  ) {
    return getOutputResponseSelector(state).outputResponse.results.notes || [];
  }
  return [];
};

export const outputResponseSolutionsByPlanIdSelector = (state) => {
  if (isOutputResponseDataAvailable(state)) {
    return getOutputResponseSelector(state).outputResponse.solutions || {};
  }
  return {};
};

export const outputResponseBuildByPlanIdSelector = (state) => {
  if (isOutputResponseDataAvailable(state)) {
    return getOutputResponseSelector(state).outputResponse.build || {};
  }
  return {};
};

export const outputResponseModuleCompletionStatusByPlanIdSelector = (state) => {
  if (isOutputResponseDataAvailable(state)) {
    return getOutputResponseSelector(state).outputResponse.completion || {};
  }
  return {};
};

export const outputResponseFAQsSelector = (state) => {
  if (isOutputResponseDataAvailable(state)) {
    return getOutputResponseSelector(state).outputResponse.faq || {};
  }
  return {};
};

export const outputResponseInsightsSelector = (state) => {
  if (isOutputResponseDataAvailable(state)) {
    return getOutputResponseSelector(state).outputResponse.insights || {};
  }
  return {};
};

export const outputResponseGoalsSelector = (state) => {
  if (isOutputResponseDataAvailable(state)) {
    return getOutputResponseSelector(state).outputResponse.goals || {};
  }
  return {};
};

export const outputResponsePlanHighlightsSelector = (state) => {
  if (isOutputResponseDataAvailable(state)) {
    return getOutputResponseSelector(state).outputResponse.planHighlights || {};
  }
};

export const outputResponseAdvisorHomeSelector = (state) => {
  if (isOutputResponseDataAvailable(state)) {
    return getOutputResponseSelector(state).outputResponse.advisorHome || {};
  }
};
export const isOutputResponseDataAvailable = (state) => {
  const isOutputResponseAvailable =
    getOutputResponseSelector(state) &&
    Object.keys(getOutputResponseSelector(state).outputResponse).length > 0
      ? true
      : false;
  return isOutputResponseAvailable;
};
