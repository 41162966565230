import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import JsPDF from 'jspdf';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import html2canvas from 'html2canvas';
import { getLoggedInUserDetails } from '../../selectors/loginUserSelector';
import {
  getClientReportsByAdvisorId,
  simulatePlanDetailsByPlanIdV2,
} from '../../services/dashboardSvc';
import { getSelectedReportDataByReportId } from '../../redux/actions/reportsAction';
import {
  getReportsInitialDataSelector,
  getReportDataLoadingSelector,
} from '../../selectors/reportsDataSelector';
import {
  selectedPlanIdForClientIdSelector,
  getPlansByClientIdSelector,
  planDetailsByPlanIdSelector,
  simulatePlanDetailsLoadingSelector,
} from '../../selectors/plannerDetailsSelector';
import {
  getPlansDetailsAction,
  simulatePlanDetailsByPlanId,
} from '../../redux/actions/plannerDetailsAction';
import { selectedClientIdSelector } from '../../selectors/clientsInfoSelector';
import {
  outputResponseChartsByPlanIdSelector,
  outputResponseTablesByPlanIdSelector,
  outputResponseDataByPlanIdSelector,
  outputResponseDetailsSelector,
  outputResponseNotesByPlanIdSelector
} from '../../selectors/outputResponseDetailsSelector';
import history from '../../history';
import { withSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import CustomChart from '../../components/shared/CustomChartComponent';
import CustomTable from '../../components/shared/CustomTableComponent';
import CustomData from '../../components/shared/CustomDataComponent';
import CustomNotes from '../../components/shared/CustomNotesComponent';
import Controls from '../controls/Controls';
import { Grid } from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import edit_blod from '../../images/icon/edit_blod.svg';
import edit_italic from '../../images/icon/edit_italic.svg';
import edit_underline from '../../images/icon/edit_underline.svg';
import edit_strikeout from '../../images/icon/edit_strikeout.svg';
import edit_pencil from '../../images/edit_pencil.svg';
import edit_brush from '../../images/icon/edit_brush.svg';
import edit_color from '../../images/icon/edit_color.svg';
import delete_icon from '../../images/delete_icon.svg';
import reports_pdf_footer_logo from '../../images/reports_pdf_footer_logo.svg';
import financial_advisors_icon from '../../images/financial_advisors_icon.svg';
import pdf_pages_white from '../../images/pdf_pages_white.svg';
import pdf_pages from '../../images/pdf_pages.svg';
import pdf_page_plus_icon from '../../images/pdf_page_plus_icon.svg';
import pdf_page_file_icon from '../../images/pdf_page_file_icon.svg';
import pdf_page_delete_icon from '../../images/pdf_page_delete_icon.svg';
import save_icon from '../../images/save.svg';
import save_edit from '../../images/save_edit.svg';
import add_icon from '../../images/add.svg';
import file_1 from '../../images/file_1.svg';
import file_2 from '../../images/file_2.svg';
import { logEvent } from '../../analytics';

class ReportTemplate extends Component {
  state = {
    selectedPageNum: 0,
    advisorReportsData: {},
    imagesArr: [],
    isReportLoading: false,
    isPreviewClicked: false,
    reports: [],
    reportId: '',
  };

  reportsPageRef = [];

  getSelectedPlanIdDetails = () => {
    const { selectedPlan: planId, getSavedSelectedPlanIdDetails } = this.props;
    const version = 'v2';
    if (planId && planId !== '') {
      getSavedSelectedPlanIdDetails({ planId, version });
    }
  };

  processReports = async (pages) => {
    const { outputResponse } = this.props;
    for (const page of pages) {
      const { rows } = page;
      for (const row of rows) {
        const { elements } = row;
        for (let element of elements) {
          const { renderType, renderWhat } = element;
          if (renderWhat) {
            const { subtabName = '' } = renderWhat;
            if (renderType && renderType.toLowerCase() === 'qanda') {
              const apiResponse = await this.handleSimulate(
                parseInt(subtabName)
              );
              element.outputResponse = apiResponse;
            } else {
              element.outputResponse = outputResponse;
            }
          }
        }
      }
    }
  };

  updateAdvisorReportsData = async () => {
    const { reportsData = {} } = this.props;
    this.updateLoaderStatus(true);
    await this.processReports(reportsData.pages);
    this.updateLoaderStatus(false);
    this.setState({
      advisorReportsData: reportsData,
    });
  };

  handleSimulate = async (newRunCode) => {
    const { planDetailsByPlanId } = this.props;
    let tempPlanDetailsByPlanId = { ...planDetailsByPlanId };
    const { moduleSettings: { model = {} } = {}, moduleSettings = {} } =
      tempPlanDetailsByPlanId;
    let tempModel = { ...model, runCode: newRunCode };
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      moduleSettings: {
        ...moduleSettings,
        model: { ...tempModel },
      },
    };
    let simulatedData = {};
    try {
      const { status, data } = await simulatePlanDetailsByPlanIdV2(
        tempPlanDetailsByPlanId
      );
      if (status === 200) {
        simulatedData = data;
      }
    } catch (err) {
      simulatedData = {};
    }
    return simulatedData;
  };

  handleSimulateOnLoad = (runCode) => {
    const { planDetailsByPlanId, triggerSimulatePlanDetailsByPlanId } =
      this.props;
    let tempPlanDetailsByPlanId = { ...planDetailsByPlanId };
    const { moduleSettings: { model = {} } = {}, moduleSettings = {} } =
      tempPlanDetailsByPlanId;
    let tempModel = { ...model, runCode: runCode };
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      moduleSettings: {
        ...moduleSettings,
        model: { ...tempModel },
      },
    };
    triggerSimulatePlanDetailsByPlanId({
      displayTermChanged: false,
      chrono: {},
      plannerFormReq: tempPlanDetailsByPlanId,
      version: 'v2',
    });
    logEvent('User Interaction', 'Reports Simulate', 'Simulate Plan');
  };

  componentDidMount() {
    const {
      selectedClientId,
      selectedPlan,
      planDetailsByPlanId = {},
    } = this.props;
    const { moduleSettings: { model = {} } = {} } = planDetailsByPlanId || {};
    const { runCode } = model;
    if (!selectedClientId && !selectedPlan) {
      history.push({ pathname: '/home' });
    }
    // else if (runCode === 0) {
    //   this.getClientReportData();
    // } 
    else {
      this.getClientReportData();
      this.handleSimulateOnLoad(1000); //sending runCode as 1000 instead of 0 or 100 on load
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { reportsData, planDetailsByPlanId } = this.props;
    const {
      reportsData: prevReportsData,
      planDetailsByPlanId: prevPlanDetailsByPlanId,
    } = prevProps;
    if (!isEqual(reportsData, prevReportsData)) {
      this.updateAdvisorReportsData(reportsData);
    }
    if (!isEqual(planDetailsByPlanId, prevPlanDetailsByPlanId)) {
      this.getClientReportData();
    }
  }

  getClientReportData = async () => {
    const {
      userDetails: { emailId },
      triggerGetReportDataByReportId,
      planDetailsByPlanId,
    } = this.props;
    const { moduleSettings: { model = {} } = {} } = planDetailsByPlanId;
    const { displayTerm = '' } = model;
    try {
      const reports = await getClientReportsByAdvisorId(emailId);
      if (reports && reports.status === 200) {
        const reportId =
          reports.data && reports.data.length > 0
            ? reports.data[0].reportId
            : '';
        if (reportId && displayTerm) {
          this.setState({ reports: reports.data, reportId: reportId }, () => {
            triggerGetReportDataByReportId({ displayTerm, reportId });
          });
        }
      }
    } catch (e) {
      this.setState({
        isApiFailed: true,
        isLoading: false,
        reports: [],
        reportId: '',
      });
    }
  };

  getSelectedPlanName = () => {
    const { clientPlans, selectedPlan: selectedPlanId } = this.props;
    let selectedPlanName = '';
    if (clientPlans.length > 0 && selectedPlanId !== '') {
      const planObj = clientPlans.find((plan) => plan.value === selectedPlanId);
      selectedPlanName =
        planObj && Object.keys(planObj).length > 0 ? planObj.label : '';
    }
    return selectedPlanName;
  };

  renderCustomChart = (renderWhatData, outputResponse) => {
    const { charts } = (outputResponse && outputResponse.results) || [];
    return (
      <CustomChart
        charts={charts}
        renderWhatData={renderWhatData}
        from={'reports'}
      />
    );
  };

  renderCustomTable = (renderWhatData, outputResponse) => {
    const { tables } = (outputResponse && outputResponse.results) || [];
    return <CustomTable tables={tables} renderWhatData={renderWhatData} />;
  };

  renderCustomData = (renderWhatData, outputResponse) => {
    const { data } = (outputResponse && outputResponse.results) || [];
    return <CustomData data={data} renderWhatData={renderWhatData} />;
  };

  renderCustomNotes = (renderWhatData, outputResponse) => {
    const { notes } = (outputResponse && outputResponse.results) || [];
    return <CustomNotes notes={notes} renderWhatData={renderWhatData} />;
  }

  renderCustomContentContainer = (obj, outputResponse) => {
    const { renderTabType, data } = obj;
    switch (renderTabType && renderTabType.toLowerCase()) {
      case 'chart':
        return this.renderCustomChart(data, outputResponse);
      case 'table':
        return this.renderCustomTable(data, outputResponse);
      case 'data':
        return this.renderCustomData(data, outputResponse);
      case 'notes':
        return this.renderCustomNotes(data, outputResponse);
      default:
        break;
    }
  };

  renderCustomContainerForGoalsAndInsights = (renderTag, outputResponse) => {
    let { aux = {}, main = {}, profile = {} } = renderTag || {};
    const { tabType: auxTabType = '' } = aux;
    const { tabType: mainTabType = '' } = main;
    const { tabType: profileTabType = '' } = profile;
    return (
      <div className="client_chart_main_div">
        {['profile', 'main', 'aux'].map((p) => {
          let obj = {};
          if (p === 'main') {
            obj = { renderTabType: mainTabType, data: main };
          } else if (p === 'aux') {
            obj = { renderTabType: auxTabType, data: aux };
          } else if (p === 'profile') {
            obj = { renderTabType: profileTabType, data: profile };
          }
          return (
            <div className="client_chart_inner">
              {this.renderCustomContentContainer(obj, outputResponse)}
            </div>
          );
        })}
      </div>
    );
  };

  renderInsights = (renderWhat, outputResponse) => {
    const { subtabName, tabType } = renderWhat;
    const runCodeValues = subtabName.toString().split('');
    const insightsSeq = runCodeValues[0];
    const tabIndex = runCodeValues[1];
    const questionNumber = runCodeValues[2];
    const { insights: { insightsTabs = [] } = {} } = outputResponse || {};
    const actualInsight =
      insightsTabs && insightsTabs.length > 0
        ? insightsTabs[tabIndex - 1].rows &&
          insightsTabs[tabIndex - 1].rows.length > 0
          ? insightsTabs[tabIndex - 1].rows[questionNumber]
          : {}
        : {};
    const {
      question,
      answer,
      // renderTag: { main = {}, aux = {}, profile = {} } = {},
      renderTag = {},
    } = actualInsight;
    return (
      <Grid
        container
        className="faq-container chrono-container"
        style={{ border: 'none' }}
        spacing={1}
      >
        <Grid item xs={12}>
          <div className="faq-content-items">
            <Accordion
              expanded={true}
              className="faq-item"
              sx={{ background: 'rgba(255, 255, 255, 0.3)' }}
            >
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <span className="faq-question">Q: {question}</span>
              </AccordionSummary>
              <AccordionDetails>
                <span className="faq-answer">
                  {answer
                    ? answer
                      .split('\n \n')
                      .map((m, i) => <p key={i}>{i === 0 ? 'A: ' + m : m}</p>)
                    : ''}
                </span>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="client_main product_main">
            <div className="client_right solution_tab_content">
              {this.renderCustomContainerForGoalsAndInsights(
                renderTag,
                outputResponse
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    );
  };

  renderGoals = (renderWhat, outputResponse) => {
    const { subtabName, tabType } = renderWhat;
    const runCodeValues = subtabName.toString().split('');
    const goalsSeq = runCodeValues[0];
    const tabIndex = runCodeValues[1];
    const questionNumber = runCodeValues[2];
    const { goals: { goalsTabs = [] } = {} } = outputResponse || {};
    const actualGoal =
      goalsTabs && goalsTabs.length > 0
        ? goalsTabs[tabIndex - 1].rows &&
          goalsTabs[tabIndex - 1].rows.length > 0
          ? goalsTabs[tabIndex - 1].rows[questionNumber]
          : {}
        : {};
    const {
      question,
      answer,
      renderTag: { main = {}, aux = {}, profile = {} } = {},
      renderTag = {},
    } = actualGoal;
    return (
      <Grid
        container
        className="faq-container chrono-container"
        style={{ border: 'none' }}
        spacing={1}
      >
        <Grid item xs={12}>
          <div className="faq-content-items">
            <Accordion
              expanded={true}
              className="faq-item"
              sx={{ background: 'rgba(255, 255, 255, 0.3)' }}
            >
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <span className="faq-question">Q: {question}</span>
              </AccordionSummary>
              <AccordionDetails>
                <span className="faq-answer">
                  {answer
                    ? answer
                      .split('\n \n')
                      .map((m, i) => <p key={i}>{i === 0 ? 'A: ' + m : m}</p>)
                    : ''}
                </span>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="client_main product_main">
            <div className="client_right solution_tab_content">
              {this.renderCustomContainerForGoalsAndInsights(
                renderTag,
                outputResponse
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    );
  };

  renderCustomContainer = ({ renderWhat, heading, outputResponse }) => {
    const { tabType } = renderWhat;
    switch (tabType && tabType.toLowerCase()) {
      case 'chart':
        return this.renderCustomChart(renderWhat, outputResponse);
      case 'table':
        return this.renderCustomTable(renderWhat, outputResponse);
      case 'data':
        return this.renderCustomData(renderWhat, outputResponse);
      case 'insights':
        return this.renderInsights(renderWhat, outputResponse);
      case 'goals':
        return this.renderGoals(renderWhat, outputResponse);
      case 'notes':
        return this.renderCustomNotes(renderWhat, outputResponse);
      default:
        break;
    }
  };

  waitForDOMReady = () => {
    return new Promise((resolve) => {
      if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', resolve);
      } else {
        resolve();
      }
    });
  };

  callSimulateOnEachPage = async (element) => {
    const { renderWhat } = element;
    let outputResponseSimulated = {};
    if (renderWhat) {
      const { subtabName = '' } = renderWhat;
      outputResponseSimulated = await this.handleSimulate(parseInt(subtabName));
      await this.waitForDOMReady();
    }
    return outputResponseSimulated;
  };

  renderQandA = (element) => {
    const { heading, renderWhat } = element;
    const { isPreviewClicked } = this.state;
    let tempElement = { ...element };
    //if (renderWhat) {
    //const { subtabName = '' } = renderWhat;
    // const outputResponseSimulated = this.callSimulateOnEachPage(element);
    // if (
    //   outputResponseSimulated &&
    //   Object.keys(outputResponseSimulated).length > 0
    // ) {
    //   tempElement = {
    //     ...tempElement,
    //     outputResponse: outputResponseSimulated,
    //   };
    // }
    return (
      <div className="card h-100" id="charts-page-container">
        <div className="card-header d-flex align-items-center justify-content-between border-0">
          <h5 className="mb-0">{heading}</h5>
          {/* {!isPreviewClicked && (
            <div className="reports_pdf_icons">
              <button>
                <img src={edit_pencil} alt="" title="" />
              </button>
              <button>
                <img src={delete_icon} alt="" title="" />
              </button>
            </div>
          )} */}
        </div>
        <div className="card-body">
          {this.renderCustomContainer(tempElement)}
        </div>
      </div>
    );
    //}
  };

  renderReportDataElement = (element) => {
    const { heading, renderType } = element;
    const { outputResponse } = this.props;
    let tempElement = { ...element, outputResponse };
    const { isPreviewClicked } = this.state;
    switch (renderType.toLowerCase()) {
      case 'comments':
        return (
          <div className="reports_advisor_comments_outer">
            <div className="reports_advisor_comments_title">
              <h4>
                {heading}
                {/* <span className="edit_advisor_comments">
                  {!isPreviewClicked && (
                    <img src={edit_pencil} alt="" title="" />
                  )}
                </span> */}
              </h4>
              <div className="reports_advisor_comments_text_editor">
                <div className="reports_advisor_comments_text_editor_tool_bar">
                  {!isPreviewClicked && (
                    <ul>
                      <li>
                        <a href="#!">
                          <img src={edit_blod} alt="" title="" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <img src={edit_italic} alt="" title="" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <img src={edit_underline} alt="" title="" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <img src={edit_strikeout} alt="" title="" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <img src={edit_color} alt="" title="" />
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <img src={edit_brush} alt="" title="" />
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
                <div className="reports_advisor_comments_text_editor_textarea">
                  <textarea
                    placeholder="Add Comments"
                    rows="15"
                    style={{ height: '300px' }}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        );
      case 'results':
        return (
          <div className="card h-100" id="charts-page-container">
            <div className="card-header d-flex align-items-center justify-content-between border-0">
              <h5 className="mb-0">{heading}</h5>
              {/* {!isPreviewClicked && (
                <div className="reports_pdf_icons">
                  <button>
                    <img src={edit_pencil} alt="" title="" />
                  </button>
                  <button>
                    <img src={delete_icon} alt="" title="" />
                  </button>
                </div>
              )} */}
            </div>
            <div className="card-body text-center">
              {this.renderCustomContainer(tempElement)}
            </div>
          </div>
        );
      case 'qanda':
        return this.renderQandA(element);
      case '@disclaimer':
        return (
          <div className="reports_disclaimer_outer">
            <h4>{heading}</h4>
            <p>Disclaimer : Your use of https://greenrfinancial.com is subject to Green'r's Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.</p>
          </div>
        );
      default:
        return null;
    }
  };

  renderSelectedPageData = (selectedPageData) => {
    const { selectedPageNum } = this.state;
    const { pageNum } = selectedPageData;
    // const currentPage = pages.find(
    //   (page) => page.pageNum === selectedPageNum + 1
    // );
    const reportsClassName = `row ${selectedPageNum + 1 === pageNum ? 'currentPage' : ''
      }`;
    return (
      <>
        {selectedPageData.rows.map((page, index) => {
          const { numElements } = page;
          const { elements } = page;
          return (
            <div
              className={reportsClassName}
              id={`reports-page-${pageNum}-row-${index}`}
            >
              {numElements > 0 && (
                <>
                  {elements.map((element) => (
                    <div className={`mb-3 col-xl-${12 / numElements}`}>
                      {this.renderReportDataElement(element)}
                    </div>
                  ))}
                </>
              )}
            </div>
          );
        })}
      </>
    );
  };

  renderReportPageData = (page, pageNum) => {
    const { selectedPageNum, isPreviewClicked } = this.state;
    const planName = this.getSelectedPlanName();
    const { advisorReportsData: reportsData = {} } = this.state;
    const { pages = [] } = reportsData;
    const { userDetails, selectedClientId, selectedPlan, clientsNotUpdated } =
      this.props;
    const selectedClientInfo = clientsNotUpdated.filter(
      (c) => c.clientId === selectedClientId
    );
    let clientName = '';
    if (selectedClientInfo.length > 0) {
      clientName = selectedClientInfo[0].clientName || '';
    }
    const date = new Date().toLocaleString();
    const { companyId } = reportsData;
    return (
      <div
        id={`reports-page-${pageNum + 1}`}
        ref={(reportsPageRef) =>
          (this.reportsPageRef[pageNum] = reportsPageRef)
        }
        className={
          selectedPageNum === pageNum || isPreviewClicked ? '' : 'vHidden'
        }
      >
        <div className="reports_pdf_header_outer" id="report-header">
          <div className="reports_pdf_financial_advisors">
            <div className="reports_pdf_financial_advisors_icon">
              <img src={financial_advisors_icon} alt="img" title="" />
            </div>
            <h4>{companyId}</h4>
          </div>

          <div className="reports_client_name_outer">
            <h1>{clientName}</h1>
            <p>{planName}</p>
          </div>

          <div className="reports_advisor_name_date">
            <div className="report_generated_by_outer">
              Report Generated by:
              <br /> <span>{userDetails.name}</span>
            </div>

            <div className="report_generated_by_date_outer">
              on <span className="date">{date}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="card-header" style={{ padding: '10px' }}>
            <h5 className="mb-0">{page.heading}</h5>
          </div>
          <div className="reports_pdf_content_outer">
            {this.renderSelectedPageData(page)}
          </div>
          <div className={isPreviewClicked ? "reports_pdf_footer_outer" : "reports_pdf_footer_outer main"} id="disclaimer_Template">
            <div className="reports_pdf_page_count">
              <div className="reports_pdf_page_count_inner">
                <div className="current_page_count">{pageNum + 1}</div>{' '}
                <div className="dividers">/</div>{' '}
                <div className="totle_count">{pages.length}</div>
              </div>
              <p>Disclaimer : Your use of https://greenrfinancial.com is subject to Green'r's Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.</p>
            </div>
            <div className="reports_pdf_footer_logo">
              <img src={reports_pdf_footer_logo} alt="" title="" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderReportDataByType = () => {
    const { selectedPageNum, isPreviewClicked } = this.state;
    const { advisorReportsData: reportsData = {} } = this.state;
    const { pages = [] } = reportsData;
    return (
      <>
        {pages.map((page, pageNum) => {
          if (selectedPageNum === pageNum) {
            return <> {this.renderReportPageData(page, pageNum)}</>;
          } else if (isPreviewClicked) {
            return <> {this.renderReportPageData(page, pageNum)}</>;
          } else return <></>;
        })}
      </>
    );
  };

  setSelectedPageNum = (pageNum) => {
    this.setState({
      selectedPageNum: pageNum,
    });
  };

  addNewPage = (pageIndex) => {
    const { advisorReportsData } = this.state;
    const { pages = [], numPages } = advisorReportsData;
    const newPageData = pages[pageIndex];
    const newPages = [...pages];
    newPages.splice(pageIndex + 1, 0, newPageData);
    this.setState({
      advisorReportsData: {
        ...advisorReportsData,
        pages: newPages,
        numPages: newPages.length,
      },
    });
  };

  deletePage = (pageIndex) => {
    const { advisorReportsData } = this.state;
    const { pages = [], numPages } = advisorReportsData;
    const newPages = [...pages];
    newPages.splice(pageIndex, 1);
    this.setState({
      advisorReportsData: {
        ...advisorReportsData,
        pages: newPages,
        numPages: newPages.length,
      },
    });
  };

  setFooterText = (imgHeight, pageHeight, doc) => {
    let heightLeft = imgHeight;
    let position = 0;
    heightLeft = pageHeight - imgHeight;
    if (heightLeft > 0) {
      position = heightLeft - imgHeight;
      doc.text(
        "Powered by Green'r Financial LLC, (Building Financial Roots).\nCopyright by Financial Roots 2022 © All Rights Reserved.",
        doc.internal.pageSize.width / 2,
        287,
        {
          align: 'center',
          fontSize: '8px',
        }
      );
    }
  };

  setHeader = async (imgHeight, pageHeight, doc) => {
    const header_id = document.querySelector('#report-header');
    if (header_id) {
      await html2canvas(header_id).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg');
        const imgWidth = 190;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        doc.addImage(imgData, 'JPEG', 10, 10, imgWidth, imgHeight, '', 'FAST');
        doc.setFontSize(10);
      });
    }
  };

  showCurrentPageData = (pageIndex) => {
    this.reportsPageRef[pageIndex].classList.remove('vHidden');
  };

  hideOtherPageData = () => {
    // const { selectedPageNum } = this.state;
    // if (selectedPageNum !== pageIndex) {
    //   this.reportsPageRef[pageIndex].classList.add('vHidden');
    // }
    const pages =
      (this.reportsPageRef && this.reportsPageRef.filter((f) => f)) || [];
    for (let i = 0; i < pages.length; ++i) {
      this.reportsPageRef[i].classList.add('vHidden');
    }
    this.showCurrentPageData(0); //showing first page on click of preview cancel
  };

  updateLoaderStatus = (isReportLoading) => {
    this.setState({
      isReportLoading,
    });
  };

  onClickDismiss = (key) => () => {
    const { closeSnackbar } = this.props;
    closeSnackbar(key);
  };

  handleDisplayToast = (msg) => {
    const { enqueueSnackbar } = this.props;
    const toasterMsg = msg;
    this.key = enqueueSnackbar(toasterMsg, {
      variant: 'success',
      autoHideDuration: 5000,
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      action: (key) => <CloseIcon onClick={this.onClickDismiss(key)} />,
    });
  };

  cancelPreview = () => {
    this.hideOtherPageData();
    this.setState({ isPreviewClicked: false, selectedPageNum: 0 });
  };

  generatePDF = async () => {
    const { userDetails } = this.props;
    const { companyName = '', name } = userDetails;
    const { advisorReportsData: reportsData, isPreviewClicked } = this.state;
    if (!isPreviewClicked) {
      window.scrollTo(0, 0);
      const elements = document.querySelectorAll('.vHidden');
      elements.forEach((element) => {
        element.classList.remove('vHidden');
      });
      this.setState({ isPreviewClicked: true });
    }
    if (isPreviewClicked) {
      const { name: reportName, companyId, pages = [] } = reportsData;
      this.handleDisplayToast('Report is set to download');
      this.updateLoaderStatus(true);
      //const pdf = new JsPDF('p', 'mm', 'a4', true);
      const pdf = new JsPDF();
      for (var pageIndex = 0; pageIndex <= pages.length - 1; pageIndex++) {
        const { pageNum, orientation } = pages[pageIndex];
        //this.showCurrentPageData(pageIndex);
        const idSelector = `reports-page-${pageIndex + 1}`;
        const divToPrint = document.querySelector('#' + idSelector);
        if (divToPrint) {
          // Configure options for html2canvas
          let options = {
            // quality: 0.3,
            // scale: 1,
            useCORS: true,
          };
          if (orientation === 'landscape') {
            options = {
              ...options,
              // quality: 0.3,
              width: divToPrint.offsetWidth * 2,
              height: divToPrint.offsetHeight * 2,
            };
          }
          await html2canvas(divToPrint, options).then((canvas) => {
            // const imgData = canvas.toDataURL('image/jpeg,', options.quality); // 0.3 to less quality, 1 to max quality
            const imgData = canvas.toDataURL('image/jpeg', 1);
            const imgWidth = 190;
            const pageHeight = 290;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            if (pdf.internal.getNumberOfPages() === 0) {
              if (orientation === 'portrait') {
                pdf.addPage('portrait');
              } else {
                pdf.addPage('landscape');
              }
            } else if (pageNum !== 1) {
              if (orientation === 'portrait') {
                pdf.addPage('portrait');
              } else {
                pdf.addPage('landscape');
              }
            }
            // pdf.setCompression(true);
            pdf.addImage(
              imgData,
              'JPEG',
              10,
              10,
              imgWidth,
              imgHeight,
              undefined,
              'FAST'
            );
            // pdf.output('dataurl', { quality: 0.95 });
            // pdf.internal.pageSize.width,
            // pdf.internal.pageSize.height,
            pdf.setFontSize(22);
          });
          // window.scrollTo(
          //   0,
          //   document.body.scrollHeight || document.documentElement.scrollHeight
          // );
        }
        //this.hideOtherPageData(pageIndex);
      }
      pdf.save(`Green'r_Report_${companyName}_${name}.pdf`);
      this.updateLoaderStatus(false);
      this.handleDisplayToast('Report is downloaded');
      this.cancelPreview(); //show only one page after download
    }
  };

  getImages = (img) => {
    const { imagesArr, currentIndex, selectedPageNum } = this.state;
    imagesArr[selectedPageNum] = img;
    this.setState({
      imagesArr,
    });
  };

  generateImg = async () => {
    const { advisorReportsData: reportsData } = this.state;
    const { name: reportName, companyId, pages = [] } = reportsData;
    const divToPrint = pages.map((page, index) =>
      document.querySelector(`#reports-page-print}`)
    );
    for (var i = 0; i <= pages.length - 1; i++) {
      await html2canvas(divToPrint[i]).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg');
        this.getImages(imgData);
      });
    }
  };

  onReportChange = (event) => {
    const { planDetailsByPlanId } = this.props;
    const { moduleSettings: { model = {} } = {} } = planDetailsByPlanId;
    const { displayTerm = '' } = model;
    const { triggerGetReportDataByReportId } = this.props;
    const { value: reportId } = event.target;
    this.setState(
      { reportId, selectedPageNum: 0, advisorReportsData: {} },
      () => {
        triggerGetReportDataByReportId({ displayTerm, reportId });
      }
    );
  };

  render() {
    const {
      userDetails,
      selectedClientId,
      selectedPlan,
      isReportDataLoading,
      simulatePlanDetailsLoading,
    } = this.props;
    const {
      advisorReportsData: reportsData,
      isReportLoading = false,
      isPreviewClicked,
      selectedPageNum,
      reportId,
      reports,
    } = this.state;
    const planName = this.getSelectedPlanName();
    const { name: reportName, companyId, pages = [] } = reportsData;
    return (
      <>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            isReportLoading || isReportDataLoading || simulatePlanDetailsLoading
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="main-content-wrapper main-content-wrapper-reports">
          {!isPreviewClicked && (
            <div className="reports_pdf_nav_tab">
              <ul>
                {pages.map((pageData, pageNumber) => (
                  <li
                    className={pageNumber === selectedPageNum ? 'active' : ''}
                    onClick={() => this.setSelectedPageNum(pageNumber)}
                  >
                    <div className="pdf_icon">
                      <img
                        src={pdf_pages_white}
                        className="white"
                        alt=""
                        title=""
                      />
                      <img src={pdf_pages} className="color" alt="" title="" />
                    </div>
                    <div className="hover_icons">
                      <button onClick={() => this.addNewPage(pageNumber)}>
                        <img src={pdf_page_plus_icon} alt="" title="" />
                      </button>
                      <button>
                        <img src={pdf_page_file_icon} alt="" title="" />
                      </button>
                      <button onClick={() => this.deletePage(pageNumber)}>
                        <img src={pdf_page_delete_icon} alt="" title="" />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className={isPreviewClicked ? 'container-fluid main-content-wrapper-inner report-content full-width' : 'container-fluid main-content-wrapper-inner report-content'}>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="reports_top_header mb-3 d-flex justify-content-between align-items-center">
                  <div className="Reports_top_button_div">
                    {!isPreviewClicked && (
                      <ul>
                        <li className="reports_save_button">
                          <button
                            className="btn btn-primary btn-xs"
                            disabled={true}
                          >
                            <img src={save_icon} alt="" title="" /> Save
                          </button>
                        </li>
                        <li className="reports_save_edit_button">
                          <button
                            className="btn btn-primary btn-xs"
                            disabled={true}
                          >
                            <img src={save_edit} width="18px" alt="" title="" />{' '}
                            Save As..
                          </button>
                        </li>
                        <li className="reports_add_button">
                          <button
                            className="btn btn-primary btn-xs"
                            disabled={true}
                          >
                            <img src={add_icon} alt="" title="" /> Report
                          </button>
                        </li>
                        {/* <li className="reports_search_button">
                        <button className="btn btn-primary btn-xs">
                          <img src="images/search_icon.svg" alt="" title="" />{' '}
                          Search
                        </button>
                      </li> */}
                      </ul>
                    )}
                  </div>

                  <div className="report_name_outer">
                    <h3>{reportName}</h3>
                  </div>
                  <div className="reports_top_right_div d-flex">
                    {!isPreviewClicked && (
                      <div className="select_client_outer_form_row report_select_dd">
                        <Controls.Select
                          className="js-example-basic-single"
                          name="selectReports"
                          id="Select Reports"
                          value={reportId}
                          placeholder="Select Reports"
                          onChange={this.onReportChange}
                          options={reports.map((r, i) => {
                            return {
                              value: r.reportId,
                              label: r.reportName,
                            };
                          })}
                        />
                      </div>
                    )}
                    {/* <div className="reports_zoom_in_out">&nbsp;</div> */}
                    {/* {!isPreviewClicked && (
                      <div className="reports_top_right_div_file_outer">
                        <button>
                          <img src={file_1} alt="" title="" />
                        </button>
                        <button>
                          <img src={file_2} alt="" title="" />
                        </button>
                      </div>
                    )} */}
                    <div className="reports_generate_pdf">
                      {isPreviewClicked && (
                        <button
                          style={{ marginRight: '5px' }}
                          className="btn btn-secondary btn-sm"
                          onClick={this.cancelPreview}
                        >
                          Cancel
                        </button>
                      )}
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={this.generatePDF}
                      >
                        {isPreviewClicked ? 'Generate PDF' : 'Preview PDF'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-12 col-lg-12 col-md-12"
                id="static_Template"
              >
                <div id="reports_pdf_id" className="reports_pdf_outer">
                  {this.renderReportDataByType()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: getLoggedInUserDetails(state),
    reportsData: getReportsInitialDataSelector(state),
    isReportDataLoading: getReportDataLoadingSelector(state),
    selectedPlan: selectedPlanIdForClientIdSelector(state),
    selectedClientId: selectedClientIdSelector(state),
    clientPlans: getPlansByClientIdSelector(state),
    planDetailsByPlanId: planDetailsByPlanIdSelector(state),
    charts: outputResponseChartsByPlanIdSelector(state) || {},
    tables: outputResponseTablesByPlanIdSelector(state) || [],
    data: outputResponseDataByPlanIdSelector(state) || [],
    notes: outputResponseNotesByPlanIdSelector(state) || [],
    clientsNotUpdated: state.clientInfoReducer.clientsNotUpdated || [],
    outputResponse: outputResponseDetailsSelector(state) || {},
    simulatePlanDetailsLoading: simulatePlanDetailsLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    triggerGetReportDataByReportId: (reportId) =>
      dispatch(getSelectedReportDataByReportId(reportId)),
    triggerSimulatePlanDetailsByPlanId: (payload) =>
      dispatch(simulatePlanDetailsByPlanId(payload)),
    getSavedSelectedPlanIdDetails: (payload) =>
      dispatch(getPlansDetailsAction(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ReportTemplate));
