import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import Alert from '@mui/material/Alert';
import Controls from '../../../components/controls/Controls';
import {
  transformRothChoicesBackToUIType,
  transformEmergencyFundsBackToUIType,
} from '../../../helpers/BuildHelperComponentV3';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const BuildIncomeWithdrawalChoicesComponent = forwardRef(
  (props, ref) => {
    const { localPlanDetailsByPlanId } = props;

    const booleanOptions = [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ];

    const { moduleEstate: { rothChoices = {} } = {} } =
      localPlanDetailsByPlanId;

    const { moduleSettings: { emergencyFunds = {} } = {} } =
      localPlanDetailsByPlanId;

    const [withdrawalChoiceState, setWithdrawalChoiceState] = useState({
      withdrawRothFirst: '',
      isDivFolioEmFunds: '',
      deficitAsPercentOfExpForPropLiquidation: '',
    });

    useEffect(() => {
      let { deficitAsPercentOfExpForPropLiquidation, isDivFolioEmFunds } =
        transformEmergencyFundsBackToUIType(emergencyFunds);
      let { withdrawRothFirst } = transformRothChoicesBackToUIType(rothChoices);
      const tempWithDrawalChoiceState = {
        ...withdrawalChoiceState,
        withdrawRothFirst,
        isDivFolioEmFunds,
        deficitAsPercentOfExpForPropLiquidation,
      };
      setWithdrawalChoiceState(tempWithDrawalChoiceState);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localPlanDetailsByPlanId]);

    const withDrawRothFirst = [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ];

    //   useEffect(() => {
    //     if (rothChoices && Object.keys(rothChoices).length > 0) {
    //       let tempRothChoices = transformRothChoicesBackToUIType(rothChoices);
    //       setRothChoicesDetails(tempRothChoices);
    //     }
    //   }, [localPlanDetailsByPlanId, rothChoices]);

    const handleSelectChange = (event, name) => {
      const { value } = event.target;
      const newWithdrawalState = { ...withdrawalChoiceState, [name]: value };
      setWithdrawalChoiceState(newWithdrawalState);
    };

    useImperativeHandle(ref, () => ({
      getWithdrawalChoicesState() {
        const { deficitAsPercentOfExpForPropLiquidation: value } =
          withdrawalChoiceState;
        let valueToApi = '';
        const percentValue = value.split('%')[0];
        if (!isNaN(percentValue)) {
          valueToApi = parseFloat(parseFloat(percentValue) / 100);
        }
        return {
          ...withdrawalChoiceState,
          deficitAsPercentOfExpForPropLiquidation: valueToApi,
        };
      },
    }));

    const renderSelectOption = (type, idName, name, label, options) => {
      return (
        <div className="row align-items-center" key={idName}>
          <div className={`col-lg-9 col-sm-8 col-6 insured_content_outer`}>
            <p>{label}</p>
          </div>
          <div className={`col-lg-3 col-sm-4 col-6 insured_radio_group`}>
            <Controls.Select
              name={idName}
              value={withdrawalChoiceState[name]}
              options={options.map((c) => {
                return {
                  value: c.value,
                  label: c.label,
                };
              })}
              onChange={(event, index) => handleSelectChange(event, name)}
            />
          </div>
        </div>
      );
    };

    const handleFormFieldChange = (event, valueType = 'percentage') => {
      let fieldValue = event.target.value;
      if (valueType === 'percentage') {
        if (fieldValue.toString().includes('%')) {
          let percentFieldValue = fieldValue.split('%')[0];
          if (percentFieldValue !== '' && !isNaN(percentFieldValue)) {
            fieldValue = percentFieldValue;
          }
        }
      }
      let tempModelObj = {
        ...withdrawalChoiceState,
        deficitAsPercentOfExpForPropLiquidation: fieldValue,
      };
      setWithdrawalChoiceState(tempModelObj);
    };

    const handleBlur = (event, valueType = 'percentage') => {
      let { value } = event && event.target;
      if (valueType === 'percentage') {
        let percentFieldValue = '';
        if (value !== null && value !== undefined && value !== '') {
          if (value.toString().includes('%')) {
            percentFieldValue = value.split('%')[0];
            if (
              percentFieldValue !== '' &&
              !isNaN(percentFieldValue) &&
              Number(percentFieldValue) >= 0
            ) {
              percentFieldValue = parseFloat(percentFieldValue).toFixed(2);
              value = `${percentFieldValue}%`;
            } else {
              value = `${0}%`;
            }
          } else if (!isNaN(value) && Number(value) >= 0) {
            percentFieldValue = parseFloat(value).toFixed(2);
            value = `${percentFieldValue}%`;
          } else {
            value = `${0}%`;
          }
        } else {
          value = `${0}%`;
        }
      }

      // let valueToApi = '';
      // if (type === 'input') {
      //   if (value !== null && value !== undefined && value !== '') {
      //     if (isNaN(value) && value.includes('%')) {
      //       const percentValue = value.split('%')[0];
      //       if (!isNaN(percentValue)) {
      //         valueToApi = parseFloat(parseFloat(percentValue) / 100);
      //       } else {
      //         valueToApi = 0;
      //       }
      //     } else if (isNaN(value) && value.includes('$')) {
      //       let dollarValue = value.split('$')[1];
      //       dollarValue = dollarValue.replace(/,/g, '');
      //       if (!isNaN(dollarValue)) {
      //         valueToApi = Number(dollarValue);
      //       } else {
      //         valueToApi = 0;
      //       }
      //     } else if (!isNaN(value)) {
      //       valueToApi = Number(value);
      //     }
      //   } else {
      //     valueToApi = 0;
      //   }
      // }
      const tempWithDrawalChoiceState = {
        ...withdrawalChoiceState,
        deficitAsPercentOfExpForPropLiquidation: value,
      };
      setWithdrawalChoiceState(tempWithDrawalChoiceState);
    };

    const renderInputRow = (label, name, validationMessage) => {
      return (
        <div className="row align-items-center">
          <div className={`col-lg-9 col-sm-8 col-6 insured_content_outer`}>
            <p>{label}</p>
          </div>
          <div className={`col-lg-3 col-sm-4 col-6 insured_radio_group`}>
            <input
              type="text"
              id="tlDeficitAsPercentOfExpForPropLiquidation"
              name={name}
              value={withdrawalChoiceState[name]}
              className={'custom-input'} // Apply the appropriate class
              onChange={(event) => handleFormFieldChange(event)}
              onBlur={(event) => handleBlur(event)}
              placeholder=""
            />
          </div>
        </div>
      );
    };

    return (
      <>
        <div className="insured_main_outer">
          <div className="row align-items-center">
            <div className="col-lg-9 col-sm-9 col-8 insured_content_outer">
              <p>
                <strong>Family Retirement Fund Withdrawals</strong>
              </p>
            </div>
          </div>
          {renderSelectOption(
            'text',
            'ddlMemOneWithdrawRothFirst',
            'withdrawRothFirst',
            'When withdrawing funds from Retirement accounts, would you like to withdraw first from ROTH?',
            withDrawRothFirst
          )}
          {renderSelectOption(
            'text',
            'ddlIsDivFolioEmFunds',
            'isDivFolioEmFunds',
            'Consider dividend portfolio assets for drawdown after liquidating properties?',
            booleanOptions
          )}
          {renderInputRow(
            'Liquidate property when deficit exceeds this percent of expenses',
            'deficitAsPercentOfExpForPropLiquidation'
          )}
          <div class="row align-items-center">
            <div className="col-12">
              <Alert severity="info">
                Funds may be withdrawn from Retirement accounts for budgetary
                purposes in addition to RMD from traditional accounts.
              </Alert>
            </div>
          </div>

          <div className="tab_main_nav">
            <div className="tab_left_nav">
              <button>
                <ArrowBackIcon /> Previous
              </button>
            </div>
            <div className="tab_right_nav">
              <button>
                Next <ArrowForwardIcon />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default BuildIncomeWithdrawalChoicesComponent;
