import React, { useEffect } from 'react';
import {
  renderFormInputRow,
  renderFooterSubmitCancelButtons,
  renderFormInputRowName,
  renderSelectAccountDropdown,
  renderFormDateField,
  DeleteConfirmation,
  renderFormSelectOption,
} from '../../helpers/ClientHelper';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook

export const VehiclesContent = (props) => {
  const {
    state,
    concatContent,
    allMenuItems,
    setLastStepInParent,
    isFormValid,
    formErrors,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleFamily',
    mainTab: 'infoVehicles',
    subTab: 'vehicles',
    countVal: 'numVehicles',
  };

  const { formDetails, isEditable, isAddNew } = state;

  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    nameValidationError,
    setNameValidationError,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  return (
    <>
      <div className="Select_account_main_outer" style={{ marginBottom: 10 }}>
        {renderSelectAccountDropdown(
          finalExistingItems,
          defaultName,
          openDeleteConfirmation,
          props,
          state,
          moduleObject
        )}
      </div>
      {(isAddNew || isEditable) && (
        <div className="insured_main_outer">
          {renderFormInputRowName(
            'text',
            'tlName',
            'name',
            'Vehicle Name',
            'text',
            state,
            moduleObject,
            props,
            finalExistingItems,
            true, //isMandatory field
            formErrors,
            isAddNew
          )}
        </div>
      )}
      {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && (
        <div className="insured_main_outer">
          {renderFormInputRow(
            'text',
            'tlPurchasePrice',
            'purchasePrice',
            'Purchase price',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormDateField(
            'purchaseDate',
            'Purchase Date',
            'text',
            moduleObject,
            state,
            props,
            'date',
            false,
            false,
            formErrors
          )}
          {renderFormInputRow(
            'text',
            'tlLoanAmount',
            'loanAmount',
            'Loan amount',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlBalance',
            'balance',
            'Current balance',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlApr',
            'apr',
            'Annual percentage rate(APR)',
            'percentage',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlTermMonths',
            'termMonths',
            'Loan term months',
            'number',
            moduleObject,
            state,
            props
          )}
          {renderFormDateField(
            'interestStartDate',
            'Date when interest starts accruing',
            'text',
            moduleObject,
            state,
            props,
            'date',
            false,
            false,
            formErrors
          )}
          {renderFormDateField(
            'paymentStartDate',
            'Date when first payment is due',
            'text',
            moduleObject,
            state,
            props,
            'date',
            false,
            false,
            formErrors
          )}
          {isAddNew || isEditable ? (
            <>
              {renderFormSelectOption(
                'text',
                'tlCompounding',
                'compounding',
                'Interest will compound',
                allMenuItems.compoundingMenuItems,
                moduleObject,
                state,
                props
              )}
            </>
          ) : (
            <>
              {renderFormInputRow(
                'text',
                'tlCompounding',
                'compounding',
                'Interest will compound',
                'textDropdown',
                moduleObject,
                state,
                props
              )}
            </>
          )}
          {renderFormInputRow(
            'text',
            'tlMonthlyPayment',
            'monthlyPayment',
            'Monthly payment (will be computed if left as zero)',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlCurrenntValue',
            'value',
            'current value of vehicle',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlDeprYrs',
            'deprYrs',
            'Number of years you want the value to be depreciated to zero',
            'number',
            moduleObject,
            state,
            props
          )}
          {renderFormInputRow(
            'text',
            'tlAMC',
            'maintenanceExp',
            'Annual maintenance expense \n (do not include insurance)',
            'currency',
            moduleObject,
            state,
            props
          )}
          {renderFooterSubmitCancelButtons(
            state,
            moduleObject,
            props,
            finalExistingItems,
            isFormValid
          )}
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      {DeleteConfirmation(
        props,
        isDeleteConfirmationOpen,
        closeDeleteConfirmation,
        handleDeleteConfirmation,
        nameToDelete,
        moduleObject
      )}
    </>
  );
};

export default VehiclesContent;
