import React, { useState, useEffect } from 'react';
import Controls from './controls/Controls';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { ltcInsuranceCols } from '../constants/dashboard';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material'; // Import dialog components
import '../css/InsuranceContent.css';
import edit_pencil from '../images/edit_pencil.svg';
import delete_icon from '../images/delete_icon.svg';
import pdf_page_plus_icon from '../images/pdf_page_plus_icon.svg';

export const LongTermCareContent = (props) => {
  const {
    longTermCareInsuranceProducts,
    state,
    concatInsurances,
    handleFormFieldChangeInsurance,
    onAccountChange,
    setSelectionModel,
    rowClick,
    handleEditInsurance,
    handleAddToPortfolio,
    transformValue,
    handleDeleteInsurance,
    handleCancelInsurance,
    setLastStepInParent,
  } = props;

  const {
    memberOptions,
    formDetails,
    selectionModel,
    isEditable,
    isAddNew,
    isFormValid,
    formErrors,
  } = state;

  // State variable to hold the default title
  const [ltTitle, setltTitle] = useState('');
  const finalExistingInsurances = concatInsurances('ltcIns');
  const defaultName =
    (formDetails && formDetails['ltcIns'] && formDetails['ltcIns'].name) || '';

  // State variable to track the name field validation error
  const [nameValidationError, setNameValidationError] = useState('');
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [nameToDelete, setNameToDelete] = useState('');

  const openDeleteConfirmation = (name) => {
    setIsDeleteConfirmationOpen(true);
    setNameToDelete(name);
  };

  // Function to close the confirmation dialog
  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  // Function to handle item deletion after confirmation
  const handleDeleteConfirmation = () => {
    closeDeleteConfirmation();
    // Perform the delete action here
    handleDeleteInsurance('ltcIns', 'longTermCare', 'numLtcIns', nameToDelete);
  };

  const handleSubmit = () => {
    // if (validateNameField()) {
    // Name field is valid, proceed with submission
    handleAddToPortfolio('ltcIns', 'longTermCare');
    //}
  };

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  useEffect(() => {
    // Set the default value of ltTitle on page load
    const defaultSelectedAccountInfo = finalExistingInsurances.find(
      (account) => account.id === defaultName
    );
    if (defaultSelectedAccountInfo) {
      setltTitle(defaultSelectedAccountInfo.title);
    }
  }, [defaultName, finalExistingInsurances]);

  const renderRadioGroup = () => (
    <div className="col-lg-4 insured_radio_group">
      <Controls.RadioGroup
        name="housing-expense-radio-buttons"
        value={
          (formDetails &&
            formDetails['ltcIns'] &&
            formDetails['ltcIns'].insured) ||
          false
        }
        rowType={true}
        onChange={(event) =>
          handleFormFieldChangeInsurance(event, 'insured', 'radio', 'ltcIns')
        }
        items={memberOptions}
        disabled={state.isEditable}
      ></Controls.RadioGroup>
    </div>
  );

  const renderInputRow = (
    type,
    idName,
    name,
    label,
    valueType,
    isDisabled = false,
    isMandatory = false,
    formErrors = []
  ) => {
    const isFieldInValid = formErrors.filter(
      (v) => v.apiFieldName.toLowerCase() === name.toLowerCase() && !v.isValid
    )[0];
    // const isNameField = name === 'name';
    return (
      <div className="row align-items-center">
        <div className="col-lg-8 insured_content_outer labelStyle">
          <p className={`${isMandatory ? 'required' : ''}`}>{label}</p>
        </div>
        <div className="col-lg-3 insured_radio_group">
          <input
            type={type}
            id={idName}
            name={idName}
            value={transformValue(formDetails['ltcIns'][name], valueType)}
            className={isDisabled ? 'disabled-input' : 'custom-input'}
            disabled={isDisabled}
            onChange={(event) =>
              handleFormFieldChangeInsurance(event, name, 'input', 'ltcIns')
            }
            placeholder=""
          />
          {/* {isNameField && (
            <span className="validation-error">{nameValidationError}</span>
          )} */}
        </div>
        {isFieldInValid && (
          <p className="error-text">{isFieldInValid.validationMessage}</p>
        )}
      </div>
    );
  };

  // // Function to validate the name field
  // const validateNameField = () => {
  //   if (!formDetails['ltcIns'] || !formDetails['ltcIns'].name) {
  //     setNameValidationError('Name is required.');
  //     return false;
  //   }

  //   const enteredName = formDetails['ltcIns'].name.toLowerCase();

  //   if (isAddNew) {
  //     // Check for duplicate name only when adding a new item
  //     const isDuplicateName = finalExistingInsurances.some(
  //       (ins) => ins.title.toLowerCase() === enteredName
  //     );

  //     if (isDuplicateName) {
  //       setNameValidationError('Name must be unique');
  //       return false;
  //     }
  //   }

  //   setNameValidationError('');
  //   return true;
  // };

  const renderChooseProduct = () => (
    <div
      className="choose_a_product_main_outer"
      style={{ display: isEditable ? 'block' : 'none' }}
    >
      <h5>Choose a product from below list:</h5>
      <div className="choose_a_product_table">
        <Box
          sx={{
            height: 150,
            width: '100%',
            backgroundColor: '#eff9f6',
            border: 'none',
          }}
        >
          <DataGrid
            rows={
              longTermCareInsuranceProducts &&
              longTermCareInsuranceProducts.length > 0
                ? longTermCareInsuranceProducts.map((m) => ({
                    ...m,
                    premium: m.premium && transformValue(m.premium, 'currency'),
                    coverage:
                      m.coverage && transformValue(m.coverage, 'currency'),
                  }))
                : []
            }
            autoHeight
            rowHeight={25}
            headerHeight={40}
            columns={ltcInsuranceCols}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel, 'ltcIns');
            }}
            selectionModel={
              selectionModel['ltcIns'] ? selectionModel['ltcIns'] : []
            }
            pageSize={10}
            onRowClick={(params, event, details) =>
              rowClick(params, event, details, 'ltcIns')
            }
            rowsPerPageOptions={[10]}
            className="data-grid"
            disableColumnMenu={true}
            hideFooter={true}
          />
        </Box>
      </div>
    </div>
  );

  const renderSelectAccountDropdown = () => (
    <div className="row align-items-center">
      <div className="col-lg-9 col-md-9 col-8">
        <div className='controls-select-main-outer'>
          <h5 className="account_dropdown_label">Select Policy</h5>
          <Controls.Select
            className="js-example-basic-single"
            name="Accountwl"
            id="Accountwl" 
            value={defaultName}
            onChange={(e) =>
              onAccountChange(e, finalExistingInsurances, 'ltcIns')
            }
            style={{ display: !state.isEditable ? 'block' : 'none' }}
            options={finalExistingInsurances.map((c) => {
              return {
                value: c.id,
                label: c.title,
              };
            })}
          />
        </div>
      </div>
      <div
        className="col-lg-3 col-md-3 col-3 button-group"
        style={{
          display: !isEditable ? 'block' : 'none',
          width: 'max-content',
        }}
      >
        {finalExistingInsurances && finalExistingInsurances.length > 0 && (
          <button
            className="action-button"
            onClick={(e) => {
              setNameValidationError('');
              handleEditInsurance(e, state.solutionsSubTab);
            }}
          >
            <img src={edit_pencil} alt="" title="" />
          </button>
        )}
        {finalExistingInsurances && finalExistingInsurances.length > 0 && (
          <button
            className="action-button"
            onClick={(e) => openDeleteConfirmation(defaultName)}
          >
            <img src={delete_icon} alt="" title="" />
          </button>
        )}
        <button
          className="action-button"
          onClick={(event) => {
            setNameValidationError('');
            handleEditInsurance(event, state.solutionsSubTab, true);
            handleFormFieldChangeInsurance(
              event,
              'insured',
              'input',
              'ltcIns',
              true
            );
          }}
        >
          <img src={pdf_page_plus_icon} alt="" title="" />
        </button>
      </div>
    </div>
  );

  return (
    <>
      <div className="insured_main_outer">
        {!isAddNew && !isEditable ? (
          <div className="row">
            <div className="col-lg-8 insured_content_outer">
              <p className={'radio-group-label required'}>Insured person</p>
            </div>
            {renderRadioGroup()}
          </div>
        ) : (
          <>
            {renderInputRow(
              'text',
              'tlInsured',
              'insured',
              'Insured person',
              'textRadioButton',
              isEditable,
              true, //isMandatory
              formErrors
            )}
          </>
        )}
      </div>
      {!isEditable && (
        <div className="Select_account_main_outer">
          {renderSelectAccountDropdown()}
        </div>
      )}
      <div
        className="insured_main_outer"
        style={{ display: isEditable && !isAddNew ? 'block' : 'none' }}
      >
        {renderInputRow(
          'text',
          'ltName',
          'name',
          'Short name of policy',
          'text',
          isEditable,
          true //isMandatory
        )}
      </div>
      {renderChooseProduct()}
      <div className="insured_main_outer">
        {renderInputRow(
          'text',
          'ltCoverage',
          'cashValueAnnual',
          'How much annual benefit do you need?',
          'currency',
          !isEditable,
          true, //isMandatory
          formErrors
        )}
      </div>
      <div className="insured_main_outer">
        {renderInputRow(
          'text',
          'ltCashValueDistributionYrs',
          'cashValueDistributionYrs',
          'How many years of benefit do you need?',
          'number',
          !isEditable,
          true, //isMandatory
          formErrors
        )}
        {renderInputRow(
          'text',
          'ltPremium',
          'premium',
          'Update premium',
          'currency',
          !isEditable,
          true, //isMandatory
          formErrors
        )}
      </div>

      <div className="insured_main_outer" style={{ marginBottom: 20 }}>
        <div className="row align-items-center">
          <div className="col-lg-8 insured_content_outer">
            <p>When would you like to start coverage?</p>
          </div>
          <div className="col-lg-3 insured_radio_group">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                views={['year']}
                className="planner-input long-text-input"
                autoOk
                variant="inline"
                InputAdornmentProps={{ position: 'start' }}
                value={
                  formDetails &&
                  formDetails['ltcIns'] &&
                  formDetails['ltcIns'].coverageStartYr
                }
                disabled={!isEditable}
                onChange={(event) =>
                  handleFormFieldChangeInsurance(
                    event,
                    'coverageStartYr',
                    'datepicker',
                    'ltcIns'
                  )
                }
                inputProps={{ style: { height: 'auto' } }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>
      {isAddNew && (
        <div className="insured_main_outer">
          {renderInputRow(
            'text',
            'ltAddName',
            'name',
            'Short name of policy',
            'text',
            false, //isDisabled
            true, //isMandatory
            formErrors
          )}
        </div>
      )}

      {isEditable ? (
        <div className="Simulate_button_group">
          <button
            className={
              !(isEditable || isAddNew)
                ? 'common-button secondary-button'
                : 'common-button'
            }
            onClick={(event) => {
              handleCancelInsurance('ltcIns', 'insured');
            }}
            disabled={!(isEditable || isAddNew)}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className={
              !isFormValid || !(isEditable || isAddNew)
                ? 'common-button secondary-button'
                : 'common-button'
            }
            disabled={!isFormValid || !(isEditable || isAddNew)}
          >
            Update
          </button>
        </div>
      ) : (
        <></>
      )}
      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmation}
        className="delete_popup_outer"
      >
        <DialogTitle>
          Are you sure you want to delete "{nameToDelete}"?
        </DialogTitle>
        <DialogActions>
          <button onClick={closeDeleteConfirmation} className="common-button">
            Cancel
          </button>
          <button onClick={handleDeleteConfirmation} className="common-button">
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LongTermCareContent;
