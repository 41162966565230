import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';
import { getLoggedInUserDetails } from '../selectors/loginUserSelector';
import BarChartIcon from '@mui/icons-material/BarChart';
import { PropertyNamesMap } from '../constants/PropertyNames';
import { EXPENSE_DISC_FIELDS } from '../constants/common';
import DescriptionIcon from '@mui/icons-material/Description';
import GoalsContainer from './GoalsContainer';
import {
  plannerDetailsLoadingSelector,
  plannerDetailsSaveStatus,
  planDetailsByPlanIdSelector,
  expenseFactorDetailsSelector,
  plannerDetailsSimulateStatus,
  termLifeInsuranceProductsSelector,
  wholeLifeInsuranceProductsSelector,
  longTermCareInsuranceProductsSelector,
  umbrellaInsuranceProductsSelector,
  propertyInsuranceProductsSelector,
  autoInsuranceProductsSelector,
  healthInsurnceProductSelector,
  selectedDisplayTermForClientIdSelector,
  selectedHorizonTermForClientIdSelector,
  selectedPlanIdForClientIdSelector,
  simulatePlanDetailsLoadingSelector,
  getPushPinCharts,
  selectedChartSelector,
  beforeAfterAssetMixValuesSelector,
  assetAllocationByProductIdLoadingSelector,
  getAssetAllocationByProductIdSelector,
  getRecommendedAssetAllocationByClientIdSelector,
  getInvestmentProductsByClientIdSelector,
} from '../selectors/plannerDetailsSelector';
import {
  getAssetAllocListByClientIdAction,
  setAssetAllocListByClientIdAction,
} from '../redux/actions/clientsInfoAction';
import { selectedClientIdSelector } from '../selectors/clientsInfoSelector';
import {
  outputResponseLoadingSelector,
  outputResponseChartsByPlanIdSelector,
  outputResponseTablesByPlanIdSelector,
  outputResponseDataByPlanIdSelector,
  outputResponseSolutionsByPlanIdSelector,
  outputResponsePlannerDetailsByPlanIdSelector,
} from '../selectors/outputResponseDetailsSelector';
import {
  setSelectedChartAction,
  setSelectedTableAction,
  setSelectedDataAction,
  pushPinCharts,
} from '../redux/actions/plansAction';
import {
  newSolutionsList,
  assetMixListValues,
  investmentFields,
  assetMixValuesTemplate,
  assetMixList,
  investmentCustomFields,
  newAccountDetailsToBeAdded,
} from '../constants/dashboard';
import HorizonDetails from './shared/HorizonDetails';
import TabsComponent from './shared/TabsComponent';
import { isEqual } from 'lodash';
import { Grid, FormControlLabel, FormGroup, Tooltip } from '@material-ui/core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SolutionsJourney from './SolutionsJourney';
import {
  simulatePlanDetailsByPlanIdSuccessAction,
  termLifeInsuranceProductsSuccessAction,
  wholeLifeInsuranceProductsSuccessAction,
  longTermCareInsuranceProductsSuccessAction,
  umbrellaInsuranceProductsSuccessAction,
  propertyInsuranceProductsSuccessAction,
  autoInsuranceProductsSuccessAction,
  healthInsuranceProductsSuccessAction,
  simulatePlanDetailsByPlanId,
  getPlansDetailsAction,
  setBeforeAfterAssetMixAction,
  saveAndUpdatePlanDetailsByPlanId,
  getAssetAllocationByProductId,
  getRecommendedAssetAllocListByClientId,
  getInvestmentProductsByClientIdAction,
} from '../redux/actions/plannerDetailsAction';
import { getSolutionsExpenseStoreValuesV2 } from '../redux/transformers/apiToClientResponseTransformer';
import CloseIcon from '@mui/icons-material/Close';
import NewChartsContainer from './NewChartsContainer';
import Controls from './controls/Controls';
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import {
  getWholeLifeInsuranceProductsByClientId,
  getTermLifeInsuranceProductsByClientId,
  getUmbrellaInsuranceProductsByClientId,
  getLongTermCareInsuranceProductsByClientId,
  getPropertyInsuranceProductsByClientId,
  getAutoInsuranceProductsByClientId,
  getInsHealthProductsByClientId,
  getTickerDetails,
} from '../services/dashboardSvc';
import history from '../history';
import Simulate_icon_1 from '../images/Simulate_icon_1.svg';
import WholeLifeContent from './WholeLifeContent';
import { LongTermCareContent } from './LongTermCareContent';
import { UmbrellaLifeContent } from './UmbrellaContent';
import { TermLifeContent } from './TermLifeContent';
import { PropertyContent } from './PropertyComponent';
import { AutoContent } from './AutoComponent';
import { HealthInsuranceComponent } from './HealthInsuranceComponent';
import CustomChart from './shared/CustomChartComponent';
import CustomTable from './shared/CustomTableComponent';
import CustomData from './shared/CustomDataComponent';
import { generateTableHTML } from '../utils/renderHtmlUtil';
import Simulate_icon_2 from '../images/Simulate_icon_2.svg';
import Save_icon from '../images/save.svg';
import delete_icon from '../images/delete_icon.svg';
import edit_pencil from '../images/edit_pencil.svg';
import pdf_page_plus_icon from '../images/pdf_page_plus_icon.svg';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {
  validateFormDetails,
  transformBackToAPIType,
} from '../helpers/ClientHelper';
import { Checkbox } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { validateNameField } from '../utils/validators';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Investments_Icon from '../images/Investments_Icon.svg';
import Expenses_icon from '../images/Expenses_icon.svg';
import Goals_Icon from '../images/Goals_Icon.svg';
import Savings_icon from '../images/Savings_icon.svg';
import Insurance_Icon from '../images/Insurance_Icon.svg';
import Annuities_Icon from '../images/Annuities_Icon.svg';
import Debt_Icon from '../images/Debt_Icon.svg';

const _ = require('lodash');

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const radioOptions = [
  { id: true, title: 'Yes' },
  { id: false, title: 'No' },
];

class NewSolutions extends Component {
  state = {
    showLeftNav: false,
    showRightNav: true,
    parentTabValue: 0,
    subTabValue: 0,
    solutionsTabs: [],
    chartsMenu: [],
    tabInfo: [],
    selectedAccount: '',
    selectedMember: '',
    selectedPropertyMember: '',
    selectedAutoMember: '',
    investmentTypeName: '',
    memberOptions: [],
    memberPropertyRadioOptions: [],
    memberAutoRadioOptions: [],
    existingInsurances: [],
    selectionModel: {},
    pushPinLabel: 'Pin it',
    isComputed: false,
    renderType: 'Custom',
    selectedAllocationInfo: {},
    selectedRecommendedAssetAllocId: '',
    localPlanDetailsByPlanId: {},
    selectedMemberInvestmentTypeInfo: {},
    assetMixBuildInfo: [],
    targetAssetMixBuildInfo: [],
    selectedProductType: '',
    tickers: [],
    selectedTicker: '',
    tickersAddedToPlan: [],
    tickersAvailableInPlan: [],
    investmentAccounts: [],
    formDetails: {
      termLifeIns: { insured: '', name: '', coverageStartYr: new Date() },
      wholeLifeIns: { insured: '', name: '', coverageStartYr: new Date() },
      ltcIns: { insured: '', name: '', coverageStartYr: new Date() },
      umbrellaIns: {
        insured: 'family',
        name: '',
        coverageStartYr: new Date(),
      },
      autoIns: {
        insured: '',
        name: '',
        coverageStartYr: new Date(),
      },
      propIns: {
        insured: '',
        name: '',
        coverageStartYr: new Date(),
      },
      healthIns: {
        insured: '',
        name: '',
        coverageStartYr: new Date(),
      },
    },
    isEditable: false,
    isAddNew: false,
    deleteInsurance: false,
    isLastStep: false,
    isProductsAlterted: false,
    isSimulateOnInvestmentsClicked: false,
    isMC: false,
    noChangeLoader: false,
    currentIndex: 0,
    selectedDDNameIndex: 0,
    isFormValid: true,
    formErrors: [],
    availableInvestmentAccounts: [],
    selectedInvestmentAccount: {},
    selectedInvestmentBucket: '',
    oldInvestmentBucket: '',
    selectedMemberOption: '',
    isOptimizedClicked: false,
    isRiskLevelAdaptive: false,
    isProductsSelected: true,
    isCustomSelected: true,
    isGetMarketDataSelected: false,
    localProdInfo: [],
    assetMixListForInv: [...assetMixList],
    invCustomFields: [...investmentCustomFields],
    isAssetMixPercentNotEqualTo100: false,
    isSimulateClicked: false, //used in goals to clear out the selected goal which is used to grey out the goal until clicked on simulate
    isUpdateClicked: false, // this is set to true on click of update in Investments portfolio
    buttonDisabledMsg: '',
    isButtonDisabled: true,
    subTabs: ['Risk Level', 'Portfolio'], // default Tabs
    mainNavItems: [
      {
        icon: Investments_Icon,
        title: 'Investments',
        parent: 'investments',
        children: ['Risk Level', 'Portfolio'], // Example children
      },
      {
        icon: Expenses_icon,
        title: 'Expenses',
        parent: 'expenses',
        children: ['Lifetime', 'Horizon', 'Retirement', 'Discretionary'], // Example children
        // children: ['Affordability', 'Planning Horizon', 'Retirement'], // Example children
      },
      {
        icon: Goals_Icon,
        title: 'Goals',
        parent: 'goals',
        // children: [
        //   "Retirement",
        //   "Kids",
        //   "Primary Home",
        //   "Second Homes",
        //   "Investment Properties",
        //   "Vehicles",
        //   "Vacations",
        // ],
        children: ['Manage'],
      },
      // {
      //   icon: family_icon,
      //   title: "Savings",
      //   parent: "Savings",
      //   children: ["Vehicles", "Retirement"], // Example children
      // },
      {
        icon: Savings_icon,
        title: 'Insurance',
        parent: 'insurance',
        children: [
          'Term Life',
          'Whole Life',
          'Long Term Care',
          'Umbrella',
          'Property',
          'Vehicle',
          'Health',
        ], // Example children
      },
      {
        icon: Annuities_Icon,
        title: 'Annuities',
        parent: 'annuities',
        children: ['Fixed', 'Index', 'Variable', 'Immediate', 'Longevity'], // Example children
      },
      {
        icon: Debt_Icon,
        title: 'Debt',
        parent: 'debt',
        children: ['Mortgage', 'CC', 'Loan'], // Example children
      },
    ],
    activeMainTab: 'investments',
    activeSubTab: '',
    leftParentTabValue: 0,
    expandedPanel: '',
    leftSubTabValue: 0,
    rightParentTabValue: 0,
    rightSubTabValue: 0,
    isReduxUpdated: false,
    snackbarShown: false,
    lastStepInExpenses: false,
  };

  handleMainNavClick = (tab) => {
    this.setState({
      activeMainTab: tab,
      activeSubTab: '',
      isComputed: false,
    });
  };
  handleSubNavClick = (subTab) => {
    this.setState({
      activeSubTab: subTab,
      isComputed: false,
    });
  };

  handleNext = () => {
    const { activeMainTab, activeSubTab, subTabs } = this.state;
    // Calculate the last sub-tab index for the current main tab
    const lastSubTabIndex = subTabs.length - 1;
    // calculate the current sub-tab index
    const currenctSubTabIndex = subTabs.indexOf(activeSubTab);
    // If we're at the last sub-tab, go back to the main navigation
    if (activeSubTab === subTabs[lastSubTabIndex]) {
      this.setState({
        activeSubTab: '',
        isLastStep: false,
        isComputed: false,
      });
    } else {
      // Move to the next sub-tab
      this.setState({
        activeSubTab: subTabs[currenctSubTabIndex + 1],
        isComputed: false,
      });
    }
  };

  handlePrevious = () => {
    const { activeMainTab, activeSubTab, subTabs } = this.state;
    // calculate the current sub-tab index
    const currenctSubTabIndex = subTabs.indexOf(activeSubTab);
    // If we're at the first sub-tab, go back to main navigation
    if (currenctSubTabIndex === 0) {
      this.setState({
        activeSubTab: '',
        isLastStep: false,
        isComputed: false,
      });
    } else {
      // Move to the previous sub-tab
      this.setState({
        activeSubTab: subTabs[currenctSubTabIndex - 1],
        isComputed: false,
      });
    }
  };

  showNextItem = () => {
    this.setState({
      showLeftNav: true,
      showRightNav: false,
    });
    const { currentIndex } = this.state;
    if (currentIndex < 1) {
      this.setState({ currentIndex: currentIndex + 1 }, () => {
        this.scrollToIndex(this.state.currentIndex);
      });
    }
  };

  showPreviousItem = () => {
    this.setState({
      showRightNav: true,
      showLeftNav: false,
    });

    const { currentIndex } = this.state;
    if (currentIndex > 0) {
      this.setState({ currentIndex: currentIndex - 1 }, () => {
        this.scrollToIndex(this.state.currentIndex);
      });
    }
  };

  // Mobile scroll
  carouselContainerRef = React.createRef();

  scrollToIndex = (index) => {
    const carouselContainer = this.carouselContainerRef.current;
    if (carouselContainer) {
      carouselContainer.scrollTo({
        left: carouselContainer.offsetWidth * index,
        behavior: 'smooth',
      });
    }
  };

  noChangeLoaderFn = () => {
    this.setState({ noChangeLoader: true });
    this.timer = setTimeout(() => {
      this.setState({ noChangeLoader: false });
    }, 3000);
  };

  getSelectedPlanIdDetails = () => {
    const { selectedPlan: planId, getSavedSelectedPlanIdDetails } = this.props;
    const version = 'v2';
    if (planId && planId !== '') {
      getSavedSelectedPlanIdDetails({ planId, version });
    }
  };

  handleSimulate = (isMC) => {
    const { planDetailsByPlanId, triggerSimulatePlanDetailsByPlanId } =
      this.props;
    const { localPlanDetailsByPlanId } = this.state;
    let tempPlanDetailsByPlanId =
      Object.keys(localPlanDetailsByPlanId).length > 0
        ? { ...localPlanDetailsByPlanId }
        : { ...planDetailsByPlanId };
    const { moduleSettings: { model = {} } = {}, moduleSettings = {} } =
      tempPlanDetailsByPlanId;
    let tempModel = {};
    tempPlanDetailsByPlanId = transformBackToAPIType(tempPlanDetailsByPlanId);

    const runCode = this.getRunCode(isMC);
    tempModel = { ...model, runCode: runCode };
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      moduleSettings: {
        ...moduleSettings,
        model: { ...tempModel },
      },
    };
    this.setState({ isMC, isOptimizedClicked: isMC, isSimulateClicked: true, isSimulateOnInvestmentsClicked: false });
    triggerSimulatePlanDetailsByPlanId({
      displayTermChanged: false,
      chrono: {},
      plannerFormReq: tempPlanDetailsByPlanId,
      version: 'v2',
    });
  };

  getRunCode = (isMC, onload) => {
    const { activeMainTab = '' } = this.state;
    let runCode = 0;
    switch (activeMainTab.toLowerCase()) {
      case 'investments':
        runCode = isMC ? 501 : onload ? 0 : 500;
        break;
      case 'expenses':
        runCode = isMC ? 401 : 400;
        break;
      case 'goals':
        runCode = isMC ? 901 : 900;
        break;
      case 'insurance':
        runCode = isMC ? 601 : 600;
        break;
      case 'annuities':
        runCode = isMC ? 701 : 700;
        break;
      case 'debt':
        runCode = isMC ? 801 : 800;
        break;
      default:
        runCode = isMC ? 100 : 0;
        break;
    }
    return runCode;
  };

  preparePlanDetailsAndSimulate = () => {
    const { activeMainTab } = this.state;
    const mainTabLowerCase = activeMainTab.toLowerCase();
    const { planDetailsByPlanId, triggerSimulatePlanDetailsByPlanId } =
      this.props;
    const { moduleSettings: { model = {} } = {}, moduleSettings = {} } =
      planDetailsByPlanId;
    const runCode = this.getRunCode(undefined, 'onload');
    let tempModel = { ...model, runCode: runCode };
    const tempPlanDetailsByPlanId = {
      ...planDetailsByPlanId,
      moduleSettings: { ...moduleSettings, model: { ...tempModel } },
    };
    if (
      mainTabLowerCase !== 'annuities' &&
      mainTabLowerCase !== 'debt' &&
      mainTabLowerCase !== 'investments'
    ) {
      //remove this condition once Annuities, Goals and Debt is implemented
      triggerSimulatePlanDetailsByPlanId({
        displayTermChanged: false,
        chrono: {},
        plannerFormReq: tempPlanDetailsByPlanId,
        version: 'v2',
      });
      this.setState({
        isSimulateOnInvestmentsClicked: false,
        isOptimizedClicked: mainTabLowerCase === 'expenses' ? true : false,
      });
    }
  };

  loadDefaultsAndCallSimulateOnLoad = () => {
    const { memberOptions } = this.state;
    //const mainTabLowerCase = activeMainTab.toLowerCase();
    if (memberOptions.length === 0) {
      this.getMemberOptions(); //load radio and check boxes
    }
    //2, 4 and 5 can be removed from down here when we are implementing it, however 0 should be here as we don't need simulate on load in investments
    // if (
    //   mainTabLowerCase !== 'investments' && // empty or 0 for investments
    //   mainTabLowerCase !== 'annuities' && //annuities
    //   mainTabLowerCase !== 'debt' // debt
    // ) {
    //   //this will execute for Expenses, Insurances for now
    //   this.preparePlanDetailsAndSimulate();
    // } else {
    //   // this is temporary till debts and annuities is implemented
    //   if (mainTabLowerCase === 'annuities' || mainTabLowerCase === 'debt') {
    //     this.setState({
    //       // localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
    //       isSimulateOnInvestmentsClicked: false,
    //     });
    //   }
    // }
  };

  componentWillUnmount() {
    const {
      triggerSetBeforeAfterAssetMixValues,
      triggerSetAssetAllocListByClientIdAction,
    } = this.props;
    triggerSetBeforeAfterAssetMixValues([]);
    triggerSetAssetAllocListByClientIdAction([]);
  }

  // getParentTabValues = (tabName) => {
  //   switch (tabName && tabName.toLowerCase()) {
  //     case 'investments':
  //       return 0;
  //     case 'expenses':
  //       return 1;
  //     case 'goals':
  //       return 2;
  //     case 'insurance':
  //       return 3;
  //     case 'annuities':
  //       return 4;
  //     case 'debt':
  //       return 5;
  //     default:
  //       return 0;
  //   }
  // };

  componentDidMount() {
    const { selectedClientId, selectedPlan, planDetailsByPlanId } = this.props;
    const { memberOptions } = this.state;
    if (!selectedClientId && !selectedPlan) {
      history.push({ pathname: '/home' });
    }
    if (
      planDetailsByPlanId &&
      Object.keys(planDetailsByPlanId).length > 0 &&
      memberOptions.length === 0
    ) {
      this.getMemberOptions();
    }
    this.scrollToIndex(this.state.currentIndex);
  }

  getTabNames = (tab) => {
    switch (tab?.toLowerCase()) {
      case 'term life':
        return 'termLifeIns';
      case 'whole life':
        return 'wholeLifeIns';
      case 'long term care':
        return 'ltcIns';
      case 'umbrella':
        return 'umbrellaIns';
      case 'property':
        return 'propIns';
      case 'vehicle':
        return 'autoIns';
      case 'health':
        return 'healthIns';
      default:
        return 'termLifeIns';
    }
  };

  loadInsuranceTabInfo = () => {
    const {
      activeSubTab,
      formDetails,
      isComputed,
      localPlanDetailsByPlanId,
      memberOptions,
      selectedMember,
    } = this.state;
    let selectedInsuredInfo = {};
    switch (activeSubTab?.toLowerCase()) {
      case 'term life':
        selectedInsuredInfo =
          formDetails &&
            formDetails['termLifeIns'] &&
            formDetails['termLifeIns'].insured !== ''
            ? formDetails['termLifeIns']
            : {};
        break;
      case 'whole life':
        selectedInsuredInfo =
          formDetails &&
            formDetails['wholeLifeIns'] &&
            formDetails['wholeLifeIns'].insured !== ''
            ? formDetails['wholeLifeIns']
            : {};
        break;
      case 'long term care':
        selectedInsuredInfo =
          formDetails &&
            formDetails['ltcIns'] &&
            formDetails['ltcIns'].insured !== ''
            ? formDetails['ltcIns']
            : {};
        break;
      case 'umbrella':
        selectedInsuredInfo =
          formDetails && formDetails['umbrellaIns']
            ? formDetails['umbrellaIns']
            : {};
        break;
      case 'property':
        selectedInsuredInfo =
          formDetails && formDetails['propIns'] ? formDetails['propIns'] : {};
        break;
      case 'vehicle':
        selectedInsuredInfo =
          formDetails && formDetails['autoIns'] ? formDetails['autoIns'] : {};
        break;
      case 'health':
        selectedInsuredInfo =
          formDetails && formDetails['healthIns']
            ? formDetails['healthIns']
            : {};
        break;
      default:
        break;
    }
    let selectedMemberVal = selectedMember;
    if (formDetails.insured === 'family' && memberOptions.length > 0) {
      formDetails.insured = memberOptions[0].id;
      selectedMemberVal = memberOptions[0].id;
    }
    if (
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      memberOptions &&
      memberOptions.length > 0 &&
      Object.keys(selectedInsuredInfo).length > 0 &&
      !isComputed
    ) {
      this.getInsuranceProducts(activeSubTab);
      const tempFormDetails = this.updateFormDetails(
        selectedInsuredInfo,
        formDetails
      );
      this.setState({
        deleteInsurance: false,
        formDetails: tempFormDetails,
        renderType: 'Custom',
        selectedMember: selectedMemberVal,
        isMC: false,
      });
    }
  };

  loadAnnuitiesTabInfo = () => {
    this.setState({ renderType: 'Custom', isMC: false });
  };

  loadDebtTabInfo = () => {
    this.setState({ renderType: 'Custom', isMC: false });
  };

  loadGoalsTabInfo = () => {
    this.setState({ isLastStep: true, renderType: 'Custom', isMC: false });
  };

  loadExpensesTabInfo = () => {
    const { activeMainTab } = this.state;
    const mainTabLowerCase = activeMainTab.toLowerCase();
    this.setState({
      renderType: 'Custom',
      isMC: false,
      isLastStep: true,
      isOptimizedClicked: mainTabLowerCase === 'expenses' ? true : false,
    });
  };

  setIsLastStep = (val) => {
    this.setState({ isLastStep: val });
  };

  loadInvestmentTabInfo = () => {
    const {
      triggerSelectInvestmentProductsByClientId,
      triggerGetRecommendedAssetAllocListByClientId,
      investmentProducts,
      planDetailsByPlanId,
      recommendedAssetAllocationValues,
    } = this.props;
    const { clientid } = planDetailsByPlanId;
    if (
      recommendedAssetAllocationValues &&
      recommendedAssetAllocationValues.length === 0
    ) {
      triggerGetRecommendedAssetAllocListByClientId(clientid); //loading risklevel recom dropdown
    }
    if (investmentProducts && investmentProducts.length === 0) {
      triggerSelectInvestmentProductsByClientId(clientid);
    }
    this.setState({
      renderType: 'Custom',
      isLastStep: true,
      isMC: false,
    });
  };

  loadTabSpecificInfo = () => {
    const { activeMainTab } = this.state;
    switch (activeMainTab) {
      case 'investments':
        this.loadInvestmentTabInfo();
        break;
      case 'expenses':
        this.loadExpensesTabInfo();
        break;
      case 'goals':
        this.loadGoalsTabInfo();
        break;
      case 'insurance':
        this.loadInsuranceTabInfo();
        break;
      case 'annuities':
        this.loadAnnuitiesTabInfo();
        break;
      case 'debt':
        this.loadDebtTabInfo();
        break;
      default:
        break;
    }
  };

  loadCurrentTargetValuesInInvestments = () => {
    const {
      tables,
      beforeAfterAssetMixValues,
      triggerSetBeforeAfterAssetMixValues,
    } = this.props;
    const { assetMixBuildInfo, targetAssetMixBuildInfo } = this.state;
    let beforeAfterValues = [];
    if (
      tables &&
      tables.length > 0 &&
      beforeAfterAssetMixValues.length === 0 &&
      assetMixBuildInfo.length > 0 &&
      tables.filter((d) => d.tabName === 'Assets').length > 0
    ) {
      beforeAfterValues = assetMixBuildInfo.map(
        ({ heading = [], elements }) => ({
          name: heading[0] || '', // Use empty string if heading is empty
          elements: elements.map(({ label, value }) => ({
            label,
            current: value,
          })),
        })
      );
    }
    if (beforeAfterValues.length > 0 && targetAssetMixBuildInfo.length > 0) {
      beforeAfterValues = beforeAfterValues.map((m) => {
        const matchFound = targetAssetMixBuildInfo.find(
          ({ heading }) => heading?.[0] === m.name
        );
        return {
          name: m.name,
          elements:
            m.elements &&
              m.elements.length > 0 &&
              matchFound &&
              matchFound.elements &&
              matchFound.elements.length > 0
              ? m.elements.map((e) => {
                let s = matchFound.elements.filter(
                  (y) => y.label.toLowerCase() === e.label.toLowerCase()
                );
                if (s && s.length > 0) {
                  return {
                    label: e.label,
                    current: e.current,
                    target: s[0].value,
                  };
                }
                return { label: e.label, current: e.current, target: '0.0%' };
              })
              : [],
        };
      });
      triggerSetBeforeAfterAssetMixValues(beforeAfterValues);
    }
  };

  setRecommendedRiskLevel = () => {
    const {
      recommendedAssetAllocationValues,
      triggerGetAssetAllocListByClientId,
    } = this.props;
    const { localPlanDetailsByPlanId } = this.state;
    const { moduleInvestments: { markets = {} } = {}, clientid } =
      localPlanDetailsByPlanId;
    const { recommendedRiskLevel, isRiskLevelAdaptive } = markets;
    const tempSelectedRecommendedAssetAlloc =
      recommendedAssetAllocationValues.filter(
        (f) => f.riskLevel === recommendedRiskLevel
      );
    let selectedRecommendedAssetAllocId = '';
    if (
      tempSelectedRecommendedAssetAlloc &&
      tempSelectedRecommendedAssetAlloc.length > 0
    ) {
      selectedRecommendedAssetAllocId =
        tempSelectedRecommendedAssetAlloc[0].value; //this is nthg but assetAllocId
    }
    //call selectAllocListByClientId" with riskLevel that is selected in "Recommended Risk Level" dropdown.
    const req = {
      clientid: clientid,
      recommendedRiskLevel: recommendedRiskLevel,
    };
    this.setState(
      {
        selectedRecommendedAssetAllocId,
        isRiskLevelAdaptive, // setting this property here
        selectedAllocationInfo: {},
      },
      () => {
        triggerGetAssetAllocListByClientId(req);
      }
    );
  };

  setAllocationIdBasedOnRiskLevel = () => {
    const { allocation } = this.props;
    const {
      isMC,
      assetAllocId: stateAssetAllocId,
      localPlanDetailsByPlanId,
    } = this.state;
    const {
      moduleInvestments: { markets = {} } = {},
      moduleInvestments = {},
      moduleSettings: { model = {} } = {},
      moduleSettings = {},
    } = localPlanDetailsByPlanId;
    const { assetAllocId } = markets;
    let tempMarkets = { ...markets };
    tempMarkets = this.updateProductsToPlan(markets);
    let tempSelectedAllocationInfo =
      allocation.filter((f) => f.value === stateAssetAllocId)[0] || {};
    const tempSelectedAllocation = allocation.filter(
      (f) => f.label?.toLowerCase() === 'advisor recommended'
    );
    if (
      Object.keys(tempSelectedAllocationInfo).length === 0 &&
      tempSelectedAllocation &&
      tempSelectedAllocation.length > 0
    ) {
      const advisorRecommendedOption = tempSelectedAllocation.filter(
        (f) => f.label.toLowerCase() === 'advisor recommended'
      );
      if (advisorRecommendedOption && advisorRecommendedOption.length > 0) {
        tempSelectedAllocationInfo = advisorRecommendedOption[0];
      } else {
        tempSelectedAllocationInfo = tempSelectedAllocation[0];
      }
    }
    tempMarkets = {
      ...tempMarkets,
      assetAllocId:
        Object.keys(tempSelectedAllocationInfo).length > 0
          ? tempSelectedAllocationInfo.value
          : assetAllocId,
    };
    const runCode = this.getRunCode(isMC);
    let tempModel = { ...model, runCode: runCode };
    const tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
      moduleSettings: { ...moduleSettings, model: { ...tempModel } },
    };
    this.setState({
      selectedAllocationInfo: tempSelectedAllocationInfo,
      localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
    });
  };

  formatCurrency = (value = '') => {
    const numFormat = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2, // To always show at least two decimal places
      maximumFractionDigits: 3, // Limit the decimal places to two
    });
    //let newNumformat = numFormat.format(0); // Default formatting for zero
    let currencyFieldValue = '';
    if (value !== null && value !== undefined && value !== '') {
      // Remove dollar sign and commas if present
      if (value.toString().includes('$')) {
        currencyFieldValue = value.split('$')[1];
        currencyFieldValue = currencyFieldValue.replace(/,/g, '');
      } else {
        currencyFieldValue = value.toString().replace(/,/g, '');
      }
      // Check if the value is a valid number and is positive
      const parsedValue = parseFloat(currencyFieldValue);
      if (!isNaN(parsedValue) && parsedValue >= 0) {
        value = parsedValue.toString();
        //newNumformat = numFormat.format(parsedValue);
      } else {
        // If invalid or negative, set to default
        value = '0';
      }
    } else {
      value = '0'; // Default if empty
    }
    // Ensure value is always positive and formatted
    return numFormat.format(parseFloat(value));
  };

  getConvertedValue = (value) => {
    let usedBalance = value;
    if (
      usedBalance &&
      isNaN(usedBalance) &&
      usedBalance !== '$' &&
      usedBalance.includes('$')
    ) {
      const tempUsedBalance = usedBalance.split('$')[1].replace(/,/g, '');
      usedBalance =
        tempUsedBalance && !isNaN(tempUsedBalance)
          ? parseFloat(tempUsedBalance)
          : 0;
    } else {
      usedBalance = usedBalance && !isNaN(usedBalance) ? usedBalance : 0;
    }
    return usedBalance;
  };

  updateBalance = (tickersAdded) => {
    const amounts = tickersAdded
      .map(
        (m) => this.getConvertedValue(m.balance) //to remove empty values
      )
      .filter((a) => a);
    //taking only amounts property
    const amountUsed = amounts.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    ); //now adding them (a + b is nthg but actual values like 10 + 20)
    return amountUsed;
  };

  getPercentValue = (value) => {
    if (!value.toString().includes('%')) {
      const percentValue = parseFloat(parseFloat(value) * 100).toFixed(2);
      return `${percentValue}%`;
    }
    return value;
  };

  getCurrencyValue = (value) => {
    if (value !== '' && !value.toString().includes('$')) {
      const currencyValue = !isNaN(value) ? value : '';
      const numFormat = Intl.NumberFormat('en-US');
      const newNumformat = numFormat.format(currencyValue);
      return `$${newNumformat}`;
    }
    return value;
  };

  loadTickersAddedInPlan = () => {
    const {
      tickersAvailableInPlan = [],
      selectedInvestmentAccount,
      isProductsSelected,
    } = this.state;
    const { invBalance = '$0' } = selectedInvestmentAccount;
    const { investmentProducts } = this.props;
    let tickersAdded = [];
    let accBalance = invBalance;
    // Build a map of unique tickers from the investment products
    //if (isProductsSelected) { // this is different to whats happening in Build as we update on the fly here, so accBalance changes unlike build
    const productMap = new Map();
    investmentProducts.forEach((product) => {
      product.tickers.forEach((ticker) => {
        if (!productMap.has(ticker.ticker)) {
          productMap.set(ticker.ticker, ticker);
        }
      });
    });
    // Map tickersAvailableInPlan to new array with details
    const updatedTickersAvailableInPlan = tickersAvailableInPlan.filter(
      (item) => !(item.ticker.toLowerCase() === 'cash' && item.price === 0)
    );
    const cashInfo = updatedTickersAvailableInPlan.find(
      (t) => t.ticker.toLowerCase() === 'cash'
    );
    if (!cashInfo) {
      const cashInfo = {
        ticker: 'CASH',
        balance: 0,
        desc: 'CASH',
        quantity: 0,
        price: '$1',
        type: 'Cash',
      };
      updatedTickersAvailableInPlan.push(cashInfo);
    }
    tickersAdded = updatedTickersAvailableInPlan.map((m) => {
      const match = productMap.get(m.ticker);
      return {
        ticker: m.ticker.toUpperCase(),
        balance: this.formatCurrency(m.balance),
        desc: match ? match.desc : m.ticker.toUpperCase(), // If no match, use ticker as desc
        quantity: !isNaN(m.quantity) ? parseFloat(m.quantity) : 0,
        price: this.formatCurrency(m.price),
        type: m.type,
      };
    });
    accBalance =
      updatedTickersAvailableInPlan.length === 1
        ? invBalance
        : this.updateBalance(tickersAdded); // add all tickers value to get final balance or if no tickers are present, set the balance to default plaid balance or account balance of the selected account
    //}
    const newInvCustomFields = investmentCustomFields.map((c) => {
      const value = selectedInvestmentAccount[c.name] || 0;
      let newPercentValue = this.getPercentValue(value);
      return {
        ...c,
        value: newPercentValue,
      };
    });
    //let newInvBalance = this.formatCurrency(invBalance);
    const newAssetMixList = assetMixList.map((listItem) => {
      const value = selectedInvestmentAccount[listItem.name] || 0;
      let newPercentValue = this.getPercentValue(value);
      return {
        ...listItem,
        value: newPercentValue,
      };
    });
    const sumOfAssetMixValue = newAssetMixList.reduce(function (acc, val) {
      return acc + parseFloat(val.value);
    }, 0);
    // Update state
    this.setState({
      tickersAddedToPlan: tickersAdded,
      accBalance: this.formatCurrency(accBalance),
      sumOfAssetMixValue,
      assetMixListForInv: newAssetMixList,
      invCustomFields: newInvCustomFields,
      isAssetMixPercentNotEqualTo100: sumOfAssetMixValue === 100 ? false : true,
      // isProductsSelected: assetProfile === 'products' ? true : false,
      // isCustomSelected: assetProfile === 'custom' ? true : false,
    });
  };

  loadTickersAvailableInPlan = () => {
    const { investmentAccounts } = this.state;
    let selectedAccount = '';
    let tickersAvailableInPlan = [];
    if (investmentAccounts && investmentAccounts.length > 0) {
      selectedAccount = investmentAccounts[0].nickName; //on load account
      tickersAvailableInPlan = investmentAccounts[0].products || [];
    }
    this.setState({ selectedAccount, tickersAvailableInPlan });
  };

  percentageConversion = (field) => {
    if (field.toString().includes('%')) {
      let percentField = field.split('%')[0];
      percentField =
        percentField !== '' && !isNaN(percentField)
          ? parseFloat(percentField) / 100
          : 0;
      return percentField;
    }
    return field !== '' && !isNaN(field) ? field : 0;
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  convertToAPIType = (obj) => {
    const arrayKeys = Object.keys(obj).filter((key) => Array.isArray(obj[key]));
    const updatedObj = { ...obj };
    arrayKeys.forEach((selectedInvestmentBucket) => {
      const availableAccounts = obj[selectedInvestmentBucket] || [];
      updatedObj[selectedInvestmentBucket] = availableAccounts.map((acc) => {
        const { customReturn, customVol, customDivRate, invBalance } = acc;
        return {
          ...acc,
          products:
            acc.products?.map((m) => {
              return {
                ticker: m.ticker,
                balance: this.transformBackToAPIType(m.balance),
                price: this.transformBackToAPIType(m.price),
                quantity: !isNaN(m.quantity) ? parseFloat(m.quantity) : 0,
                type: m.type,
              };
            }) || [],
          altPercent: this.percentageConversion(acc.altPercent),
          bondPercent: this.percentageConversion(acc.bondPercent),
          cashPercent: this.percentageConversion(acc.cashPercent),
          reitPercent: this.percentageConversion(acc.reitPercent),
          shortTermPercent: this.percentageConversion(acc.shortTermPercent),
          stockPercent: this.percentageConversion(acc.stockPercent),
          cryptoPercent: this.percentageConversion(acc.cryptoPercent),
          commoditiesPercent: this.percentageConversion(acc.commoditiesPercent),
          emktBondPercent: this.percentageConversion(acc.emktBondPercent),
          emktStockPercent: this.percentageConversion(acc.emktStockPercent),
          intlBondPercent: this.percentageConversion(acc.intlBondPercent),
          intlStockPercent: this.percentageConversion(acc.intlStockPercent),
          customVol: this.percentageConversion(customVol),
          customReturn: this.percentageConversion(customReturn),
          customDivRate: this.percentageConversion(customDivRate),
          invBalance: this.transformBackToAPIType(invBalance),
        };
      });
    });
    return updatedObj;
  };

  updateProductsToPlan = (markets, from) => {
    const {
      memberOptions,
      selectedMember,
      tickersAddedToPlan,
      selectedMemberOption,
      selectedInvestmentAccount = {},
      isRiskLevelAdaptive,
      isProductsSelected,
      isCustomSelected,
      accBalance,
      assetMixListForInv,
      invCustomFields,
      oldInvestmentBucket,
      selectedInvestmentBucket = '',
      newNickName = '',
      isAddNew,
      localProdInfo,
      isGetMarketDataSelected
    } = this.state;
    const selectedMemberInfo = memberOptions.find(
      (m) => m.id === selectedMember
    );
    if (!selectedMemberInfo) {
      return markets;
    }
    if (
      selectedInvestmentAccount &&
      Object.keys(selectedInvestmentAccount).length > 0 &&
      markets &&
      Object.keys(markets).length > 0
    ) {
      const {
        first = {},
        second = {},
        baseFirst = {},
        baseSecond = {},
      } = markets;
      const updatedFirst = this.convertToAPIType(first);
      const updatedSecond = this.convertToAPIType(second);
      const { lockedInSolution: lockedInSolutionFirst = false } = first;
      const { lockedInSolution: lockedInSolutionSecond = false } = second;
      const { nickName = '', products: availableProducts = [] } =
        selectedInvestmentAccount;
      const tempNickName = isAddNew ? newNickName : nickName;
      const tempSelectedInvestmentAccount = {
        ...selectedInvestmentAccount,
        nickName: tempNickName,
        investmentBucket: selectedInvestmentBucket,
        id:
          selectedInvestmentBucket !== ''
            ? `${tempNickName}-${selectedInvestmentBucket}`
            : '',
      };
      let selectedMemberInvestmentTypeInfo =
        JSON.parse(JSON.stringify(markets[selectedMemberOption])) || {};
      const newAssetMixList = assetMixListForInv.map((list) => {
        const percentFieldValue = list.value.toString().split('%')[0];
        const newFieldValue =
          isProductsSelected && tickersAddedToPlan.length > 0
            ? 0
            : percentFieldValue !== '' &&
            !isNaN(percentFieldValue) &&
            parseFloat(percentFieldValue) / 100;
        return {
          ...list,
          value: newFieldValue,
        };
      });
      const newInvCustomFields = invCustomFields.map((list) => {
        const percentFieldValue = list.value.toString().split('%')[0];
        const newFieldValue =
          isProductsSelected && tickersAddedToPlan.length > 0
            ? 0
            : percentFieldValue !== '' &&
            !isNaN(percentFieldValue) &&
            parseFloat(percentFieldValue) / 100;
        return {
          ...list,
          value: newFieldValue,
        };
      });
      let products =
        isProductsSelected && tickersAddedToPlan?.length > 0
          ? tickersAddedToPlan
          : availableProducts; // retaining products to make sure they are not lost
      products = products.map((m) => ({
        ticker: m.ticker,
        balance: this.transformBackToAPIType(m.balance),
        price: this.transformBackToAPIType(m.price),
        quantity: !isNaN(m.quantity) ? parseFloat(m.quantity) : 0,
        type: m.type,
      }));
      const {
        investmentBucket = '',
        id = '',
        ...rest
      } = tempSelectedInvestmentAccount || {};
      const index =
        oldInvestmentBucket !== ''
          ? selectedMemberInvestmentTypeInfo[oldInvestmentBucket].findIndex(
            (item) =>
              item.nickName.toLowerCase() === rest.nickName.toLowerCase()
          )
          : -1;
      if (
        index !== -1 &&
        oldInvestmentBucket.toLowerCase() !==
        selectedInvestmentBucket.toLowerCase()
      ) {
        // Remove the object from trad401k array
        selectedMemberInvestmentTypeInfo[oldInvestmentBucket].splice(index, 1);
        // Add the removed object to the rsu array
        selectedMemberInvestmentTypeInfo[selectedInvestmentBucket].push(rest);
      } else if (index === -1) {
        const accounts =
          selectedMemberInvestmentTypeInfo[selectedInvestmentBucket];
        const newIndex = accounts.indexOf(
          accounts.filter(
            (f) => f.nickName.toLowerCase() === rest.nickName.toLowerCase()
          )[0]
        );
        if (newIndex === -1) {
          selectedMemberInvestmentTypeInfo[selectedInvestmentBucket].push(rest);
        }
      }
      const availableAccounts =
        selectedMemberInvestmentTypeInfo[selectedInvestmentBucket] || [];
      const availableAccountsAfterMoving =
        selectedMemberInvestmentTypeInfo[oldInvestmentBucket] || [];
      selectedMemberInvestmentTypeInfo = {
        ...selectedMemberInvestmentTypeInfo,
        [`num${this.capitalizeFirstLetter(selectedInvestmentBucket)}`]:
          availableAccounts.length,
        // [`num${this.capitalizeFirstLetter(oldInvestmentBucket)}`]:
        //   availableAccountsAfterMoving.length,
        [selectedInvestmentBucket]: availableAccounts.map((acc, index) => {
          const { customReturn, customVol, customDivRate, invBalance } = acc;
          if (acc.nickName.toLowerCase() === rest.nickName.toLowerCase()) {
            return {
              ...acc,
              products: products,
              altPercent: newAssetMixList.find(
                (inv) => inv.name === 'altPercent'
              ).value,
              bondPercent: newAssetMixList.find(
                (inv) => inv.name === 'bondPercent'
              ).value,
              cashPercent: newAssetMixList.find(
                (inv) => inv.name === 'cashPercent'
              ).value,
              reitPercent: newAssetMixList.find(
                (inv) => inv.name === 'reitPercent'
              ).value,
              shortTermPercent: newAssetMixList.find(
                (inv) => inv.name === 'shortTermPercent'
              ).value,
              stockPercent: newAssetMixList.find(
                (inv) => inv.name === 'stockPercent'
              ).value,
              cryptoPercent: newAssetMixList.find(
                (inv) => inv.name === 'cryptoPercent'
              ).value,
              commoditiesPercent: newAssetMixList.find(
                (inv) => inv.name === 'commoditiesPercent'
              ).value,
              emktBondPercent: newAssetMixList.find(
                (inv) => inv.name === 'emktBondPercent'
              ).value,
              emktStockPercent: newAssetMixList.find(
                (inv) => inv.name === 'emktStockPercent'
              ).value,
              intlBondPercent: newAssetMixList.find(
                (inv) => inv.name === 'intlBondPercent'
              ).value,
              intlStockPercent: newAssetMixList.find(
                (inv) => inv.name === 'intlStockPercent'
              ).value,
              customVol: !isCustomSelected
                ? 0
                : newInvCustomFields.find((inv) => inv.name === 'customVol')
                  .value,
              customReturn: !isCustomSelected
                ? 0
                : newInvCustomFields.find((inv) => inv.name === 'customReturn')
                  .value,
              customDivRate: !isCustomSelected
                ? 0
                : newInvCustomFields.find((inv) => inv.name === 'customDivRate')
                  .value,
              invBalance: this.transformBackToAPIType(accBalance),
              assetProfile: isProductsSelected
                ? 'products'
                : isCustomSelected
                  ? 'custom'
                  : 'generic',
            };
          } else {
            return {
              ...acc,
              products:
                acc.products?.map((m) => {
                  return {
                    ticker: m.ticker,
                    balance: this.transformBackToAPIType(m.balance),
                    price: this.transformBackToAPIType(m.price),
                    quantity: !isNaN(m.quantity) ? parseFloat(m.quantity) : 0,
                    type: m.type,
                  };
                }) || [],
              altPercent: this.percentageConversion(acc.altPercent),
              bondPercent: this.percentageConversion(acc.bondPercent),
              cashPercent: this.percentageConversion(acc.cashPercent),
              reitPercent: this.percentageConversion(acc.reitPercent),
              shortTermPercent: this.percentageConversion(acc.shortTermPercent),
              stockPercent: this.percentageConversion(acc.stockPercent),
              cryptoPercent: this.percentageConversion(acc.cryptoPercent),
              commoditiesPercent: this.percentageConversion(
                acc.commoditiesPercent
              ),
              emktBondPercent: this.percentageConversion(acc.emktBondPercent),
              emktStockPercent: this.percentageConversion(acc.emktStockPercent),
              intlBondPercent: this.percentageConversion(acc.intlBondPercent),
              intlStockPercent: this.percentageConversion(acc.intlStockPercent),
              customVol: !isCustomSelected
                ? 0
                : this.percentageConversion(customVol),
              customReturn: !isCustomSelected
                ? 0
                : this.percentageConversion(customReturn),
              customDivRate: !isCustomSelected
                ? 0
                : this.percentageConversion(customDivRate),
              invBalance: this.transformBackToAPIType(invBalance),
            };
          }
        }),
      };
      // If oldInvestmentBucket is not empty, update `num<oldInvestmentBucket>` field and other related fields
      if (oldInvestmentBucket !== '') {
        selectedMemberInvestmentTypeInfo = {
          ...selectedMemberInvestmentTypeInfo,
          [`num${this.capitalizeFirstLetter(oldInvestmentBucket)}`]:
            availableAccountsAfterMoving.length,
        };
      } else {
        // If oldInvestmentBucket is empty, remove the `num<oldInvestmentBucket>` field
        const {
          [`num${this.capitalizeFirstLetter(oldInvestmentBucket)}`]: removed,
          ...rest
        } = selectedMemberInvestmentTypeInfo;
        selectedMemberInvestmentTypeInfo = rest;
      }
      const canCopy = !lockedInSolutionSecond || !lockedInSolutionFirst;
      let updatedMarkets = {
        ...markets,
        baseFirst: canCopy ? { ...updatedFirst } : { ...baseFirst },
        baseSecond: canCopy ? { ...updatedSecond } : { ...baseSecond },
        first: { ...updatedFirst, lockedInSolution: true },
        second: { ...updatedSecond, lockedInSolution: true },
      };
      updatedMarkets = {
        ...updatedMarkets,
        getMarketData: isGetMarketDataSelected,
        prodInfo: localProdInfo,
        isRiskLevelAdaptive,
        [selectedMemberOption]: {
          ...selectedMemberInvestmentTypeInfo,
          lockedInSolution: true,
        },
      };
      return updatedMarkets;
    }
    return markets;
  };

  runSimulateForTargets = () => {
    const { triggerSimulatePlanDetailsByPlanId } = this.props;
    const { selectedAllocationInfo, isMC, localPlanDetailsByPlanId } =
      this.state;
    const {
      moduleInvestments: { markets = {} } = {},
      moduleInvestments = {},
      moduleSettings: { model = {} } = {},
      moduleSettings = {},
    } = localPlanDetailsByPlanId;
    let tempMarkets = { ...markets };
    tempMarkets = {
      ...tempMarkets,
      assetAllocId: selectedAllocationInfo.value,
    };
    const runCode = this.getRunCode(isMC);
    let tempModel = { ...model, runCode: runCode };
    const tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
      moduleSettings: { ...moduleSettings, model: { ...tempModel } },
    };
    triggerSimulatePlanDetailsByPlanId({
      displayTermChanged: false,
      chrono: {},
      plannerFormReq: tempPlanDetailsByPlanId,
      version: 'v2',
    });
  };

  getAllAccountsFromBuckets = () => {
    const { selectedInvestmentAccount, selectedMemberOption, memberOptions } =
      this.state;
    const { nickName = '' } = selectedInvestmentAccount || {};
    const tempSelectedMemberKey =
      selectedMemberOption !== ''
        ? selectedMemberOption
        : memberOptions[0]?.key || '';
    const selectedMemberState = this.getSelectedMemberInvestmentTypeAccounts(
      tempSelectedMemberKey
    );
    const availableInvestmentAccounts =
      this.getAccounts(selectedMemberState) || [];
    const currentSelectedInvestmentAccount =
      availableInvestmentAccounts.filter((a) => a.nickName === nickName)[0] ||
      {};
    const investmentAccountSelected =
      currentSelectedInvestmentAccount &&
        Object.keys(currentSelectedInvestmentAccount).length > 0
        ? currentSelectedInvestmentAccount
        : availableInvestmentAccounts.length > 0
          ? availableInvestmentAccounts[0]
          : undefined;
    this.setState({
      availableInvestmentAccounts,
      tickersAvailableInPlan: investmentAccountSelected?.products || [],
      selectedInvestmentAccount: investmentAccountSelected,
      selectedInvestmentBucket:
        investmentAccountSelected?.investmentBucket || '',
      selectedAccount: investmentAccountSelected?.nickName || '',
      accBalance: investmentAccountSelected?.invBalance || '$0',
    });
  };

  updateProductsToRedux = (from) => {
    const { localPlanDetailsByPlanId } = this.state;
    let clonedPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    const { moduleInvestments: { markets = {} } = {}, moduleInvestments = {} } =
      clonedPlanDetailsByPlanId;
    let tempPlanDetailsByPlanId = { ...clonedPlanDetailsByPlanId };
    let tempMarkets = { ...markets };
    tempMarkets = this.updateProductsToPlan(markets, from);
    if (!isEqual(tempMarkets, markets)) {
      tempPlanDetailsByPlanId = {
        ...clonedPlanDetailsByPlanId,
        moduleInvestments: {
          ...moduleInvestments,
          markets: { ...tempMarkets },
        },
      };
      this.setState((prevState) => ({
        ...prevState,
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
      }));
    }
  };

  updatePlanDetailsOnInvestments = () => {
    const { localPlanDetailsByPlanId } = this.state;
    const {
      solutions,
      recommendedAssetAllocationValues,
      triggerGetAssetAllocListByClientId,
    } = this.props;
    const { investments = {} } = solutions || {};
    const { recommendedRiskLevel } = investments;
    const {
      clientid,
      moduleInvestments: { markets = {} } = {},
      moduleInvestments = {},
    } = localPlanDetailsByPlanId;
    let tempRecommendedRiskLevel = '';
    let selectedRecommendedAssetAllocId = '';
    const tempSelectedRecommendedAssetAlloc =
      recommendedAssetAllocationValues.filter(
        (f) => f.riskLevel === recommendedRiskLevel
      );
    if (
      tempSelectedRecommendedAssetAlloc &&
      tempSelectedRecommendedAssetAlloc.length > 0
    ) {
      tempRecommendedRiskLevel = tempSelectedRecommendedAssetAlloc[0].riskLevel;
      selectedRecommendedAssetAllocId =
        tempSelectedRecommendedAssetAlloc[0].value;
    }
    const req = {
      clientid: clientid,
      recommendedRiskLevel: tempRecommendedRiskLevel,
    };
    let tempMarkets = {
      ...markets,
      assetAllocId: selectedRecommendedAssetAllocId, //updating assetAllocId in plan
      recommendedRiskLevel: tempRecommendedRiskLevel, //updating recommendedRiskLevel in plan
    };
    const tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
    };
    this.setState(
      (prevState) => ({
        ...prevState,
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
        selectedAllocationInfo: {},
        assetAllocId: '',
        selectedRecommendedAssetAllocId,
        isOptimizedClicked: false,
      }),
      () => {
        triggerGetAssetAllocListByClientId(req);
      }
    );
  };

  updatePlanDetailsOnExpenses = () => {
    const { localPlanDetailsByPlanId } = this.state;
    const { solutions, triggerUpdatePlannerDetailsinRedux } = this.props;
    const { expenses = {} } = solutions || {};
    const { affordabilityFactor } = expenses;
    const { moduleFamily: { infoExpenses = {} } = {}, moduleFamily = {} } =
      localPlanDetailsByPlanId;
    let tempInfoExpenses = {
      ...infoExpenses,
      affordabilityFactor: affordabilityFactor, //updating assetAllocId in plan
    };
    const tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleFamily: {
        ...moduleFamily,
        infoExpenses: { ...tempInfoExpenses },
      },
    };
    triggerUpdatePlannerDetailsinRedux({
      plannerData: tempPlanDetailsByPlanId,
    });
    this.setState((prevState) => ({
      ...prevState,
      localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
      isOptimizedClicked: false,
    }));
  };

  updatePlanDetailsOnSolutions = () => {
    const { activeMainTab } = this.state;
    switch (activeMainTab) {
      case 'investments':
        this.updatePlanDetailsOnInvestments();
        break;
      case 'expenses':
        this.updatePlanDetailsOnExpenses();
        break;
      default:
        break;
    }
  };

  handleCancelOrReset = () => {
    this.setState({
      isUpdateClicked: false,
      isAddNew: false,
      isEditable: false,
      selectedTicker: '',
      selectedProductType: '',
      tickersAddedToPlan: [],
      tickersAvailableInPlan: [],
      isProductsSelected: true,
      isCustomSelected: true,
      selectedInvestmentAccount: {},
      isButtonDisabled: false,
      buttonDisabledMsg: '',
      isLastStep: true,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      planDetailsByPlanId,
      charts,
      tables,
      data,
      recommendedAssetAllocationValues,
      allocation,
      investmentProducts,
      triggerUpdatePlannerDetailsinRedux,
      solutions,
    } = this.props;
    const {
      renderType,
      selectedAllocationInfo,
      localPlanDetailsByPlanId,
      assetMixBuildInfo,
      targetAssetMixBuildInfo,
      selectedRecommendedAssetAllocId,
      activeSubTab,
      deleteInsurance,
      memberOptions,
      isSimulateOnInvestmentsClicked,
      isMC,
      selectedMember,
      formDetails,
      isEditable,
      isAddNew,
      tickersAddedToPlan,
      selectedInvestmentAccount,
      isOptimizedClicked,
      assetMixListForInv,
      invCustomFields,
      isProductsSelected,
      isUpdateClicked,
      snackbarShown,
      activeMainTab,
      mainNavItems,
    } = this.state;
    const {
      renderType: prevRenderType,
      selectedAllocationInfo: prevSelectedAllocationInfo,
      localPlanDetailsByPlanId: prevLocalPlanDetailsByPlanId,
      assetMixBuildInfo: prevAssetMixBuildInfo,
      targetAssetMixBuildInfo: prevTargetAssetMixBuildInfo,
      formDetails: prevFormDetails,
      selectedMember: prevSelectedMember,
      tickersAddedToPlan: prevTickersAddedToPlan,
      selectedInvestmentAccount: prevSelectedInvestmentAccount,
      assetMixListForInv: prevAssetMixListForInv,
      invCustomFields: prevInvCustomFields,
      memberOptions: prevMemberOptions,
      isProductsSelected: prevIsProductsSelected,
      activeMainTab: prevActiveMainTab,
      activeSubTab: prevActiveSubTab,
    } = prevState;
    const {
      charts: prevCharts,
      tables: prevTables,
      data: prevData,
      recommendedAssetAllocationValues: prevRecommendedAssetAllocationValues,
      allocation: prevAllocation,
      planDetailsByPlanId: prevPlanDetailsByPlanId,
      solutions: prevSolutions,
    } = prevProps;
    //const queryParams = new URLSearchParams(this.props.location.search);
    //let tab = queryParams.get('tab');
    // if (tab) {
    //   const tempSolutionsParentTab = this.getParentTabValues(tab);
    //   if (tempSolutionsParentTab !== activeMainTab) {
    //     const solutionsParentTabName = this.getParentTabNames(
    //       tempSolutionsParentTab
    //     );
    //     history.push(`/solutions?tab=${solutionsParentTabName}`);
    //     this.setState({ activeMainTab: tempSolutionsParentTab });
    //   }
    // }
    if (activeMainTab !== prevActiveMainTab) {
      const selectedTab = mainNavItems.find((f) => f.parent === activeMainTab);
      if (selectedTab) {
        this.setState({ subTabs: selectedTab.children });
      }
    }
    if (
      planDetailsByPlanId &&
      Object.keys(planDetailsByPlanId).length > 0 &&
      !isEqual(planDetailsByPlanId, prevPlanDetailsByPlanId)
    ) {
      this.getMemberOptions();
    }
    if (
      selectedMember &&
      activeMainTab === 'investments' &&
      activeSubTab &&
      memberOptions.length > 0 &&
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      investmentProducts?.length > 0 &&
      (!isEqual(memberOptions, prevMemberOptions) ||
        selectedMember !== prevSelectedMember ||
        (selectedInvestmentAccount &&
          Object.keys(selectedInvestmentAccount).length === 0) ||
        !isEqual(localPlanDetailsByPlanId, prevLocalPlanDetailsByPlanId))
    ) {
      this.getAllAccountsFromBuckets();
      triggerUpdatePlannerDetailsinRedux({
        plannerData: localPlanDetailsByPlanId,
      });
    }
    // if (
    //   planDetailsByPlanId &&
    //   Object.keys(planDetailsByPlanId).length > 0 &&
    //   memberOptions.length === 0
    // ) {
    //   this.loadDefaultsAndCallSimulateOnLoad(); // first simulate
    // }
    if (
      planDetailsByPlanId &&
      Object.keys(planDetailsByPlanId).length > 0 &&
      (!isEqual(prevRenderType, renderType) ||
        (charts && charts.length > 0 && !isEqual(charts, prevCharts)) ||
        (data && data.length > 0 && !isEqual(data, prevData)) ||
        (tables && tables.length > 0 && !isEqual(tables, prevTables)))
    ) {
      this.loadDefaultValues();
    }
    if (
      activeMainTab === 'investments' &&
      activeSubTab &&
      tables &&
      tables.length > 0 &&
      assetMixBuildInfo &&
      assetMixBuildInfo.length > 0 &&
      targetAssetMixBuildInfo &&
      targetAssetMixBuildInfo.length > 0 &&
      !isEqual(assetMixBuildInfo, prevAssetMixBuildInfo) &&
      !isEqual(targetAssetMixBuildInfo, prevTargetAssetMixBuildInfo)
    ) {
      this.loadCurrentTargetValuesInInvestments();
    }
    if (
      activeMainTab === 'investments' &&
      activeSubTab &&
      recommendedAssetAllocationValues &&
      recommendedAssetAllocationValues.length > 0 &&
      localPlanDetailsByPlanId &&
      Object.keys(localPlanDetailsByPlanId).length > 0 &&
      (!isEqual(
        recommendedAssetAllocationValues,
        prevRecommendedAssetAllocationValues
      ) ||
        selectedRecommendedAssetAllocId === '')
    ) {
      this.setRecommendedRiskLevel(); //first dd in investments
    }
    if (
      activeMainTab === 'investments' &&
      activeSubTab &&
      allocation &&
      allocation.length > 0 &&
      !isEqual(allocation, prevAllocation) &&
      selectedRecommendedAssetAllocId &&
      (Object.keys(selectedAllocationInfo).length === 0 ||
        !isEqual(selectedAllocationInfo, prevSelectedAllocationInfo))
    ) {
      this.setAllocationIdBasedOnRiskLevel(); //last dropdown in investments
    }
    if (
      (isOptimizedClicked || (activeMainTab === 'expenses' && activeSubTab)) && // for expenses, we need to update the plan irrespective of optimize or simulate as affordability factor should always be read from the response
      solutions &&
      Object.keys(solutions).length > 0 &&
      !isEqual(solutions, prevSolutions)
    ) {
      this.updatePlanDetailsOnSolutions();
    }
    if (
      (activeMainTab && activeSubTab && activeSubTab !== prevActiveSubTab) ||
      deleteInsurance
    ) {
      this.loadTabSpecificInfo();
    }
    if (
      activeMainTab === 'investments' &&
      activeSubTab &&
      allocation.length > 0 &&
      selectedAllocationInfo &&
      Object.keys(selectedAllocationInfo).length > 0 &&
      localPlanDetailsByPlanId &&
      !isSimulateOnInvestmentsClicked &&
      Object.keys(localPlanDetailsByPlanId).length > 0
    ) {
      this.runSimulateForTargets();
      this.setState({
        isSimulateOnInvestmentsClicked: true,
        isOptimizedClicked: isMC,
      });
    }
    if (
      (activeMainTab === 'investments' &&
        activeSubTab &&
        selectedInvestmentAccount &&
        Object.keys(selectedInvestmentAccount).length > 0 &&
        !isEqual(
          selectedInvestmentAccount?.nickName,
          prevSelectedInvestmentAccount?.nickName
        )) ||
      isProductsSelected !== prevIsProductsSelected
    ) {
      this.loadTickersAddedInPlan();
    }
    if (!isEqual(prevFormDetails, formDetails) && (isAddNew || isEditable)) {
      this.validateAllFormDetails();
    }
    if (
      activeMainTab === 'investments' &&
      activeSubTab &&
      isUpdateClicked &&
      isEqual(activeMainTab, prevActiveMainTab)
    ) {
      this.updateProductsToRedux('tickersChange');
      this.handleCancelOrReset();
    }
    if (
      !isEqual(planDetailsByPlanId, prevPlanDetailsByPlanId) &&
      !snackbarShown
    ) {
      this.showSnackbar();
    }
    if (prevState.currentIndex !== this.state.currentIndex) {
      this.scrollToIndex(this.state.currentIndex);
    }
  }

  showSnackbar = () => {
    this.setState({ isReduxUpdated: true, snackbarShown: true });
    setTimeout(() => {
      this.setState({ isReduxUpdated: false, snackbarShown: false });
    }, 2000);
    this.handleDisplayToast('redux');
  };

  validateTabwiseFormDetails = (
    selectedFormDetails,
    validateFields,
    existingItems
  ) => {
    const { isAddNew, isEditable, selectedDDNameIndex } = this.state;
    let validationErrors = {};
    validationErrors = validateFormDetails(
      selectedFormDetails,
      validateFields,
      existingItems,
      isAddNew,
      isEditable,
      selectedDDNameIndex
    );
    if (validationErrors && validationErrors.length > 0) {
      this.setState({ formErrors: validationErrors, isFormValid: false });
    } else {
      this.setState({ formErrors: [], isFormValid: true });
    }
  };

  concatContent = (mainTab, subTab, objectArray) => {
    const { localPlanDetailsByPlanId } = this.state;
    let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    // Dynamically fetch the moduleSettings object based on tab
    const tabModule = tempPlanDetailsByPlanId[mainTab] || {};
    const itemArray = tabModule[subTab]
      ? tabModule[subTab][objectArray] || []
      : [];
    let existingItemsOptions = [];
    if (Array.isArray(itemArray)) {
      existingItemsOptions = itemArray?.map((m) => {
        return {
          title: subTab === 'invProps' ? m.prop?.name : m.name,
          id: subTab === 'invProps' ? m.prop?.name : m.name,
        };
      });
    } else {
      if (Object.keys(itemArray).length > 0) {
        existingItemsOptions = [
          {
            title: itemArray.name,
            id: itemArray.name,
          },
        ];
      }
    }
    return existingItemsOptions.filter((f) => f.title && f.id); //removing nulls
  };

  validateAllFormDetails = () => {
    const { formDetails, activeSubTab, activeMainTab } = this.state;
    let mandatoryFields = [];
    let formFields = {};
    let existingItems = [];
    let moduleTab = '';
    let mainTab = '';
    let subTab = '';
    const subTabLowerCase = activeSubTab?.toLowerCase();
    switch (activeMainTab) {
      case 'insurance':
        moduleTab = 'moduleInsurance';
        switch (subTabLowerCase) {
          case 'term life':
            mainTab = 'termLife';
            subTab = 'termLifeIns';
            mandatoryFields = Object.entries(PropertyNamesMap['termLifeIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['termLifeIns'];
            break;
          case 'whole life':
            mainTab = 'wholeLife';
            subTab = 'wholeLifeIns';
            mandatoryFields = Object.entries(PropertyNamesMap['wholeLifeIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['wholeLifeIns'];
            break;
          case 'long term care':
            mainTab = 'longTermCare';
            subTab = 'ltcIns';
            mandatoryFields = Object.entries(PropertyNamesMap['ltcIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['ltcIns'];
            break;
          case 'umbrella':
            mainTab = 'umbrella';
            subTab = 'umbrellaIns';
            mandatoryFields = Object.entries(PropertyNamesMap['umbrellaIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['umbrellaIns'];
            break;
          case 'property':
            mainTab = 'property';
            subTab = 'propIns';
            mandatoryFields = Object.entries(PropertyNamesMap['propIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['propIns'];
            break;
          case 'vehicle':
            mainTab = 'auto';
            subTab = 'autoIns';
            mandatoryFields = Object.entries(PropertyNamesMap['autoIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['autoIns'];
            break;
          case 'health':
            mainTab = 'health';
            subTab = 'healthIns';
            mandatoryFields = Object.entries(PropertyNamesMap['healthIns'])
              .filter(([key, value]) => value.isMandatory === true)
              .map(([key, value]) => value);
            formFields = formDetails['healthIns'];

            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    existingItems = this.concatContent(moduleTab, mainTab, subTab);
    this.validateTabwiseFormDetails(formFields, mandatoryFields, existingItems);
  };

  getInitialFormDetails = () => {
    const formDetails = {
      termLifeIns: { insured: '', name: '', coverageStartYr: new Date() },
      wholeLifeIns: { insured: '', name: '', coverageStartYr: new Date() },
      longTermCare: { insured: '', name: '', coverageStartYr: new Date() },
      umbrellaIns: {
        insured: 'umbrella',
        name: '',
        coverageStartYr: new Date(),
      },
    };
    return formDetails;
  };

  resolveData = (data) => {
    return data.map((m) => {
      return {
        ...m,
        id: m.productId,
      };
    });
  };

  getInsuranceProducts = async (subTabValue) => {
    const {
      triggerSetLongTermCareInsuranceProducts,
      triggerSetTermInsuranceProducts,
      triggerSetUmbrellaInsuranceProducts,
      triggerSetWholeInsuranceProducts,
      triggerSetPropertyInsuranceProducts,
      triggerSetAutoInsuranceProducts,
      triggerSetHealthInsuranceProducts,
      termLifeInsuranceProducts,
      wholeLifeInsuranceProducts,
      umbrellaInsuranceProducts,
      longTermCareInsuranceProducts,
      propertyInsuranceProducts,
      autoInsuranceProducts,
      healthInsuranceProducts,
      planDetailsByPlanId,
    } = this.props;
    const { clientid } = planDetailsByPlanId;
    switch (subTabValue.toLowerCase()) {
      case 'term life':
        if (
          termLifeInsuranceProducts &&
          termLifeInsuranceProducts.length === 0
        ) {
          let { data: termInsProducts } =
            await getTermLifeInsuranceProductsByClientId(clientid);
          termInsProducts = this.resolveData(termInsProducts);
          triggerSetTermInsuranceProducts(termInsProducts);
        }
        break;
      case 'whole life':
        if (
          wholeLifeInsuranceProducts &&
          wholeLifeInsuranceProducts.length === 0
        ) {
          let { data: wholeLifeInsProducts } =
            await getWholeLifeInsuranceProductsByClientId(clientid);
          wholeLifeInsProducts = this.resolveData(wholeLifeInsProducts);
          triggerSetWholeInsuranceProducts(wholeLifeInsProducts);
        }
        break;
      case 'long term care':
        if (
          longTermCareInsuranceProducts &&
          longTermCareInsuranceProducts.length === 0
        ) {
          let { data: ltcProducts } =
            await getLongTermCareInsuranceProductsByClientId(clientid);
          ltcProducts = this.resolveData(ltcProducts);
          triggerSetLongTermCareInsuranceProducts(ltcProducts);
        }
        break;
      case 'umbrella':
        if (
          umbrellaInsuranceProducts &&
          umbrellaInsuranceProducts.length === 0
        ) {
          let { data: umbrellaProducts } =
            await getUmbrellaInsuranceProductsByClientId(clientid);
          umbrellaProducts = this.resolveData(umbrellaProducts);
          triggerSetUmbrellaInsuranceProducts(umbrellaProducts);
        }
        break;
      case 'property':
        if (
          propertyInsuranceProducts &&
          propertyInsuranceProducts.length === 0
        ) {
          let { data: propertyProducts } =
            await getPropertyInsuranceProductsByClientId(clientid);
          propertyProducts = this.resolveData(propertyProducts);
          triggerSetPropertyInsuranceProducts(propertyProducts);
        }
        break;
      case 'vehicle':
        if (autoInsuranceProducts && autoInsuranceProducts.length === 0) {
          let { data: autoProducts } = await getAutoInsuranceProductsByClientId(
            clientid
          );
          autoProducts = this.resolveData(autoProducts);
          triggerSetAutoInsuranceProducts(autoProducts);
        }
        break;
      case 'health':
        if (healthInsuranceProducts && healthInsuranceProducts.length === 0) {
          let { data: healthProducts } = await getInsHealthProductsByClientId(
            clientid
          );
          healthProducts = this.resolveData(healthProducts);
          triggerSetHealthInsuranceProducts(healthProducts);
        }

        break;
      default:
        break;
    }
  };

  addMissingPropertiesToElements = (elements) => {
    // Create a new result object that starts with the template values
    const result = { ...assetMixValuesTemplate };
    // Update result with elements
    elements.forEach(({ cells }) => {
      if (cells.length > 0) {
        result[cells[0]] = cells[1];
      }
    });
    // Return the result as an array of objects (label-value pairs)
    return Object.entries(result).map(([key, value]) => ({
      label: key,
      value,
    }));
  };

  updateArray = ({ aux, main }) => {
    const newArray = [...main, ...aux];
    return newArray.map((item) => ({
      ...item,
      elements:
        item?.rows?.length > 0
          ? this.addMissingPropertiesToElements(item.rows)
          : [],
    }));
  };

  getAssetMixBuildInfo = () => {
    const { tables } = this.props;
    let assetMixBuildInfo = [];
    let targetAssetMixBuildInfo = [];
    const assetMixInfo = tables
      .filter((d) => d.tabName === 'Assets')[0]
      .subtabs.filter(
        (s) => s.subtabName?.toLowerCase() === 'asset mix(current)'
      );
    const targetAssetMixInfo = tables
      .filter((d) => d.tabName === 'Assets')[0]
      .subtabs.filter(
        (s) => s.subtabName?.toLowerCase() === 'asset mix(target)'
      );
    if (
      assetMixInfo &&
      assetMixInfo.length > 0 &&
      targetAssetMixInfo &&
      targetAssetMixInfo.length > 0
    ) {
      assetMixBuildInfo =
        assetMixInfo[0].solutions &&
          assetMixInfo[0].solutions.main &&
          assetMixInfo[0].solutions.main.length > 0 &&
          assetMixInfo[0].solutions.aux.length > 0
          ? this.updateArray(assetMixInfo[0].solutions)
          : [];
      targetAssetMixBuildInfo =
        targetAssetMixInfo[0].solutions &&
          targetAssetMixInfo[0].solutions.main &&
          targetAssetMixInfo[0].solutions.main.length > 0 &&
          targetAssetMixInfo[0].solutions.aux.length > 0
          ? this.updateArray(targetAssetMixInfo[0].solutions)
          : [];
    }
    this.setState({
      assetMixBuildInfo,
      targetAssetMixBuildInfo,
    });
  };

  getSelectedMemberInvestmentTypeAccounts = (selectedMember) => {
    const { localPlanDetailsByPlanId } = this.state;
    const { moduleInvestments: { markets = {} } = {} } =
      localPlanDetailsByPlanId;
    const { first = {}, second = {} } = markets;
    if (selectedMember === 'first') return first;
    return second;
  };

  getMemberOptions = () => {
    const { planDetailsByPlanId } = this.props;
    const { formDetails } = this.state;
    let tempFormDetails = { ...formDetails };
    const {
      moduleFamily: { infoCore = {}, infoVehicles = {} } = {},
      moduleInvestments: { markets = {} } = {},
    } = planDetailsByPlanId;
    const { prodInfo = [], getMarketData = false } = markets;
    const uniqueProdInfo = prodInfo.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.ticker.toLowerCase() === value.ticker.toLowerCase()
        )
    );
    const { basicsFirst = {}, basicsSecond = {} } = infoCore;
    let tempMemberOptions = [];
    if (basicsFirst && Object.keys(basicsFirst).length > 0) {
      const { name = '', age = '', retirementAge = '' } = basicsFirst;
      if (
        tempMemberOptions.indexOf(
          tempMemberOptions.filter((f) => f.key === 'first')
        ) === -1
      ) {
        tempMemberOptions.push({
          key: 'first',
          id: name,
          title: name,
          isSelected: true,
          age: age,
          retirementAge: retirementAge,
        });
      }
    }
    if (
      basicsSecond &&
      Object.keys(basicsSecond).length > 0 &&
      basicsSecond['name'] &&
      basicsSecond['age']
    ) {
      const { name = '', age = '', retirementAge = '' } = basicsSecond;
      if (
        tempMemberOptions.indexOf(
          tempMemberOptions.filter((f) => f.key === 'second')
        ) === -1
      ) {
        tempMemberOptions.push({
          key: 'second',
          id: name,
          title: name,
          isSelected: true,
          age: age,
          retirementAge: retirementAge,
        });
      }
    }
    let selectedPropertyMember = '';
    let selectedAutoMember = '';
    let selectedMember = '';
    const {
      moduleInvestments: { primaryHome, secondHomes, invProps },
    } = planDetailsByPlanId;
    let tempPropertyRadioOptions = [];
    // Define a function to add options to tempPropertyRadioOptions
    const addOptions = (items, prefix) => {
      if (items && items.length > 0) {
        tempPropertyRadioOptions.push(
          ...items.map((item, index) => ({
            key: `${prefix}_${index}`,
            id: item.name,
            title: item.name,
          }))
        );
      }
    };
    // Calculate tempPropertyRadioOptions for primaryHome
    if (primaryHome && primaryHome.prop) {
      const { name } = primaryHome.prop;
      tempPropertyRadioOptions.push({
        key: 'primaryHome',
        id: name,
        title: name,
      });
    }
    // Calculate tempPropertyRadioOptions for secondaryHomes
    secondHomes && addOptions(secondHomes.props, 'secondaryHome'); // revert this after checking with Reva

    // Calculate tempPropertyRadioOptions for invProps
    if (invProps && invProps.props && Array.isArray(invProps.props)) {
      // Loop through the props array and access the name property in each prop object
      invProps.props.forEach((item, index) => {
        if (item && item.prop && item.prop.name) {
          tempPropertyRadioOptions.push({
            key: `invProps_${index}`,
            id: item.prop.name,
            title: item.prop.name,
          });
        }
      });
    }

    const tempAutoRadioOptions = [];
    const addAutoRadioOptions = (vehicles) => {
      if (Array.isArray(vehicles) && vehicles.length > 0) {
        vehicles.forEach((vehicle, index) => {
          tempAutoRadioOptions.push({
            key: `vehicle_${index}`,
            id: vehicle.name, // Replace with the actual property that should be used as 'id'
            title: vehicle.name, // Replace with the actual property that should be used as 'title'
          });
        });
      }
    };
    infoVehicles && addAutoRadioOptions(infoVehicles.vehicles);
    let selectedMemberOption = '';
    if (tempMemberOptions.length > 0) {
      selectedMemberOption = tempMemberOptions[0].key;
      selectedMember =
        this.state.selectedMember === ''
          ? tempMemberOptions[0].id
          : this.state.selectedMember;
    }
    if (tempPropertyRadioOptions.length > 0) {
      selectedPropertyMember =
        this.state.selectedPropertyMember === ''
          ? tempPropertyRadioOptions[0].id
          : this.state.selectedPropertyMember;
    }
    if (tempAutoRadioOptions.length > 0) {
      selectedAutoMember =
        this.state.selectedAutoMember === ''
          ? tempAutoRadioOptions[0].id
          : this.state.selectedAutoMember;
    }
    if (selectedMember) {
      // Update termLifeIns, wholeLifeIns, and ltcIns with selectedMember
      tempFormDetails = {
        ...tempFormDetails,
        termLifeIns: {
          ...tempFormDetails['termLifeIns'],
          insured: selectedMember,
        },
        wholeLifeIns: {
          ...tempFormDetails['wholeLifeIns'],
          insured: selectedMember,
        },
        ltcIns: {
          ...tempFormDetails['ltcIns'],
          insured: selectedMember,
        },
        healthIns: {
          ...tempFormDetails['healthIns'],
          insured: selectedMember,
        },
      };
    }

    if (selectedPropertyMember) {
      // Update propIns with selectedPropertyMember
      tempFormDetails = {
        ...tempFormDetails,
        propIns: {
          ...tempFormDetails['propIns'],
          insured: selectedPropertyMember,
        },
      };
    }

    if (selectedAutoMember) {
      // Update autoIns with selectedPropertyMember
      tempFormDetails = {
        ...tempFormDetails,
        autoIns: {
          ...tempFormDetails['autoIns'],
          insured: selectedAutoMember,
        },
      };
    }
    this.setState({
      isGetMarketDataSelected: getMarketData,
      localProdInfo: uniqueProdInfo,
      formDetails: tempFormDetails,
      memberOptions: tempMemberOptions,
      memberPropertyRadioOptions: tempPropertyRadioOptions,
      memberAutoRadioOptions: tempAutoRadioOptions,
      selectedMember,
      selectedMemberOption, // key of the member option, e.g. first or second
      selectedPropertyMember,
      selectedAutoMember,
      localPlanDetailsByPlanId: planDetailsByPlanId,
    });
  };

  loadDefaultValues = () => {
    const { renderType, activeMainTab } = this.state;
    const { tables, beforeAfterAssetMixValues } = this.props;
    switch (renderType) {
      case 'Chart':
        this.getChartsMenu();
        break;
      case 'Table':
        this.getTableMenu();
        break;
      case 'Data':
        this.getDataMenu();
        break;
      default:
        break;
    }
    if (tables && tables.length > 0 && activeMainTab === 'investments') {
      if (beforeAfterAssetMixValues.length === 0) {
        this.getAssetMixBuildInfo();
      }
    }
  };

  getExistingInsurances = (subTabValue, selectedInsuredInfo) => {
    const { planDetailsByPlanId } = this.props;
    const {
      moduleInsurance: {
        termLife = {},
        wholeLife = {},
        longTermCare = {},
        umbrella = {},
        auto = {},
        property = {},
        health = {},
      },
    } = planDetailsByPlanId;
    const termLifeIns = (termLife && termLife.termLifeIns) || [];
    const wholeLifeIns = (wholeLife && wholeLife.wholeLifeIns) || [];
    const ltcIns = (longTermCare && longTermCare.ltcIns) || [];
    const umbrellaIns = (umbrella && umbrella.umbrellaIns) || [];
    const autoIns = (auto && auto.autoIns) || [];
    const healthIns = (health && health.healthIns) || [];
    const propIns = (property && property.propIns) || [];
    let existingInsurances = [];
    switch (subTabValue?.toLowerCase()) {
      case 'term life':
        existingInsurances = termLifeIns.filter(
          (t) => t.insured === selectedInsuredInfo.insured
        );
        break;
      case 'whole life':
        existingInsurances = wholeLifeIns.filter(
          (t) => t.insured === selectedInsuredInfo.insured
        );
        break;
      case 'long term care':
        existingInsurances = ltcIns.filter(
          (t) => t.insured === selectedInsuredInfo.insured
        );
        break;
      case 'umbrella':
        existingInsurances = umbrellaIns.filter(
          (t) => t.insured === selectedInsuredInfo.insured
        );
        break;
      case 'property':
        existingInsurances = propIns.filter(
          (t) => t.insured === selectedInsuredInfo.insured
        );
        break;
      case 'vehicle':
        existingInsurances = autoIns.filter(
          (t) => t.insured === selectedInsuredInfo.insured
        );
        break;
      case 'health':
        existingInsurances = healthIns.filter(
          (t) => t.insured === selectedInsuredInfo.insured
        );
        break;
      default:
        break;
    }
    return existingInsurances;
  };

  categorizeAndTransformEvents = (events, memberOptions) => {
    const retirementEvents = [];
    const otherEvents = [];
    events.forEach((e) => {
      if (e.goalCategory?.toLowerCase() === 'retirement') {
        retirementEvents.push({
          ...e,
          retirementAge:
            memberOptions.find((o) => o.id === e.name)?.retirementAge || '',
        });
      } else {
        otherEvents.push(e);
      }
    });
    return [...retirementEvents, ...otherEvents];
  };

  setIsSimulateClicked = (value) => {
    this.setState({ isSimulateClicked: value });
  };

  renderGoalsContainer = () => {
    const { solutions = {}, simulatePlanDetailsLoading } = this.props;
    const { memberOptions = [], isSimulateClicked } = this.state;
    const { goals = {} } = solutions || {};
    const { milestones = {} } = goals || {};
    const { event = [] } = milestones || {};
    const updatedEvents = this.categorizeAndTransformEvents(
      event,
      memberOptions
    );
    return (
      <div className="chrono-charts-container">
        <GoalsContainer
          simulatePlanDetailsLoading={simulatePlanDetailsLoading}
          goals={updatedEvents}
          isSimulateClicked={isSimulateClicked}
          setIsSimulateClicked={this.setIsSimulateClicked}
        />
      </div>
    );
  };

  renderFutureImplementationContainer = () => {
    return (
      <div className="chrono-charts-container">For Future Implementation</div>
    );
  };

  getChartsMenu = () => {
    const { charts, triggerSetSelectedChart } = this.props;
    const { parentTabValue, subTabValue } = this.state;
    let chartInfo = [];
    if (charts && charts.length > 0) {
      chartInfo = charts.map((c) => {
        return {
          tabName: c.tabName,
          subMenu: c.subtabs,
        };
      });
      const selectedChart =
        (chartInfo[parentTabValue] &&
          chartInfo[parentTabValue].subMenu &&
          chartInfo[parentTabValue].subMenu.length > 0 &&
          chartInfo[parentTabValue].subMenu[subTabValue].subtabName) ||
        '';
      triggerSetSelectedChart(selectedChart);
    }

    this.setState({
      tabInfo: chartInfo,
    });
  };

  getTableMenu = () => {
    const { tables, triggerSetSelectedTable } = this.props;
    const { parentTabValue, subTabValue } = this.state;
    let tableInfo = [];
    if (tables && tables.length > 0) {
      tableInfo = tables.map((c) => {
        return {
          tabName: c.tabName,
          subMenu: c.subtabs,
        };
      });
      const selectedTable =
        (tableInfo[parentTabValue] &&
          tableInfo[parentTabValue].subMenu &&
          tableInfo[parentTabValue].subMenu.length > 0 &&
          tableInfo[parentTabValue].subMenu[subTabValue].subtabName) ||
        '';
      triggerSetSelectedTable(selectedTable);
    }
    this.setState({
      tabInfo: tableInfo,
    });
  };

  getDataMenu = () => {
    const { data, triggerSetSelectedData } = this.props;
    const { parentTabValue, subTabValue } = this.state;
    let dataInfo = [];
    if (data && data.length > 0) {
      dataInfo = data.map((c) => {
        return {
          tabName: c.tabName,
          subMenu: c.subtabs,
        };
      });
      const selectedData =
        (dataInfo[parentTabValue] &&
          dataInfo[parentTabValue].subMenu &&
          dataInfo[parentTabValue].subMenu.length > 0 &&
          dataInfo[parentTabValue].subMenu[subTabValue].subtabName) ||
        '';
      triggerSetSelectedData(selectedData);
    }
    this.setState({
      tabInfo: dataInfo,
    });
  };

  transformToApiType = (fieldValue) => {
    let convertedValue = fieldValue;
    if (isNaN(fieldValue) && fieldValue.includes('$')) {
      convertedValue = parseFloat(fieldValue.split('$')[1].replace(/,/g, ''));
    }
    return convertedValue;
  };

  handleDeleteInsurance = (tab, mainTab, countVal, selectedAccountName) => {
    const { existingInsurances, formDetails, selectedMember } = this.state;
    let { planDetailsByPlanId, triggerSimulatePlanDetailsByPlanId } =
      this.props;
    let { moduleInsurance } = { ...planDetailsByPlanId };
    let tempModuleInsurance = { ...moduleInsurance };
    let tempInsurances = [...tempModuleInsurance[mainTab][tab]];
    let tempCountVal = tempModuleInsurance[mainTab][countVal];

    // Find the index of the insurance entry to be deleted based on selectedAccountName
    const indexToDelete = tempInsurances.findIndex((ins) =>
      mainTab &&
        ['termLife', 'wholeLife', 'longTermCare', 'propIns', 'autoIns'].includes(
          mainTab
        )
        ? ins.insured === selectedMember &&
        ins.name.toLowerCase() === selectedAccountName.toLowerCase()
        : ins.name.toLowerCase() === selectedAccountName.toLowerCase()
    );

    if (indexToDelete !== -1) {
      // Remove the insurance entry from the temporary array and log it
      const tempDeletedInsurance = tempInsurances.splice(indexToDelete, 1);
      // console.log('Deleted Insurance:', tempDeletedInsurance);
      // Update the moduleInsurance object with the modified insurances
      tempModuleInsurance[mainTab][tab] = tempInsurances;
      tempModuleInsurance[mainTab][countVal] = tempCountVal - 1;

      // Filter the existingInsurances outside of setState
      const updatedExistingInsurances = existingInsurances.filter(
        (ins) =>
          ins.insured !== selectedMember ||
          ins.name.toLowerCase() !== selectedAccountName.toLowerCase()
      );
      // Update the state and trigger a simulation
      this.setState(
        (prevState) => ({
          ...prevState,
          moduleInsurance: tempModuleInsurance,
          existingInsurances: updatedExistingInsurances,
          deleteInsurance: true,
        }),
        () => {
          // Trigger the simulation with the updated plan details
          triggerSimulatePlanDetailsByPlanId({
            displayTermChanged: false,
            chrono: {},
            plannerFormReq: {
              ...planDetailsByPlanId,
              moduleInsurance: tempModuleInsurance,
            },
            version: 'v2',
          });
        }
      );
    }
  };

  getTabInfo = () => {
    const { activeSubTab, activeMainTab } = this.state;
    if (activeMainTab === 'insurance') {
      switch (activeSubTab.toLowerCase()) {
        case 'term life':
          return { tab: 'termLifeIns', mainTab: 'termLife' };
        case 'whole life':
          return { tab: 'wholeLifeIns', mainTab: 'wholeLife' };
        case 'umbrella':
          return { tab: 'umbrellaIns', mainTab: 'umbrella' };
        case 'long term care':
          return { tab: 'ltcIns', mainTab: 'longTermCare' };
        case 'property':
          return { tab: 'propIns', mainTab: 'property' };
        case 'vehicle':
          return { tab: 'autoIns', mainTab: 'auto' };
        case 'health':
          return { tab: 'healthIns', mainTab: 'health' };
        default:
          break;
      }
    }
  };

  handleAddToPortfolio = (tab, mainTab) => {
    const {
      formDetails,
      existingInsurances,
      selectedMember,
      selectedPropertyMember,
      selectedAutoMember,
      localPlanDetailsByPlanId,
    } = this.state;
    let { triggerUpdatePlannerDetailsinRedux } = this.props;
    let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    let {
      moduleInsurance: {
        termLife = {},
        wholeLife = {},
        longTermCare = {},
        umbrella = {},
        auto = {},
        property = {},
        health = {},
      },
      moduleInsurance = {},
      moduleSettings: { model = {} } = {},
      moduleSettings = {},
    } = tempPlanDetailsByPlanId;
    const termLifeIns = (termLife && termLife.termLifeIns) || [];
    const wholeLifeIns = (wholeLife && wholeLife.wholeLifeIns) || [];
    const ltcIns = (longTermCare && longTermCare.ltcIns) || [];
    const umbrellaIns = (umbrella && umbrella.umbrellaIns) || [];
    const autoIns = (auto && auto.autoIns) || [];
    const healthIns = (health && health.healthIns) || [];
    const propIns = (property && property.propIns) || [];
    let tempTermLifeIns = [...termLifeIns];
    let tempWholeLifeIns = [...wholeLifeIns];
    let tempLongTermCare = [...ltcIns];
    let tempLiabilityIns = [...umbrellaIns];
    let tempPropIns = [...propIns];
    let tempAutoIns = [...autoIns];
    let tempHealthIns = [...healthIns];
    let tempInsuredDetails = formDetails[tab];
    let tempModel = { ...model, runCode: 600 };
    let addNew = false;
    tempInsuredDetails = {
      ...tempInsuredDetails,
      premium:
        (tempInsuredDetails.premium &&
          this.transformToApiType(tempInsuredDetails.premium)) ||
        0,
      coverage:
        (tempInsuredDetails.coverage &&
          this.transformToApiType(tempInsuredDetails.coverage)) ||
        0,
      cashValueAnnual:
        (tempInsuredDetails.cashValueAnnual &&
          this.transformToApiType(tempInsuredDetails.cashValueAnnual)) ||
        0,
      coverageStartYr: tempInsuredDetails.coverageStartYr?.getFullYear(),
    };
    switch (tab) {
      case 'termLifeIns':
        let existingTermInsuredInfoIndex = tempTermLifeIns.indexOf(
          tempTermLifeIns.filter(
            (t) =>
              t.insured &&
              tempInsuredDetails.insured &&
              t.insured.toLowerCase() ===
              tempInsuredDetails.insured.toLowerCase() &&
              t.name.toLowerCase() === tempInsuredDetails.name.toLowerCase()
          )[0]
        );
        if (existingTermInsuredInfoIndex > -1) {
          let existingInsuredInfo =
            tempTermLifeIns[existingTermInsuredInfoIndex];
          existingInsuredInfo = {
            ...existingInsuredInfo,
            ...tempInsuredDetails,
          };
          tempTermLifeIns[existingTermInsuredInfoIndex] = existingInsuredInfo;
        } else {
          addNew = true;
          const otherFields = {
            cashValueAnnual: 0,
            cashValueDistributionYrs: 0,
            deductible: 0,
            premiumGrowth: 0,
            renewAtExpiry: false,
          };
          const completeObject = { ...tempInsuredDetails, ...otherFields };
          tempTermLifeIns.push(completeObject);
        }
        break;
      case 'ltcIns':
        const existingLTCInsuredInfoIndex = tempLongTermCare.indexOf(
          tempLongTermCare.filter(
            (t) =>
              t.insured &&
              tempInsuredDetails.insured &&
              t.insured.toLowerCase() ===
              tempInsuredDetails.insured.toLowerCase() &&
              t.name.toLowerCase() === tempInsuredDetails.name.toLowerCase()
          )[0]
        );
        if (existingLTCInsuredInfoIndex > -1) {
          let existingInsuredInfo =
            tempLongTermCare[existingLTCInsuredInfoIndex];
          existingInsuredInfo = {
            ...existingInsuredInfo,
            ...tempInsuredDetails,
          };
          tempLongTermCare[existingLTCInsuredInfoIndex] = existingInsuredInfo;
        } else {
          addNew = true;
          const otherFields = {
            deductible: 0,
            premiumGrowth: 0,
            renewAtExpiry: false,
            coverage: 0,
            coverageYrs: 0,
          };
          const completeObject = { ...tempInsuredDetails, ...otherFields };
          tempLongTermCare.push(completeObject);
        }
        break;
      case 'umbrellaIns':
        let existingLiabilityInsuredInfoIndex = tempLiabilityIns.indexOf(
          tempLiabilityIns.filter(
            (t) =>
              t.insured &&
              tempInsuredDetails.insured &&
              t.insured.toLowerCase() ===
              tempInsuredDetails.insured.toLowerCase() &&
              t.name.toLowerCase() === tempInsuredDetails.name.toLowerCase()
          )[0]
        );
        if (existingLiabilityInsuredInfoIndex > -1) {
          let existingInsuredInfo =
            tempLiabilityIns[existingLiabilityInsuredInfoIndex];
          existingInsuredInfo = {
            ...existingInsuredInfo,
            ...tempInsuredDetails,
          };
          tempLiabilityIns[existingLiabilityInsuredInfoIndex] =
            existingInsuredInfo;
        } else {
          addNew = true;
          const otherFields = {
            cashValueAnnual: 0,
            coverageYrs: 1,
            cashValueDistributionYrs: 0,
            deductible: 0,
            // premiumGrowth: 0,
            renewAtExpiry: true,
            insured: 'family',
          };
          const completeObject = { ...tempInsuredDetails, ...otherFields };
          tempLiabilityIns.push(completeObject);
        }
        break;
      case 'wholeLifeIns':
        let existingWholeLifeInsuredInfoIndex = tempWholeLifeIns.indexOf(
          tempWholeLifeIns.filter(
            (t) =>
              t.insured &&
              tempInsuredDetails.insured &&
              t.insured.toLowerCase() ===
              tempInsuredDetails.insured.toLowerCase() &&
              t.name.toLowerCase() === tempInsuredDetails.name.toLowerCase()
          )[0]
        );
        if (existingWholeLifeInsuredInfoIndex > -1) {
          let existingInsuredInfo =
            tempWholeLifeIns[existingWholeLifeInsuredInfoIndex];
          existingInsuredInfo = {
            ...existingInsuredInfo,
            ...tempInsuredDetails,
          };
          tempWholeLifeIns[existingWholeLifeInsuredInfoIndex] =
            existingInsuredInfo;
        } else {
          addNew = true;
          const otherFields = {
            cashValueDistributionYrs: 1,
            deductible: 0,
            premiumGrowth: 0,
            renewAtExpiry: false,
          };
          const completeObject = { ...tempInsuredDetails, ...otherFields };
          tempWholeLifeIns.push(completeObject);
        }
        break;
      case 'propIns':
        let existingPropertyInsuredInfoIndex = tempPropIns.indexOf(
          tempPropIns.filter(
            (t) =>
              t.insured &&
              tempInsuredDetails.insured &&
              t.insured.toLowerCase() ===
              tempInsuredDetails.insured.toLowerCase() &&
              t.name.toLowerCase() === tempInsuredDetails.name.toLowerCase()
          )[0]
        );
        if (existingPropertyInsuredInfoIndex > -1) {
          let existingInsuredInfo =
            tempPropIns[existingPropertyInsuredInfoIndex];
          existingInsuredInfo = {
            ...existingInsuredInfo,
            ...tempInsuredDetails,
          };
          tempPropIns[existingPropertyInsuredInfoIndex] = existingInsuredInfo;
        } else {
          addNew = true;
          const otherFields = {
            cashValueAnnual: 0,
            cashValueDistributionYrs: 0,
            deductible: 0,
            premiumGrowth: 0,
            renewAtExpiry: false,
          };
          const completeObject = { ...tempInsuredDetails, ...otherFields };
          tempPropIns.push(completeObject);
        }
        break;
      case 'autoIns':
        let existingAutoInsuredInfoIndex = tempAutoIns.indexOf(
          tempAutoIns.filter(
            (t) =>
              t.insured &&
              tempInsuredDetails.insured &&
              t.insured.toLowerCase() ===
              tempInsuredDetails.insured.toLowerCase() &&
              t.name.toLowerCase() === tempInsuredDetails.name.toLowerCase()
          )[0]
        );
        if (existingAutoInsuredInfoIndex > -1) {
          let existingInsuredInfo = tempAutoIns[existingAutoInsuredInfoIndex];
          existingInsuredInfo = {
            ...existingInsuredInfo,
            ...tempInsuredDetails,
          };
          tempAutoIns[existingAutoInsuredInfoIndex] = existingInsuredInfo;
        } else {
          addNew = true;
          const otherFields = {
            cashValueAnnual: 0,
            cashValueDistributionYrs: 0,
            deductible: 0,
            premiumGrowth: 0,
            renewAtExpiry: false,
          };
          const completeObject = { ...tempInsuredDetails, ...otherFields };
          tempAutoIns.push(completeObject);
        }
        break;

      case 'healthIns':
        let existingHealthInsuredInfoIndex = tempHealthIns.indexOf(
          tempHealthIns.filter(
            (t) =>
              t.insured &&
              tempInsuredDetails.insured &&
              t.insured.toLowerCase() ===
              tempInsuredDetails.insured.toLowerCase() &&
              t.name.toLowerCase() === tempInsuredDetails.name.toLowerCase()
          )[0]
        );
        if (existingHealthInsuredInfoIndex > -1) {
          let existingInsuredInfo =
            tempHealthIns[existingHealthInsuredInfoIndex];
          existingInsuredInfo = {
            ...existingInsuredInfo,
            ...tempInsuredDetails,
          };
          tempHealthIns[existingHealthInsuredInfoIndex] = existingInsuredInfo;
        } else {
          addNew = true;
          const otherFields = {
            cashValueDistributionYrs: 0,
            coverageYrs: 1,
            deductible: 0,
            renewAtExpiry: true,
          };
          const completeObject = { ...tempInsuredDetails, ...otherFields };
          tempHealthIns.push(completeObject);
        }
        break;
      default:
        break;
    }
    termLife = {
      ...termLife,
      termLifeIns: [...tempTermLifeIns],
      numTermLifeIns: tempTermLifeIns.length,
    };
    wholeLife = {
      ...wholeLife,
      wholeLifeIns: [...tempWholeLifeIns],
      numWholeLifeIns: tempWholeLifeIns.length,
    };
    umbrella = {
      ...umbrella,
      umbrellaIns: [...tempLiabilityIns],
      numUmbrellaIns: tempLiabilityIns.length,
    };
    longTermCare = {
      ...longTermCare,
      ltcIns: [...tempLongTermCare],
      numLtcIns: tempLongTermCare.length,
    };
    property = {
      ...property,
      propIns: [...tempPropIns],
      numPropIns: tempPropIns.length,
    };
    auto = {
      ...auto,
      autoIns: [...tempAutoIns],
      numAutoIns: tempAutoIns.length,
    };
    health = {
      ...health,
      healthIns: [...tempHealthIns],
      numHealthIns: tempHealthIns.length,
    };
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      moduleInsurance: {
        ...moduleInsurance,
        termLife: { ...termLife },
        wholeLife: { ...wholeLife },
        umbrella: { ...umbrella },
        longTermCare: { ...longTermCare },
        property: { ...property },
        health: { ...health },
        auto: { ...auto },
      },
      moduleSettings: {
        ...moduleSettings,
        model: { ...tempModel },
      },
      // infoInputsCompleted: {
      //   ...infoInputsCompleted,
      //   termLifeIns: tempTermLifeIns.length > 0 ? true : false,
      //   wholeLifeIns: tempWholeLifeIns.length > 0 ? true : false,
      //   umbrellaIns: tempLiabilityIns.length > 0 ? true : false,
      //   longTermIns: tempLongTermCare.length > 0 ? true : false,
      // },
    };
    let existingIns = existingInsurances;
    if (addNew === true) {
      let filterValue =
        tab === 'umbrellaIns'
          ? 'family'
          : tab === 'propIns'
            ? selectedPropertyMember
            : tab === 'autoIns'
              ? selectedAutoMember
              : selectedMember;
      existingIns = tempPlanDetailsByPlanId.moduleInsurance[mainTab][
        tab
      ].filter((t) => t.insured === filterValue);
    }
    //const newFormDetails = this.getInitialFormDetails();
    this.setState(
      (prevState) => ({
        ...prevState,
        existingInsurances: existingIns,
        isComputed: false,
        isEditable: false,
        isAddNew: false,
        selectionModel: {
          ...prevState.selectionModel,
          [tab]: undefined,
        },
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
      }),
      () => {
        triggerUpdatePlannerDetailsinRedux({
          plannerData: tempPlanDetailsByPlanId,
        });
      }
    );
  };

  handleCancelInsurance = (tab, from) => {
    const { isEditable, isAddNew, formDetails, localPlanDetailsByPlanId } =
      this.state;
    let tempFormDetails = { ...formDetails };
    let selectedInsuredInfo =
      tempFormDetails &&
        tempFormDetails[tab] &&
        tempFormDetails[tab][from] !== ''
        ? tempFormDetails[tab]
        : {};
    if (isEditable && tab !== '' && from !== '') {
      tempFormDetails = this.updateFormDetails(
        selectedInsuredInfo,
        tempFormDetails
      );
      this.setState({
        isEditable: false,
        isAddNew: false,
        formDetails: tempFormDetails,
        isFormValid: true,
        formErrors: [],
      });
    }
  };

  getCurrencyValue = (value) => {
    if (value !== '' && !value.toString().includes('$')) {
      const currencyValue = !isNaN(value) ? value : '';
      const numFormat = Intl.NumberFormat('en-US');
      const newNumformat = numFormat.format(currencyValue);
      return `$${newNumformat}`;
    }
    return value;
  };

  getAccounts = (selectedMemberState) => {
    let accounts = [];
    accounts = Object.entries(selectedMemberState).reduce(
      (acc, [key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            const index = acc.indexOf(
              acc.filter(
                (a) =>
                  a.id.toLowerCase() === `${item.nickName}-${key}`.toLowerCase()
              )[0]
            );
            if (index === -1) {
              acc.push({
                ...item,
                investmentBucket: key,
                invBalance: this.getCurrencyValue(item.invBalance),
                id: `${item.nickName}-${key}`,
              });
            }
          });
        }
        return acc;
      },
      []
    );
    return accounts;
  };

  handleMemberOptionChange = (e, type) => {
    const { memberOptions } = this.state;
    let fieldValue =
      e && type === 'radio'
        ? e.target.checked
          ? e.target.defaultValue
          : false
        : e.target.value;
    const selectedMemberOption =
      memberOptions.filter((m) => m.id === fieldValue)[0]?.key || '';
    this.setState({
      selectedMember: fieldValue,
      selectedMemberOption,
      selectedInvestmentAccount: {},
      selectedProductType: '',
      tickers: [],
      selectedTicker: '',
      tickersAddedToPlan: [],
      tickersAvailableInPlan: [],
    });
  };

  handleCheckboxFieldChange = (e, index) => {
    const { assetMixBuildInfo, memberOptions } = this.state;
    let { checked } = e && e.target;
    let tempMemberCheckboxOptions = [...memberOptions];
    tempMemberCheckboxOptions[index].isSelected = checked;
    let selectedMembers = tempMemberCheckboxOptions.filter((f) => f.isSelected);
    let selectedMember = '';
    if (selectedMembers && selectedMembers.length > 0) {
      if (selectedMembers.length === tempMemberCheckboxOptions.length) {
        selectedMember = 'family';
      } else {
        selectedMember = selectedMembers[0].id;
      }
      const selectedMemberLowerCase = selectedMember.toLowerCase();
      const selectedAssetMixInfo = assetMixBuildInfo.filter(
        ({ heading }) =>
          heading?.length > 0 &&
          heading[0].toLowerCase() === selectedMemberLowerCase
      );
      this.setState({
        memberOptions: tempMemberCheckboxOptions,
        selectedAssetMix:
          selectedAssetMixInfo.length > 0
            ? selectedAssetMixInfo[0].elements
            : [],
      });
    }
  };

  transformValue = (val, valueType) => {
    let value = val;
    if (valueType === 'currency') {
      const numFormat = Intl.NumberFormat('en-US');
      let newNumformat = numFormat.format(0);
      let currencyFieldValue = '';
      if (value !== null && value !== undefined && value !== '') {
        if (value.toString().includes('$')) {
          currencyFieldValue = value.split('$')[1];
          currencyFieldValue = currencyFieldValue.replace(/,/g, '');
          if (currencyFieldValue !== '' && !isNaN(currencyFieldValue)) {
            newNumformat = numFormat.format(currencyFieldValue);
          }
        } else if (!isNaN(value)) {
          newNumformat = numFormat.format(value);
        }
        value = `$${newNumformat}`;
      } else {
        value = `$${newNumformat}`;
      }
    } else if (valueType === 'number') {
      if (value !== '' && value !== null && value !== undefined) {
        if (!isNaN(value) && Number(value) >= 0) {
          value = Number(value);
        } else {
          value = 0;
        }
      } else {
        value = 0;
      }
    }

    return value;
  };

  rowClick = (params, event, details, tab) => {
    const { row } = params;
    if (row && Object.keys(row).length > 0) {
      this.setState((prevState) => ({
        ...prevState,
        formDetails: {
          ...prevState.formDetails,
          [tab]: {
            ...prevState.formDetails[tab],
            coverage: row.coverage,
            coverageYrs: row.coverageYrs,
            premium: row.premium,
            cashValueAnnual: row.cashValueAnnual,
            cashValueDistributionYrs: row.cashValueDistributionYrs,
          },
        },
      }));
    }
  };

  setSelectionModel = (newSelectionModel, tab) => {
    this.setState((prevState) => ({
      ...prevState,
      selectionModel: {
        ...prevState.selectionModel,
        [tab]: newSelectionModel[0],
      },
    }));
  };

  concatInsurances = (type) => {
    const { formDetails, existingInsurances } = this.state;
    const existingInsuranceOptions = existingInsurances.map((m) => {
      return {
        title: m.name,
        id: m.name,
      };
    });
    // const finalInsurances =
    //   formDetails && formDetails[type] && formDetails[type].insured !== ''
    //     ? [...[{ title: 'Add New', id: 'addNew' }], ...existingInsuranceOptions]
    //     : existingInsuranceOptions;
    return existingInsuranceOptions;
  };

  updateFormDetails = (selectedInsuredInfo, tempFormDetails) => {
    const { activeSubTab } = this.state;
    let existingInsurances = this.getExistingInsurances(
      activeSubTab,
      selectedInsuredInfo
    );
    const selectedInsurance = existingInsurances.filter(
      (f) => f.insured === selectedInsuredInfo.insured
    )[0];
    const tab = this.getTabNames(activeSubTab);
    if (selectedInsurance && Object.keys(selectedInsurance).length > 0) {
      tempFormDetails = {
        ...tempFormDetails,
        [tab]: {
          ...tempFormDetails[tab],
          coverage: (selectedInsurance && selectedInsurance.coverage) || '',
          coverageYrs:
            (selectedInsurance && selectedInsurance.coverageYrs) || '',
          coverageStartYr:
            (selectedInsurance &&
              new Date(`${selectedInsurance.coverageStartYr}`)) ||
            new Date(),
          premium: (selectedInsurance && selectedInsurance.premium) || '',
          cashValueAnnual:
            (selectedInsurance && selectedInsurance.cashValueAnnual) || '',
          cashValueDistributionYrs:
            (selectedInsurance && selectedInsurance.cashValueDistributionYrs) ||
            '',
          name: (selectedInsurance && selectedInsurance.name) || '',
        },
      };
    } else {
      tempFormDetails = {
        ...tempFormDetails,
        [tab]: {
          ...tempFormDetails[tab],
          coverage: '',
          coverageYrs: '',
          coverageStartYr: new Date(),
          premium: '',
          cashValueAnnual: '',
          cashValueDistributionYrs: '',
          name: '',
        },
      };
    }
    this.setState({
      existingInsurances,
    });
    return tempFormDetails;
  };

  handleBlurForInsurance = (event, from, type, tab, valueType) => {
    let { value } = event && event.target;
    const { formDetails } = this.state;
    let tempFormDetails = { ...formDetails };
    if (valueType === 'percentage') {
      let percentFieldValue = '';
      if (value !== null && value !== undefined && value !== '') {
        if (value.toString().includes('%')) {
          percentFieldValue = value.split('%')[0];
          if (
            percentFieldValue !== '' &&
            !isNaN(percentFieldValue) &&
            Number(percentFieldValue) >= 0
          ) {
            percentFieldValue = parseFloat(percentFieldValue).toFixed(2);
            value = `${percentFieldValue}%`;
          } else {
            value = `${0}%`;
          }
        } else if (!isNaN(value) && Number(value) >= 0) {
          percentFieldValue = parseFloat(value).toFixed(2);
          value = `${percentFieldValue}%`;
        } else {
          value = `${0}%`;
        }
      } else {
        value = `${0}%`;
      }
    }
    if (valueType === 'currency') {
      const numFormat = Intl.NumberFormat('en-US');
      let newNumformat = numFormat.format(0);
      let currencyFieldValue = '';
      if (value !== null && value !== undefined && value !== '') {
        if (value.toString().includes('$')) {
          currencyFieldValue = value.split('$')[1];
          currencyFieldValue = currencyFieldValue.replace(/,/g, '');
          if (
            currencyFieldValue !== '' &&
            !isNaN(currencyFieldValue) &&
            Number(currencyFieldValue) >= 0
          ) {
            newNumformat = numFormat.format(currencyFieldValue);
          }
        } else if (!isNaN(value) && Number(value) >= 0) {
          newNumformat = numFormat.format(value);
        }
        value = `$${newNumformat}`;
      } else {
        value = `$${newNumformat}`;
      }
    }
    if (valueType === 'number') {
      if (value !== '' && value !== null && value !== undefined) {
        if (!isNaN(value) && Number(value) >= 0) {
          value = Number(value);
        } else {
          value = 0;
        }
      } else {
        value = 0;
      }
    }
    let valueToApi = '';
    if (type === 'input') {
      if (value !== null && value !== undefined && value !== '') {
        if (isNaN(value) && value.includes('%')) {
          const percentValue = value.split('%')[0];
          if (!isNaN(percentValue)) {
            valueToApi = parseFloat(parseFloat(percentValue) / 100);
          } else {
            valueToApi = 0;
          }
        } else if (isNaN(value) && value.includes('$')) {
          let dollarValue = value.split('$')[1];
          dollarValue = dollarValue.replace(/,/g, '');
          if (!isNaN(dollarValue)) {
            valueToApi = Number(dollarValue);
          } else {
            valueToApi = 0;
          }
        } else if (!isNaN(value)) {
          valueToApi = Number(value);
        }
      } else {
        valueToApi = 0;
      }
    }
    // Dynamically fetch the moduleTab object based on tab

    tempFormDetails = {
      ...tempFormDetails,
      [tab]: {
        ...tempFormDetails[tab],
        [from]: value,
      },
    };

    this.setState({
      formDetails: tempFormDetails,
    });
    // setLocalPlanDetailsByPlanId(tempPlanDetailsByPlanId, ageModified);
  };

  handleHealthInsCheckboxFieldChange = (event, index, checkedValue) => {
    const { formDetails, memberOptions } = this.state;
    let { checked } = event && event.target;
    let tempFormDetails = { ...formDetails };

    const updatedOptions = memberOptions.map((option, i) =>
      i === index ? { ...option, isSelected: checked } : option
    );
    let insuredValue = '';
    if (updatedOptions.every((option) => option.isSelected)) {
      insuredValue = 'family';
    } else {
      const selectedOptions = updatedOptions.filter(
        (option) => option.isSelected
      );
      insuredValue = selectedOptions.length === 1 ? selectedOptions[0].id : '';
    }

    tempFormDetails = {
      ...tempFormDetails,
      healthIns: {
        ...tempFormDetails.healthIns,
        insured: insuredValue,
      },
    };
    let selectedInsuredInfo =
      tempFormDetails &&
        tempFormDetails.healthIns &&
        tempFormDetails['healthIns']['insured'] !== ''
        ? tempFormDetails['healthIns']
        : {};
    tempFormDetails = this.updateFormDetails(
      selectedInsuredInfo,
      tempFormDetails
    );
    this.setState({
      formDetails: { ...tempFormDetails },
      memberOptions: updatedOptions,
    });
  };

  handleFormFieldChangeInsurance = (e, from, type, tab, addNew = false) => {
    const {
      formDetails,
      activeSubTab,
      selectedMember,
      selectedPropertyMember,
      selectedAutoMember,
      selectedInsurance,
      existingInsurances,
    } = this.state;
    let tempFormDetails = { ...formDetails };
    let fieldValue =
      e && type === 'radio'
        ? e.target.checked
          ? e.target.defaultValue
          : false
        : e && type === 'datepicker'
          ? e
          : e.target.value;
    let selectedMemberValue = selectedMember;
    let selectedPropertyValue = selectedPropertyMember;
    let selectedAutoValue = selectedAutoMember;
    if (type === 'radio') {
      selectedMemberValue = fieldValue;
      if (tab === 'propIns') {
        selectedPropertyValue = fieldValue;
      }
      if (tab === 'autoIns') {
        selectedAutoValue = fieldValue;
      }
    }
    tempFormDetails = {
      ...tempFormDetails,
      [tab]: {
        ...tempFormDetails[tab],
        [from]: fieldValue,
      },
    };
    if (type === 'input') {
      fieldValue =
        fieldValue !== '' && fieldValue !== undefined && fieldValue !== null
          ? isNaN(fieldValue) && fieldValue.includes('%')
            ? parseFloat(parseFloat(fieldValue.split('%')[0]) / 100)
            : isNaN(fieldValue) && fieldValue.includes('$')
              ? fieldValue.split('$')[1].replace(/,/g, '') &&
                !isNaN(fieldValue.split('$')[1].replace(/,/g, ''))
                ? parseFloat(fieldValue.split('$')[1].replace(/,/g, ''))
                : fieldValue
              : !isNaN(fieldValue)
                ? Number(fieldValue)
                : fieldValue
          : '';
    } else if (type === 'radio' && from === 'insured') {
      let selectedInsuredInfo =
        tempFormDetails &&
          tempFormDetails[tab] &&
          tempFormDetails[tab][from] !== ''
          ? tempFormDetails[tab]
          : {};
      tempFormDetails = this.updateFormDetails(
        selectedInsuredInfo,
        tempFormDetails
      );
    }
    if (from !== 'name' && from === 'insured' && addNew === true) {
      const fieldValue =
        tab === 'umbrellaIns'
          ? 'family'
          : tab === 'propIns'
            ? selectedPropertyMember
            : tab === 'autoIns'
              ? selectedAutoMember
              : selectedMember;

      tempFormDetails = {
        ...tempFormDetails,
        insured:
          tab === 'umbrellaIns'
            ? 'family'
            : tab === 'propIns'
              ? selectedPropertyMember
              : tab === 'autoIns'
                ? selectedAutoMember
                : selectedMember,
        [tab]: {
          [from]: fieldValue,
          coverage: '', // clear other values except insured when change of insured value (e.g. mom or dad)
          coverageYrs: '',
          premium: '',
          cashValueAnnual: '',
          cashValueDistributionYrs: '',
          coverageStartYr: new Date(),
          name: '',
        },
      };
    }
    // else {
    //   // update individual field on change event by retaining existing fields
    //   tempFormDetails = {
    //     ...tempFormDetails,
    //     [tab]: {
    //       ...tempFormDetails[tab],
    //       [from]: fieldValue,
    //     },
    //   };
    // }

    this.setState((prevState) => ({
      ...prevState,
      selectedPropertyMember:
        tab === 'propIns'
          ? selectedPropertyValue
          : prevState.selectedPropertyMember,
      selectedAutoMember:
        tab === 'autoIns' ? selectedAutoMember : prevState.selectedAutoMember,
      selectedMember:
        tab === 'healthIns'
          ? tempFormDetails[tab]['insured']
          : tab !== 'propIns' || tab !== 'autoIns'
            ? selectedMemberValue
            : prevState.selectedMember,
      isComputed:
        type === 'radio' && from === 'insured' ? false : prevState.isComputed,
      formDetails: { ...tempFormDetails },
      selectionModel: {
        ...prevState.selectionModel,
        [tab]: type === 'radio' && from === 'insured' ? undefined : [tab],
      },
    }));
    if (type === 'radio') {
      this.setState({ isEditable: false, isAddNew: false });
    } else if (addNew === true) {
      this.setState({ isEditable: true, isAddNew: true });
    }
  };

  renderTermLifeContent = (selectedChart) => {
    return (
      <div>
        <TermLifeContent
          termLifeInsuranceProducts={this.props.termLifeInsuranceProducts}
          state={this.state}
          concatInsurances={this.concatInsurances}
          transformValue={this.transformValue}
          handleFormFieldChangeInsurance={this.handleFormFieldChangeInsurance}
          handleBlur={this.handleBlur}
          onAccountChange={this.onAccountChange}
          setSelectionModel={this.setSelectionModel}
          rowClick={this.rowClick}
          handleEditInsurance={this.handleEditInsurance}
          handleAddToPortfolio={this.handleAddToPortfolio}
          handleDeleteInsurance={this.handleDeleteInsurance}
          handleCancelInsurance={this.handleCancelInsurance}
          setLastStepInParent={this.setIsLastStep}
        />
      </div>
    );
  };

  renderWholeLifeContent = () => {
    return (
      <div>
        <WholeLifeContent
          wholeLifeInsuranceProducts={this.props.wholeLifeInsuranceProducts}
          state={this.state}
          concatInsurances={this.concatInsurances}
          transformValue={this.transformValue}
          handleFormFieldChangeInsurance={this.handleFormFieldChangeInsurance}
          onAccountChange={this.onAccountChange}
          setSelectionModel={this.setSelectionModel}
          rowClick={this.rowClick}
          handleEditInsurance={this.handleEditInsurance}
          handleAddToPortfolio={this.handleAddToPortfolio}
          handleDeleteInsurance={this.handleDeleteInsurance}
          handleCancelInsurance={this.handleCancelInsurance}
          setLastStepInParent={this.setIsLastStep}
        />
      </div>
    );
  };

  renderUmbrellaContent = () => {
    return (
      <div>
        <UmbrellaLifeContent
          handleBlur={this.handleBlurForInsurance}
          umbrellaInsuranceProducts={this.props.umbrellaInsuranceProducts}
          state={this.state}
          concatInsurances={this.concatInsurances}
          transformValue={this.transformValue}
          handleFormFieldChangeInsurance={this.handleFormFieldChangeInsurance}
          onAccountChange={this.onAccountChange}
          setSelectionModel={this.setSelectionModel}
          rowClick={this.rowClick}
          handleEditInsurance={this.handleEditInsurance}
          handleAddToPortfolio={this.handleAddToPortfolio}
          handleDeleteInsurance={this.handleDeleteInsurance}
          handleCancelInsurance={this.handleCancelInsurance}
          setLastStepInParent={this.setIsLastStep}
        />
      </div>
    );
  };

  renderLongTermCareContent = () => {
    return (
      <div>
        <LongTermCareContent
          longTermCareInsuranceProducts={
            this.props.longTermCareInsuranceProducts
          }
          state={this.state}
          concatInsurances={this.concatInsurances}
          transformValue={this.transformValue}
          handleFormFieldChangeInsurance={this.handleFormFieldChangeInsurance}
          onAccountChange={this.onAccountChange}
          setSelectionModel={this.setSelectionModel}
          rowClick={this.rowClick}
          handleEditInsurance={this.handleEditInsurance}
          handleAddToPortfolio={this.handleAddToPortfolio}
          handleDeleteInsurance={this.handleDeleteInsurance}
          handleCancelInsurance={this.handleCancelInsurance}
          setLastStepInParent={this.setIsLastStep}
        />
      </div>
    );
  };

  renderPropertyContent = () => {
    return (
      <div>
        <PropertyContent
          propertyInsuranceProducts={this.props.propertyInsuranceProducts}
          state={this.state}
          concatInsurances={this.concatInsurances}
          transformValue={this.transformValue}
          handleFormFieldChangeInsurance={this.handleFormFieldChangeInsurance}
          onAccountChange={this.onAccountChange}
          setSelectionModel={this.setSelectionModel}
          rowClick={this.rowClick}
          handleEditInsurance={this.handleEditInsurance}
          handleAddToPortfolio={this.handleAddToPortfolio}
          handleDeleteInsurance={this.handleDeleteInsurance}
          onPropertyChange={this.onPropertyChange}
          handleCancelInsurance={this.handleCancelInsurance}
          setLastStepInParent={this.setIsLastStep}
        />
      </div>
    );
  };
  renderAutoContent = () => {
    return (
      <div>
        <AutoContent
          autoInsuranceProducts={this.props.autoInsuranceProducts}
          state={this.state}
          concatInsurances={this.concatInsurances}
          transformValue={this.transformValue}
          handleFormFieldChangeInsurance={this.handleFormFieldChangeInsurance}
          onAccountChange={this.onAccountChange}
          setSelectionModel={this.setSelectionModel}
          rowClick={this.rowClick}
          handleEditInsurance={this.handleEditInsurance}
          handleAddToPortfolio={this.handleAddToPortfolio}
          handleDeleteInsurance={this.handleDeleteInsurance}
          onAutoChange={this.onAutoChange}
          handleCancelInsurance={this.handleCancelInsurance}
          setLastStepInParent={this.setIsLastStep}
        />
      </div>
    );
  };

  renderHealthContent = () => {
    return (
      <div>
        <HealthInsuranceComponent
          handleBlur={this.handleBlurForInsurance}
          healthInsuranceProducts={this.props.healthInsuranceProducts}
          state={this.state}
          concatInsurances={this.concatInsurances}
          transformValue={this.transformValue}
          handleFormFieldChangeInsurance={this.handleFormFieldChangeInsurance}
          onAccountChange={this.onAccountChange}
          setSelectionModel={this.setSelectionModel}
          rowClick={this.rowClick}
          handleEditInsurance={this.handleEditInsurance}
          handleAddToPortfolio={this.handleAddToPortfolio}
          handleDeleteInsurance={this.handleDeleteInsurance}
          onHealthInsuranceChange={this.onHealthInsuranceChange}
          handleCancelInsurance={this.handleCancelInsurance}
          setLastStepInParent={this.setIsLastStep}
          handleHealthInsCheckboxFieldChange={
            this.handleHealthInsCheckboxFieldChange
          }
        />
      </div>
    );
  };

  renderChartContainer = () => {
    const { charts, selectedChart, triggerPushPinCharts } = this.props;
    const { pushPinLabel, parentTabValue, subTabValue, tabInfo } = this.state;
    if (charts && charts.length > 0) {
      const chartsData =
        (tabInfo[parentTabValue] &&
          tabInfo[parentTabValue].subMenu &&
          tabInfo[parentTabValue].subMenu.length > 0 &&
          tabInfo[parentTabValue].subMenu[subTabValue]) ||
        {};
      return (
        <div className="client_chart_main_div Chart">
          <div className="client_chart_inner">
            <NewChartsContainer
              selectedChart={selectedChart}
              chartsData={chartsData}
              triggerPushPinCharts={triggerPushPinCharts}
              isDashboard={false}
              pushPinLabel={pushPinLabel}
            />
          </div>
          {/* considering aux is always of timeseries type */}
          {chartsData?.subtabType === 'timeseries' &&
            chartsData.hasOwnProperty('timeSeries') &&
            chartsData.timeSeries?.aux?.yaxis?.length > 0 && (
              <div className="client_chart_inner">
                <NewChartsContainer
                  isAux={true}
                  selectedChart={selectedChart}
                  chartsData={chartsData}
                  triggerPushPinCharts={triggerPushPinCharts}
                  isDashboard={false}
                  pushPinLabel={pushPinLabel}
                />
              </div>
            )}
        </div>
      );
    }
  };

  deriveTableAuxAndMain = (tablesData) => {
    const { subtabType } = tablesData || {};
    const { [subtabType]: { aux = [], main = [] } = {} } = tablesData || {};
    const filteredAux = aux.filter(
      (item) => item.heading.length > 0 && item.rows.length > 0
    );
    // Filter the "main" array to only include items where heading and rows have length > 0
    const filteredMain = main.filter(
      (item) => item.heading.length > 0 && item.rows.length > 0
    );
    // Return the filtered object
    const filteredData = {
      aux: filteredAux,
      main: filteredMain,
    };
    return filteredData;
  };

  renderTableContainer = () => {
    const { tables } = this.props;
    const { parentTabValue, subTabValue, tabInfo } = this.state;
    if (tables && tables.length > 0) {
      const parentTabName =
        (tabInfo[parentTabValue] && tabInfo[parentTabValue].tabName) || '';
      const tablesData =
        (tabInfo[parentTabValue] &&
          tabInfo[parentTabValue].subMenu &&
          tabInfo[parentTabValue].subMenu.length > 0 &&
          tabInfo[parentTabValue].subMenu[subTabValue]) ||
        {};
      const auxAndMain = this.deriveTableAuxAndMain(tablesData);
      const { aux = [], main = [] } = auxAndMain;
      const renderTable = (data) =>
        data.map((val) => generateTableHTML(val, parentTabName));
      if ((main && main.length > 0) || (aux && aux.length > 0)) {
        return (
          <div className="client_chart_main_div Table">
            {main && main.length > 0 && (
              <div className="client_chart_inner">
                <div className="Table_main_outer d-lg-flex">
                  {renderTable(main)}
                </div>
              </div>
            )}
            {aux && aux.length > 0 && (
              <div className="client_chart_inner">
                <div className="Table_main_outer d-lg-flex">
                  {renderTable(aux)}
                </div>
              </div>
            )}
          </div>
        );
      }
    }
  };

  renderDataContainer = () => {
    const { data } = this.props;
    const { parentTabValue, subTabValue, tabInfo } = this.state;
    if (data && data.length > 0) {
      const dataInfo =
        (tabInfo[parentTabValue] &&
          tabInfo[parentTabValue].subMenu &&
          tabInfo[parentTabValue].subMenu.length > 0 &&
          tabInfo[parentTabValue].subMenu[subTabValue]) ||
        {};
      const { subtabType, build, input } = dataInfo || {};
      let mainData = [];
      let auxData = [];
      if (subtabType === 'input' && input) {
        const {
          main: { heading, rows = [] },
          aux,
        } = input;
        if (aux && aux.rows && aux.rows.length > 0) {
          auxData = [
            {
              name: aux.heading,
              elements: aux.rows.filter((a) => a && a.label && a.value),
            },
          ];
        }
        mainData =
          rows && rows.length > 0
            ? [
              {
                name: heading,
                elements: rows.filter((r) => r && r.label && r.value),
              },
            ]
            : [];
      } else if (subtabType === 'build' && build) {
        const { main = [], aux = [] } = build;
        const tempMainData =
          (main &&
            main.filter(
              (m) => m.elements && m.elements.find((v) => v.label && v.value)
            )) ||
          [];
        mainData =
          tempMainData.length > 0
            ? tempMainData.map((m, i) => {
              return {
                name: m.name || 'Heading',
                elements: m.elements.filter((v) => v && v.label && v.value),
              };
            })
            : [];
        const tempAuxData =
          (aux &&
            aux.filter(
              (m) =>
                m && m.elements && m.elements.find((v) => v.label && v.value)
            )) ||
          [];
        auxData =
          tempAuxData.length > 0
            ? aux.map((m, i) => {
              return {
                name: m.name || 'Heading',
                elements: m.elements,
              };
            })
            : [];
      }
      if (mainData?.length > 0 || auxData?.length > 0) {
        return (
          <div className="client_chart_main_div data">
            {mainData && mainData.length > 0 && (
              <div className="client_chart_inner">
                <div className="Table_main_outer d-lg-flex">
                  {mainData.map((val, index) => {
                    return (
                      <table
                        className={
                          mainData.length > 2
                            ? 'small-width-grid'
                            : 'full-width-grid'
                        }
                      >
                        {val && val.name && (
                          <thead>
                            <tr>
                              {/* <th className="left">{''}</th> */}
                              <th className="center-data" colSpan={2}>
                                {val?.name}
                              </th>
                              {/* <th className="left">{''}</th> */}
                            </tr>
                          </thead>
                        )}
                        {val.elements &&
                          val.elements.map((ele) => {
                            return (
                              <tbody>
                                <tr>
                                  <td className="data-label">{ele.label}</td>
                                  <td className="data-value">{ele.value}</td>
                                </tr>
                              </tbody>
                            );
                          })}
                      </table>
                    );
                  })}
                </div>
              </div>
            )}
            {auxData && auxData.length > 0 && (
              <div className="client_chart_inner">
                <div className="Table_main_outer d-lg-flex">
                  {auxData.map((val, index) => {
                    return (
                      <table
                        className={
                          auxData.length > 2
                            ? 'small-width-grid'
                            : 'full-width-grid'
                        }
                      >
                        {val && val.name && (
                          <thead>
                            <tr>
                              {/* <th className="left">{''}</th> */}
                              <th className="center-data" colspan="2">{val?.name}</th>
                              {/* <th className="left">{''}</th> */}
                            </tr>
                          </thead>
                        )}
                        {val.elements &&
                          val.elements.map((ele) => {
                            return (
                              <tbody>
                                <tr>
                                  <td className="data-label">{ele.label}</td>
                                  <td className="data-value">{ele.value}</td>
                                </tr>
                              </tbody>
                            );
                          })}
                      </table>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        );
      }
    }
  };

  renderCustomChart = (renderWhatData) => {
    const { charts } = this.props;
    return <CustomChart charts={charts} renderWhatData={renderWhatData} />;
  };

  renderCustomTable = (renderWhatData) => {
    const { tables } = this.props;
    return <CustomTable tables={tables} renderWhatData={renderWhatData} />;
  };

  renderCustomData = (renderWhatData) => {
    const { data } = this.props;
    return <CustomData data={data} renderWhatData={renderWhatData} />;
  };

  renderCustomContentContainer = ({ renderTabType, data }) => {
    switch (renderTabType && renderTabType.toLowerCase()) {
      case 'chart':
        return this.renderCustomChart(data);
      case 'table':
        return this.renderCustomTable(data);
      case 'data':
        return this.renderCustomData(data);
      default:
        break;
    }
  };

  deriveAuxMainAndProfile = () => {
    const { solutions } = this.props;
    const { activeMainTab, activeSubTab } = this.state;
    let aux = {};
    let main = {};
    let profile = {};
    switch (activeMainTab) {
      case 'investments':
        const { investments: { rebalance = {} } = {} } = solutions;
        aux = rebalance.aux || {};
        main = rebalance.main || {};
        profile = rebalance.profile || {};
        break;
      case 'expenses':
        const { expenses: { optimize = {} } = {} } = solutions;
        aux = optimize.aux || {};
        main = optimize.main || {};
        profile = optimize.profile || {};
        break;
      case 'goals':
        const { goals: { optimize: goalsOptimize = {} } = {} } = solutions;
        aux = goalsOptimize.aux || {};
        main = goalsOptimize.main || {};
        profile = goalsOptimize.profile || {};
        break;
      case 'insurance':
        const {
          insurance: {
            termLife = {},
            wholeLife = {},
            longTermCare = {},
            umbrella = {},
            property = {},
            auto = {},
            health = {},
          } = {},
        } = solutions;
        switch (activeSubTab.toLowerCase()) {
          case 'term life':
            aux = termLife.aux || {};
            main = termLife.main || {};
            profile = termLife.profile || {};
            break;
          case 'whole life':
            aux = wholeLife.aux || {};
            main = wholeLife.main || {};
            profile = wholeLife.profile || {};
            break;
          case 'long term care':
            aux = longTermCare.aux || {};
            main = longTermCare.main || {};
            profile = longTermCare.profile || {};
            break;
          case 'umbrella':
            aux = umbrella.aux || {};
            main = umbrella.main || {};
            profile = umbrella.profile || {};
            break;
          case 'property':
            aux = property.aux || {};
            main = property.main || {};
            profile = property.profile || {};
            break;
          case 'vehicle':
            aux = auto.aux || {};
            main = auto.main || {};
            profile = auto.profile || {};
            break;
          case 'health':
            aux = health.aux || {};
            main = health.main || {};
            profile = health.profile || {};
            break;
          default:
            break;
        }
        break;
      case 4:
        break;
      case 5:
        break;
      default:
        break;
    }
    const auxMainAndProfile = { aux, main, profile };
    return auxMainAndProfile;
  };

  renderCustomContainer = () => {
    const auxMainAndProfile = this.deriveAuxMainAndProfile();
    const { aux = {}, main = {}, profile = {} } = auxMainAndProfile;
    const { tabType: auxTabType = '' } = aux;
    const { tabType: mainTabType = '' } = main;
    const { tabType: profileTabType = '' } = profile;
    return (
      <div className="client_chart_main_div">
        {['profile', 'main', 'aux'].map((p) => {
          let obj = {};
          if (p === 'main') {
            obj = { renderTabType: mainTabType, data: main };
          } else if (p === 'aux') {
            obj = { renderTabType: auxTabType, data: aux };
          } else if (p === 'profile') {
            obj = { renderTabType: profileTabType, data: profile };
          }
          return (
            <div className="client_chart_inner">
              {this.renderCustomContentContainer(obj)}
            </div>
          );
        })}
      </div>
    );
  };

  renderContentContainer = () => {
    const { renderType } = this.state;
    switch (renderType) {
      case 'Chart':
        return this.renderChartContainer();
      case 'Table':
        return this.renderTableContainer();
      case 'Data':
        return this.renderDataContainer();
      case 'Custom':
        return this.renderCustomContainer();
      default:
        break;
    }
  };

  renderInsuranceContentContainer = (from) => {
    switch (from) {
      case 'termLife':
        return this.renderTermLifeContent();
      case 'wholeLife':
        return this.renderWholeLifeContent();
      case 'umbrella':
        return this.renderUmbrellaContent();
      case 'longTermCare':
        return this.renderLongTermCareContent();
      case 'property':
        return this.renderPropertyContent();
      case 'auto':
        return this.renderAutoContent();
      case 'health':
        return this.renderHealthContent();
      default:
        return this.renderTermLifeContent();
    }
  };

  // setIsLastStepInExpenses = (isLastStep) => {
  //   this.setState({ isLastStepInExpenses: isLastStep });
  // };

  setIsFormValidInExpenses = (isFormValidInExpenses) => {
    this.setState({ isFormValid: isFormValidInExpenses })
  }

  renderExpensesContainer = (from) => {
    const { expenseFactorDetails, planDetailsByPlanId } = this.props;
    const { localPlanDetailsByPlanId } = this.state;
    const { moduleFamily: { infoExpenses = {} } = {} } = planDetailsByPlanId;
    return (
      <Grid container>
        <Grid item xs={12}>
          <SolutionsJourney
            updateExpenseDetailsSolutionsInRedux={
              this.updateExpenseDetailsSolutionsInRedux
            }
            storeValues={getSolutionsExpenseStoreValuesV2(
              infoExpenses,
              expenseFactorDetails
            )}
            from={from}
            handleNextStep={this.handleNext}
            handlePreviousStep={this.handlePrevious}
            setIsFormValidInParent={this.setIsFormValidInExpenses}
          //passIsLastStepInfoToParent={this.setIsLastStepInExpenses}
          />
        </Grid>
      </Grid>
    );
  };

  onClickDismiss = (key) => () => {
    const { closeSnackbar } = this.props;
    closeSnackbar(key);
  };

  handleDisplayToast = () => {
    const { enqueueSnackbar, isPlanDetailsSaved, planDetailsSimulated } =
      this.props;
    const toasterMsg = 'Updated Plan details, click on Simulate';
    this.key = enqueueSnackbar(toasterMsg, {
      variant: 'success',
      autoHideDuration: 2000,
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      action: (key) => <CloseIcon onClick={this.onClickDismiss(key)} />,
    });
  };

  updateExpenseDetailsSolutionsInRedux = (
    expenseDetails,
    stepName,
    newFieldValue
  ) => {
    const { triggerUpdatePlannerDetailsinRedux } = this.props;
    const { localPlanDetailsByPlanId } = this.state;
    const {
      moduleFamily: {
        infoExpenses: { retFactor = {}, horFactor = {} } = {},
        infoExpenses = {},
      } = {},
      moduleFamily = {},
    } = localPlanDetailsByPlanId;
    let tempInfoExpenses = { ...infoExpenses };
    let tempRetFactor = { ...retFactor };
    let tempHorFactor = { ...horFactor };
    const { fieldValue = "", field = "", parent = "" } = newFieldValue || {}
    if (
      stepName &&
      stepName !== '' &&
      stepName === 'retirement' &&
      expenseDetails &&
      Object.keys(expenseDetails).length > 0
    ) {
      tempRetFactor = {
        ...tempRetFactor,
        rental: Number(expenseDetails.retirementRentalExpense),
      };
      tempInfoExpenses = {
        ...tempInfoExpenses,
        discRetFactor1: Number(expenseDetails.retirementFactor1),
        discRetFactor2: Number(expenseDetails.retirementFactor2),
        discRetFactor3: Number(expenseDetails.retirementFactor3),
      };
    } else if (stepName === 'horizon') {
      tempInfoExpenses = {
        ...tempInfoExpenses,
        discHorFactor: Number(expenseDetails.horizonFactorPercent),
      };
      tempHorFactor = {
        ...tempHorFactor,
        rental: Number(expenseDetails.horizonRentalExpense),
      };
    } else if (stepName === 'lifetime') {
      tempInfoExpenses = {
        ...tempInfoExpenses,
        affordabilityFactor: expenseDetails.affordabilityFactor,
      };
    } else if (stepName === 'discretionary' &&
      EXPENSE_DISC_FIELDS.includes(field) &&
      !expenseDetails.disc[field]
    ) {
      //default to 1 if it is not disc
      tempHorFactor = {
        ...tempHorFactor,
        // [field]: 1,
      };
      tempRetFactor = {
        ...tempRetFactor,
        // [field]: 1,
      };
    } else {
      tempHorFactor = {
        ...expenseDetails.horFactor,
      };
      tempRetFactor = {
        ...expenseDetails.retFactor,
      };
    }
    tempInfoExpenses = {
      ...tempInfoExpenses,
      useDiscretionaryFlag: expenseDetails.discSameFactorAcross,
      disc: {
        ...expenseDetails.disc,
      },
      horFactor: { ...tempHorFactor },
      retFactor: { ...tempRetFactor },
    };
    let tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleFamily: {
        ...moduleFamily,
        infoExpenses: { ...tempInfoExpenses },
      },
    };
    triggerUpdatePlannerDetailsinRedux({
      plannerData: tempPlanDetailsByPlanId,
    });
    //if (isLastStep) {
    //this.handleDisplayToast();
    //}
  };

  handleSubTabChange = (event, value) => {
    const {
      triggerSetSelectedChart,
      triggerSetSelectedTable,
      triggerSetSelectedData,
    } = this.props;
    const { parentTabValue, tabInfo, renderType } = this.state;
    const selectedItem =
      (tabInfo[parentTabValue] &&
        tabInfo[parentTabValue].subMenu &&
        tabInfo[parentTabValue].subMenu.length > 0 &&
        tabInfo[parentTabValue].subMenu[value].subtabName) ||
      '';
    switch (renderType) {
      case 'Chart':
        triggerSetSelectedChart(selectedItem);
        break;
      case 'Table':
        triggerSetSelectedTable(selectedItem);
        break;
      case 'Data':
        triggerSetSelectedData(selectedItem);
        break;
      default:
        break;
    }
    this.setState({
      subTabValue: value,
      isEditable: false,
    });
  };

  handleParentTabChange = (event, value) => {
    this.setState({
      parentTabValue: value,
      subTabValue: 0,
    });
  };

  getParentTabNames = (tabValue) => {
    switch (tabValue) {
      case 0:
        return 'investments';
      case 1:
        return 'expenses';
      case 2:
        return 'goals';
      case 3:
        return 'insurance';
      case 4:
        return 'annuities';
      case 5:
        return 'debt';
      default:
        return 'investments';
    }
  };

  // handleSolutionsParentTabChange = (event, value) => {
  //   const {
  //     triggerSetBeforeAfterAssetMixValues,
  //     triggerSetAssetAllocListByClientIdAction,
  //   } = this.props;
  //   const isLastStep = value === 0 ? true : false;
  //   const solutionsParentTabName = this.getParentTabNames(value);
  //   history.push(`/solutions?tab=${solutionsParentTabName}`);
  //   this.setState({
  //     activeMainTab: value,
  //     activeSubTab: 0,
  //     isEditable: false,
  //     isLastStep,
  //     selectedRecommendedAssetAllocId: '',
  //     assetMixBuildInfo: [],
  //     targetAssetMixBuildInfo: [],
  //     selectedAllocationInfo: {},
  //     selectedDDNameIndex: 0,
  //     isFormValid: true,
  //     formErrors: [],
  //   });
  //   triggerSetBeforeAfterAssetMixValues([]); // to re-calculate before after values on tab change
  //   triggerSetAssetAllocListByClientIdAction([]);
  // };

  // handleSolutionsSubTabChange = (event, value) => {
  //   this.setState({
  //     activeSubTab: value,
  //     isComputed: false,
  //     isEditable: false,
  //     selectedDDNameIndex: 0,
  //     isFormValid: true,
  //     formErrors: [],
  //   });
  // };

  renderSubMenuContainer = () => {
    const { subTabValue, parentTabValue, tabInfo } = this.state;
    const subMenus = tabInfo[parentTabValue]
      ? tabInfo[parentTabValue].subMenu
      : [];
    return (
      <div className="chrono-sub-tab">
        <Tabs
          value={subTabValue}
          onChange={this.handleSubTabChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          {subMenus &&
            subMenus.map((item, index) => (
              <Tab
                label={item.subtabName}
                {...a11yProps(index)}
                sx={{ padding: '10px !important' }}
              />
            ))}
        </Tabs>
      </div>
    );
  };

  updateRenderType = (e, renderType) => {
    this.setState({
      renderType,
      parentTabValue: 0,
      subTabValue: 0,
      tabInfo: [],
    });
  };

  handleEditInsurance = (e, solutionSubTab, isNewRec = false) => {
    this.setState({ isEditable: true, isAddNew: isNewRec });
  };

  onInvAccountChange = (event) => {
    const { availableInvestmentAccounts } = this.state;
    let { value: selectedAccount } = event.target;
    const selectedAccountInfo = availableInvestmentAccounts.filter(
      (f) => f.id.toLowerCase() === selectedAccount.toLowerCase()
    );
    let selectedInvestmentAccount = {};
    if (selectedAccountInfo && selectedAccountInfo.length > 0) {
      selectedInvestmentAccount = selectedAccountInfo[0];
    }
    const {
      assetProfile = 'products',
      invBalance = '$0',
      products = [],
    } = selectedInvestmentAccount || {};
    this.setState({
      selectedAccount,
      selectedInvestmentAccount,
      tickersAvailableInPlan: products,
      accBalance: invBalance,
      selectedProductType: '',
      tickers: [],
      selectedTicker: '',
      isProductsSelected: assetProfile === 'products',
      isCustomSelected: assetProfile === 'custom',
    });
  };

  onAutoChange = (event, properties, tab, from) => {
    let { value: selectedAuto } = event.target;
    const {
      formDetails,
      existingInsurances,
      selectedMember,
      selectedAutoMember,
    } = this.state;
    let tempFormDetails = { ...formDetails };

    let selectedAutoValue = selectedAutoMember;
    if (tab === 'autoIns') {
      selectedAutoValue = selectedAuto;
    }

    tempFormDetails = {
      ...tempFormDetails,
      [tab]: {
        ...tempFormDetails[tab],
        [from]: selectedAuto,
      },
    };
    let selectedInsuredInfo =
      tempFormDetails &&
        tempFormDetails[tab] &&
        tempFormDetails[tab][from] !== ''
        ? tempFormDetails[tab]
        : {};
    tempFormDetails = this.updateFormDetails(
      selectedInsuredInfo,
      tempFormDetails
    );
    this.setState((prevState) => ({
      ...prevState,
      selectedAutoMember: selectedAutoValue,
      isComputed: from === 'insured' ? false : prevState.isComputed,
      formDetails: { ...tempFormDetails },
      isEditable: false,
      isAddNew: false,
      selectionModel: {
        ...prevState.selectionModel,
        [tab]: from === 'insured' ? undefined : [tab],
      },
    }));
  };

  onHealthInsuranceChange = (event, properties, tab, from) => {
    let { value: selectedAuto } = event.target;
    const {
      formDetails,
      existingInsurances,
      selectedMember,
      selectedAutoMember,
    } = this.state;
    let tempFormDetails = { ...formDetails };

    let selectedAutoValue = selectedAutoMember;
    if (tab === 'autoIns') {
      selectedAutoValue = selectedAuto;
    }

    tempFormDetails = {
      ...tempFormDetails,
      [tab]: {
        ...tempFormDetails[tab],
        [from]: selectedAuto,
      },
    };
    let selectedInsuredInfo =
      tempFormDetails &&
        tempFormDetails[tab] &&
        tempFormDetails[tab][from] !== ''
        ? tempFormDetails[tab]
        : {};
    tempFormDetails = this.updateFormDetails(
      selectedInsuredInfo,
      tempFormDetails
    );
    this.setState((prevState) => ({
      ...prevState,
      selectedAutoMember: selectedAutoValue,
      isComputed: from === 'insured' ? false : prevState.isComputed,
      formDetails: { ...tempFormDetails },
      isEditable: false,
      isAddNew: false,
      selectionModel: {
        ...prevState.selectionModel,
        [tab]: from === 'insured' ? undefined : [tab],
      },
    }));
  };

  onPropertyChange = (event, properties, tab, from) => {
    let { value: selectedProperty } = event.target;
    const {
      formDetails,
      existingInsurances,
      selectedMember,
      selectedPropertyMember,
    } = this.state;
    let tempFormDetails = { ...formDetails };

    let selectedPropertyValue = selectedPropertyMember;
    if (tab === 'propIns') {
      selectedPropertyValue = selectedProperty;
    }

    tempFormDetails = {
      ...tempFormDetails,
      [tab]: {
        ...tempFormDetails[tab],
        [from]: selectedProperty,
      },
    };
    let selectedInsuredInfo =
      tempFormDetails &&
        tempFormDetails[tab] &&
        tempFormDetails[tab][from] !== ''
        ? tempFormDetails[tab]
        : {};
    tempFormDetails = this.updateFormDetails(
      selectedInsuredInfo,
      tempFormDetails
    );
    this.setState((prevState) => ({
      ...prevState,
      selectedPropertyMember: selectedPropertyValue,
      isComputed: from === 'insured' ? false : prevState.isComputed,
      formDetails: { ...tempFormDetails },
      isEditable: false,
      isAddNew: false,
      selectionModel: {
        ...prevState.selectionModel,
        [tab]: from === 'insured' ? undefined : [tab],
      },
    }));
  };

  onAccountChange = (event, accounts, tab) => {
    let { value: selectedAccount, index } = event.target;
    const { formDetails, existingInsurances, selectedMember } = this.state;
    let tempFormDetails = { ...formDetails };
    const selectedInsurance = existingInsurances.filter(
      (f) => f.name === selectedAccount
    )[0];
    if (selectedInsurance && Object.keys(selectedInsurance).length > 0) {
      tempFormDetails = {
        ...tempFormDetails,
        insured: tab !== 'umbrellaIns' ? selectedMember : 'family',
        [tab]: {
          ...tempFormDetails[tab],
          coverage: (selectedInsurance && selectedInsurance.coverage) || '',
          coverageYrs:
            (selectedInsurance && selectedInsurance.coverageYrs) || '',
          coverageStartYr:
            (selectedInsurance &&
              new Date(`${selectedInsurance.coverageStartYr}`)) ||
            new Date(),
          premium: (selectedInsurance && selectedInsurance.premium) || '',
          cashValueAnnual:
            (selectedInsurance && selectedInsurance.cashValueAnnual) || '',
          cashValueDistributionYrs:
            (selectedInsurance && selectedInsurance.cashValueDistributionYrs) ||
            '',
          name: (selectedInsurance && selectedInsurance.name) || '',
        },
      };
    }
    this.setState({
      isEditable: false,
      formDetails: { ...tempFormDetails },
      selectedDDNameIndex: index,
    });
  };

  onRecommendedAssetAllocationChange = (event) => {
    const {
      triggerGetAssetAllocListByClientId,
      recommendedAssetAllocationValues,
      triggerSetBeforeAfterAssetMixValues,
    } = this.props;
    const { localPlanDetailsByPlanId } = this.state;
    const {
      clientid,
      moduleInvestments: { markets = {} } = {},
      moduleInvestments = {},
    } = localPlanDetailsByPlanId;
    let { value: selectedRecommendedAssetAllocId } = event.target;
    let recommendedRiskLevel = '';
    const tempSelectedRecommendedAssetAlloc =
      recommendedAssetAllocationValues.filter(
        (f) => f.value === selectedRecommendedAssetAllocId
      );
    if (
      tempSelectedRecommendedAssetAlloc &&
      tempSelectedRecommendedAssetAlloc.length > 0
    ) {
      recommendedRiskLevel = tempSelectedRecommendedAssetAlloc[0].riskLevel;
    }
    const req = {
      clientid: clientid,
      recommendedRiskLevel: recommendedRiskLevel,
    };
    let tempMarkets = {
      ...markets,
      recommendedRiskLevel: recommendedRiskLevel,
    };
    const tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
    };
    this.setState(
      (prevState) => ({
        ...prevState,
        selectedProductType: '',
        tickers: [],
        selectedTicker: '',
        isProductsAlterted: false,
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
        assetMixBuildInfo: [],
        targetAssetMixBuildInfo: [],
        selectedAllocationInfo: {},
        assetAllocId: '',
        selectedRecommendedAssetAllocId,
        isMC: false,
        isSimulateOnInvestmentsClicked: false,
      }),
      () => {
        triggerSetBeforeAfterAssetMixValues([]);
        triggerGetAssetAllocListByClientId(req);
      }
    );
  };

  onAllocationChange = (event) => {
    let { value: id } = event.target;
    const { allocation, triggerSetBeforeAfterAssetMixValues } = this.props;
    const { localPlanDetailsByPlanId } = this.state;
    const selectedAllocationInfo =
      allocation.filter((f) => f.id === id)[0] || {};
    const { moduleInvestments: { markets = {} } = {}, moduleInvestments = {} } =
      localPlanDetailsByPlanId;
    const selectedAssetAllocId =
      Object.keys(selectedAllocationInfo).length > 0
        ? selectedAllocationInfo?.value
        : '';
    let tempMarkets = { ...markets };
    tempMarkets = this.updateProductsToPlan(markets);
    tempMarkets = {
      ...tempMarkets,
      assetAllocId: selectedAssetAllocId, //updating plan
    };
    const tempPlanDetailsByPlanId = {
      ...localPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
    };
    this.setState(
      (prevState) => ({
        ...prevState,
        selectedProductType: '',
        tickers: [],
        selectedTicker: '',
        isProductsAlterted: false,
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
        assetMixBuildInfo: [],
        targetAssetMixBuildInfo: [],
        selectedAllocationInfo,
        assetAllocId: selectedAssetAllocId,
        isMC: false,
        isSimulateOnInvestmentsClicked: false,
      }),
      () => {
        triggerSetBeforeAfterAssetMixValues([]);
      }
    );
  };

  // renderSolutionSubMenuContainer = () => {
  //   const { activeSubTab, activeMainTab, solutionsTabs } = this.state;
  //   const subMenus = solutionsTabs[activeMainTab]
  //     ? solutionsTabs[activeMainTab].subMenu
  //     : [];
  //   return (
  //     <div className="chrono-sub-tab">
  //       <Tabs
  //         value={activeSubTab}
  //         onChange={this.handleSolutionsSubTabChange}
  //         aria-label="basic tabs example"
  //         TabIndicatorProps={{
  //           style: {
  //             display: 'none',
  //           },
  //         }}
  //       >
  //         {subMenus &&
  //           subMenus.map((item, index) => (
  //             <Tab
  //               label={item.name}
  //               {...a11yProps(index)}
  //               sx={{ padding: '10px !important' }}
  //             />
  //           ))}
  //       </Tabs>
  //     </div>
  //   );
  // };

  getClassOperator = ({ current, target }) => {
    let currentValue = current * 100;
    let targetValue = target * 100;
    const operator =
      currentValue > targetValue
        ? 'red_text'
        : currentValue < targetValue
          ? 'orange_text'
          : 'green_text';
    return operator;
  };

  // formatCurrency = (value) => {
  //   const numFormat = Intl.NumberFormat('en-US');
  //   let newNumformat = numFormat.format(0);
  //   let currencyFieldValue = '';
  //   if (value !== null && value !== undefined && value !== '') {
  //     if (value.toString().includes('$')) {
  //       currencyFieldValue = value.split('$')[1];
  //       currencyFieldValue = currencyFieldValue.replace(/,/g, '');
  //       if (
  //         currencyFieldValue !== '' &&
  //         !isNaN(currencyFieldValue) &&
  //         Number(currencyFieldValue) >= 0
  //       ) {
  //         newNumformat = numFormat.format(currencyFieldValue);
  //       }
  //     } else if (!isNaN(value) && Number(value) >= 0) {
  //       newNumformat = numFormat.format(value);
  //     }
  //     value = `$${newNumformat}`;
  //   } else {
  //     value = `$${newNumformat}`;
  //   }
  //   return value;
  // };

  getConvertedValue = (value) => {
    let usedBalance = value;
    if (
      usedBalance &&
      isNaN(usedBalance) &&
      usedBalance !== '$' &&
      usedBalance.includes('$')
    ) {
      const tempUsedBalance = usedBalance.split('$')[1].replace(/,/g, '');
      usedBalance =
        tempUsedBalance && !isNaN(tempUsedBalance)
          ? parseFloat(tempUsedBalance)
          : 0;
    } else {
      usedBalance = usedBalance && !isNaN(usedBalance) ? usedBalance : 0;
    }
    return usedBalance;
  };

  calculateQuantity = (ticker) => {
    let { balance = '', price = '' } = ticker || {};
    balance = this.getConvertedValue(balance); // Convert "$10,000" to float
    price = this.getConvertedValue(price); // Convert "$100" to float
    return price ? balance / price : 0; // Prevent division by zero
  };

  calculateBalance = (ticker) => {
    let { quantity = '', price = '' } = ticker || {};
    price = this.getConvertedValue(price); // Convert "$100" to float
    quantity = !isNaN(quantity) ? parseFloat(quantity) : 0; // Convert "$100" to float
    return this.formatCurrency(price * quantity);
  };

  handleOnBlurTickers = (event, index, type) => {
    const value =
      type !== 'quantity'
        ? this.formatCurrency(event?.target?.value)
        : event?.target?.value;
    const { tickersAddedToPlan } = this.state;

    // Update the tickers in a single map operation
    const tempTickersAddedToPlan = tickersAddedToPlan.map((ticker, i) => {
      // Create a copy of the ticker
      const updatedTicker = { ...ticker };

      // If the index matches, update the appropriate field
      if (i === index) {
        updatedTicker[type] = value;
      }

      // Always update the balance for each ticker
      updatedTicker.balance = this.calculateBalance(updatedTicker);

      return updatedTicker;
    });

    // Calculate the account balance
    const accBalance = this.formatCurrency(
      this.updateBalance(tempTickersAddedToPlan)
    );

    // Update the state with the new tickers and account balance
    this.setState({ tickersAddedToPlan: tempTickersAddedToPlan, accBalance });
  };

  // updateRemainingBalance = (tempTickersAddedToPlan, balance) => {
  //   const amounts = tempTickersAddedToPlan
  //     .map(
  //       (m) => this.getConvertedValue(m.balance) //to remove empty values
  //     )
  //     .filter((a) => a);
  //   //taking only amounts property
  //   const amountUsed = amounts.reduce(
  //     (a, b) => parseFloat(a) + parseFloat(b),
  //     0
  //   ); //now adding them (a + b is nthg but actual values like 10 + 20)
  //   let tempBalance = this.getConvertedValue(balance);
  //   const remainingBalance =
  //     amountUsed !== '' && !isNaN(amountUsed)
  //       ? parseFloat(tempBalance) - parseFloat(amountUsed)
  //       : 0;
  //   let inValidAmount = false;
  //   if (amountUsed > tempBalance) {
  //     inValidAmount = true;
  //   }
  //   let amountUsedFormatted = '$0';
  //   if (amountUsed !== '' && !isNaN(amountUsed)) {
  //     const numFormat = Intl.NumberFormat('en-US');
  //     amountUsedFormatted = numFormat.format(amountUsed);
  //   }
  //   this.setState({ inValidAmount, amountUsed: `$${amountUsedFormatted}` });
  //   return remainingBalance;
  // };

  updateTickerQuantity = (e, index, balance) => {
    let { value: quantity } = e.target;
    const { tickersAddedToPlan } = this.state;
    const tempTickersAddedToPlan = tickersAddedToPlan.map((ticker, i) => {
      // Initialize the updated ticker object
      const updatedTicker = {
        ...ticker,
        quantity: i === index ? quantity : ticker.quantity, // Update price conditionally
      };
      // Calculate balance using the possibly updated price
      // updatedTicker.balance = updatedTicker.isQuantitySelected
      //   ? this.calculateBalance(updatedTicker)
      //   : ticker.balance;
      updatedTicker.balance = this.calculateBalance(updatedTicker);
      return updatedTicker;
    });
    // const remainingBalance = this.updateRemainingBalance(
    //   tempTickersAddedToPlan,
    //   balance
    // );
    this.setState({
      tickersAddedToPlan: tempTickersAddedToPlan,
      //remainingBalance,
    });
  };

  updateTickerPrice = (event, index, balance) => {
    const price = this.formatCurrency(event?.target?.value);
    const { tickersAddedToPlan } = this.state;
    const tempTickersAddedToPlan = tickersAddedToPlan.map((ticker, i) => {
      // Initialize the updated ticker object
      const updatedTicker = {
        ...ticker,
        price: i === index ? price : ticker.price, // Update price conditionally
      };
      // Calculate balance using the possibly updated price
      // updatedTicker.balance = updatedTicker.isQuantitySelected
      //   ? this.calculateBalance(updatedTicker)
      //   : ticker.balance;
      updatedTicker.balance = this.calculateBalance(updatedTicker);
      return updatedTicker;
    });
    // const remainingBalance = this.updateRemainingBalance(
    //   tempTickersAddedToPlan,
    //   balance
    // );
    this.setState({
      tickersAddedToPlan: tempTickersAddedToPlan,
      //remainingBalance,
    });
  };

  // purchaseTicker = (e, i, balance) => {
  //   const usedBalance = this.formatCurrency(e?.target?.value);
  //   const { tickersAddedToPlan } = this.state;
  //   let tempTickersAddedToPlan = [...tickersAddedToPlan];
  //   tempTickersAddedToPlan.forEach((f, index) => {
  //     if (i === index) {
  //       f.balance = usedBalance;
  //     }
  //   });
  //   // const remainingBalance = this.updateRemainingBalance(
  //   //   tempTickersAddedToPlan,
  //   //   balance
  //   // );
  //   this.setState({
  //     tickersAddedToPlan: tempTickersAddedToPlan,
  //     selectedProductType: '',
  //     selectedTicker: '',
  //     isProductsAlterted: true,
  //     //remainingBalance,
  //   });
  // };

  deleteTicker = (e, index, ticker) => {
    const { tickersAddedToPlan, localProdInfo } = this.state;
    // Remove the ticker at the specified index
    const updatedTickers = tickersAddedToPlan.filter((_, i) => i !== index);
    // Update account balance after ticker removal
    const accBalance = this.formatCurrency(this.updateBalance(updatedTickers));
    // Update product info by filtering out the ticker
    // const updatedProdInfo = localProdInfo.filter(
    //   (item) => item.ticker.toLowerCase() !== ticker?.ticker?.toLowerCase()
    // );
    // Update state
    this.setState({
      tickersAddedToPlan: updatedTickers,
      selectedTicker: '',
      selectedProductType: '',
      accBalance,
      //localProdInfo: updatedProdInfo,
      isProductsAlterted: true,
    });
  };

  onTickerChange = async (event) => {
    let { value: selectedTicker } = event.target;
    const { localProdInfo, tickersAddedToPlan } = this.state;
    const { status, data } = await getTickerDetails(selectedTicker);
    if (status === 200) {
      const tickerDetails = data;
      const tickerInfo = tickerDetails.find((t) => t.ticker === selectedTicker);
      if (!tickerInfo) return; // If no ticker info found, early exit
      const existingTicker = tickersAddedToPlan.find(
        (t) => t.ticker === selectedTicker
      );
      if (!existingTicker) {
        tickersAddedToPlan.unshift({
          ticker: tickerInfo.ticker,
          desc: tickerInfo.desc,
          balance: 0,
          quantity: 0,
          price: this.formatCurrency(tickerInfo.currentPrice),
          type: tickerInfo.type,
        });
      }
      // Update localProdInfo
      if (tickerInfo.prodInfo && Object.keys(tickerInfo.prodInfo).length > 0) {
        const existingProdInfo = localProdInfo.find(
          (p) => p.ticker === tickerInfo.prodInfo.ticker
        );
        if (!existingProdInfo) {
          this.setState((prevState) => ({
            localProdInfo: [...prevState.localProdInfo, tickerInfo.prodInfo],
          }));
        }
      }
      // Set state with selectedTicker and updated tickersAddedToPlan
      this.setState({
        selectedTicker,
        tickersAddedToPlan: [...tickersAddedToPlan], // Make sure to create a new array
      });
    }
  };

  onProductTypeChange = (event) => {
    let { value: selectedProductType } = event.target;
    const { investmentProducts } = this.props;
    const selectedProductTickersInfo = investmentProducts.filter(
      (i) => i.productType === selectedProductType
    );
    let tickers = [];
    if (selectedProductTickersInfo && selectedProductTickersInfo.length > 0) {
      tickers = selectedProductTickersInfo[0].tickers;
    }
    this.setState({ selectedProductType, tickers });
  };

  transformBackToAPIType = (value) => {
    if (value) {
      let numVal = value;
      if (isNaN(numVal) && numVal.includes('$')) {
        numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
      }
      return numVal;
    } else if (value === 0 || value === '') {
      return 0;
    }
  };

  handleAddProductsToPortfolio = (isMC) => {
    const { triggerSetBeforeAfterAssetMixValues } = this.props;
    const { isMC: isTempMC, localPlanDetailsByPlanId } = this.state;
    let clonedPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    const { moduleInvestments: { markets = {} } = {}, moduleInvestments = {} } =
      clonedPlanDetailsByPlanId;
    let tempPlanDetailsByPlanId = { ...clonedPlanDetailsByPlanId };
    let tempMarkets = { ...markets };
    tempMarkets = this.updateProductsToPlan(markets);
    //if (!isEqual(tempMarkets, markets) || isMC !== isTempMC) {
    tempPlanDetailsByPlanId = {
      ...clonedPlanDetailsByPlanId,
      moduleInvestments: {
        ...moduleInvestments,
        markets: { ...tempMarkets },
      },
    };
    this.setState(
      (prevState) => ({
        ...prevState,
        //selectedRecommendedAssetAllocId: '', revisit this if something is breaking - important check this
        selectedProductType: '',
        tickers: [],
        selectedTicker: '',
        isProductsAlterted: false,
        isMC,
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
        assetMixBuildInfo: [],
        targetAssetMixBuildInfo: [],
        isSimulateOnInvestmentsClicked: false,
      }),
      () => {
        triggerSetBeforeAfterAssetMixValues([]);
      }
    );
  };

  fnToRemoveCommaAndParse = (value) => {
    let valueWithComma = '';
    if (isNaN(value) && value.toString().includes('$')) {
      valueWithComma = value.split('$')[1];
    } else if (!isNaN(value)) {
      valueWithComma = value;
    } else {
      valueWithComma = '';
    }
    const valueWithoutComma = valueWithComma.replace(/,/g, '');
    return !isNaN(valueWithoutComma) && parseFloat(valueWithoutComma);
  };

  handleOnChangeRiskLevelAdaptive = (e) => {
    let { checked } = e && e.target;
    this.setState({ isRiskLevelAdaptive: checked });
  };

  getInvestmentBucketName = (investmentBucket) => {
    if (investmentBucket) {
      const investmentBucketName = investmentFields.find(
        (f) => f.id === investmentBucket
      );
      return investmentBucketName?.title || '';
    }
    return '';
  };

  // toggleQuantityToValue = (e, index) => {
  //   const { checked: isQuantitySelected } = e.target;
  //   const { tickersAddedToPlan } = this.state;
  //   let tempTickersAddedToPlan = [...tickersAddedToPlan];
  //   tempTickersAddedToPlan.forEach((f, i) => {
  //     if (i === index) {
  //       f.isQuantitySelected = isQuantitySelected
  //     }
  //   });
  //   this.setState({
  //     tickersAddedToPlan: tempTickersAddedToPlan,
  //   });
  // };

  renderRiskLevelInvestmentContainer = () => {
    const {
      allocation,
      beforeAfterAssetMixValues,
      recommendedAssetAllocationValues,
    } = this.props;
    const {
      selectedAllocationInfo,
      selectedRecommendedAssetAllocId,
      memberOptions,
      isRiskLevelAdaptive,
    } = this.state;
    let fields_per_column = [];
    let selectedCBMembers = memberOptions.filter((f) => f.isSelected);
    let selectedCBMember = '';
    if (selectedCBMembers && selectedCBMembers.length > 0) {
      if (selectedCBMembers.length === memberOptions.length) {
        selectedCBMember = 'family';
      } else {
        selectedCBMember = selectedCBMembers[0].id;
      }
    }
    const selectedMemberAssetMix = beforeAfterAssetMixValues.filter(
      (a) => a.name.toLowerCase() === selectedCBMember.toLowerCase()
    );
    let selectedFieldValues = [];
    if (selectedMemberAssetMix.length > 0) {
      selectedFieldValues =
        (selectedMemberAssetMix[0].elements &&
          selectedMemberAssetMix[0].elements) ||
        [];
    }
    const heading = { label: 'Current', label1: 'Target' };
    const tempSelectedFieldValues = assetMixListValues.map((m) => {
      const x = selectedFieldValues.filter(
        (s) => s.label.toLowerCase() === m.label.toLowerCase()
      );
      if (x && x.length > 0) {
        return {
          ...m,
          current: x[0].current,
          target: x[0].target,
          operator: this.getClassOperator(x[0]),
        };
      }
      return { ...m };
    });
    const fieldValues = [...tempSelectedFieldValues];
    const num_of_columns = Math.round(tempSelectedFieldValues.length / 4);
    if (tempSelectedFieldValues.length > 0) {
      for (let index = 0; index < num_of_columns; index += 1) {
        fields_per_column.push(fieldValues.splice(0, 4));
      }
    }
    return (
      <>
        <div className="Choose_Risk_Appetite_outer d-lg-flex">
          <div className="Choose_Risk_Appetite_title">
            <h5 className="mb-0 title-div">Asset Mix</h5>
            {/* <p className="mb-0">for</p> */}
            <div className="Choose_Risk_Appetite_member">
              <FormGroup aria-label="members" row={false}>
                {memberOptions.map((cb, i) => (
                  <FormControlLabel
                    key={cb.id}
                    control={
                      <input
                        type="checkbox"
                        name={`members[${i}]`}
                        checked={cb.isSelected}
                        onChange={(event) =>
                          this.handleCheckboxFieldChange(event, i)
                        }
                      />
                    }
                    label={cb.title}
                  />
                ))}
              </FormGroup>
            </div>
          </div>
          <div className="Choose_Risk_Appetite_title">
            <h5 className="mb-0 title-div">Recommended Risk Level</h5>
            <div className="Choose_Risk_Appetite_dropdown select_client_outer">
              <Controls.Select
                className="js-example-basic-single"
                name="Moderately_Conservative"
                id="Moderately_Conservative"
                value={selectedRecommendedAssetAllocId}
                onChange={this.onRecommendedAssetAllocationChange}
                options={recommendedAssetAllocationValues.map((t) => {
                  return { label: t.label, value: t.value };
                })}
              />
            </div>
          </div>
          <div className="Choose_Risk_Appetite_title">
            <h5 className="mb-0 title-div">Adaptive?</h5>
            <div className="Choose_Risk_Appetite_dropdown select_client_outer">
              <FormControlLabel
                control={
                  <Checkbox
                    name="riskLevelAdaptive"
                    color="primary"
                    checked={isRiskLevelAdaptive || false}
                    onChange={(event) =>
                      this.handleOnChangeRiskLevelAdaptive(event)
                    }
                  />
                }
              />
            </div>
          </div>
          <div className="Choose_Risk_Appetite_title">
            <h5 className="mb-0 title-div">Model Portfolio</h5>
            <div className="Choose_Risk_Appetite_dropdown select_client_outer">
              <Controls.Select
                className="js-example-basic-single"
                name="Moderately_Conservative"
                id="Moderately_Conservative"
                value={selectedAllocationInfo.id || ''}
                onChange={this.onAllocationChange}
                options={allocation.map((p) => {
                  return { label: p.label, value: p.id };
                })}
              />
            </div>
          </div>
        </div>
        <div className="Current_Target_main_div d-lg-flex">
          {fields_per_column.map((column, index) => {
            return (
              <table>
                <thead>
                  <tr>
                    <th className="left">{heading.label}</th>
                    <th className="center"></th>
                    <th className="left">{heading.label1}</th>
                  </tr>
                </thead>
                {column.map((a) => {
                  return (
                    <tbody>
                      <tr>
                        <td className={`left ${a.operator}`}>{a.current}</td>
                        <td className="center">{a.label}</td>
                        <td className="rights">{a.target}</td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            );
          })}
        </div>
      </>
    );
  };

  handleProductsSelection = (e) => {
    let fieldValue =
      e.target.checked && e.target.defaultValue === 'true' ? true : false;
    this.setState({ isProductsSelected: fieldValue });
  };

  handleGetMarketDataChange = (e) => {
    let fieldValue =
      e.target.checked && e.target.defaultValue === 'true' ? true : false;
    this.setState({ isGetMarketDataSelected: fieldValue });
  }

  handleCustomSelection = (e) => {
    let fieldValue =
      e.target.checked && e.target.defaultValue === 'true' ? true : false;
    this.setState({ isCustomSelected: fieldValue });
  };

  handleBlur = (event, field) => {
    const { value } = event && event.target;
    const { selectedInvestmentAccount, assetMixListForInv, invCustomFields } =
      this.state;
    let newCustValue = value;
    if (!value.toString().includes('%')) {
      const percentCustVal =
        value !== '' && !isNaN(value) ? parseFloat(value).toFixed(2) : 0;
      newCustValue = `${percentCustVal}%`;
    }
    const newAssetMixList = assetMixListForInv.map((list) => {
      if (list.name === field) {
        return {
          ...list,
          value: newCustValue,
        };
      }
      return {
        ...list,
      };
    });
    const newInvCustomFields = invCustomFields.map((list) => {
      if (list.name === field) {
        return {
          ...list,
          value: newCustValue,
        };
      }
      return {
        ...list,
      };
    });
    const sumOfAssetMixValue = newAssetMixList.reduce(function (acc, val) {
      return acc + parseFloat(val.value);
    }, 0);

    const tempSelectedInvestmentAccount = {
      ...selectedInvestmentAccount,
      [field]: newCustValue,
    };
    this.setState({
      assetMixListForInv: [...newAssetMixList],
      invCustomFields: [...newInvCustomFields],
      isAssetMixPercentNotEqualTo100: sumOfAssetMixValue === 100 ? false : true,
      sumOfAssetMixValue,
      selectedInvestmentAccount: tempSelectedInvestmentAccount,
    });
  };

  setSelectedInvReturnValue = (event, fieldKey) => {
    let { value } = event.target;
    const { selectedInvestmentAccount = {} } = this.state;
    if (value.toString().includes('%')) {
      const percentFieldValue = value.split('%')[0];
      value =
        percentFieldValue !== '' && !isNaN(percentFieldValue)
          ? percentFieldValue
          : '';
    } else {
      value = value !== '' && !isNaN(value) ? value : '';
    }
    const tempSelectedInvestmentAccount = {
      ...selectedInvestmentAccount,
      [fieldKey]: value,
    };
    this.setState({
      selectedInvestmentAccount: tempSelectedInvestmentAccount,
    });
  };

  setInvCustomFieldValue = (event, name) => {
    let { value } = event.target;
    //const { selectedInvestmentAccount } = this.state;
    // if (value.toString().includes('%')) {
    //   const percentFieldValue = value.split('%')[0];
    //   value =
    //     percentFieldValue !== '' && !isNaN(percentFieldValue)
    //       ? percentFieldValue
    //       : '';
    // } else {
    //   value = value !== '' && !isNaN(value) ? value : '';
    // }
    const { invCustomFields } = this.state;
    const newInvCustomFields = invCustomFields.map((list) => {
      if (list.name === name) {
        return {
          ...list,
          value,
        };
      }
      return {
        ...list,
      };
    });
    // const tempSelectedInvestmentAccount = {
    //   ...selectedInvestmentAccount,
    //   [name]: value,
    // };
    this.setState({
      invCustomFields: [...newInvCustomFields],
      // selectedInvestmentAccount: tempSelectedInvestmentAccount,
    });
  };

  setAssetMixValue = (event, name) => {
    let { value } = event.target;
    // if (value.toString().includes('%')) {
    //   const percentFieldValue = value.split('%')[0];
    //   value =
    //     percentFieldValue !== '' && !isNaN(percentFieldValue)
    //       ? percentFieldValue
    //       : '';
    // } else {
    //   value = value !== '' && !isNaN(value) ? value : '';
    // }
    const { assetMixListForInv } = this.state;
    const newAssetMixList = assetMixListForInv.map((list) => {
      if (list.name === name) {
        return {
          ...list,
          value,
        };
      }
      return {
        ...list,
      };
    });
    this.setState({
      assetMixListForInv: [...newAssetMixList],
    });
  };

  updateInvBalance = (event) => {
    let { value } = event && event.target;
    this.setState({
      accBalance: value,
    });
  };

  handleOnBlur = (event) => {
    const value = this.formatCurrency(event?.target?.value);
    this.setState({
      accBalance: value,
    });
  };

  handleBlurAssetMix = (event, field) => {
    const { value } = event && event.target;
    const { selectedInvestmentAccount, assetMixListForInv, invCustomFields } =
      this.state;
    let newCustValue = value;
    if (!value.toString().includes('%')) {
      const percentCustVal =
        value !== '' && !isNaN(value) ? parseFloat(value).toFixed(2) : 0;
      newCustValue = `${percentCustVal}%`;
    }
    const newAssetMixList = assetMixListForInv.map((list) => {
      if (list.name === field) {
        return {
          ...list,
          value: newCustValue,
        };
      }
      return {
        ...list,
      };
    });
    const sumOfAssetMixValue = newAssetMixList.reduce(function (acc, val) {
      return acc + parseFloat(val.value);
    }, 0);

    const newInvCustomFields = invCustomFields.map((list) => {
      if (list.name === field) {
        return {
          ...list,
          value: newCustValue,
        };
      }
      return {
        ...list,
      };
    });
    this.setState({
      assetMixListForInv: [...newAssetMixList],
      isAssetMixPercentNotEqualTo100: sumOfAssetMixValue === 100 ? false : true,
      sumOfAssetMixValue,
      invCustomFields: newInvCustomFields,
    });
  };

  updateAssetMixInfo = (accountDetails) => {
    const { customReturn, customVol, customDivRate, invBalance } =
      accountDetails;
    let newCustRet = this.getPercentValue(customReturn);
    let newCustVol = this.getPercentValue(customVol);
    let newCustomDivRate = this.getPercentValue(customDivRate);
    const newAssetMixList = assetMixList.map((listItem) => {
      const value = accountDetails[listItem.name] || 0;
      let newPercentValue = this.getPercentValue(value);
      return {
        ...listItem,
        value: newPercentValue,
      };
    });
    const sumOfAssetMixValue = newAssetMixList.reduce(function (acc, val) {
      return acc + parseFloat(val.value);
    }, 0);
    this.setState({
      assetMixListForInv: newAssetMixList,
      isAssetMixPercentNotEqualTo100: sumOfAssetMixValue === 100 ? false : true,
      sumOfAssetMixValue,
      selectedInvestmentAccount: {
        ...accountDetails,
        customReturn: newCustRet,
        customVol: newCustVol,
        customDivRate: newCustomDivRate,
      },
    });
  };

  handleEdit = (isEditable = true, isNewRec = false, name = '') => {
    const { selectedInvestmentAccount = {}, accBalance } = this.state;
    const {
      products = [],
      nickName = '',
      assetProfile = '',
      investmentBucket = '',
    } = selectedInvestmentAccount;
    let tickersAvailableInPlan = isNewRec ? [] : products;
    let newNickName = isNewRec ? '' : nickName;
    let accountBalance = isNewRec ? '$0' : accBalance;
    let accountDetails = isNewRec
      ? {
        ...newAccountDetailsToBeAdded,
        nickName: newNickName,
        invBalance: `${accountBalance}`,
      }
      : { ...selectedInvestmentAccount };

    this.setState({
      isEditable,
      isAddNew: isNewRec,
      accBalance: accountBalance,
      newNickName,
      isButtonDisabled: false,
      tickersAvailableInPlan,
      selectedInvestmentBucket: isNewRec ? '' : investmentBucket,
      isProductsSelected: isNewRec ? true : assetProfile === 'products',
      isCustomSelected: isNewRec ? false : assetProfile === 'custom',
      isLastStep: false, // this is only for investments
    });
    this.updateAssetMixInfo(accountDetails);
  };

  openDeleteConfirmation = () => {
    this.setState({ isDeleteConfirmationOpen: true });
  };

  closeDeleteConfirmation = () => {
    this.setState({ isDeleteConfirmationOpen: false });
  };

  updateCountOnDelete = (markets, availableInvestmentAccounts) => {
    const {
      memberOptions,
      selectedMember,
      selectedMemberOption,
      selectedInvestmentAccount,
      isRiskLevelAdaptive,
      isCustomSelected,
      selectedInvestmentBucket,
    } = this.state;
    const selectedMemberInfo = memberOptions.find(
      (m) => m.id === selectedMember
    );
    if (!selectedMemberInfo) {
      return markets;
    }
    if (
      selectedInvestmentAccount &&
      Object.keys(selectedInvestmentAccount).length > 0 &&
      markets &&
      Object.keys(markets).length > 0
    ) {
      let selectedMemberInvestmentTypeInfo =
        markets[selectedMemberOption] || {};
      const { investmentBucket = '' } = selectedInvestmentAccount;

      const availableInvestmentBucketAccounts =
        availableInvestmentAccounts.filter(
          (f) => f.investmentBucket === investmentBucket
        );
      const availableAccounts = availableInvestmentBucketAccounts || [];
      selectedMemberInvestmentTypeInfo = {
        ...selectedMemberInvestmentTypeInfo,
        [`num${this.capitalizeFirstLetter(investmentBucket)}`]:
          availableAccounts.length,
        [investmentBucket]: availableAccounts.map((acc) => {
          const { customReturn, customVol, customDivRate, invBalance } = acc;
          return {
            ...acc,
            altPercent: this.percentageConversion(acc.altPercent),
            bondPercent: this.percentageConversion(acc.bondPercent),
            cashPercent: this.percentageConversion(acc.cashPercent),
            reitPercent: this.percentageConversion(acc.reitPercent),
            shortTermPercent: this.percentageConversion(acc.shortTermPercent),
            stockPercent: this.percentageConversion(acc.stockPercent),
            cryptoPercent: this.percentageConversion(acc.cryptoPercent),
            commoditiesPercent: this.percentageConversion(
              acc.commoditiesPercent
            ),
            emktBondPercent: this.percentageConversion(acc.emktBondPercent),
            emktStockPercent: this.percentageConversion(acc.emktStockPercent),
            intlBondPercent: this.percentageConversion(acc.intlBondPercent),
            intlStockPercent: this.percentageConversion(acc.intlStockPercent),
            customVol: !isCustomSelected
              ? 0
              : this.percentageConversion(customVol),
            customReturn: !isCustomSelected
              ? 0
              : this.percentageConversion(customReturn),
            customDivRate: !isCustomSelected
              ? 0
              : this.percentageConversion(customDivRate),
            invBalance: this.transformBackToAPIType(invBalance),
          };
        }),
      };
      const updatedMarkets = {
        ...markets,
        isRiskLevelAdaptive,
        [selectedMemberOption]: {
          ...selectedMemberInvestmentTypeInfo,
        },
      };
      return updatedMarkets;
    }
    return markets;
  };

  updateAccountsOnDelete = (availableInvestmentAccounts) => {
    const { localPlanDetailsByPlanId } = this.state;
    let clonedPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    const { moduleInvestments: { markets = {} } = {}, moduleInvestments = {} } =
      clonedPlanDetailsByPlanId;
    let tempPlanDetailsByPlanId = { ...clonedPlanDetailsByPlanId };
    let tempMarkets = { ...markets };
    tempMarkets = this.updateCountOnDelete(
      markets,
      availableInvestmentAccounts
    );
    if (!isEqual(tempMarkets, markets)) {
      tempPlanDetailsByPlanId = {
        ...clonedPlanDetailsByPlanId,
        moduleInvestments: {
          ...moduleInvestments,
          markets: { ...tempMarkets },
        },
      };
      this.setState((prevState) => ({
        ...prevState,
        localPlanDetailsByPlanId: tempPlanDetailsByPlanId,
      }));
    }
    // if (selectedMember === 'first') {
    //   newMemberValues.first = this.updateCountOnDelete(
    //     first,
    //     availableInvestmentAccounts
    //   );
    //   this.getAllAccountsFromBuckets(newMemberValues.first);
    // } else if (selectedMember === 'second') {
    //   newMemberValues.second = this.updateCountOnDelete(
    //     second,
    //     availableInvestmentAccounts
    //   );
    //   this.getAllAccountsFromBuckets(newMemberValues.second);
    // }
    // this.setState({ memberValues: newMemberValues });
    // updateAssetMixInRedux({
    //   invbalanceReq: newMemberValues,
    //   invValueAsOf,
    //   localProdInfo,
    // });
  };

  handleDeleteConfirm = () => {
    const { availableInvestmentAccounts, selectedInvestmentAccount } =
      this.state;
    const selectedIndex = availableInvestmentAccounts.indexOf(
      availableInvestmentAccounts.filter(
        (f) =>
          f.nickName.toLowerCase() ===
          selectedInvestmentAccount?.nickName.toLowerCase()
      )[0]
    );
    if (selectedIndex !== -1) {
      availableInvestmentAccounts.splice(selectedIndex, 1);
    }
    this.setState(
      {
        isDeleteConfirmationOpen: false,
        tickersAddedToPlan: [],
        tickersAvailableInPlan: [],
        availableInvestmentAccounts,
      },
      () => {
        this.updateAccountsOnDelete(availableInvestmentAccounts);
      }
    );
  };

  deleteConfirmation = () => {
    const {
      isDeleteConfirmationOpen,
      selectedInvestmentAccount: { nickName = '' } = {},
    } = this.state;
    return (
      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={this.closeDeleteConfirmation}
        className="delete_popup_outer"
      >
        <DialogTitle>Are you sure you want to delete "{nickName}"?</DialogTitle>
        <DialogActions>
          <Button
            onClick={this.closeDeleteConfirmation}
            color="primary"
            style={{
              padding: '7.5px 15px',
              color: '#fff',
              fontWeight: 700,
              fontSize: '12px',
              marginRight: '10px',
              backgroundColor: 'gray !important',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleDeleteConfirm}
            color="primary"
            style={{
              padding: '7.5px 15px',
              color: '#fff',
              fontWeight: 700,
              fontSize: '12px',
              marginRight: '10px',
              backgroundColor: '#009fe3',
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  onInvBucketChange = (event) => {
    let { value } = event.target;
    const { selectedInvestmentAccount = {}, newNickName = '' } = this.state;
    const selectedAccountInfo = investmentFields.filter(
      (f) => f.id.toLowerCase() === value.toLowerCase()
    );
    let selectedInvestmentBucket = '';
    if (selectedAccountInfo && selectedAccountInfo.length > 0) {
      selectedInvestmentBucket = selectedAccountInfo[0].id;
    }
    this.setState({
      selectedInvestmentBucket,
      oldInvestmentBucket: selectedInvestmentAccount?.investmentBucket || '',
      // selectedInvestmentAccount: {
      //   ...selectedInvestmentAccount,
      //   investmentBucket: selectedInvestmentBucket,
      //   id:
      //     newNickName !== ''
      //       ? `${newNickName}-${selectedInvestmentBucket}`
      //       : '',
      // },
    });
  };

  updateInvAccNickName = (event) => {
    let { value } = (event && event.target) || '';
    const { availableInvestmentAccounts } = this.state;
    const isNameValid = validateNameField(value);
    let index = -1;
    if (isNameValid) {
      index = availableInvestmentAccounts.indexOf(
        availableInvestmentAccounts.filter(
          (a) => a.nickName.toLowerCase() === value.toLowerCase()
        )[0]
      );
    }
    let isButtonDisabled = isNameValid && index === -1 ? false : true;
    this.setState({
      newNickName: value,
      isButtonDisabled,
      buttonDisabledMsg: !isNameValid
        ? 'Invalid Name'
        : isButtonDisabled
          ? 'Name already exists'
          : '',
    });
  };

  updatePortfolio = () => {
    this.setState({ isUpdateClicked: true, isLastStep: true });
  };

  renderPortfolioInvestmentContainer = () => {
    const { investmentProducts } = this.props;
    const {
      memberOptions,
      selectedMember,
      selectedProductType,
      selectedTicker,
      tickers,
      tickersAddedToPlan,
      selectedInvestmentAccount,
      availableInvestmentAccounts,
      isProductsSelected,
      isCustomSelected,
      assetMixListForInv,
      invCustomFields,
      isAssetMixPercentNotEqualTo100,
      sumOfAssetMixValue,
      accBalance,
      selectedInvestmentBucket,
      isEditable,
      isAddNew,
      newNickName,
      isButtonDisabled,
      buttonDisabledMsg,
      isGetMarketDataSelected
    } = this.state;
    const {
      investmentBucket = '',
      id = '',
      nickName = '',
    } = selectedInvestmentAccount || {};
    const investmentBucketName = this.getInvestmentBucketName(investmentBucket);
    return (
      <>
        <div className="expenses_grid_main_outer">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-8 col-sm-8">
              <h5 className="mb-0 title-div">Balance portfolio for</h5>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <Controls.RadioGroup
                name="member-radio-buttons"
                value={selectedMember}
                rowType={true}
                onChange={(event) =>
                  this.handleMemberOptionChange(event, 'radio')
                }
                disabled={isEditable}
                items={memberOptions}
              ></Controls.RadioGroup>
            </div>
          </div>
        </div>

        {!isEditable && !isAddNew ? (
          <div className="expenses_grid_main_outer">
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-8 col-sm-8">
                <p className="mb-0">Get Market Data</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <Controls.RadioGroup
                  name="member-radio-buttons"
                  value={isGetMarketDataSelected}
                  rowType={true}
                  onChange={this.handleGetMarketDataChange}
                  items={radioOptions}
                ></Controls.RadioGroup>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        
        <div className="Select_account_main_outer">
          {!isEditable ? (
            <div className="select_account_dropdown-outer_one">
              <div className="row">
                <div className="col-lg-9 col-md-9 col-8">
                  <div className="Select_account_dropdown">
                    <div className='controls-select-main-outer'>
                      <h5 className="account_dropdown_label">Select Account </h5>
                      <Controls.Select
                        className="js-example-basic-single"
                        name="Account"
                        id="Account"
                        value={id}
                        onChange={(e) => this.onInvAccountChange(e)}
                        options={availableInvestmentAccounts.map((p, index) => {
                          return { label: p.nickName, value: p.id, index: index };
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3 col-3 button-group"
                  style={{
                    width: 'max-content',
                  }}
                >
                  {availableInvestmentAccounts &&
                    availableInvestmentAccounts.length > 0 && (
                      <>
                        <button
                          className="action-button"
                          onClick={(e) => {
                            this.handleEdit(true);
                          }}
                        >
                          <img src={edit_pencil} alt="" title="" />
                        </button>
                        <button
                          className="action-button"
                          onClick={this.openDeleteConfirmation}
                        >
                          <img src={delete_icon} alt="" title="" />
                        </button>
                      </>
                    )}
                  <button
                    className="action-button"
                    onClick={(event) => {
                      this.handleEdit(true, true);
                    }}
                  >
                    <img src={pdf_page_plus_icon} alt="" title="" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="select_account_dropdown-outer">
              <div className="Balance_input_div">
                <h5 className="mb-10 title-div">Account Name </h5>
                <div className="Balance_input_div_inner">
                  <input
                    type="text"
                    id="account_name"
                    name="Account Name"
                    disabled={isEditable && !isAddNew}
                    value={isAddNew ? newNickName || '' : nickName || ''}
                    placeholder={'Enter Account Name'}
                    onChange={(e) => this.updateInvAccNickName(e)}
                  />
                </div>
              </div>
            </div>
          )}
          {selectedInvestmentAccount &&
            Object.keys(selectedInvestmentAccount).length > 0 && (
              <div className="select_account_dropdown-outer">
                <div className="Balance_input_div">
                  <h5 className="mb-10 title-div">Balance</h5>
                  <div className="Balance_input_div_inner">
                    <input
                      type="text"
                      id="total_Balance"
                      name="Total Balance"
                      value={accBalance || '$0'}
                      onChange={(e) => this.updateInvBalance(e)}
                      onBlur={(event) => this.handleOnBlur(event)}
                      disabled={isProductsSelected}
                    />
                  </div>
                </div>
                {!isEditable && !isAddNew ? (
                  <div className="Balance_input_div">
                    <h5 className="mb-10 title-div">
                      Investment Bucket
                    </h5>
                    <div className="Balance_input_div_inner">
                      <input
                        type={'text'}
                        id={investmentBucket}
                        name={investmentBucket}
                        value={investmentBucketName || ''}
                        disabled
                        className={'custom-input'} // Apply the appropriate class
                      />
                    </div>
                  </div>
                ) : (
                  <div className="Balance_input_div">
                    <h5 className="mb-10 title-div required">
                      Investment Bucket{' '}
                    </h5>
                    <div className="Balance_input_div_inner">
                      <Controls.Select
                        className="js-example-basic-single"
                        name="Account"
                        maxMenuHeight={150}
                        id="Account"
                        value={selectedInvestmentBucket || ''}
                        onChange={(e) => this.onInvBucketChange(e)}
                        options={investmentFields.map((p, index) => {
                          return { label: p.title, value: p.id, index: index };
                        })}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
        </div>
        <div className="markets_grid_full">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-12">
              {isButtonDisabled && buttonDisabledMsg && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
                      <Alert severity="error">
                        <p>{buttonDisabledMsg}</p>
                      </Alert>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </div>
        {isEditable && (
          <>
            <div className="expenses_grid_main_outer">
              <div className="row align-items-center">
                <div className="col-lg-8 col-md-8 col-sm-8">
                  <p className="mb-0">Do you have product details?</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <Controls.RadioGroup
                    name="member-radio-buttons"
                    value={isProductsSelected}
                    rowType={true}
                    onChange={this.handleProductsSelection}
                    items={radioOptions}
                  ></Controls.RadioGroup>
                </div>
              </div>
            </div>
            {!isProductsSelected && (
              <div className="GenericExpences_no">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-md-6 col-sm-4">
                    <p className="mb-0">
                      Does this account have customized risk/return?
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-8">
                    <Controls.RadioGroup
                      name="member-radio-buttons"
                      value={isCustomSelected}
                      rowType={true}
                      onChange={this.handleCustomSelection}
                      items={radioOptions}
                    ></Controls.RadioGroup>
                  </div>
                </div>
              </div>
            )}
            <div className="markets_grid_full">
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-12">
                  <Grid container>
                    {isProductsSelected && (
                      <Grid item xs={12}>
                        <div className="select_product_main_outer">
                          <div className="select_product_dropdown_outer">
                            <div className="select_product_title">
                              <h5 className="mb-0 title-div">Select Product</h5>
                            </div>
                            <Controls.Select
                              className="js-example-basic-single"
                              name="select_product"
                              id="select_product"
                              value={selectedProductType}
                              onChange={this.onProductTypeChange}
                              options={investmentProducts.map((t) => {
                                return {
                                  label: t.productType,
                                  value: t.productType,
                                };
                              })}
                            />
                          </div>
                          <div className="select_product_dropdown_outer">
                            <div className="select_product_title">
                              <h5 className="mb-0 title-div">Add Ticker</h5>
                            </div>
                            <Controls.Select
                              className="js-example-basic-single"
                              name="select_ticker"
                              id="select_ticker"
                              value={selectedTicker}
                              options={tickers.map((t) => {
                                return { label: t.ticker, value: t.ticker };
                              })}
                              onChange={(e) => this.onTickerChange(e)}
                              allowAddNewItem
                            />
                          </div>
                        </div>
                        <div className="select_product_table_main_outer">
                          {tickersAddedToPlan &&
                            tickersAddedToPlan.length > 0 &&
                            tickersAddedToPlan.map((ticker, index) => {
                              return (
                                <div className="select_product_table_inner">
                                  <div className="select_product_table_column">
                                    <div className="select_product_table_content">
                                      <Tooltip
                                        title={ticker.desc}
                                        placement="bottom-end"
                                      >
                                        <p className="value">{ticker.ticker}</p>
                                      </Tooltip>
                                    </div>
                                    <div className="select_product_table_amount">
                                      <input
                                        id={`tickerQuantity-${index}`}
                                        placeholder="Quantity"
                                        value={ticker.quantity || ''}
                                        type="number"
                                        onChange={(e) => {
                                          this.updateTickerQuantity(
                                            e,
                                            index,
                                            accBalance
                                          );
                                        }}
                                        onBlur={(event) => {
                                          this.handleOnBlurTickers(
                                            event,
                                            index,
                                            'quantity'
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="select_product_table_column">
                                    <div className="select_product_table_amount">
                                      <input
                                        id={`tickerPrice-${index}`}
                                        placeholder="Price"
                                        disabled={
                                          index ===
                                          tickersAddedToPlan.length - 1
                                        }
                                        value={ticker.price || ''}
                                        onChange={(e) => {
                                          this.updateTickerPrice(
                                            e,
                                            index,
                                            accBalance
                                          );
                                        }}
                                        onBlur={(event) => {
                                          this.handleOnBlurTickers(
                                            event,
                                            index,
                                            'price'
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="select_product_table_amount">
                                      <input
                                        id={`tickerAmount-${index}`}
                                        placeholder="Amount $"
                                        disabled
                                        value={ticker.balance || ''}
                                      />
                                    </div>
                                    <div className="select_product_table_delete">
                                      {index !==
                                        tickersAddedToPlan.length - 1 && (
                                          <button>
                                            <img
                                              src={delete_icon}
                                              title=""
                                              alt=""
                                              onClick={(e) =>
                                                this.deleteTicker(
                                                  e,
                                                  index,
                                                  ticker
                                                )
                                              }
                                            />
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </Grid>
                    )}
                    <div className="asset-mix-input-outer">
                      {!isProductsSelected && isCustomSelected && (
                        <>
                          <Grid item xs={12}>
                            <div className="asset-mix-input">
                              {invCustomFields.map((list) => (
                                <Grid container>
                                  <Grid
                                    item
                                    xs={6}
                                    className="planner-label"
                                    style={{ padding: '10px 0' }}
                                  >
                                    {list.label}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ marginTop: '10px' }}
                                  >
                                    <input
                                      className="planner-input"
                                      name={list.name}
                                      value={list.value || ''}
                                      label={list.label}
                                      onChange={(event) =>
                                        this.setInvCustomFieldValue(
                                          event,
                                          list.name
                                        )
                                      }
                                      onBlur={(event) =>
                                        this.handleBlurAssetMix(
                                          event,
                                          list.name
                                        )
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack sx={{ width: '100%', padding: '2px' }}>
                              <Alert severity="warning">
                                <p className="select-bank-text">
                                  Please make sure to enter Custom Return,
                                  Custom Volatality and Custom Dividend Rate
                                </p>
                              </Alert>
                            </Stack>
                          </Grid>
                        </>
                      )}
                    </div>
                    <div className="asset-mix-input-outer">
                      {!isProductsSelected && (
                        <Grid item xs={12}>
                          <div className="asset-mix-input">
                            {assetMixListForInv.map((list) => (
                              <Grid container>
                                <Grid
                                  item
                                  xs={6}
                                  className="planner-label"
                                  style={{ padding: '10px 0' }}
                                >
                                  {list.label}
                                </Grid>
                                <Grid item xs={6} style={{ marginTop: '10px' }}>
                                  <input
                                    className="planner-input"
                                    name={list.name}
                                    value={list.value || ''}
                                    label={list.label}
                                    onChange={(event) =>
                                      this.setAssetMixValue(event, list.name)
                                    }
                                    onBlur={(event) =>
                                      this.handleBlurAssetMix(event, list.name)
                                    }
                                  />
                                </Grid>
                              </Grid>
                            ))}
                          </div>
                        </Grid>
                      )}

                      {!isProductsSelected &&
                        isAssetMixPercentNotEqualTo100 && (
                          <Grid item xs={12}>
                            <Stack sx={{ width: '100%', padding: '2px', marginBottom: '20px' }}>
                              <Alert severity="error">
                                <p>
                                  Asset mix should add upto 100%, currently it
                                  is at {sumOfAssetMixValue}%{' '}
                                </p>
                              </Alert>
                            </Stack>
                          </Grid>
                        )}
                    </div>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="markets_grid_full">
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-12">
                  <Grid container spacing={2}>
                    <Grid item xs={12} className={`${'save_button'}`}>
                      <div className="plaid_save_button_group">
                        <button onClick={this.handleCancelOrReset}>
                          Cancel
                        </button>
                        <button
                          className={
                            isButtonDisabled ||
                              (isAssetMixPercentNotEqualTo100 &&
                                !isProductsSelected) ||
                              !newNickName ||
                              !selectedInvestmentBucket
                              ? 'secondary-button'
                              : ''
                          }
                          onClick={this.updatePortfolio}
                          disabled={
                            isButtonDisabled ||
                            (isAssetMixPercentNotEqualTo100 &&
                              !isProductsSelected) ||
                            !newNickName ||
                            !selectedInvestmentBucket
                          }
                        >
                          Update
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </>
        )}
        {this.deleteConfirmation()}
      </>
    );
  };

  renderLeftContentContainer = () => {
    const { activeMainTab, activeSubTab } = this.state;
    const subTabLowerCase = activeSubTab.toLowerCase();
    switch (activeMainTab) {
      case 'investments':
        switch (subTabLowerCase) {
          case 'risk level':
            return this.renderRiskLevelInvestmentContainer();
          case 'portfolio':
            return this.renderPortfolioInvestmentContainer();
          default:
            return this.renderRiskLevelInvestmentContainer();
        }
      case 'expenses':
        switch (subTabLowerCase) {
          case 'lifetime':
            return this.renderExpensesContainer('lifetime');
          case 'horizon':
            return this.renderExpensesContainer('horizon');
          case 'retirement':
            return this.renderExpensesContainer('retirement');
          case 'discretionary':
            return this.renderExpensesContainer('discretionary');
          default:
            return this.renderExpensesContainer('lifetime');
        }
      case 'goals':
        switch (subTabLowerCase) {
          case 'goals':
            return this.renderGoalsContainer();
          default:
            return this.renderGoalsContainer();
        }
      case 'insurance':
        switch (subTabLowerCase) {
          case 'term life':
            return this.renderInsuranceContentContainer('termLife');
          case 'whole life':
            return this.renderInsuranceContentContainer('wholeLife');
          case 'long term care':
            return this.renderInsuranceContentContainer('longTermCare');
          case 'umbrella':
            return this.renderInsuranceContentContainer('umbrella');
          case 'property':
            return this.renderInsuranceContentContainer('property');
          case 'vehicle':
            return this.renderInsuranceContentContainer('auto');
          case 'health':
            return this.renderInsuranceContentContainer('health');
          default:
            return this.renderInsuranceContentContainer('termLife');
        }
      case 'annuities':
        switch (subTabLowerCase) {
          case 'fixed':
          case 'index':
          case 'variable':
          case 'immediate':
          case 'longevity':
            return this.renderFutureImplementationContainer();
          default:
            return this.renderFutureImplementationContainer();
        }
      case 'debt':
        switch (subTabLowerCase) {
          case 'mortgage':
          case 'cc':
          case 'loan':
            return this.renderFutureImplementationContainer();
          default:
            return this.renderFutureImplementationContainer();
        }
      default:
        break;
    }
  };

  renderSolutionsContainer = () => {
    const {
      parentTabValue,
      solutionsTabs,
      renderType,
      tabInfo,
      activeMainTab,
      activeSubTab,
      isLastStep,
      isAssetMixPercentNotEqualTo100,
      isProductsSelected,
      isFormValid,
      isEditable,
      isAddNew,
      subTabs,
      // isLastStepInExpenses,
    } = this.state;
    const { showLeftNav, showRightNav, mainNavItems } = this.state;
    const subTabLowerCase = activeSubTab?.toLowerCase();
    return (
      <div className="client_main product_main outer">
        <div className="carousel-container" ref={this.carouselContainerRef}>
          <div key="left" className="carousel-itemm">
            <div className="inner">
              <div
                className={
                  isLastStep
                    ? 'client_left product_left solution_tab_content'
                    : 'client_left product_left solution_tab_content solution_action_height_full'
                }
              >
                <div className="product_left_content">
                  <div className="Main_Build_nav_main_outer">
                    {/* Main Navigation */}
                    {subTabLowerCase !== '' ? (
                      <></>
                    ) : (
                      <>
                        <div className="Main_Build_nav_outer">
                          {mainNavItems.map((item, index) => (
                            <div
                              key={index}
                              className={`main_build_nav_item ${item.parent === activeMainTab ? 'active' : ''
                                }`}
                              onClick={() =>
                                this.handleMainNavClick(item.parent)
                              }
                            >
                              <div className="main_build_nav_icon">
                                <img
                                  src={item.icon}
                                  alt={item.title}
                                  title={item.title}
                                />
                              </div>
                              <div className="main_build_nav_title">
                                <h5>{item.title}</h5>
                              </div>
                            </div>
                          ))}
                        </div>
                        {/* Sub Navigation */}
                        <div className="Main_Build_sub_nav">
                          <ul>
                            {subTabs.map((item, index) => {
                              const isLastItem = index === subTabs.length - 1;
                              return (
                                <li
                                  key={index}
                                  className={`Main_Build_sub_nav_item ${item.toLowerCase() === subTabLowerCase
                                    ? 'active'
                                    : ''
                                    } ${isLastItem ? 'last-item' : ''}`}
                                  onClick={() => this.handleSubNavClick(item)}
                                >
                                  {item}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                  {subTabLowerCase === '' ? (
                    <></>
                  ) : (
                    <div className="product_left_content-inner">
                      {/* Render Content */}
                      <div className="Main_Build_content">
                        <div className="breadcrumb">
                          <ul>
                            <li>Solutions</li>
                            <li>
                              {mainNavItems.find(
                                (f) => f.parent.toLowerCase() === activeMainTab
                              )?.title || ''}
                            </li>
                            {subTabLowerCase !== '' && <li>{activeSubTab}</li>}
                          </ul>
                        </div>
                        {this.renderLeftContentContainer()}
                      </div>
                      {isEditable ||
                        isAddNew ? (
                        <></>
                      ) : (
                        <div className="tab_main_nav block">
                          <div className="tab_left_nav">
                            <Button
                              onClick={this.handlePrevious}
                              disabled={!isFormValid}
                            >
                              <ArrowBackIcon /> Previous
                            </Button>
                          </div>
                          <div className="tab_right_nav">
                            <Button
                              onClick={this.handleNext}
                              disabled={!isFormValid}
                            >
                              Next <ArrowForwardIcon />
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* {solutionsTabs &&
                    solutionsTabs.length > 0 &&
                    this.renderSolutionSubMenuContainer()} */}
                </div>
                {/* <div className="product_left_content">
                  {this.renderLeftContentContainer()}
                </div> */}
                {isLastStep && activeMainTab !== 'investments' && activeSubTab !== '' && (
                  <div className="Save_Simulate_button_outer">
                    <div className="plan_results_main_outer_right">
                      <div className="Simulate_button_group">
                        <button
                          onClick={() => this.handleSimulate(false)}
                          disabled={activeSubTab === ''}
                          className={
                            activeSubTab === ''
                              ? 'common-button secondary-button'
                              : 'common-button'
                          }
                        >
                          <img src={Simulate_icon_1} alt="" title="" /> Simulate
                        </button>
                        <button
                          onClick={() => this.handleSimulate(true)}
                          disabled={activeSubTab === ''}
                          className={
                            activeSubTab === ''
                              ? 'common-button secondary-button'
                              : 'common-button'
                          }
                        >
                          <img src={Simulate_icon_2} alt="" title="" /> Optimize
                        </button>
                        <button onClick={this.handleSave}>
                          <img src={Save_icon} alt="" title="" /> Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {isLastStep && activeMainTab === 'investments' && activeSubTab !== '' && (
                  <div className="Save_Simulate_button_outer">
                    <div className="plan_results_main_outer_right">
                      <div className="Simulate_button_group">
                        <button
                          onClick={() =>
                            this.handleAddProductsToPortfolio(false)
                          }
                          disabled={
                            activeSubTab === '' ||
                            (!isProductsSelected &&
                              isAssetMixPercentNotEqualTo100)
                          }
                          className={
                            activeSubTab === '' ||
                              (!isProductsSelected &&
                                isAssetMixPercentNotEqualTo100)
                              ? 'common-button secondary-button'
                              : 'common-button'
                          }
                        >
                          <img src={Simulate_icon_1} alt="" title="" /> Simulate
                        </button>
                        <button
                          onClick={() =>
                            this.handleAddProductsToPortfolio(true)
                          }
                          disabled={
                            activeSubTab === '' ||
                            (!isProductsSelected &&
                              isAssetMixPercentNotEqualTo100)
                          }
                          className={
                            activeSubTab === '' ||
                              (!isProductsSelected &&
                                isAssetMixPercentNotEqualTo100)
                              ? 'common-button secondary-button'
                              : 'common-button'
                          }
                        >
                          <img src={Simulate_icon_2} alt="" title="" /> Optimize
                        </button>
                        <button
                          onClick={this.handleSave}
                          className={
                            !isProductsSelected &&
                              isAssetMixPercentNotEqualTo100
                              ? 'common-button secondary-button'
                              : 'common-button'
                          }
                          disabled={
                            !isProductsSelected &&
                            isAssetMixPercentNotEqualTo100
                          }
                        >
                          <img src={Save_icon} alt="" title="" /> Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="divider_line"></div>
          <div key="right" className="carousel-itemm">
            <div className="inner">
              <div className="client_right solution_tab_content">
                <div className="client_main_right">
                  <div className="client_main_right_left">
                    <TabsComponent
                      renderType={renderType}
                      updateRenderType={this.updateRenderType}
                    />

                    <div className="chrono-tabs-wrapper">
                      {renderType !== 'Custom' ? (
                        <div className="chrono-parent-tab">
                          <Tabs
                            value={parentTabValue}
                            onChange={this.handleParentTabChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            TabIndicatorProps={{
                              style: {
                                display: 'none',
                              },
                            }}
                          >
                            {tabInfo &&
                              tabInfo.length > 0 &&
                              tabInfo.map((item, index) => (
                                <Tab
                                  iconPosition="start"
                                  label={item.tabName}
                                  {...a11yProps(index)}
                                  sx={{ padding: '10px !important' }}
                                />
                              ))}
                          </Tabs>
                          {tabInfo &&
                            tabInfo.length > 0 &&
                            this.renderSubMenuContainer()}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      className={
                        renderType !== 'Custom'
                          ? 'result_scroll_div'
                          : 'result_scroll_div result_scroll_div_custom'
                      }
                    >
                      <HorizonDetails
                        isSolutions={true}
                        isV1={false}
                        isV2={true}
                        renderType={renderType}
                        isNewSolutions={true}
                        renderMainType={'solutions'}
                        leftParentTabValue={activeMainTab}
                        leftSubTabValue={activeSubTab}
                      />
                      {this.renderContentContainer()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Left and Right Arrows */}
        {showLeftNav && (
          <div
            className="arrow left"
            id="prevButton"
            onClick={this.showPreviousItem}
          >
            <DescriptionIcon />
          </div>
        )}
        {showRightNav && (
          <div
            className="arrow right"
            id="nextButton"
            onClick={this.showNextItem}
          >
            <BarChartIcon />
          </div>
        )}
      </div>
    );
  };

  handleSave = () => {
    const { planDetailsByPlanId, triggerSaveAndUpdatePlanDetailsbyPlanId } =
      this.props;

    const version = 'v2';
    triggerSaveAndUpdatePlanDetailsbyPlanId({
      version,
      planDetailsByPlanId: transformBackToAPIType(planDetailsByPlanId),
    });
  };

  render() {
    const {
      simulatePlanDetailsLoading,
      isAssetAllocationListByClientIdLoading,
    } = this.props;
    const { noChangeLoader } = this.state;

    return (
      <>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            simulatePlanDetailsLoading ||
            isAssetAllocationListByClientIdLoading ||
            noChangeLoader
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="main-content-wrapper">
          <div className="container-fluid main-content-wrapper-inner">
            <div className="row">{this.renderSolutionsContainer()}</div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userDetails: getLoggedInUserDetails(state),
    clients: state.clientInfoReducer.clients || [],
    planDetailsByPlanId: planDetailsByPlanIdSelector(state),
    termLifeInsuranceProducts: termLifeInsuranceProductsSelector(state),
    wholeLifeInsuranceProducts: wholeLifeInsuranceProductsSelector(state),
    longTermCareInsuranceProducts: longTermCareInsuranceProductsSelector(state),
    umbrellaInsuranceProducts: umbrellaInsuranceProductsSelector(state),
    propertyInsuranceProducts: propertyInsuranceProductsSelector(state),
    autoInsuranceProducts: autoInsuranceProductsSelector(state),
    healthInsuranceProducts: healthInsurnceProductSelector(state),
    plannerDetailsLoading: plannerDetailsLoadingSelector(state),
    isOutputResponseLoading: outputResponseLoadingSelector(state),
    isPlanDetailsSaved: plannerDetailsSaveStatus(state),
    planDetailsSimulated: plannerDetailsSimulateStatus(state),
    expenseFactorDetails: expenseFactorDetailsSelector(state),
    charts: outputResponseChartsByPlanIdSelector(state) || [],
    tables: outputResponseTablesByPlanIdSelector(state) || [],
    data: outputResponseDataByPlanIdSelector(state) || [],
    solutions: outputResponseSolutionsByPlanIdSelector(state) || {},
    selectedDisplayTerm: selectedDisplayTermForClientIdSelector(state),
    selectedHorizonTerm: selectedHorizonTermForClientIdSelector(state),
    selectedClientId: selectedClientIdSelector(state),
    selectedPlan: selectedPlanIdForClientIdSelector(state),
    simulatePlanDetailsLoading: simulatePlanDetailsLoadingSelector(state),
    planner: outputResponsePlannerDetailsByPlanIdSelector(state),
    selectedChart: selectedChartSelector(state) || '',
    pushPinCharts: getPushPinCharts(state),
    allocation: state.clientInfoReducer.allocation || [],
    isAssetAllocationListByClientIdLoading:
      state.clientInfoReducer.isAssetAllocationListByClientIdLoading,
    beforeAfterAssetMixValues: beforeAfterAssetMixValuesSelector(state) || [],
    assetAllocationByProductIdLoading:
      assetAllocationByProductIdLoadingSelector(state),
    assetMixValues: getAssetAllocationByProductIdSelector(state),
    recommendedAssetAllocationValues:
      getRecommendedAssetAllocationByClientIdSelector(state),
    investmentProducts: getInvestmentProductsByClientIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    triggerUpdatePlannerDetailsinRedux: (payload) =>
      dispatch(simulatePlanDetailsByPlanIdSuccessAction(payload)),
    triggerSetTermInsuranceProducts: (payload) =>
      dispatch(termLifeInsuranceProductsSuccessAction(payload)),
    triggerSetWholeInsuranceProducts: (payload) =>
      dispatch(wholeLifeInsuranceProductsSuccessAction(payload)),
    triggerSetLongTermCareInsuranceProducts: (payload) =>
      dispatch(longTermCareInsuranceProductsSuccessAction(payload)),
    triggerSetUmbrellaInsuranceProducts: (payload) =>
      dispatch(umbrellaInsuranceProductsSuccessAction(payload)),
    triggerSetPropertyInsuranceProducts: (payload) =>
      dispatch(propertyInsuranceProductsSuccessAction(payload)),
    triggerSetAutoInsuranceProducts: (payload) =>
      dispatch(autoInsuranceProductsSuccessAction(payload)),
    triggerSetHealthInsuranceProducts: (payload) =>
      dispatch(healthInsuranceProductsSuccessAction(payload)),
    triggerSimulatePlanDetailsByPlanId: (payload) =>
      dispatch(simulatePlanDetailsByPlanId(payload)),
    triggerSaveAndUpdatePlanDetailsbyPlanId: (payload) =>
      dispatch(saveAndUpdatePlanDetailsByPlanId(payload)),
    getSavedSelectedPlanIdDetails: (payload) =>
      dispatch(getPlansDetailsAction(payload)),
    triggerSetSelectedChart: (payload) =>
      dispatch(setSelectedChartAction(payload)),
    triggerSetSelectedTable: (payload) =>
      dispatch(setSelectedTableAction(payload)),
    triggerSetSelectedData: (payload) =>
      dispatch(setSelectedDataAction(payload)),
    triggerSetBeforeAfterAssetMixValues: (payload) =>
      dispatch(setBeforeAfterAssetMixAction(payload)),
    triggerPushPinCharts: (payload) => dispatch(pushPinCharts(payload)),
    triggerGetAssetAllocationByProductId: (payload) =>
      dispatch(getAssetAllocationByProductId(payload)),
    triggerGetRecommendedAssetAllocListByClientId: (payload) =>
      dispatch(getRecommendedAssetAllocListByClientId(payload)),
    triggerGetAssetAllocListByClientId: (payload) =>
      dispatch(getAssetAllocListByClientIdAction(payload)),
    triggerSelectInvestmentProductsByClientId: (payload) =>
      dispatch(getInvestmentProductsByClientIdAction(payload)),
    triggerSetAssetAllocListByClientIdAction: (payload) =>
      dispatch(setAssetAllocListByClientIdAction(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(NewSolutions));
