import React, {
  Component,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import JourneyButtons from '../../components/shared/JourneyButtons';
import { Grid } from '@material-ui/core';
import Controls from '../../components/controls/Controls';
import { ExpenseTypes, lifestyleTypes } from '../../constants/dashboard';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

// const radioOptions = [
//   { title: 'Yes', id: true },
//   { title: 'No', id: false },
// ];

export default function BuildExpensesLivingComponent(props) {
  const {
    updateExpenseDetailsInRedux,
    storeValues,
    isV2,
    setLastStepInParent,
    infoCore,
  } = props;

  const [isBlurCalled, setIsBlurCalled] = useState(false);
  const [expenseTab, setExpenseTab] = useState('');
  const [formDetails, setFormDetails] = useState({ ...storeValues });
  const [stepName, setStepName] = useState({
    label: 'Rental',
    value: 'rental',
  });
  const [retired, setRetired] = useState(false);

  const [selectedGenericExpences, setSelectedGenericExpences] = useState(false);

  const GenericExpences = [
    { id: true, title: 'Yes' },
    { id: false, title: 'No' },
  ];
  const handleGenericExpencesChange = (e) => {
    let fieldValue =
      e.target.checked && e.target.defaultValue === 'true' ? true : false;
    let lifeStyleValue = 'custom';
    if (fieldValue) {
      lifeStyleValue =
        formDetails.lifestyle === 'custom' ? 'prudent' : formDetails.lifestyle;
    }
    const tempFormDetails = {
      ...formDetails,
      lifestyle: lifeStyleValue,
      // useDiscretionaryFlag,
    };
    setFormDetails(tempFormDetails);
    setSelectedGenericExpences(fieldValue);
    onReduxUpdate(tempFormDetails);
  };

  const ProvideExpences = [
    { id: 'aggregate', title: 'Aggregate' },
    { id: 'itemized', title: 'Itemized' },
  ];

  const transformValue = (val) => {
    let fieldValue = val;
    if (val !== null && val !== undefined && val !== '') {
      if (val.toString().includes('%')) {
        const percentFieldValue = val.split('%')[0];
        fieldValue =
          percentFieldValue !== '' && !isNaN(percentFieldValue)
            ? parseFloat(percentFieldValue) / 100
            : 0;
      }
      fieldValue = parseFloat(parseFloat(fieldValue) * 100).toFixed(2);
      return `${fieldValue}%`;
    }
    return `0.00%`;
  };

  useEffect(() => {
    const {
      basicsFirst = {},
      basicsSecond = {},
      taxFilingStatus = '',
    } = infoCore || {};
    let retired = false;
    let membersCount = 0;
    if (
      basicsFirst &&
      Object.keys(basicsFirst).length > 0 &&
      basicsFirst.age > 0 &&
      basicsFirst.name
    ) {
      membersCount += 1;
      if (
        (basicsSecond &&
          Object.keys(basicsSecond).length > 0 &&
          basicsSecond.age > 0 &&
          basicsSecond.name) ||
        taxFilingStatus.toLowerCase() === 'mfj'
      ) {
        membersCount += 1;
      }
    }
    if (
      Object.keys(basicsFirst).length > 0 &&
      Object.keys(basicsSecond).length > 0
    ) {
      if (
        membersCount >= 1 &&
        basicsFirst['age'] &&
        basicsFirst['retirementAge'] &&
        basicsFirst['age'] >= basicsFirst['retirementAge']
      ) {
        retired = true;
        if (membersCount > 1) {
          if (
            basicsSecond['age'] &&
            basicsSecond['retirementAge'] &&
            basicsSecond['age'] >= basicsSecond['retirementAge']
          ) {
            retired = true;
          } else {
            retired = false;
          }
        }
      }
    } else if (
      membersCount === 1 &&
      (Object.keys(basicsFirst).length === 0 ||
        Object.keys(basicsSecond).length === 0)
    ) {
      if (
        basicsFirst['age'] &&
        basicsFirst['retirementAge'] &&
        basicsFirst['age'] > 0 &&
        basicsFirst['age'] >= basicsFirst['retirementAge']
      ) {
        retired = true;
      } else if (
        basicsSecond['age'] &&
        basicsSecond['retirementAge'] &&
        basicsSecond['age'] > 0 &&
        basicsSecond['age'] >= basicsSecond['retirementAge']
      ) {
        retired = true;
      }
    }
    setRetired(retired);
  }, [infoCore]);

  // setFormDetailsFromStepName = () => {

  // };
  useEffect(() => {
    setSelectedGenericExpences(storeValues.lifestyle !== 'custom');
  }, [storeValues.lifestyle]);

  const getFormDetailsValue = (stepName) => {
    let value = formDetails.amounts[stepName.value];
    const numFormat = Intl.NumberFormat('en-US');
    let newNumformat = numFormat.format(0);
    let currencyFieldValue = '';
    if (value !== null && value !== undefined && value !== '') {
      if (value.toString().includes('$')) {
        currencyFieldValue = value.split('$')[1];
        currencyFieldValue = currencyFieldValue.replace(/,/g, '');
        if (
          currencyFieldValue !== '' &&
          !isNaN(currencyFieldValue) &&
          Number(currencyFieldValue) >= 0
        ) {
          newNumformat = numFormat.format(currencyFieldValue);
        }
      } else if (!isNaN(value) && Number(value) >= 0) {
        newNumformat = numFormat.format(value);
      }
      value = `$${newNumformat}`;
    } else {
      value = `$${newNumformat}`;
    }
    return value;
  };

  const onReduxUpdate = useCallback(
    (newFormDetails) => {
      const transformedFormatFormDetails = Object.keys(newFormDetails).reduce(
        (acc, curr) => {
          if (['amounts', 'retFactor', 'horFactor', 'disc'].includes(curr)) {
            const tempObj = { ...newFormDetails[curr] };
            const transformedAmts = Object.keys(tempObj).reduce((a, b) => {
              return {
                ...a,
                [b]: convertBackToApiType(tempObj[b]),
              };
            }, {});
            return { ...acc, [curr]: { ...transformedAmts } };
          }
          return {
            ...acc,
            [curr]: convertBackToApiType(newFormDetails[curr]),
          };
        },
        {}
      );
      updateExpenseDetailsInRedux(transformedFormatFormDetails);
    },
    [updateExpenseDetailsInRedux]
  );

  const handleBlurOnNumbers = useCallback(
    (event, field, from, type) => {
      let value = from ? event : (event && event.target.value) || '';
      const { amounts } = formDetails;
      let newFormDetails = { ...formDetails };
      const fieldValue = formatFieldValueByType(value, type);
      if (!from) {
        setIsBlurCalled(true);
      } else {
        setIsBlurCalled(false);
      }
      if (!from) {
        newFormDetails = {
          ...newFormDetails,
          amounts: {
            ...amounts,
            [field]: fieldValue,
          },
        };
        setFormDetails(newFormDetails);
        onReduxUpdate(newFormDetails);
      } else {
        return fieldValue;
      }
    },
    [formDetails, onReduxUpdate]
  );

  // const transformedAmountsValues = useCallback(
  //   (amounts) => {
  //     const transformedAmounts = Object.keys(amounts).reduce((acc, curr) => {
  //       const fieldValue = handleBlurOnNumbers(
  //         amounts[curr],
  //         curr,
  //         'onLoad',
  //         'currency'
  //       );
  //       return {
  //         ...acc,
  //         [curr]: fieldValue,
  //       };
  //     }, {});
  //     return transformedAmounts;
  //   },
  //   [handleBlurOnNumbers]
  // );

  useEffect(() => {
    const { amounts } = storeValues;
    const transformedAmountsValues = (amounts) => {
      const transformedAmounts = Object.keys(amounts).reduce((acc, curr) => {
        const fieldValue = handleBlurOnNumbers(
          amounts[curr],
          curr,
          'onLoad',
          'currency'
        );
        return {
          ...acc,
          [curr]: fieldValue,
        };
      }, {});
      return transformedAmounts;
    };
    const transformedAmts = transformedAmountsValues(amounts);
    const tempFormDetails = {
      discPercentOfAgg:
        (storeValues.discPercentOfAgg &&
          transformValue(storeValues.discPercentOfAgg)) ||
        '0%',

      // useAggregateCost:
      //   (storeValues.useAggNonHousingExpense &&
      //     storeValues.useAggNonHousingExpense) ||
      //   false,
      lifestyle: (storeValues.lifestyle && storeValues.lifestyle) || '',
      useDiscretionaryFlag:
        (storeValues.useDiscretionaryFlag &&
          storeValues.useDiscretionaryFlag) ||
        false,
      rentalPercent: retired
        ? '100%'
        : (storeValues.retFactor[stepName.value] &&
            transformValue(storeValues.retFactor[stepName.value])) ||
          '0%',
      discRetFactor1:
        (retired
          ? '100%'
          : storeValues.discRetFactor1 &&
            transformValue(storeValues.discRetFactor1)) || '0%',
      amounts: {
        ...transformedAmts,
      },
    };
    const localExpenseTab = storeValues.useAggNonHousingExpense
      ? 'aggregate'
      : 'itemized';
    setExpenseTab(localExpenseTab);
    setFormDetails((old) => ({
      ...old,
      ...tempFormDetails,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeValues, retired, stepName.value]);

  const updateStepName = (stepNo) => {
    const { useAggNonHousingExpense: useAggregateCost } = formDetails;
    let tempStepName = stepName;
    switch (stepNo) {
      case 1:
        tempStepName = useAggregateCost
          ? {
              label: 'Aggregate Non Housing',
              value: 'aggNonHousing',
            }
          : { label: 'Groceries & Staples', value: 'food' };
        break;
      case 2:
        tempStepName = useAggregateCost
          ? { label: 'Rental', value: 'rental' }
          : {
              label: 'Phones & Entertainment',
              value: 'phonesAndEnt',
            };
        break;
      case 3:
        tempStepName = { label: 'Travel', value: 'travel' };
        break;
      case 4:
        tempStepName = { label: 'Restaurants', value: 'restaurants' };
        break;
      case 5:
        tempStepName = {
          label: 'Personal / Pet / Other care',
          value: 'insurancesNonProp',
        };
        break;
      case 6:
        tempStepName = {
          label: 'Health & Fitness (excluding what is included in Insurances)',
          value: 'healthAndFitness',
        };
        break;
      case 7:
        tempStepName = {
          label: 'Utilities',
          value: 'utils',
        };
        break;
      case 8:
        tempStepName = {
          label:
            'Transportation (excluding what is included in Vehicles and Insurances)',
          value: 'cars',
        };
        break;
      case 9:
        tempStepName = {
          label: 'Shopping & Miscellaneous',
          value: 'shoppingAndMisc',
        };
        break;
      default:
        tempStepName = { label: 'Rental', value: 'rental' };
        break;
    }
    return tempStepName;
  };

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  const convertBackToApiType = (value) => {
    let convertedValue =
      typeof value !== 'boolean'
        ? value.toString().includes('%')
          ? value.split('%')[0]
          : value.toString().includes('$')
          ? parseFloat(value.split('$')[1].replace(/,/g, ''))
          : value !== ''
          ? value
          : ''
        : value;
    const newFieldValue =
      typeof value !== 'boolean' && value.toString().includes('%')
        ? convertedValue !== '' &&
          !isNaN(convertedValue) &&
          parseFloat(convertedValue) / 100
        : convertedValue;
    return newFieldValue;
  };

  const handleFormFieldChange = (e, updateinAmounts = false, from, type) => {
    let fieldValue = e.target.value;
    let newFormDetails = {
      ...formDetails,
    };
    fieldValue = formatFieldValueByType(fieldValue, type);
    if (updateinAmounts) {
      newFormDetails = {
        ...formDetails,
        amounts: {
          ...formDetails.amounts,
          [from]: fieldValue,
        },
      };
    } else {
      newFormDetails = {
        ...formDetails,
        [from]: fieldValue,
      };
    }
    setIsBlurCalled(false);
    setFormDetails(newFormDetails);
  };

  const handleBlur = (event, field) => {
    let { value } = event && event.target;
    let percentFieldValue = '';
    let newFormDetails = { ...formDetails };
    if (value !== null && value !== undefined && value !== '') {
      if (value.toString().includes('%')) {
        percentFieldValue = value.split('%')[0];
        if (
          percentFieldValue !== '' &&
          !isNaN(percentFieldValue) &&
          Number(percentFieldValue) >= 0
        ) {
          percentFieldValue = parseFloat(percentFieldValue).toFixed(2);
          value = `${percentFieldValue}%`;
        } else {
          value = `${0}%`;
        }
      } else if (!isNaN(value) && Number(value) >= 0) {
        percentFieldValue = parseFloat(value).toFixed(2);
        value = `${percentFieldValue}%`;
      } else {
        value = `${0}%`;
      }
    } else {
      value = `${0}%`;
    }
    newFormDetails = {
      ...newFormDetails,
      [field]: value,
    };
    setIsBlurCalled(true);
    setFormDetails(newFormDetails);
    onReduxUpdate(newFormDetails);
  };

  const formatFieldValueByType = (value, type) => {
    let fieldValue = value;
    if (type === 'percent') {
      if (fieldValue.toString().includes('%')) {
        let percentFieldValue = fieldValue.split('%')[0];
        if (percentFieldValue !== '' && !isNaN(percentFieldValue)) {
          fieldValue = percentFieldValue;
        }
      }
    }
    if (type === 'currency') {
      const numFormat = Intl.NumberFormat('en-US');
      let newNumformat = numFormat.format(0);
      let currencyFieldValue = '';
      if (
        fieldValue !== null &&
        fieldValue !== undefined &&
        fieldValue !== ''
      ) {
        if (fieldValue.toString().includes('$')) {
          currencyFieldValue = fieldValue.split('$')[1];
          currencyFieldValue = currencyFieldValue.replace(/,/g, '');
          if (
            currencyFieldValue !== '' &&
            !isNaN(currencyFieldValue) &&
            Number(currencyFieldValue) >= 0
          ) {
            newNumformat = numFormat.format(currencyFieldValue);
          }
        } else if (!isNaN(fieldValue) && Number(fieldValue) >= 0) {
          newNumformat = numFormat.format(fieldValue);
        }
        fieldValue = `$${newNumformat}`;
      } else {
        fieldValue = `$${newNumformat}`;
      }
    }
    if (type === 'number') {
      if (
        fieldValue !== '' &&
        fieldValue !== null &&
        fieldValue !== undefined
      ) {
        if (!isNaN(fieldValue) && Number(fieldValue) >= 0) {
          fieldValue = Number(fieldValue);
        } else {
          fieldValue = 0;
        }
      } else {
        fieldValue = 0;
      }
    }
    return fieldValue;
  };

  const updateExpenseTab = (event) => {
    const tab = event.target.value;
    if (tab) {
      const selectedTab = tab.toLowerCase() || '';
      let isAggregate = false;
      let { useDiscretionaryFlag, lifestyle } = formDetails;
      switch (selectedTab) {
        // case 'lifestyle':
        //   isAggregate = formDetails.useAggNonHousingExpense; // dont change this field, when lifestyle is selected
        //   lifestyle = lifestyleTypes[0].value;
        //   break;
        case 'aggregate':
          isAggregate = true;
          lifestyle = 'custom';
          //useDiscretionaryFlag = true;
          break;
        case 'itemized':
          isAggregate = false;
          lifestyle = 'custom';
          break;
        default:
          break;
      }
      const tempFormDetails = {
        ...formDetails,
        useAggNonHousingExpense: isAggregate,
        lifestyle,
        // useDiscretionaryFlag,
      };
      setExpenseTab(selectedTab);
      setFormDetails(tempFormDetails);
      onReduxUpdate(tempFormDetails);
    }
  };

  const onLifestyleChange = (event) => {
    const { value: lifestyle } = event.target;
    const tempFormDetails = {
      ...formDetails,
      lifestyle,
    };
    setFormDetails(tempFormDetails);
    onReduxUpdate(tempFormDetails);
  };

  const renderAggregateFields = () => {
    const { amounts } = formDetails;
    const { rental = '', aggNonHousing = '' } = amounts;
    return (
      <Grid container className="expenses_grid_outer">
        <Grid item md={8} sm={7} xs={12} className="">
          <p className="mb-0">
            Annual Rental expense (If you own a primary home, enter a potential
            rental expense if you were to rent today)
          </p>
        </Grid>

        <Grid item md={4} sm={5} xs={12}>
          <input
            name="Annualized housing expense"
            placeholder="Expenses: Non-housing cost"
            className=""
            value={rental}
            type="text"
            min={0}
            onChange={(event) =>
              handleFormFieldChange(event, true, 'rental', 'currency')
            }
            onBlur={(event) =>
              handleBlurOnNumbers(event, 'rental', undefined, 'currency')
            }
          />
        </Grid>

        {/* new */}

        <Grid
          item
          sm={8}
          xs={12}
          gap={15}
          className=""
          style={{
            marginTop: '10px',
            paddingRight: '15px',
            // fontSize: '12px',
          }}
        >
          Aggregate non-housing annual expenses
        </Grid>
        <Grid item sm={4} xs={12}>
          <input
            name="Aggregate non-housing annual expenses"
            className=""
            value={aggNonHousing}
            type={'text'}
            onChange={(event) =>
              handleFormFieldChange(event, true, 'aggNonHousing', 'currency')
            }
            onBlur={(event) =>
              handleBlurOnNumbers(event, 'aggNonHousing', undefined, 'currency')
            }
          />
        </Grid>
        <Grid
          item
          sm={8}
          xs={12}
          gap={15}
          className=""
          style={{
            paddingRight: '15px',
            marginTop: '10px',
            // fontSize: '12px',
          }}
        >
          What percent of this could be discretionary?
        </Grid>

        <Grid item sm={4} xs={12}>
          <input
            name="Discretionary percentage"
            className=""
            value={formDetails.discPercentOfAgg}
            onBlur={(event) => handleBlur(event, 'discPercentOfAgg')}
            type={'text'}
            onChange={(event) =>
              handleFormFieldChange(event, false, 'discPercentOfAgg', 'percent')
            }
          />
        </Grid>

        <Grid item xs={12}>
          <hr className="mb-0" />
        </Grid>

        {/* {!retired && (
          <>
            <Grid item xs={12} style={{ fontSize: '14px', fontWeight: '600' }}>
              <p className="mb-0">Adjust Expenses for Retirement</p>
            </Grid>

            <Grid
              item
              md={8}
              xs={12}
              className=""
              style={{
                marginTop: '10px',
                paddingRight: '15px',
                // fontSize: '12px',
              }}
            >
              What percent of pre-retirement rental expenses do you expect
              during phase-1 of retirement? (If you were to liquidate your
              primary home)
            </Grid>
            <Grid item md={4} xs={12}>
              <input
                name="Aggregate non-housing annual expenses"
                className=""
                disabled={retired}
                value={formDetails.rentalPercent}
                onBlur={(event) => handleBlur(event, 'rentalPercent')}
                type={'text'}
                onChange={(event) =>
                  handleFormFieldChange(
                    event,
                    false,
                    'rentalPercent',
                    'percent'
                  )
                }
              />
            </Grid>
            <Grid
              item
              sm={8}
              xs={12}
              className=""
              style={{
                marginTop: '10px',
                paddingRight: '15px',
                // fontSize: '12px',
              }}
            >
              What percent of pre-retirement non-housing discretionary expenses
              do you expect during retirement?
            </Grid>
            <Grid item sm={4} xs={12}>
              <input
                name="Discretionary percentage"
                className=""
                disabled={retired}
                value={formDetails.discRetFactor1}
                type={'text'}
                onBlur={(event) => handleBlur(event, 'discRetFactor1')}
                onChange={(event) =>
                  handleFormFieldChange(
                    event,
                    false,
                    'discRetFactor1',
                    'percent'
                  )
                }
              />
            </Grid>
          </>
        )} */}
      </Grid>
    );
  };

  const renderItemizedFields = () => {
    const { amounts } = formDetails;
    const { rental = '' } = amounts;
    return (
      <Grid container className="expenses_grid_outer">
        <Grid
          item
          md={8}
          sm={7}
          xs={12}
          className=""
          style={{ paddingRight: '15px' }}
        >
          <p className="mb-0">
            Annual Rental expense (If you own a primary home, enter a potential
            rental expense if you were to rent today)
          </p>
        </Grid>

        <Grid item md={4} sm={5} xs={12}>
          <input
            name="Annualized housing expense"
            placeholder="Expenses: Non-housing cost"
            className=""
            value={rental}
            type="text"
            min={0}
            onChange={(event) =>
              handleFormFieldChange(event, true, 'rental', 'currency')
            }
            onBlur={(event) =>
              handleBlurOnNumbers(event, 'rental', undefined, 'currency')
            }
          />
        </Grid>
        <Grid container className="expenses_grid_outer">
          <>
            {[...Array(9)].map((item, index) => {
              const currstepName = updateStepName(index + 1);
              const fieldValue = getFormDetailsValue(currstepName);
              return (
                <>
                  <Grid item md={8} sm={7} xs={12} gap={15} className="">
                    <p className="mb-0">{currstepName.label} (annually)</p>
                    {/* Transportation expenses, excluding what is included in Vehicles and Insurances (annually) */}
                  </Grid>
                  <Grid item md={4} sm={5} xs={12}>
                    <input
                      name={currstepName.value}
                      className=""
                      value={fieldValue}
                      type={'text'}
                      onChange={(event) =>
                        handleFormFieldChange(
                          event,
                          true,
                          currstepName.value,
                          'currency'
                        )
                      }
                      onBlur={(event) =>
                        handleBlurOnNumbers(
                          event,
                          currstepName.value,
                          undefined,
                          'currency'
                        )
                      }
                    />
                  </Grid>
                </>
              );
            })}
          </>
        </Grid>
        {/* {!retired && (
          <Grid container className="expenses_grid_outer">
            <Grid item xs={12}>
              <hr className="mb-0" />
            </Grid>
            <Grid item xs={12} style={{ fontSize: '14px', fontWeight: '600' }}>
              <p className="mb-0">Adjust Expenses for Retirement</p>
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
              className=""
              style={{
                marginRight: '15px',
                marginTop: '10px',
                // fontSize: '12px',
              }}
            >
              <p className="mb-0">
                What percent of pre-retirement rental expenses do you expect
                during phase-1 of retirement? (If you were to liquidate your
                primary home)
              </p>
            </Grid>
            <Grid item md={4} xs={12}>
              <input
                name="Aggregate non-housing annual expenses"
                className=""
                disabled={retired}
                value={formDetails.rentalPercent}
                type={'text'}
                onChange={(event) =>
                  handleFormFieldChange(
                    event,
                    false,
                    'rentalPercent',
                    'percent'
                  )
                }
                onBlur={(event) => handleBlur(event, 'rentalPercent')}
              />
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
              className=""
              style={{
                marginRight: '15px',
                marginTop: '10px',
                // fontSize: '12px',
              }}
            >
              <p className="mb-0">
                What percent of pre-retirement non-housing discretionary
                expenses do you expect during retirement?
              </p>
            </Grid>
            <Grid item md={4} xs={12}>
              <input
                name="Discretionary percentage"
                className="planner-input"
                disabled={retired}
                value={formDetails.discRetFactor1}
                type={'text'}
                onChange={(event) =>
                  handleFormFieldChange(
                    event,
                    false,
                    'discRetFactor1',
                    'percent'
                  )
                }
                onBlur={(event) => handleBlur(event, 'discRetFactor1')}
              />
            </Grid>
          </Grid>
        )} */}
      </Grid>
    );
  };

  const renderLivingExpenseFields = () => {
    return (
      <>
        <Grid container className="expenses_warning">
          <Grid>
            <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
              <Alert severity="info">
                <p>
                  These expenses are in addition to what is captured in Kids /
                  Vehicles / Insurances / Vacations / Debt / Assets
                </p>
              </Alert>
            </Stack>
          </Grid>
        </Grid>

        <div className="expenses_grid_main_outer">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-8 col-sm-8">
              <p className="mb-0">
                Would you like to use lifestyle based generic expenses
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <Controls.RadioGroup
                name="member-radio-buttons"
                value={selectedGenericExpences}
                rowType={true}
                onChange={handleGenericExpencesChange}
                items={GenericExpences}
              ></Controls.RadioGroup>
            </div>
          </div>
        </div>

        {selectedGenericExpences && (
          <div className="GenericExpences_yes Select_account_main_outer">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-9 col-sm-12">
                <div className='controls-select-main-outer'>
                <h5 className="account_dropdown_label">Select lifestyle</h5>
                  <Controls.Select
                    name="Lifestyle"
                    placeholder="Choose Lifestyle"
                    value={
                      formDetails.lifestyle === 'custom'
                        ? 'prudent'
                        : formDetails.lifestyle
                    }
                    options={lifestyleTypes.map((p) => {
                      return { label: p.label, value: p.value };
                    })}
                    onChange={onLifestyleChange}
                  />
              </div>
                
              </div>
            </div>
          </div>
        )}

        {!selectedGenericExpences && (
          <>
            <div className="GenericExpences_no">
              <div className="row align-items-center">
                <div className="col-lg-8 col-md-6 col-sm-4">
                  <p className="mb-0">How would you like to provide expenses</p>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-8">
                  <Controls.RadioGroup
                    name="member-radio-buttons"
                    value={expenseTab}
                    rowType={true}
                    onClick={updateExpenseTab}
                    items={ProvideExpences}
                  ></Controls.RadioGroup>
                </div>
              </div>
            </div>
            <div className="expenses_grid_main_outer">
              {expenseTab === 'aggregate' && renderAggregateFields()}
              {expenseTab === 'itemized' && renderItemizedFields()}
            </div>
          </>
        )}

        {/*         
        <div className="expenses_grid_main_outer">
          {expenseTab === 'lifestyle' && (
            <Grid container className="expenses_grid_outer">
              <Grid item sm={6} xs={12}>
                <Controls.Select
                  name="Lifestyle"
                  placeholder="Choose Lifestyle"
                  value={formDetails.lifestyle}
                  options={lifestyleTypes.map((p) => {
                    return { label: p.label, value: p.value };
                  })}
                  onChange={onLifestyleChange}
                />
              </Grid>
            </Grid>
          )}
          {expenseTab === 'aggregate' && renderAggregateFields()}
          {expenseTab === 'itemized' && renderItemizedFields()}
        </div> */}
      </>
    );
  };

  return <>{renderLivingExpenseFields()}</>;
}
