import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import {
  renderInputRow,
  renderSelectOption,
  transformRothChoicesBackToUIType,
  transformEmergencyFundsBackToUIType,
} from '../../helpers/BuildHelperComponentV3';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const BuildIncomeDividendsComponent = (props) => {
  const {
    state,
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    setLastStepInParent,
    setValidationError,
  } = props;

  const booleanOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const {
    moduleEstate: { rothChoices = {} } = {},
    moduleFamily: { infoCore = {} } = {},
  } = localPlanDetailsByPlanId;

  const [rothChoicesDetails, setRothChoicesDetails] = useState(rothChoices);
  const { moduleSettings: { emergencyFunds = {} } = {} } =
    localPlanDetailsByPlanId;
  const [emergencyFundsDetails, setEmergencyFundsDetails] =
    useState(emergencyFunds);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getIsFieldsValidState = Object.keys(emergencyFunds).reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr]: true,
      };
    },
    {}
  );

  const [isFormFieldsValid, setIsFormFieldsValid] = useState(
    getIsFieldsValidState
  );

  useEffect(() => {
    if (emergencyFunds && Object.keys(emergencyFunds).length > 0) {
      let tempEmergencyFunds =
        transformEmergencyFundsBackToUIType(emergencyFunds);
      setEmergencyFundsDetails(tempEmergencyFunds);
    }
  }, [localPlanDetailsByPlanId, emergencyFunds]);

  const validateYoungerAgeAtDivWD = React.useCallback(() => {
    const { youngerAgeAtDivWD } = emergencyFundsDetails;
    return Number.isInteger(youngerAgeAtDivWD) && youngerAgeAtDivWD >= 62;
  }, [emergencyFundsDetails]);

  useEffect(() => {
    const validationMethods = {
      validateYoungerAgeAtDivWD,
    };
    let newIsformFieldsValid = { ...isFormFieldsValid };
    const isAllFieldsValid = Object.keys(emergencyFundsDetails).map((key) => {
      const validationFunction = `validate${capitalizeFirstLetter(key)}`;
      let isValid = true;
      if (validationMethods[validationFunction]) {
        isValid = validationMethods[validationFunction](
          emergencyFundsDetails[key]
        );
        newIsformFieldsValid[key] = isValid;
      }
      return isValid;
    });
    setIsFormFieldsValid(newIsformFieldsValid);
    setValidationError(isAllFieldsValid.every(Boolean));

    // setIsFormValid(isAllFieldsValid.every(Boolean));
  }, [emergencyFundsDetails, validateYoungerAgeAtDivWD]);

  const withDrawRothFirst = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  useEffect(() => {
    if (rothChoices && Object.keys(rothChoices).length > 0) {
      let tempRothChoices = transformRothChoicesBackToUIType(rothChoices);
      setRothChoicesDetails(tempRothChoices);
    }
  }, [localPlanDetailsByPlanId, rothChoices]);

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  return (
    <>
      <div className="insured_main_outer">
        <div className="row align-items-center">
          <div className="col-lg-9 col-sm-9 col-8 insured_content_outer">
            <p>
              <strong>Family Dividend Income</strong>
            </p>
          </div>
        </div>
        <div className="row align-items-center">
          {renderInputRow(
            'text',
            'tlYoungerAgeAtDivWD',
            'youngerAgeAtDivWD',
            'From what age would you like to withdraw dividends?',
            'number',
            emergencyFundsDetails,
            'emergencyFunds',
            localPlanDetailsByPlanId,
            setLocalPlanDetailsByPlanId,
            'moduleSettings',
            false,
            setEmergencyFundsDetails,
            isFormFieldsValid['youngerAgeAtDivWD'],
            'Minimum Age should be 62.'
          )}

          {/* <div className="col-lg-9 col-sm-9 col-8 insured_content_outer">
            <p>From what age would you like to withdraw dividends?</p>
          </div>
          <div className="col-lg-3 col-sm-3 col-4 insured_radio_group">
            <input type="text" id="tlName" name="tlName" required="" className="custom-input" placeholder="" value="" data-listener-added_7ac6b3a3="true"></input>
          </div> */}
        </div>

        <div className="row align-items-center">
          <div className="col-12">
            <Alert severity="info">
              Actual dividend income will depend upon your investment accounts
              that you may input in Investments/Markets, and market expectations
              that the Advisor may input as part of their profile.
            </Alert>
          </div>
        </div>

        <div className="tab_main_nav">
          <div className="tab_left_nav">
            <button>
              <ArrowBackIcon /> Previous
            </button>
          </div>
          <div className="tab_right_nav">
            <button>
              Next <ArrowForwardIcon />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuildIncomeDividendsComponent;
