import React, { useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  renderFormInputRow,
  renderFooterSubmitCancelButtons,
  renderFormInputRowName,
  renderSelectAccountDropdown,
  renderFormDateField,
  DeleteConfirmation,
  renderFormSelectOption,
} from '../../helpers/BuildHelperComponentV3';
import { useDeleteConfirmation } from '../../helpers/DeleteConfirmationUtils'; // Import the hook
import { ExpenseVehicleAccordionNames } from '../../constants/BuildTabComponentV3';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const [VEHICLES, AUTOINS] = ExpenseVehicleAccordionNames;

export const BuildExpensesVehiclesComponent = (props) => {
  const {
    state,
    concatContent,
    allMenuItems,
    autoOptions,
    setLastStepInParent,
    isFormValid,
    formErrors,
    handleAccordionChange,
    expandedPanel,
    renderBuildVehiclesComponent,
    renderBuildVehicleInsuranceComponent,
  } = props;

  const moduleObject = {
    moduleTab: 'moduleFamily',
    mainTab: 'infoVehicles',
    subTab: 'vehicles',
    countVal: 'numVehicles',
  };

  const insuranceModuleObj = {
    moduleTab: 'moduleInsurance',
    mainTab: 'auto',
    subTab: 'autoIns',
    countVal: 'numVehicles',
  };
  const { formDetails, isEditable, isAddNew } = state;
  const finalExistingItems = concatContent(
    moduleObject.moduleTab,
    moduleObject.mainTab,
    moduleObject.subTab
  );

  const defaultName =
    (formDetails &&
      formDetails[moduleObject.subTab] &&
      formDetails[moduleObject.subTab].name) ||
    '';

  // Use the delete confirmation hook
  const {
    isDeleteConfirmationOpen,
    openDeleteConfirmation,
    nameValidationError,
    setNameValidationError,
    closeDeleteConfirmation,
    handleDeleteConfirmation,
    nameToDelete,
  } = useDeleteConfirmation();

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);
  return (
    <>
      <div className="faq-container-new">{renderBuildVehiclesComponent()}</div>

      {/* Delete Confirmation Dialog */}
    </>
  );
};

export default BuildExpensesVehiclesComponent;
