import React, { useState, useEffect } from 'react';
import Controls from './controls/Controls';
import Box from '@mui/material/Box';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { healthInsuranceCols } from '../constants/dashboard';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material'; // Import dialog components
import '../css/InsuranceContent.css';
import edit_pencil from '../images/edit_pencil.svg';
import delete_icon from '../images/delete_icon.svg';
import pdf_page_plus_icon from '../images/pdf_page_plus_icon.svg';

export const HealthInsuranceComponent = (props) => {
  const {
    healthInsuranceProducts,
    state,
    concatInsurances,
    transformValue,
    handleFormFieldChangeInsurance,
    onAccountChange,
    setSelectionModel,
    rowClick,
    handleEditInsurance,
    handleAddToPortfolio,
    handleDeleteInsurance,
    onAutoChange,
    handleCancelInsurance,
    setLastStepInParent,
    handleHealthInsCheckboxFieldChange,
  } = props;

  const {
    memberOptions,
    memberAutoRadioOptions,
    formDetails,
    selectionModel,
    isEditable,
    isAddNew,
    isFormValid,
    formErrors,
  } = state;

  // State variable to hold the default title
  const [wlTitle, setwlTitle] = useState('');
  const finalExistingInsurances = concatInsurances('healthIns');
  const defaultName =
    (formDetails &&
      formDetails['healthIns'] &&
      formDetails['healthIns'].name) ||
    '';

  const [nameValidationError, setNameValidationError] = useState('');
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [nameToDelete, setNameToDelete] = useState('');

  const openDeleteConfirmation = (name) => {
    setIsDeleteConfirmationOpen(true);
    setNameToDelete(name);
  };

  // Function to close the confirmation dialog
  const closeDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false);
  };

  // Function to handle item deletion after confirmation
  const handleDeleteConfirmation = () => {
    closeDeleteConfirmation();
    // Perform the delete action here
    handleDeleteInsurance('healthIns', 'health', 'numHealthIns', nameToDelete);
  };

  // Handle submission and validation
  const handleSubmit = () => {
    // if (validateNameField()) {
    // Name field is valid, proceed with submission
    handleAddToPortfolio('healthIns', 'health');
    //}
  };

  useEffect(() => {
    if (isEditable || isAddNew) {
      setLastStepInParent(false);
    } else {
      setLastStepInParent(true);
    }
  }, [isEditable, isAddNew, setLastStepInParent]);

  useEffect(() => {
    // Set the default value of wlTitle on page load
    const defaultSelectedAccountInfo = finalExistingInsurances.find(
      (account) => account.id === defaultName
    );

    if (defaultSelectedAccountInfo) {
      setwlTitle(defaultSelectedAccountInfo.title);
    }
  }, [defaultName, finalExistingInsurances]);

  const renderSelectAuto = () => (
    <div className="row">
      <div
        className="col-lg-8 insured_content_outer"
        style={{ marginBottom: 10 }}
      >
        <p className="radio-group-label">Insured</p>
      </div>
      <div className="col-lg-3">
        <Controls.Select
          className="js-example-basic-single"
          name="Account"
          id="Account"
          value={
            formDetails &&
            formDetails['healthIns'] &&
            formDetails['healthIns'].insured
          }
          onChange={(e) =>
            onAutoChange(e, memberAutoRadioOptions, 'healthIns', 'insured')
          }
          disabled={state.isEditable}
          options={memberAutoRadioOptions.map((c) => {
            return {
              value: c.id,
              label: c.title,
              key: c.id,
            };
          })}
        />
      </div>
    </div>
  );

  // Define the method to render the select account dropdown
  const renderSelectAccountDropdown = () => (
    <div className="row align-items-center">
      <div className="col-lg-3 col-md-4 col-6">
        <Controls.Select
          className="js-example-basic-single"
          name="Accountwl"
          id="Accountwl"
          value={defaultName}
          onChange={(e) =>
            onAccountChange(e, finalExistingInsurances, 'healthIns')
          }
          style={{ display: !state.isEditable ? 'block' : 'none' }}
          options={finalExistingInsurances.map((c) => {
            return {
              value: c.id,
              label: c.title,
            };
          })}
        />
      </div>
      <div
        className="col-lg-8 col-md-8 col-5 button-group"
        style={{
          display: !isEditable ? 'block' : 'none',
          width: 'max-content',
        }}
      >
        {finalExistingInsurances && finalExistingInsurances.length > 0 && (
          <button
            className="action-button"
            onClick={(e) => {
              setNameValidationError('');
              handleEditInsurance(e, state.solutionsSubTab);
            }}
          >
            <img src={edit_pencil} alt="" title="" />
          </button>
        )}
        {finalExistingInsurances && finalExistingInsurances.length > 0 && (
          <button
            className="action-button"
            onClick={(e) => openDeleteConfirmation(defaultName)}
          >
            <img src={delete_icon} alt="" title="" />
          </button>
        )}
        <button
          className="action-button"
          onClick={(event) => {
            setNameValidationError('');
            handleEditInsurance(event, state.solutionsSubTab, true);
            handleFormFieldChangeInsurance(
              event,
              'insured',
              'input',
              'healthIns',
              true
            );
          }}
        >
          <img src={pdf_page_plus_icon} alt="" title="" />
        </button>
      </div>
    </div>
  );

  const renderChooseProduct = () => (
    <div
      className="choose_a_product_main_outer"
      style={{ display: isEditable ? 'block' : 'none' }}
    >
      <h5>Choose a product from below list:</h5>
      <div className="choose_a_product_table">
        <Box
          sx={{
            height: 150,
            width: '100%',
            backgroundColor: '#eff9f6',
            border: 'none',
          }}
        >
          <DataGrid
            rows={
              healthInsuranceProducts && healthInsuranceProducts.length > 0
                ? healthInsuranceProducts.map((m) => ({
                    ...m,
                    premium: m.premium && transformValue(m.premium, 'currency'),
                    coverage:
                      m.coverage && transformValue(m.coverage, 'currency'),
                  }))
                : []
            }
            autoHeight
            rowHeight={25}
            headerHeight={40}
            columns={healthInsuranceCols}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel, 'healthIns');
            }}
            selectionModel={
              selectionModel['healthIns'] ? selectionModel['healthIns'] : []
            }
            pageSize={10}
            onRowClick={(params, event, details) =>
              rowClick(params, event, details, 'healthIns')
            }
            rowsPerPageOptions={[10]}
            className="data-grid"
            disableColumnMenu={true}
            hideFooter={true}
          />
        </Box>
      </div>
    </div>
  );

  const renderInputRow = (
    type,
    idName,
    name,
    label,
    valueType,
    isDisabled = false,
    isMandatory = false,
    formErrors = []
  ) => {
    const isFieldInValid = formErrors.filter(
      (v) => v.apiFieldName.toLowerCase() === name.toLowerCase() && !v.isValid
    )[0];
    return (
      <div className="row align-items-center">
        <div className="col-lg-8 insured_content_outer">
          <p className={`${isMandatory ? 'required' : ''}`}>{label}</p>
        </div>
        <div className="col-lg-3 insured_radio_group">
          <input
            type={type}
            id={idName}
            name={idName}
            value={transformValue(formDetails['healthIns'][name], valueType)}
            className={isDisabled ? 'disabled-input' : 'custom-input'} // Apply the appropriate class
            disabled={isDisabled}
            onChange={(event) =>
              handleFormFieldChangeInsurance(event, name, 'input', 'healthIns')
            }
            placeholder=""
          />
        </div>
        {isFieldInValid && (
          <p className="error-text">{isFieldInValid.validationMessage}</p>
        )}
      </div>
    );
  };

  const renderCheckboxGroup = () => (
    <div className="col-lg-4 insured_radio_group Choose_Risk_Appetite_member">
      <FormGroup aria-label="members" row={true}>
        {memberOptions.map((cb, i) => (
          <FormControlLabel
            key={cb.id}
            control={
              <input
                type="checkbox"
                name={memberOptions[i]}
                onChange={(event) =>
                  handleHealthInsCheckboxFieldChange(
                    event,
                    i,
                    memberOptions[i],
                    'insured',
                    'radio',
                    'healthIns'
                  )
                }
                checked={cb.isSelected}
              />
            }
            label={cb.title}
          />
        ))}
      </FormGroup>

      {/* <Controls.RadioGroup
        name="housing-expense-radio-buttons"
        value={
          (formDetails &&
            formDetails['healthIns'] &&
            formDetails['healthIns'].insured) ||
          false
        }
        rowType={true}
        onChange={(event) =>
          handleFormFieldChangeInsurance(event, 'insured', 'radio', 'healthIns')
        }
        items={memberOptions}
        disabled={state.isEditable}
      ></Controls.RadioGroup> */}
    </div>
  );

  return (
    <>
      <div className="insured_main_outer">
        {!isAddNew ? (
          <div className="row">
            {renderInputRow(
              'text',
              'hlInsured',
              'insured',
              'Who is covered?',
              'text',
              true,
              true, //isMandatory
              formErrors
            )}
          </div>
        ) : (
          <div className="insured_main_outer">
            {isAddNew && (
              <div className="row">
                <div className="col-lg-8 insured_content_outer">
                  <p className={'radio-group-label required'}>
                    Who is covered?
                  </p>
                </div>
                {renderCheckboxGroup(
                  'text',
                  'hlInsured',
                  'insured',
                  'Who is covered?',
                  'textRadioButton',
                  !isEditable,
                  true, //isMandatory
                  formErrors
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {!isEditable && (
        <div className="Select_account_main_outer">
          {renderSelectAccountDropdown()}
        </div>
      )}
      <div className="insured_main_outer">
        {renderInputRow(
          'text',
          'hlName',
          'name',
          'Short name of insurance',
          'text',
          !isEditable,
          true, //isMandatory,
          formErrors
        )}
      </div>

      {renderChooseProduct()}

      {/* {(isAddNew || (finalExistingItems && finalExistingItems.length > 0)) && ( */}
      <div className="insured_main_outer">
        {renderInputRow(
          'text',
          'hlPremium',
          'premium',
          'Annual Premium',
          'currency',
          !isEditable
        )}
        {renderInputRow(
          'text',
          'hlPremiumGrowth',
          'premiumGrowth',
          'Annual growth of premium',
          'percentage',
          !isEditable
        )}
      </div>
      {isEditable ? (
        <div className="Simulate_button_group">
          <button
            className={
              !(isEditable || isAddNew)
                ? 'common-button secondary-button'
                : 'common-button'
            }
            onClick={(event) => {
              handleCancelInsurance('healthIns', 'insured');
            }}
            disabled={!(isEditable || isAddNew)}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className={
              !isFormValid || !(isEditable || isAddNew)
                ? 'common-button secondary-button'
                : 'common-button'
            }
            disabled={!isFormValid || !(isEditable || isAddNew)}
          >
            Update
          </button>
        </div>
      ) : (
        <></>
      )}
      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={closeDeleteConfirmation}
        className="delete_popup_outer"
      >
        <DialogTitle>
          Are you sure you want to delete "{nameToDelete}"?
        </DialogTitle>
        <DialogActions>
          <button onClick={closeDeleteConfirmation} className="common-button">
            Cancel
          </button>
          <button onClick={handleDeleteConfirmation} className="common-button">
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HealthInsuranceComponent;
