import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { planDetailsByPlanIdSelector } from '../../selectors/plannerDetailsSelector';
import Controls from '../controls/Controls';
import { vacationsFormFields } from '../../constants/Client';
import { PropertyNamesMap } from '../../constants/PropertyNames';
import {
  generateProps,
  percentageConversionToUI,
  formatValueByType,
  formatValuesForUI,
  getDateValue,
} from '../../helpers/SolutionsHelper';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const VacationsContentGoals = forwardRef((props, ref) => {
  const { commonProps = {} } = props;
  const {
    modalType,
    handleModalOpen,
    category,
    goal = {},
    setIsFormValid,
    formDetails,
  } = commonProps;
  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );
  const conditionalFields = ['vacationYear', 'name'];
  const [selectedVacation, setSelectedVacation] = useState({});

  const isEditingRef = useRef(false);

  useImperativeHandle(ref, () => ({
    getFormObject() {
      return selectedVacation;
    },
  }));

  const generatePropsMemoized = useCallback(
    (keyVal, key) =>
      generateProps(keyVal, key, 'vacations', planDetailsByPlanId),
    [planDetailsByPlanId] // Dependencies for memoization
  );

  useEffect(() => {
    if (isEditingRef.current) return;
    const vacation = formDetails?.vacations.find((k) => k.name === goal.name);
    if (
      !vacation &&
      modalType === 'add' &&
      Object.keys(selectedVacation).length === 0
    ) {
      const newRecord = Object.keys(PropertyNamesMap['vacations']).reduce(
        (acc, key) => {
          const keyVal = PropertyNamesMap['vacations'][key];
          acc[key] = generatePropsMemoized(keyVal, key);
          return acc;
        },
        {}
      );

      if (Object.keys(selectedVacation).length === 0) {
        const formattedNewRecord = formatValuesForUI(
          newRecord,
          vacationsFormFields
        );
        if (
          JSON.stringify(formattedNewRecord) !==
          JSON.stringify(selectedVacation)
        ) {
          setSelectedVacation(formattedNewRecord);
        }
      }
    }

    if (vacation && Object.keys(selectedVacation).length === 0) {
      const formattedVacation = formatValuesForUI(
        vacation,
        vacationsFormFields
      );
      if (
        JSON.stringify(formattedVacation) !== JSON.stringify(selectedVacation)
      ) {
        setSelectedVacation(formattedVacation); // Only update if necessary
      }
    }
  }, [
    formDetails.vacations,
    goal.name,
    modalType,
    selectedVacation,
    generatePropsMemoized,
  ]);

  const validateVacationNames = useCallback(
    (value) => {
      const existingVacation = formDetails?.vacations.find(
        (vacation) => vacation?.name.toLowerCase() === value.toLowerCase()
      );
      if (modalType === 'add' && existingVacation) {
        return false;
      }
      return true;
    },
    [modalType, formDetails?.vacations]
  );

  const getIsValidFieldValue = useCallback(
    (key) => {
      const value = selectedVacation[key];
      let isValid = true;
      switch (key) {
        case 'vacationExpense':
          isValid = value;
          break;
        case 'vacationYear':
          isValid =
            modalType === 'add'
              ? value && parseInt(value) >= new Date().getFullYear()
              : true;
          break;
        case 'name':
          isValid = value !== '' ? validateVacationNames(value) : false;
          break;
        default:
          break;
      }
      return isValid;
    },
    [selectedVacation, validateVacationNames, modalType]
  );

  const checkFormValidity = useCallback(() => {
    const allFields = ['name', 'vacationYear', 'vacationExpense'];
    const isValid = allFields.every((field) => getIsValidFieldValue(field));
    setIsFormValid(isValid);
  }, [getIsValidFieldValue, setIsFormValid]);

  const handleBlur = (field) => {
    const fieldType = vacationsFormFields.find((ft) => ft.key === field);
    let formattedValue = selectedVacation[field];
    if (fieldType && fieldType.dataType === 'percent') {
      // Apply the formatting only on blur
      formattedValue = percentageConversionToUI(selectedVacation[field]);
    }
    setSelectedVacation({ ...selectedVacation, [field]: formattedValue }); // Update the selected vacation state
    isEditingRef.current = false;
  };

  const handleOnChange = (event, key) => {
    isEditingRef.current = true;
    const { value } = event?.target || '';
    const fieldType = vacationsFormFields.find((ft) => ft.key === key);
    let formattedValue = value;
    if (fieldType) {
      if (fieldType.dataType === 'percent') {
        // Allow the raw value to be typed without adding "%" on every key press
        formattedValue = value; // Keep it raw for now during typing
      } else {
        formattedValue = formatValueByType(value, fieldType.dataType);
      }
    }
    const vacation = { ...selectedVacation, [key]: formattedValue };
    // Update selected vacation state to reflect changes in UI
    setSelectedVacation(vacation);
  };

  const handleDateChange = (date, field) => {
    if (date) {
      // Convert date to the appropriate format if necessary
      const formattedDate =
        field.dataType === 'year'
          ? date.getFullYear().toString() // For year view, use the year part only
          : date.toISOString().split('T')[0]; // For full date view, use 'yyyy-MM-dd'
      isEditingRef.current = true;
      const fieldType = vacationsFormFields.find((ft) => ft.key === field.key);
      if (fieldType) {
        const vacation = {
          ...selectedVacation,
          [field.key]: formattedDate,
        };
        setSelectedVacation(vacation);
      }
    }
  };

  useEffect(() => {
    if (selectedVacation && Object.keys(selectedVacation).length > 0) {
      checkFormValidity();
    }
  }, [formDetails, checkFormValidity, selectedVacation]);

  return (
    <>
      <div className="signup-title-container">
        <span className="signup-title">
          {modalType === 'add' ? 'Add New Vacation' : 'Update Vacation'}
        </span>
        <CloseIcon
          className="signup-title-close-icon"
          onClick={() => handleModalOpen(false)}
        />
      </div>
      <div className="signup-body-container">
        <div className="insured_main_outer">
          {vacationsFormFields.map((field) => {
            switch (field.type) {
              case 'text':
                return (
                  <div className="row align-items-center">
                    <div
                      className={`col-lg-8 insured_content_outer labelStyle 
          }`}
                    >
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className="col-lg-3 insured_radio_group">
                      <input
                        type={field.type}
                        id={field.key}
                        name={field.key}
                        required={true}
                        disabled={modalType === 'edit' && field.isDisabled}
                        value={selectedVacation[field.key]}
                        className={'custom-input'} // Apply the appropriate class
                        onChange={(event) => handleOnChange(event, field.key)}
                        onBlur={() => handleBlur(field.key)}
                        placeholder=""
                      />
                    </div>
                    {conditionalFields.includes(field.key) &&
                      selectedVacation &&
                      Object.keys(selectedVacation).length > 0 &&
                      !getIsValidFieldValue(field.key) && (
                        <p className="error-text">
                          {selectedVacation[field.key] !== '' &&
                          selectedVacation[field.key] !== undefined // dont check for zero
                            ? field.errorMessage
                            : ''}
                        </p>
                      )}
                  </div>
                );
              case 'select':
                return (
                  <div className="row align-items-center">
                    <div className={`col-lg-8 insured_content_outer`}>
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className={`col-lg-3 insured_radio_group`}>
                      <Controls.Select
                        name={field.key}
                        id={field.key}
                        value={selectedVacation[field.key]}
                        onChange={(event, index) =>
                          handleOnChange(event, field.key)
                        }
                        className={'custom-input'}
                        options={field.options.map((c) => {
                          return {
                            value: c.value,
                            label: c.label,
                          };
                        })}
                      />
                    </div>
                  </div>
                );
              case 'date':
                const dateValue = getDateValue(
                  selectedVacation[field.key],
                  field
                );
                return (
                  <div className="row align-items-center">
                    <div className="col-lg-8 insured_content_outer">
                      <p className={`${field.isRequired ? 'required' : ''}`}>
                        {field.name}
                      </p>
                    </div>
                    <div className="col-lg-3 insured_radio_group vacation_date_input">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          views={[field.dataType]}
                          className="planner-input long-text-input"
                          format={
                            field.dataType === 'year' ? 'yyyy' : 'yyyy-MM-dd'
                          }
                          autoOk
                          variant="inline"
                          InputAdornmentProps={{ position: 'start' }}
                          value={dateValue}
                          onChange={(date) => handleDateChange(date, field)}
                          inputProps={{ style: { height: '39px' } }}
                          minDate={
                            modalType === 'add' ||
                            (modalType === 'edit' &&
                              selectedVacation[field.key] >=
                                new Date().getFullYear())
                              ? new Date()
                              : undefined
                          }
                          disabled={
                            modalType === 'edit' &&
                            selectedVacation[field.key] <
                              new Date().getFullYear()
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
      </div>
    </>
  );
});
export default VacationsContentGoals;
