import React, { useState } from 'react';
import { FormHelperText, IconButton } from '@material-ui/core';
import Autocomplete from 'react-select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

export default function SelectComp(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    options = [],
    disabled = false,
    isAutoComplete = false,
    maxMenuHeight = 200,
    placeholder = 'Select',
    required = false,
    noOptionsMessage,
    allowAddNewItem = false,  // New prop to control the "Add" behavior
  } = props;


  const [newItem, setNewItem] = useState("");

  const handleChange = (event) => {
    const { value } = event;
    const index = options.findIndex((option) => option.value === value);
    onChange({ target: { value, index, name } });
  };


  const handleAddNewItem = () => {
    if (newItem) {
      const newOption = { value: newItem, label: newItem };
      onChange({ target: { value: newOption.value, index: options.length, name } });
      setNewItem(""); // Reset the input field after adding
    }
  };

  const selectedValue = options.find((option) => option.value === value);

  const CustomNoOptionsMessage = () => {
    if (allowAddNewItem && newItem) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{`"${newItem}" not found. `}</span>
          <IconButton onClick={handleAddNewItem} size="small">
            <AddIcon />
          </IconButton>
        </div>
      );
    }
    else {
      // Return the default message if not adding a new item
      return <span style={{ marginLeft: '10px', fontSize: '14px', color: '#888', textAlign: 'center' }}>No options</span>;
    }
  };

  return (
    <div className="controls-select">
      <FormControl fullWidth size="small">
        <label className={required ? 'required' : ''}>{label}</label>
        <Autocomplete
          options={options}
          sx={{ width: 300 }}
          value={selectedValue || null}
          onChange={handleChange}
          classNamePrefix="select-search"
          placeholder={placeholder}
          noOptionsMessage={allowAddNewItem ? () => 'No options. Type to add new' : () => 'No options'}
          components={{ NoOptionsMessage: CustomNoOptionsMessage }}
          maxMenuHeight={maxMenuHeight}
          isDisabled={disabled}
          menuPortalTarget={document.body}
          onInputChange={(inputValue) => setNewItem(inputValue)}
          inputValue={newItem}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </FormControl>
      {error && <FormHelperText>{error}</FormHelperText>}
    </div>
  );
}
