import React, { useState, useEffect } from 'react';
import Controls from './controls/Controls';
import {
  transformInfoCoreBackToUIType,
  validateForm,
} from '../helpers/BuildHelperComponentV3';
import { infoCoreValidatableFields } from '../constants/BuildTabComponentV3';

const disabledFields = ['age', 'retirementAge', 'gender', 'name'];

const RothSavingsComp = (props) => {
  const {
    membersCount,
    isFirstPersonRetired,
    isSecondPersonRetired,
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    retiredState,
  } = props;
  const { moduleFamily: { infoCore = {} } = {} } = localPlanDetailsByPlanId;

  const [formErrors, setFormErrors] = useState([]);

  const [infoCoreDetails, setinfoCoreDetails] = useState(infoCore);

  useEffect(() => {
    if (infoCore && Object.keys(infoCore).length > 0) {
      //let tempInfoCore = getAndUpdatePensionContribYrs(infoCore, membersCount);
      let tempInfoCore = transformInfoCoreBackToUIType(infoCore);
      let validationErrors = {};
      validationErrors = validateForm(
        tempInfoCore,
        membersCount,
        infoCoreValidatableFields
      );
      if (Object.values(validationErrors).length > 0) {
        setFormErrors(validationErrors);
      }
      const { validationObj1 = [], validationObj2 = [] } = validationErrors;
      //   if (
      //     Object.values(validationObj1).length > 0 ||
      //     Object.values(validationObj2).length > 0
      //   ) {
      //     setValidationError(false);
      //   } else {
      //     setValidationError(true);
      //   }
      setinfoCoreDetails(tempInfoCore);
    }
  }, [localPlanDetailsByPlanId, infoCore, membersCount]);

  const renderMemberLabelRowWithoutEdits = (
    modelObj,
    name,
    memberOne,
    memberTwo
  ) => {
    return (
      <>
        <div
          className="row align-items-center personal_div_first_row"
          key={name}
        >
          <div className="col-lg-6 col-md-6">
            <p>
              <strong>Family Members</strong>
            </p>
          </div>
          {modelObj && modelObj[memberOne] && modelObj[memberOne][name] && (
            <div className="col-lg-3 col-md-3 col-6 personal_div_first_content">
              <p style={{ textAlign: 'center' }}>
                <strong style={{ fontWeight: 'bold', color: '#3e9479' }}>
                  {modelObj[memberOne][name]}
                </strong>
                <br />
                {isFirstPersonRetired && <p>(Retired)</p>}
              </p>
            </div>
          )}
          {membersCount > 1 &&
            modelObj &&
            modelObj[memberTwo] &&
            modelObj[memberTwo] && (
              <div className="col-lg-3 col-md-3 col-6 personal_div_first_content">
                <p style={{ textAlign: 'center' }}>
                  {modelObj[memberTwo][name] ? (
                    <>
                      <strong style={{ fontWeight: 'bold', color: '#3e9479' }}>
                        {modelObj[memberTwo][name]}
                      </strong>
                      <br />
                      {isSecondPersonRetired && <p>(Retired)</p>}
                    </>
                  ) : (
                    <strong style={{ fontWeight: 'bold' }}>Name?</strong>
                  )}
                </p>
              </div>
            )}
        </div>

        <hr style={{ marginTop: '0px' }} />
      </>
    );
  };

  const deriveGender = (value) => {
    return value === 'male'
      ? 'Male'
      : value === 'female'
      ? 'Female'
      : 'Unspecified';
  };

  const handleFormFieldChangeForMembers = (
    event,
    from,
    type,
    modelObj,
    tab,
    subTab,
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    valueType,
    memberFieldType,
    idName,
    member,
    setFormDetailsObj
  ) => {
    //let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    let fieldValue = event.target.value;
    if (valueType === 'percentage') {
      if (fieldValue.toString().includes('%')) {
        let percentFieldValue = fieldValue.split('%')[0];
        if (percentFieldValue !== '' && !isNaN(percentFieldValue)) {
          fieldValue = percentFieldValue;
        }
      }
    } else if (valueType === 'currency') {
      if (fieldValue.toString().includes('$')) {
        let currencyFieldValue = fieldValue.split('$')[1];
        currencyFieldValue = currencyFieldValue.replace(/,/g, '');
        if (currencyFieldValue !== '' && !isNaN(currencyFieldValue)) {
          fieldValue = currencyFieldValue;
        }
      }
    } else if (valueType === 'number') {
      if (
        fieldValue !== '' &&
        fieldValue !== null &&
        fieldValue !== undefined
      ) {
        if (isNaN(fieldValue)) {
          fieldValue = 0;
        }
      }
    }
    const selectedObj = { ...modelObj[memberFieldType] };
    let tempModelObj = {
      ...modelObj,
      [memberFieldType]: { ...selectedObj, [from]: fieldValue },
    };
    setFormDetailsObj(tempModelObj);
  };

  const handleBlur = (
    event,
    from,
    type,
    modelObj,
    tab,
    subTab,
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    valueType,
    memberFieldType,
    idName,
    member,
    setFormDetailsObj
  ) => {
    let { value } = event && event.target;
    let tempPlanDetailsByPlanId = { ...localPlanDetailsByPlanId };
    if (valueType === 'percentage') {
      let percentFieldValue = '';
      if (value !== null && value !== undefined && value !== '') {
        if (value.toString().includes('%')) {
          percentFieldValue = value.split('%')[0];
          if (
            percentFieldValue !== '' &&
            !isNaN(percentFieldValue) &&
            Number(percentFieldValue) >= 0
          ) {
            percentFieldValue = parseFloat(percentFieldValue).toFixed(2);
            value = `${percentFieldValue}%`;
          } else {
            value = `${0}%`;
          }
        } else if (!isNaN(value) && Number(value) >= 0) {
          percentFieldValue = parseFloat(value).toFixed(2);
          value = `${percentFieldValue}%`;
        } else {
          value = `${0}%`;
        }
      } else {
        value = `${0}%`;
      }
    }
    if (valueType === 'currency') {
      const numFormat = Intl.NumberFormat('en-US');
      let newNumformat = numFormat.format(0);
      let currencyFieldValue = '';
      if (value !== null && value !== undefined && value !== '') {
        if (value.toString().includes('$')) {
          currencyFieldValue = value.split('$')[1];
          currencyFieldValue = currencyFieldValue.replace(/,/g, '');
          if (
            currencyFieldValue !== '' &&
            !isNaN(currencyFieldValue) &&
            Number(currencyFieldValue) >= 0
          ) {
            newNumformat = numFormat.format(currencyFieldValue);
          }
        } else if (!isNaN(value) && Number(value) >= 0) {
          newNumformat = numFormat.format(value);
        }
        value = `$${newNumformat}`;
      } else {
        value = `$${newNumformat}`;
      }
    }
    if (valueType === 'number') {
      if (value !== '' && value !== null && value !== undefined) {
        if (!isNaN(value) && Number(value) >= 0) {
          value = Number(value);
        } else {
          value = 0;
        }
      } else {
        value = 0;
      }
    }
    let valueToApi = '';
    if (type === 'input') {
      if (value !== null && value !== undefined && value !== '') {
        if (isNaN(value) && value.includes('%')) {
          const percentValue = value.split('%')[0];
          if (!isNaN(percentValue)) {
            valueToApi = parseFloat(parseFloat(percentValue) / 100);
          } else {
            valueToApi = 0;
          }
        } else if (isNaN(value) && value.includes('$')) {
          let dollarValue = value.split('$')[1];
          dollarValue = dollarValue.replace(/,/g, '');
          if (!isNaN(dollarValue)) {
            valueToApi = Number(dollarValue);
          } else {
            valueToApi = 0;
          }
        } else if (!isNaN(value)) {
          valueToApi = Number(value);
        }
      } else {
        valueToApi = 0;
      }
    }
    // Dynamically fetch the moduleTab object based on tab
    const tabModule = tempPlanDetailsByPlanId[tab] || {}; // Tab will be module Family
    const memberModule = tabModule[subTab]
      ? tabModule[subTab][memberFieldType] || {}
      : {};

    let tempModelObj = {};
    if (memberFieldType) {
      tempModelObj = {
        ...modelObj,
        [memberFieldType]: { ...memberModule, [from]: value },
      };
    } else {
      tempModelObj = {
        ...modelObj,
        [from]: value,
      };
    }
    setFormDetailsObj(tempModelObj);
    if (memberFieldType) {
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        [tab]: {
          ...tabModule,
          [subTab]: {
            ...tabModule[subTab],
            [memberFieldType]: {
              ...memberModule,
              [from]: valueToApi,
            },
          },
        },
      };
    } else {
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        [tab]: {
          ...tabModule,
          [subTab]: {
            ...tabModule[subTab],
            [from]: valueToApi,
          },
        },
      };
    }
    let ageModified = false;
    if (from === 'age' || from === 'retirementAge') {
      ageModified = true;
    }
    setLocalPlanDetailsByPlanId(tempPlanDetailsByPlanId, ageModified);
  };

  const renderMembersInputRow = (
    type,
    firstInputIdName,
    inputName, //APIfieldName
    secondInputIdName,
    label,
    valueType,
    modelObj, //infoCore obj or infoExpenses obj
    subTabName, //infoCore or infoExpenses
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    mainTabName, //module family
    firstMemberFieldName, // incSavFirst or defCompFirst
    secondMemberFieldName, // incSavSecond or defCompSecond
    setFormDetailsObj,
    formErrors,
    isRetirementAgeSpecificField
  ) => {
    const { validationObj1 = [], validationObj2 = [] } = formErrors || [];
    const isMember1FieldInValid = validationObj1.filter(
      (v) =>
        v.apiFieldName.toLowerCase() === inputName.toLowerCase() && !v.isValid
    )[0];
    const isMember2FieldInValid = validationObj2.filter(
      (v) =>
        v.apiFieldName.toLowerCase() === inputName.toLowerCase() && !v.isValid
    )[0];
    let firstMemberValue =
      modelObj &&
      modelObj[firstMemberFieldName] &&
      modelObj[firstMemberFieldName][inputName] !== null
        ? modelObj[firstMemberFieldName][inputName]
        : '';
    firstMemberValue =
      inputName === 'gender' && mainTabName === 'moduleFamily'
        ? deriveGender(firstMemberValue)
        : firstMemberValue;
    let secondMemberValue = '';
    if (membersCount > 1) {
      secondMemberValue =
        modelObj &&
        modelObj[secondMemberFieldName] &&
        modelObj[secondMemberFieldName][inputName] !== null
          ? modelObj[secondMemberFieldName][inputName]
          : '';
      secondMemberValue =
        inputName === 'gender' && mainTabName === 'moduleFamily'
          ? deriveGender(secondMemberValue)
          : secondMemberValue;
    }
    return (
      <div
        className="row align-items-center"
        key={firstInputIdName + '_' + secondInputIdName}
      >
        <div className="col-lg-6 insured_content_outer">
          <p>{label}</p>
        </div>
        {!isRetirementAgeSpecificField || !isFirstPersonRetired ? (
          <div className="col-lg-3 col-6 insured_radio_group">
            <input
              type={type}
              onKeyDown={(event) => {
                if (event.key === '.' && valueType === 'number') {
                  event.preventDefault();
                }
              }}
              id={firstInputIdName}
              name={firstInputIdName}
              disabled={disabledFields.indexOf(inputName) !== -1}
              value={firstMemberValue}
              className={'custom-input'} // Apply the appropriate class
              onChange={(event) =>
                handleFormFieldChangeForMembers(
                  event,
                  inputName,
                  'input',
                  modelObj,
                  mainTabName,
                  subTabName,
                  localPlanDetailsByPlanId,
                  setLocalPlanDetailsByPlanId,
                  valueType,
                  firstMemberFieldName,
                  firstInputIdName,
                  'first',
                  setFormDetailsObj
                )
              }
              onBlur={(event) =>
                handleBlur(
                  event,
                  inputName,
                  'input',
                  modelObj,
                  mainTabName,
                  subTabName,
                  localPlanDetailsByPlanId,
                  setLocalPlanDetailsByPlanId,
                  valueType,
                  firstMemberFieldName,
                  firstInputIdName,
                  'first',
                  setFormDetailsObj
                )
              }
              placeholder=""
            />
            {isMember1FieldInValid && !isMember1FieldInValid.isValid && (
              <p className="error-text">{isMember1FieldInValid.errorMessage}</p>
            )}
          </div>
        ) : (
          <div className="col-lg-3 col-6 insured_radio_group"></div>
        )}
        {membersCount > 1 &&
        (!isRetirementAgeSpecificField || !isSecondPersonRetired) ? (
          <div className="col-lg-3 col-6 insured_radio_group">
            <input
              type={type}
              onKeyDown={(event) => {
                if (event.key === '.' && valueType === 'number') {
                  event.preventDefault();
                }
              }}
              id={secondInputIdName}
              name={secondInputIdName}
              value={secondMemberValue}
              disabled={disabledFields.indexOf(inputName) !== -1}
              className={'custom-input'} // Apply the appropriate class
              onChange={(event) =>
                handleFormFieldChangeForMembers(
                  event,
                  inputName,
                  'input',
                  modelObj,
                  mainTabName,
                  subTabName,
                  localPlanDetailsByPlanId,
                  setLocalPlanDetailsByPlanId,
                  valueType,
                  secondMemberFieldName,
                  secondInputIdName,
                  'second',
                  setFormDetailsObj
                )
              }
              onBlur={(event) =>
                handleBlur(
                  event,
                  inputName,
                  'input',
                  modelObj,
                  mainTabName,
                  subTabName,
                  localPlanDetailsByPlanId,
                  setLocalPlanDetailsByPlanId,
                  valueType,
                  secondMemberFieldName,
                  secondInputIdName,
                  'second',
                  setFormDetailsObj
                )
              }
              placeholder=""
            />
            {isMember2FieldInValid && !isMember2FieldInValid.isValid && (
              <p className="error-text">{isMember2FieldInValid.errorMessage}</p>
            )}
          </div>
        ) : (
          <div className="col-lg-3 col-6 insured_radio_group"></div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="insured_main_outer">
        {/* <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
                <Alert severity="info">
                  <p>{'No savings to be captured since the family is retired'}</p>
                </Alert>
              </Stack> */}
        {renderMemberLabelRowWithoutEdits(
          infoCoreDetails,
          'name',
          'basicsFirst',
          'basicsSecond'
        )}
      </div>

      <div className="insured_main_outer">
        {renderMembersInputRow(
          'text',
          'tlMemberOneiraContrib',
          'iraContrib',
          'tlMemberSeciraContrib',
          'IRA contribution as % of income',
          'percentage',
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          'incSavFirst',
          'incSavSecond',
          setinfoCoreDetails,
          formErrors
        )}
        {renderMembersInputRow(
          'text',
          'tlMemberOnePercentRothIra',
          'percentRothIra',
          'tlMemberSecPercentRothIra',
          'What % of IRA contribution is into ROTH',
          'percentage',
          infoCoreDetails,
          'infoCore',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleFamily',
          'incSavFirst',
          'incSavSecond',
          setinfoCoreDetails,
          formErrors
        )}

        <hr />
        {!retiredState && (
          <>
            <div className="insured_main_outer">
              {renderMembersInputRow(
                'text',
                'tlMemberOneSelfContrib401k',
                'selfContrib401k',
                'tlMemberSecSelfContrib401k',
                '401k self contribution as % of wages',
                'percentage',
                infoCoreDetails,
                'infoCore',
                localPlanDetailsByPlanId,
                setLocalPlanDetailsByPlanId,
                'moduleFamily',
                'incSavFirst',
                'incSavSecond',
                setinfoCoreDetails,
                formErrors,
                'isRetirementAgeSpecificField'
              )}
              {renderMembersInputRow(
                'text',
                'tlMemberOneEmployerContrib401k',
                'employerContrib401k',
                'tlMemberSecEmployerContrib401k',
                '401k employer contribution as % of self contribution',
                'percentage',
                infoCoreDetails,
                'infoCore',
                localPlanDetailsByPlanId,
                setLocalPlanDetailsByPlanId,
                'moduleFamily',
                'incSavFirst',
                'incSavSecond',
                setinfoCoreDetails,
                formErrors,
                'isRetirementAgeSpecificField'
              )}
              {renderMembersInputRow(
                'text',
                'tlMemberOnePercentRoth401k',
                'percentRoth401k',
                'tlMemberSecPercentRoth401k',
                'What % of 401K contribution is into ROTH',
                'percentage',
                infoCoreDetails,
                'infoCore',
                localPlanDetailsByPlanId,
                setLocalPlanDetailsByPlanId,
                'moduleFamily',
                'incSavFirst',
                'incSavSecond',
                setinfoCoreDetails,
                formErrors,
                'isRetirementAgeSpecificField'
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RothSavingsComp;
