import React, { useEffect, useState } from 'react';
import {
  renderInputRow,
  transformTaxesBackToUIType,
} from '../../helpers/BuildHelperComponentV3';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { isEqual } from 'lodash';

const BuildSettingsTaxesComponent = (props) => {
  const {
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    setLastStepInParent,
  } = props;

  const { moduleSettings: { taxes = {} } = {} } = localPlanDetailsByPlanId;
  const [taxesDetails, setTaxesDetails] = useState(taxes);

  useEffect(() => {
    if (taxes && Object.keys(taxes).length > 0) {
      let tempTaxes = transformTaxesBackToUIType(taxes);
      if (!isEqual(tempTaxes, taxes)) {
        setTaxesDetails(tempTaxes);
      }
    }
  }, [localPlanDetailsByPlanId, taxes]);

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  return (
    <>
      <div className="insured_main_outer">
        {renderInputRow(
          'text',
          'tlDeductions',
          'itemizedDeduction',
          'Itemized deductions (standard deduction will be used if this is zero)',
          'currency',
          taxesDetails,
          'taxes',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setTaxesDetails
        )}
        {renderInputRow(
          'text',
          'tlGrowthDed',
          'itemizedDedGrowth',
          'Annual growth % of itemized deductions',
          'percentage',
          taxesDetails,
          'taxes',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setTaxesDetails
        )}
        {renderInputRow(
          'text',
          'tlBzDeductions',
          'qualifiedBusinessIncDeduction',
          'Qualified Business Income deduction',
          'currency',
          taxesDetails,
          'taxes',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setTaxesDetails
        )}
        {renderInputRow(
          'text',
          'tlDedGrowth',
          'qualifiedBusinessIncDedGrowth',
          'Annual growth % of qualified business income deduction',
          'percentage',
          taxesDetails,
          'taxes',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleSettings',
          false,
          setTaxesDetails
        )}
      </div>

      <div className='tab_main_nav'>
        <div className='tab_left_nav'>
          <button>
            <ArrowBackIcon /> Previous
          </button>
        </div>
        <div className='tab_right_nav'>
          <button>
            Next <ArrowForwardIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default BuildSettingsTaxesComponent;
