import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { planDetailsByPlanIdSelector } from '../../selectors/plannerDetailsSelector';
import Controls from '../controls/Controls';
import { invPropsFormFields } from '../../constants/Client';
import {
  generateProps,
  percentageConversionToUI,
  formatValueByType,
  formatValuesForInvPropsUI,
  createLocalDateFromDateString,
  getDateValue,
  validateDateFields,
  parseCurrency,
} from '../../helpers/SolutionsHelper';
import { PropertyNamesMap } from '../../constants/PropertyNames';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const InvPropsContentGoals = forwardRef((props, ref) => {
  const { commonProps = {}, taxStateOptions = [] } = props;
  const {
    modalType,
    handleModalOpen,
    category,
    goal = {},
    setIsFormValid,
    formDetails,
  } = commonProps;
  const planDetailsByPlanId = useSelector((state) =>
    planDetailsByPlanIdSelector(state)
  );

  const outerObjectKeys = useMemo(
    () => ['annualRent', 'annualRentGrowth', 'priorDeprAccumulated'],
    []
  );

  const [selectedInvProp, setSelectedInvProp] = useState({});
  const [invPropertiesFormFields, setInvPropertiesFormFields] =
    useState(invPropsFormFields);

  const conditionalFields = [
    'name',
    'origOrRefiLoanAmount',
    'purchaseDate',
    'origOrRefiLoanDate',
  ];

  useEffect(() => {
    let tempInvPropsFormFields = [...invPropsFormFields];
    if (taxStateOptions && taxStateOptions.length > 0) {
      tempInvPropsFormFields = tempInvPropsFormFields.map((f) => {
        if (f.key === 'propDomicile') {
          return { ...f, options: taxStateOptions };
        }
        return f;
      });
    }
    setInvPropertiesFormFields(tempInvPropsFormFields);
  }, [setInvPropertiesFormFields, taxStateOptions]);

  const isEditingRef = useRef(false);

  useImperativeHandle(ref, () => ({
    getFormObject() {
      return selectedInvProp;
    },
  }));

  const generatePropsMemoized = useCallback(
    (keyVal, key) => generateProps(keyVal, key, 'props', planDetailsByPlanId),
    [planDetailsByPlanId] // Dependencies for memoization
  );

  useEffect(() => {
    if (isEditingRef.current) return;
    const invProp = formDetails?.props.find((k) => k.prop.name === goal.name);
    let newRecord = {};
    if (
      !invProp &&
      modalType === 'add' &&
      Object.keys(selectedInvProp).length === 0
    ) {
      const propertyMap = PropertyNamesMap['invProps'];
      for (const key in propertyMap) {
        let keyVal = propertyMap[key];
        if (propertyMap.hasOwnProperty(key)) {
          if (key === 'prop') {
            newRecord[key] = {};
            for (const subKey in keyVal) {
              let subKeyVal = keyVal[subKey];
              if (keyVal.hasOwnProperty(subKey)) {
                let newRecordKey = generatePropsMemoized(subKeyVal, subKey);
                newRecord['prop'][subKey] = newRecordKey;
              }
            }
          } else {
            let newRecordKey = generatePropsMemoized(keyVal, key);
            newRecord[key] = newRecordKey;
          }
        }
      }

      if (Object.keys(selectedInvProp).length === 0) {
        const formattedNewRecord = formatValuesForInvPropsUI(
          newRecord,
          invPropsFormFields,
          outerObjectKeys
        );
        if (
          JSON.stringify(formattedNewRecord) !== JSON.stringify(selectedInvProp)
        ) {
          setSelectedInvProp(formattedNewRecord);
        }
      }
    }

    if (invProp && Object.keys(selectedInvProp).length === 0) {
      const formattedInvProp = formatValuesForInvPropsUI(
        invProp,
        invPropsFormFields,
        outerObjectKeys
      );
      if (
        JSON.stringify(formattedInvProp) !== JSON.stringify(selectedInvProp)
      ) {
        setSelectedInvProp(formattedInvProp); // Only update if necessary
      }
    }
  }, [
    formDetails.props,
    goal.name,
    modalType,
    selectedInvProp,
    generatePropsMemoized,
    outerObjectKeys,
  ]);

  const validateInvPropNames = useCallback(
    (value) => {
      const existingInvProp = formDetails?.props.find(
        (invProp) => invProp?.prop?.name.toLowerCase() === value.toLowerCase()
      );
      if (modalType === 'add' && existingInvProp) {
        return false;
      }
      return true;
    },
    [modalType, formDetails?.props]
  );

  const validateLoanAmountField = useCallback(
    (value) => {
      const loanAmount = parseCurrency(value || 0);
      const purchasePrice = parseCurrency(
        selectedInvProp?.prop.purchasePrice || 0
      );

      if (isNaN(loanAmount) || isNaN(purchasePrice)) {
        return false;
      }
      // Ensure loan amount is not greater than the purchase price
      return loanAmount <= purchasePrice;
    },
    [selectedInvProp]
  );

  const getIsValidFieldValue = useCallback(
    (key) => {
      let value = selectedInvProp.hasOwnProperty(key)
        ? selectedInvProp[key]
        : selectedInvProp.prop.hasOwnProperty(key)
        ? selectedInvProp.prop[key]
        : '';
      let isValid = true;
      switch (key) {
        case 'aggMaintenanceExp':
        case 'loanAmountOutstanding':
        case 'purchasePrice':
        case 'taxBasis':
        case 'value':
          isValid = value;
          break;
        case 'origOrRefiLoanAmount':
          isValid = value !== '' ? validateLoanAmountField(value) : false;
          break;
        case 'aggMaintenanceExpGrowth':
        case 'appreciationRate':
        case 'fixedIntRate':
        case 'taxBasisGrowth':
        case 'taxRate':
          isValid = value;
          break;
        case 'loanTerm':
          isValid = value !== '' && parseInt(value) >= 0;
          break;
        case 'purchaseDate':
          const date =
            value &&
            validateDateFields(value) &&
            createLocalDateFromDateString(value);
          isValid = validateDateFields(value)
            ? modalType === 'add'
              ? date &&
                new Date(date).setHours(0, 0, 0, 0) >=
                  new Date().setHours(0, 0, 0, 0)
              : true
            : false;
          break;
        case 'origOrRefiLoanDate':
          isValid = value ? validateDateFields(value) : false;
          break;
        case 'name':
          isValid = value ? validateInvPropNames(value) : false;
          break;
        default:
          break;
      }
      return isValid;
    },
    [selectedInvProp, validateInvPropNames, modalType, validateLoanAmountField]
  );

  const checkFormValidity = useCallback(() => {
    const allFields = invPropsFormFields.map((s) => s.key);
    const isValid = allFields.every((field) => getIsValidFieldValue(field));
    setIsFormValid(isValid);
  }, [getIsValidFieldValue, setIsFormValid]);

  const handleBlur = (field) => {
    const fieldType = invPropertiesFormFields.find((ft) => ft.key === field);
    let formattedValue = selectedInvProp.hasOwnProperty(field)
      ? selectedInvProp[field]
      : selectedInvProp.prop.hasOwnProperty(field)
      ? selectedInvProp.prop[field]
      : '';
    // let { value: formattedValue } = event && event.target;
    if (fieldType && fieldType.dataType === 'percent') {
      // Apply the formatting only on blur
      formattedValue = percentageConversionToUI(formattedValue);
    }
    let invProp = { ...selectedInvProp };

    if (field === 'purchaseDate') {
      invProp = {
        ...invProp,
        prop: {
          ...invProp.prop,
          ...(invProp.prop.hasOwnProperty(field) && {
            [field]: formattedValue,
            origOrRefiLoanDate: formattedValue,
          }),
        },
      };
    } else if (field === 'purchasePrice') {
      invProp = {
        ...invProp,
        prop: {
          ...invProp.prop,
          ...(invProp.prop.hasOwnProperty(field) && {
            [field]: formattedValue,
            taxBasis: formattedValue,
            value: formattedValue,
          }),
        },
      };
    } else if (
      field === 'origOrRefiLoanAmount' &&
      parseCurrency(formattedValue) === 0
    ) {
      invProp = {
        ...invProp,
        prop: {
          ...invProp.prop,
          ...(invProp.prop.hasOwnProperty(field) && {
            [field]: formattedValue,
            loanAmountOutstanding: formattedValue,
            loanTerm: 0,
          }),
        },
      };
    } else {
      invProp = {
        ...invProp,
        ...(invProp.hasOwnProperty(field) && { [field]: formattedValue }),
        prop: {
          ...invProp.prop,
          ...(invProp.prop.hasOwnProperty(field) && {
            [field]: formattedValue,
          }),
        },
      };
    }
    setSelectedInvProp(invProp);
    isEditingRef.current = false;
  };

  const handleBlur1 = (field) => {
    const fieldType = invPropertiesFormFields.find((ft) => ft.key === field);
    let formattedValue = selectedInvProp[field];

    if (fieldType?.dataType === 'percent') {
      formattedValue = percentageConversionToUI(formattedValue);
    }

    const updateField = (fieldUpdates = {}, propUpdates = {}) => ({
      ...selectedInvProp,
      ...(selectedInvProp.hasOwnProperty(field) && { [field]: formattedValue }),
      ...fieldUpdates,
      prop: {
        ...selectedInvProp.prop,
        ...(selectedInvProp.prop.hasOwnProperty(field) && {
          [field]: formattedValue,
        }),
        ...propUpdates,
      },
    });

    let invProp;
    switch (field) {
      case 'purchaseDate':
        invProp = updateField({}, { origOrRefiLoanDate: formattedValue });
        break;
      case 'purchasePrice':
        invProp = updateField(
          {},
          {
            taxBasis: formattedValue,
            value: formattedValue,
          }
        );
        break;
      case 'origOrRefiLoanAmount':
        invProp =
          parseCurrency(formattedValue) === 0
            ? updateField(
                {},
                { loanAmountOutstanding: formattedValue, loanTerm: 0 }
              )
            : updateField();
        break;
      default:
        invProp = updateField();
    }

    setSelectedInvProp(invProp);
    isEditingRef.current = false;
  };

  const handleOnChange = (event, key) => {
    isEditingRef.current = true;
    const { value } = event?.target || '';
    const fieldType = invPropertiesFormFields.find((ft) => ft.key === key);
    let formattedValue = value;
    if (fieldType) {
      if (fieldType.dataType === 'percent') {
        // Allow the raw value to be typed without adding "%" on every key press
        formattedValue = value; // Keep it raw for now during typing
      } else {
        formattedValue = formatValueByType(value, fieldType.dataType);
      }
    }

    // Set the updated object with the modified secondHomes array
    //setInvPropsInfo(updatedInvProps); // Update the local state with the new data
    const invProp = {
      ...selectedInvProp,
      ...(selectedInvProp.hasOwnProperty(key) && { [key]: formattedValue }),
      prop: {
        ...selectedInvProp.prop,
        ...(selectedInvProp.prop.hasOwnProperty(key) && {
          [key]: formattedValue,
        }),
      },
    };
    // Update selected secondHome state to reflect changes in UI
    setSelectedInvProp(invProp);
  };

  useEffect(() => {
    if (selectedInvProp && Object.keys(selectedInvProp).length > 0) {
      checkFormValidity();
    }
  }, [formDetails, checkFormValidity, selectedInvProp]);

  const isDisabledFn = ({ isDisabled = false, key = '' }) => {
    if (isDisabled) return true;
    if (key === 'purchaseDate' || key === 'purchasePrice') {
      const { purchaseDate = '' } = selectedInvProp?.prop || {};
      return purchaseDate && new Date(purchaseDate) < new Date();
    }
    return false;
  };

  const handleDateChange = (date, field) => {
    if (date) {
      // Convert date to the appropriate format if necessary
      const formattedDate =
        field.dataType === 'year'
          ? date.getFullYear().toString() // For year view, use the year part only
          : date.toISOString().split('T')[0]; // For full date view, use 'yyyy-MM-dd'
      isEditingRef.current = true;
      const fieldType = invPropertiesFormFields.find(
        (ft) => ft.key === field.key
      );
      let invProp = {};
      if (fieldType) {
        if (field.key === 'purchaseDate') {
          invProp = {
            ...selectedInvProp,
            prop: {
              ...selectedInvProp.prop,
              ...(selectedInvProp.prop.hasOwnProperty(field.key) && {
                [field.key]: formattedDate,
                origOrRefiLoanDate: formattedDate,
              }),
            },
          };
        } else {
          invProp = {
            ...selectedInvProp,
            prop: {
              ...selectedInvProp.prop,
              ...(selectedInvProp.prop.hasOwnProperty(field.key) && {
                [field.key]: formattedDate,
              }),
            },
          };
        }
        setSelectedInvProp(invProp);
      }
    }
  };

  return (
    <>
      <div className="signup-title-container">
        <span className="signup-title">
          {modalType === 'add' ? 'Add New Rentals' : 'Update Rentals'}
        </span>
        <CloseIcon
          className="signup-title-close-icon"
          onClick={() => handleModalOpen(false)}
        />
      </div>
      <div className="signup-body-container">
        <div className="insured_main_outer">
          {Object.keys(selectedInvProp).length > 0 &&
            invPropertiesFormFields.map((field) => {
              const value = outerObjectKeys.includes(field.key)
                ? selectedInvProp[field.key]
                : selectedInvProp?.prop?.[field.key];
              switch (field.type) {
                case 'text':
                  return (
                    <div className="row align-items-center">
                      <div
                        className={`col-lg-9 col-md-8 col-sm-6 col-12 insured_content_outer labelStyle 
          }`}
                      >
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 insured_radio_group">
                        <input
                          type={field.type}
                          id={field.key}
                          name={field.key}
                          // disabled={modalType === 'edit' && isDisabledFn(field)}
                          disabled={modalType === 'edit' && field.isDisabled}
                          value={value}
                          className={'custom-input'} // Apply the appropriate class
                          onChange={(event) => handleOnChange(event, field.key)}
                          onBlur={(event) => handleBlur(field.key)}
                          placeholder=""
                        />
                      </div>
                      {conditionalFields.includes(field.key) &&
                        selectedInvProp &&
                        Object.keys(selectedInvProp).length > 0 &&
                        !getIsValidFieldValue(field.key) && (
                          <p className="error-text">
                            {value !== '' && value !== undefined
                              ? field.errorMessage
                              : ''}
                          </p>
                        )}
                    </div>
                  );
                case 'select':
                  return (
                    <div className="row align-items-center">
                      <div className={`col-lg-9 col-md-8 col-sm-6 col-12 insured_content_outer`}>
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className={`col-lg-3 col-md-4 col-sm-6 col-12 insured_radio_group`}>
                        <Controls.Select
                          name={field.key}
                          id={field.key}
                          value={value}
                          onChange={(event, index) =>
                            handleOnChange(event, field.key)
                          }
                          className={'custom-input'}
                          options={field.options.map((c) => {
                            return {
                              value: c.value,
                              label: c.label,
                            };
                          })}
                        />
                      </div>
                    </div>
                  );
                case 'date':
                  const dateValue = getDateValue(value, field);
                  return (
                    <div className="row align-items-center">
                      <div className="col-lg-9 col-md-8 col-sm-6 col-12 insured_content_outer">
                        <p className={`${field.isRequired ? 'required' : ''}`}>
                          {field.name}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6 col-12 insured_radio_group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            views={[field.dataType]}
                            className="planner-input long-text-input"
                            format={
                              field.dataType === 'year' ? 'yyyy' : 'yyyy-MM-dd'
                            }
                            autoOk
                            variant="inline"
                            InputAdornmentProps={{ position: 'start' }}
                            value={dateValue}
                            onChange={(date) => handleDateChange(date, field)}
                            onBlur={() => handleBlur(field.key)}
                            inputProps={{ style: { height: '39px' } }}
                            minDate={
                              (modalType === 'add' ||
                                (modalType === 'edit' &&
                                  new Date(dateValue).setHours(0, 0, 0, 0) >=
                                    new Date().setHours(0, 0, 0, 0))) &&
                              field.key === 'origOrRefiLoanDate'
                                ? getDateValue(
                                    selectedInvProp?.prop['purchaseDate']
                                  )
                                : new Date()
                            }
                            disabled={
                              modalType === 'edit' &&
                              new Date(dateValue).setHours(0, 0, 0, 0) <
                                new Date().setHours(0, 0, 0, 0)
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  );
                default:
                  return null;
              }
            })}
        </div>
      </div>
    </>
  );
});
export default InvPropsContentGoals;
