export const ClientTabs = [
  {
    mainTab: 'Build',
    data: [
      {
        tabIndex: 0,
        tabName: 'FAMILY',
        subtabs: [
          'PROFILE',
          'INCOME',
          'SAVINGS',
          'EXPENSES',
          'KIDS',
          'VEHICLES',
          'VACATIONS',
        ],
      },
      {
        tabName: 'ASSETS',
        subtabs: [
          'MARKETS',
          'PRIMARY HOME',
          'SECONDARY HOMES',
          'INVESTMENT PROPERTIES',
        ],
      },
      {
        tabName: 'DEBT',
        subtabs: ['CREDIT CARDS', 'STUDENT LOANS', 'OTHER LOANS'],
      },
      {
        tabName: 'INSURANCE',
        subtabs: [
          'TERM LIFE',
          'WHOLE LIFE',
          'LONG TERM CARE',
          'UMBRELLA',
          'PROPERTY',
          'AUTO',
          'HEALTH',
        ],
      },
      {
        tabName: 'ESTATE',
        subtabs: ['ROTH', 'HEIRS'],
      },
      {
        tabName: 'SETTINGS',
        subtabs: ['MODEL', 'CASH FLOWS', 'EMERGENCY FUNDS', 'TAXES', 'ALERTS'],
      },
      // Add more objects as needed
    ],
  },
  // Add more mainTabs as needed
];

export const infoCoreValidatableFields = [
  {
    apiFieldName: 'pensionContribYrs',
    errorMessage: 'Cannot be greater than retirement Age - Current Age',
  },
  {
    apiFieldName: 'socialSecAge',
    errorMessage: 'Social Security Age should be minimum 62',
  },
];
