import { DATATYPES } from '../common';
import { OUTPUT_FIELD_TYPE } from '../retirementPlan';

export const rothVsTraditionalConstants = [
  {
    label: 'Trad IRA now',
    name: 'TradIRAnow',
    fieldType: OUTPUT_FIELD_TYPE,
    isDollarSignRequired: true,
  },
  {
    label: 'Trad 401(k) now',
    name: 'Trad401(k)now',
    fieldType: OUTPUT_FIELD_TYPE,
    isDollarSignRequired: true,
  },
  {
    label: 'Trad 401(k) ret',
    name: 'Trad401(k)andIRAatRetirement',
    fieldType: OUTPUT_FIELD_TYPE,
    isDollarSignRequired: true,
  },
  {},
  {},
  {},

  {
    label: 'Trad ira to roth now',
    name: 'tradIraToRothPercent',
    colSize: 3,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Trad 401k to roth now',
    name: 'trad401kToRothPercentNow',
    colSize: 4,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {
    label: 'Trad 401k to roth @ retmt',
    name: 'trad401kToRothPercentAtRet',
    colSize: 4,
    type: 'number',
    dataType: DATATYPES.PERCENTAGE,
    errorText: '% field should range from 0 to 100',
  },
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
];

export const rothConversionsForOutputData = [
  'tradIraToRothPercent',
  'trad401kToRothPercentNow',
  'trad401kToRothPercentAtRet',
];
