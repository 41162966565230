import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  topScrollPaper: {
    justifyContent: 'center',
  },
  topScrollPaperOther: {
    justifyContent: 'right',
  },
});
const ConfirmModal = (props) => {
  const classes = useStyles();
  const {
    content,
    open,
    setOpen,
    onConfirm, 
    dialogId,  
    from,
    okLabel = 'OK',
    size = 'sm',
    isOkButtonDisabled = false,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="dialog-title"
      scroll="paper"
      maxWidth={size}
      classes={{
        scrollPaper: from
          ? classes.topScrollPaper
          : classes.topScrollPaperOther,
      }}
      PaperProps={{}}
    >
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          style={{
            //background: 'grey',
            background: '#225ea8',
            borderRadius: '5px',
            textAlign: 'center',
            color: '#ffffff',
            fontSize: '12px',
            fontWeight: 400,
          }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          style={{
            background: isOkButtonDisabled ? 'grey' : '#225ea8',
            borderRadius: '5px',
            textAlign: 'center',
            color: '#ffffff',
            fontSize: '12px',
            fontWeight: 400,
          }}
          onClick={() => {
            onConfirm();
          }}
          disabled={isOkButtonDisabled} 
          id={dialogId}
        >
          {okLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
