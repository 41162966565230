import React, { useEffect, useState } from 'react';
import {
  renderMembersInputRow,
  renderMemberLabel,
  transformBackToAPIType,
  transformRothChoicesBackToUIType,
  convertPropertyValue,
} from '../helpers/BuildHelperComponentV3.js';
import { isEqual } from 'lodash';
import { rothConversionsForOutputData } from '../constants/Retirement/rothVsTraditionalConstants.js';

export const BuildEstatePlanningRothComponent = (props) => {
  const {
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    isFirstPersonRetired,
    isSecondPersonRetired,
    membersCount,
    outputResponseData,
  } = props;

  const state = {
    isFirstPersonRetired,
    isSecondPersonRetired,
    membersCount,
    memberOne: false,
    memberTwo: false,
  };
  const { moduleEstate } = localPlanDetailsByPlanId;
  const {
    moduleEstate: { rothChoices = {} } = {},
    moduleFamily: { infoCore = {} } = {},
  } = localPlanDetailsByPlanId;

  const [rothChoicesDetails, setRothChoicesDetails] = useState(rothChoices);

  const withDrawRothFirst = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  useEffect(() => {
    if (rothChoices && Object.keys(rothChoices).length > 0) {
      let tempRothChoices = transformRothChoicesBackToUIType(rothChoices);

      if (!isEqual(tempRothChoices, rothChoices)) {
        setRothChoicesDetails(tempRothChoices);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localPlanDetailsByPlanId, rothChoices]);

  useEffect(() => {
    let updatedRothChoiceDetails = { ...rothChoicesDetails };
    if (outputResponseData && outputResponseData.length > 0) {
      let rothChoiceData = [];
      const estateTabData = outputResponseData.find(
        (response) => response.tabName.toLowerCase() === 'estate'
      );
      if (estateTabData && estateTabData.subtabs) {
        rothChoiceData = estateTabData.subtabs.find(
          (tabData) => tabData.subtabName.toLowerCase() === 'roth'
        );
        if (rothChoiceData) {
          const { aux = {} } = rothChoiceData.build;
          const firstMemberNameDetails = aux
            .find((detail) => detail.name === infoCore.basicsFirst.name)
            .elements.reduce((acc, curr, index) => {
              const keyName = rothConversionsForOutputData[index];

              return {
                ...acc,
                [keyName]: convertPropertyValue(curr.value, 'percentage'),
              };
            }, {});
          updatedRothChoiceDetails = {
            ...updatedRothChoiceDetails,
            first: {
              ...firstMemberNameDetails,
            },
          };
          if (membersCount > 1) {
            const secondMemberNameDetails = aux
              .find((detail) => detail.name === infoCore.basicsSecond.name)
              .elements.reduce((acc, curr, index) => {
                const keyName = rothConversionsForOutputData[index];

                return {
                  ...acc,
                  [keyName]: convertPropertyValue(curr.value, 'percentage'),
                };
              }, {});
            updatedRothChoiceDetails = {
              ...updatedRothChoiceDetails,
              second: {
                ...secondMemberNameDetails,
              },
            };
          }
        }
      }

      const updatedPlanDetailsByPlanId = {
        ...localPlanDetailsByPlanId,
        moduleEstate: {
          ...moduleEstate,
          rothChoices: {
            ...updatedRothChoiceDetails,
          },
        },
      };
      updatedRothChoiceDetails = transformRothChoicesBackToUIType(
        updatedRothChoiceDetails
      );
      setRothChoicesDetails(updatedRothChoiceDetails);

      setLocalPlanDetailsByPlanId(
        transformBackToAPIType(updatedPlanDetailsByPlanId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputResponseData]);
  return (
    <>
      <div className="insured_main_outer">
        {renderMemberLabel(
          infoCore,
          'name',
          'basicsFirst',
          'basicsSecond',
          state
        )}
      </div>
      <hr style={{ marginTop: '0px' }} />
      <div className="insured_main_outer">
        {renderMembersInputRow(
          'text',
          'tlOneTradIraToRothPercent',
          'tradIraToRothPercent',
          'tlTwoTradIraToRothPercent',
          'What % of Traditional IRA would you like to consider converting to ROTH now',
          'percentage',
          rothChoicesDetails,
          'rothChoices',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          'first',
          'second',
          state,
          setRothChoicesDetails
        )}
        {renderMembersInputRow(
          'text',
          'tlOneTrad401kToRothPercentNow',
          'trad401kToRothPercentNow',
          'tlTwoTrad401kToRothPercentNow',
          'What % of Traditional 401k would you like to consider converting to ROTH now',
          'percentage',
          rothChoicesDetails,
          'rothChoices',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          'first',
          'second',
          state,
          setRothChoicesDetails
        )}
        {renderMembersInputRow(
          'text',
          'tlOneTrad401kToRothPercentAtRet',
          'trad401kToRothPercentAtRet',
          'tlTwoTrad401kToRothPercentAtRet',
          'What % of Traditional 401k would you like to consider converting to ROTH at retirement',
          'percentage',
          rothChoicesDetails,
          'rothChoices',
          localPlanDetailsByPlanId,
          setLocalPlanDetailsByPlanId,
          'moduleEstate',
          'first',
          'second',
          state,
          setRothChoicesDetails
        )}
      </div>
    </>
  );
};

export default BuildEstatePlanningRothComponent;
