import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { Grid } from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BarChartIcon from '@mui/icons-material/BarChart';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  getPlansByClientIdAction,
  setSelectedChartAction,
  setSelectedTableAction,
  setSelectedDataAction,
} from '../redux/actions/plansAction';
import {
  saveAndUpdatePlanDetailsByPlanId,
  simulatePlanDetailsByPlanIdSuccessAction,
  simulatePlanDetailsByPlanId,
} from '../redux/actions/plannerDetailsAction';
import { selectedClientIdSelector } from '../selectors/clientsInfoSelector';
import { getLoggedInUserDetails } from '../selectors/loginUserSelector';
import {
  outputResponseLoadingSelector,
  outputResponseChartsByPlanIdSelector,
  outputResponseTablesByPlanIdSelector,
  outputResponseDataByPlanIdSelector,
  outputResponseInsightsSelector,
  outputResponseNotesByPlanIdSelector
} from '../selectors/outputResponseDetailsSelector';
import {
  planDetailsByPlanIdSelector,
  plannerDetailsLoadingSelector,
  plannerDetailsSimulateStatus,
  selectedPlanIdForClientIdSelector,
  selectedChartSelector,
  plannerDetailsErrorStatus,
  simulatePlanDetailsLoadingSelector,
} from '../selectors/plannerDetailsSelector';
import history from '../history';
import HorizonDetails from './shared/HorizonDetails';
import TabsComponent from './shared/TabsComponent';
import NewChartsContainer from './NewChartsContainer';
import CustomChart from './shared/CustomChartComponent';
import CustomTable from './shared/CustomTableComponent';
import CustomData from './shared/CustomDataComponent';
import CustomNotes from './shared/CustomNotesComponent';
import { generateTableHTML } from '../utils/renderHtmlUtil';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { logEvent } from '../analytics';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
class Insights extends Component {
  state = {
    showLeftNav: false,
    showRightNav: true,
    selectedTab: 0,
    expanded: false,
    selectedTabIndex: 1,
    monteCarloRuns: 0,
    parentTabValue: 0,
    subTabValue: 0,
    tabInfo: [],
    renderType: 'Custom',
    selectedFaqIndex: 0,
    currentIndex: 0,
  };

  // Mobile scroll
  carouselContainerRef = React.createRef();

  showNextItem = () => {
    this.setState({
      showLeftNav: true,
      showRightNav: false,
    });
    const { currentIndex } = this.state;
    if (currentIndex < 1) {
      this.setState({ currentIndex: currentIndex + 1 }, () => {
        this.scrollToIndex(this.state.currentIndex);
      });
    }
  };

  showPreviousItem = () => {
    this.setState({
      showRightNav: true,
      showLeftNav: false,
    });
    const { currentIndex } = this.state;
    if (currentIndex > 0) {
      this.setState({ currentIndex: currentIndex - 1 }, () => {
        this.scrollToIndex(this.state.currentIndex);
      });
    }
  };

  scrollToIndex = (index) => {
    const carouselContainer = this.carouselContainerRef.current;
    if (carouselContainer) {
      carouselContainer.scrollTo({
        left: carouselContainer.offsetWidth * index,
        behavior: 'smooth',
      });
    }
  };

  handleSimulateOnQuestion = (monteCarloRuns) => {
    const { triggerSimulatePlanDetailsByPlanId, planDetailsByPlanId } =
      this.props;
    this.setState({ isHorSimulateClicked: false }, () => {
      let tempPlanDetailsByPlanId = { ...planDetailsByPlanId };
      const { moduleSettings: { model = {} } = {}, moduleSettings = {} } =
        tempPlanDetailsByPlanId;
      let tempModel = {};
      tempModel = { ...model, runCode: monteCarloRuns };
      tempPlanDetailsByPlanId = {
        ...tempPlanDetailsByPlanId,
        moduleSettings: {
          ...moduleSettings,
          model: { ...tempModel },
        },
      };
      triggerSimulatePlanDetailsByPlanId({
        displayTermChanged: false,
        chrono: {},
        plannerFormReq: tempPlanDetailsByPlanId,
        version: 'v2',
        from: 'insights',
      });
    });
    logEvent('User Interaction', 'Insights Question Click', 'Simulate Plan');
  };

  handleChange = (index, insight) => (event, isExpanded) => {
    const { selectedTabIndex, monteCarloRuns } = this.state;
    const updatedMonteCarloRuns = `${2}${selectedTabIndex}${index}`;
    if (isExpanded && updatedMonteCarloRuns !== monteCarloRuns) {
      this.handleSimulateOnQuestion(parseInt(updatedMonteCarloRuns));
    }
    this.setState({
      expanded: isExpanded ? index : false,
      selectedFaqIndex: index,
      monteCarloRuns: updatedMonteCarloRuns,
      renderType: 'Custom',
    });
  };

  handleTabChange = (event, value) => {
    const tabIndex = value + 1;
    this.setState({
      selectedTab: value,
      selectedTabIndex: tabIndex,
      expanded: false,
      // monteCarloRuns: `${2}${tabIndex}0`,
      renderType: 'Custom',
    });
  };

  componentDidMount() {
    const { insights } = this.props;
    const { selectedClientId, selectedPlan, isV2, planDetailsByPlanId } =
      this.props;
    const { moduleLifeStyle } = planDetailsByPlanId;
    if (!selectedClientId && !selectedPlan) {
      history.push({ pathname: '/home' });
    } else if (
      insights &&
      Object.keys(insights).length > 0 &&
      isV2 &&
      !moduleLifeStyle &&
      selectedClientId &&
      selectedClientId !== '' &&
      selectedPlan &&
      selectedPlan !== ''
    ) {
      const { insightsTabs = [] } = insights;
      // if (insightsTabs.length > 0) {
      //   this.handleSimulateOnQuestion(210); // on load set 210
      // }
      this.setState({
        insightsTabs,
        // expanded: 0,
        // selectedFaqIndex: 0,
        // monteCarloRuns: 210,
      });
    }
  }

  getChartsMenu = () => {
    const { charts, triggerSetSelectedChart } = this.props;
    const { parentTabValue, subTabValue } = this.state;
    let chartInfo = [];
    if (charts && charts.length > 0) {
      chartInfo = charts.map((c) => {
        return {
          tabName: c.tabName,
          subMenu: c.subtabs,
        };
      });
      const selectedChart =
        (chartInfo[parentTabValue] &&
          chartInfo[parentTabValue].subMenu &&
          chartInfo[parentTabValue].subMenu.length > 0 &&
          chartInfo[parentTabValue].subMenu[subTabValue].subtabName) ||
        '';
      triggerSetSelectedChart(selectedChart);
    }

    this.setState({
      tabInfo: chartInfo,
    });
  };

  getTableMenu = () => {
    const { tables, triggerSetSelectedTable } = this.props;
    const { parentTabValue, subTabValue } = this.state;
    let tableInfo = [];
    if (tables && tables.length > 0) {
      tableInfo = tables.map((c) => {
        return {
          tabName: c.tabName,
          subMenu: c.subtabs,
        };
      });
      const selectedTable =
        (tableInfo[parentTabValue] &&
          tableInfo[parentTabValue].subMenu &&
          tableInfo[parentTabValue].subMenu.length > 0 &&
          tableInfo[parentTabValue].subMenu[subTabValue].subtabName) ||
        '';
      triggerSetSelectedTable(selectedTable);
    }
    this.setState({
      tabInfo: tableInfo,
    });
  };

  getDataMenu = () => {
    const { data, triggerSetSelectedData } = this.props;
    const { parentTabValue, subTabValue } = this.state;
    let dataInfo = [];
    if (data && data.length > 0) {
      dataInfo = data.map((c) => {
        return {
          tabName: c.tabName,
          subMenu: c.subtabs,
        };
      });
      const selectedData =
        (dataInfo[parentTabValue] &&
          dataInfo[parentTabValue].subMenu &&
          dataInfo[parentTabValue].subMenu.length > 0 &&
          dataInfo[parentTabValue].subMenu[subTabValue].subtabName) ||
        '';
      triggerSetSelectedData(selectedData);
    }
    this.setState({
      tabInfo: dataInfo,
    });
  };

  loadDefaultValues = () => {
    const { renderType } = this.state;
    switch (renderType) {
      case 'Chart':
        this.getChartsMenu();
        break;
      case 'Table':
        this.getTableMenu();
        break;
      case 'Data':
        this.getDataMenu();
        break;
      default:
        break;
    }
  };

  destructureMonteCarloNumber = (number = '') => {
    const [insightsIndex = '', selectedTabIndex = '', selectedFaqIndex = ''] =
      number.toString().split('').map(Number);
    return { selectedTabIndex, selectedFaqIndex };
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      planDetailsError,
      planDetailsByPlanId,
      charts,
      tables,
      data,
      insights,
    } = this.props;
    const {
      insightsTabs: prevInsightsTabs,
      renderType: prevRenderType,
      selectedTab: prevSelectedTab,
      monteCarloRuns: prevMonteCarloRuns,
    } = prevState;
    const { insightsTabs, renderType, selectedTab, monteCarloRuns } =
      this.state;
    const {
      charts: prevCharts,
      tables: prevTables,
      data: prevData,
      insights: prevInsights,
    } = prevProps;
    if (
      planDetailsByPlanId &&
      Object.keys(planDetailsByPlanId).length > 0 &&
      (!isEqual(prevRenderType, renderType) ||
        (charts && charts.length > 0 && !isEqual(charts, prevCharts)) ||
        (data && data.length > 0 && !isEqual(data, prevData)) ||
        (tables && tables.length > 0 && !isEqual(tables, prevTables)))
    ) {
      this.loadDefaultValues();
    }
    if (!isEqual(insights, prevInsights)) {
      const { insightsTabs = [] } = insights;
      this.setState({
        insightsTabs,
      });
    }
    if (
      selectedTab !== prevSelectedTab &&
      monteCarloRuns &&
      Number(monteCarloRuns) > 200 &&
      monteCarloRuns === prevMonteCarloRuns
    ) {
      const destructuredMonteCarloObject =
        this.destructureMonteCarloNumber(monteCarloRuns);
      const { selectedTabIndex, selectedFaqIndex } =
        destructuredMonteCarloObject;
      //this.handleSimulateOnQuestion(monteCarloRuns); // on load value
      this.setState({
        expanded:
          selectedTabIndex - 1 === selectedTab ? selectedFaqIndex : false,
        selectedFaqIndex,
        renderType: 'Custom',
      });
    }
    if (planDetailsError && monteCarloRuns > 0) {
      this.setState({
        expanded: false,
        monteCarloRuns: 0,
        selectedFaqIndex: undefined,
        renderType: 'Custom',
      });
    }
  }

  transformValue = (value) => {
    if (value) {
      let numVal = value;
      if (isNaN(numVal) && numVal.includes('$')) {
        numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
      }
      const numFormat = Intl.NumberFormat('en-US');
      const newNumformat = numFormat.format(numVal);
      return `$${newNumformat}`;
    }
  };

  renderSelectedTabContainer = () => {
    const { expanded, insightsTabs, selectedTab } = this.state;
    const insights =
      insightsTabs && insightsTabs.length > 0
        ? insightsTabs[selectedTab].rows &&
        insightsTabs[selectedTab].rows.filter((r) => r.question)
        : [];
    return (
      <>
        <Grid
          container
          className="faq-container chrono-container"
          style={{ border: 'none' }}
          spacing={1}
        >
          <Grid item xs={12}>
            {insights &&
              insights.map((insight, index) => (
                <div className="faq-content-items">
                  <Accordion
                    expanded={expanded === index}
                    onChange={this.handleChange(index, insight)}
                    className="faq-item"
                    sx={{ background: 'rgba(255, 255, 255, 0.3)' }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <span className="faq-question">
                        Q: {insight.question}
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <span className="faq-answer">
                        {insight.answer
                          ? insight.answer
                            .split('\n \n')
                            .map((m, i) => (
                              <p key={i}>{i === 0 ? 'A: ' + m : m}</p>
                            ))
                          : 'A: '}
                      </span>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
          </Grid>
        </Grid>
        {!expanded && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
                <Alert severity="info">
                  <p>Toggle the questions above to simulate!</p>
                </Alert>
              </Stack>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  renderChartContainer = () => {
    const { charts, selectedChart, triggerPushPinCharts } = this.props;
    const { pushPinLabel, parentTabValue, subTabValue, tabInfo } = this.state;
    if (charts && charts.length > 0) {
      const chartsData =
        (tabInfo[parentTabValue] &&
          tabInfo[parentTabValue].subMenu &&
          tabInfo[parentTabValue].subMenu.length > 0 &&
          tabInfo[parentTabValue].subMenu[subTabValue]) ||
        {};
      return (
        <div className="client_chart_main_div Chart">
          <div className="client_chart_inner">
            <NewChartsContainer
              selectedChart={selectedChart}
              chartsData={chartsData}
              triggerPushPinCharts={triggerPushPinCharts}
              isDashboard={false}
              pushPinLabel={pushPinLabel}
            />
          </div>
          <div className="client_chart_inner">
            <NewChartsContainer
              isAux={true}
              selectedChart={selectedChart}
              chartsData={chartsData}
              triggerPushPinCharts={triggerPushPinCharts}
              isDashboard={false}
              pushPinLabel={pushPinLabel}
            />
          </div>
        </div>
      );
    }
  };

  deriveTableAuxAndMain = (tablesData) => {
    const { subtabType } = tablesData || {};
    const { [subtabType]: { aux = [], main = [] } = {} } = tablesData || {};
    const auxAndMain = { aux, main };
    return auxAndMain;
  };

  renderTableContainer = () => {
    const { tables, selectedTable } = this.props;
    const { parentTabValue, subTabValue, tabInfo } = this.state;
    if (tables && tables.length > 0) {
      const parentTabName =
        (tabInfo[parentTabValue] && tabInfo[parentTabValue].tabName) || '';
      const tablesData =
        (tabInfo[parentTabValue] &&
          tabInfo[parentTabValue].subMenu &&
          tabInfo[parentTabValue].subMenu.length > 0 &&
          tabInfo[parentTabValue].subMenu[subTabValue]) ||
        {};
      const auxAndMain = this.deriveTableAuxAndMain(tablesData);
      const { aux = [], main = [] } = auxAndMain;
      if ((main && main.length > 0) || (aux && aux.length > 0)) {
        return (
          <div className="client_chart_main_div Table">
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {main.map((val, index) => {
                  return generateTableHTML(val, parentTabName);
                })}
              </div>
            </div>
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {aux.map((val, index) => {
                  return generateTableHTML(val, parentTabName);
                })}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  renderDataContainer = () => {
    const { data, selectedData } = this.props;
    const { parentTabValue, subTabValue, tabInfo } = this.state;
    if (data && data.length > 0) {
      const dataInfo =
        (tabInfo[parentTabValue] &&
          tabInfo[parentTabValue].subMenu &&
          tabInfo[parentTabValue].subMenu.length > 0 &&
          tabInfo[parentTabValue].subMenu[subTabValue]) ||
        {};
      const { subtabName, subtabType, build, input } = dataInfo || {};
      let mainData = [];
      let auxData = [];
      if (subtabType === 'input' && input) {
        const {
          main: { heading, rows = [] },
          aux,
        } = input;
        if (aux && aux.rows && aux.rows.length > 0) {
          auxData = [
            {
              name: aux.heading,
              elements: aux.rows.filter((a) => a && a.label && a.value),
            },
          ];
        }
        mainData =
          rows && rows.length > 0
            ? [
              {
                name: heading,
                elements: rows.filter((r) => r && r.label && r.value),
              },
            ]
            : [];
      } else if (subtabType === 'build' && build) {
        const { main = [], aux = [] } = build;
        const tempMainData =
          main &&
          main.filter(
            (m) => m.elements && m.elements.find((v) => v.label && v.value)
          );
        mainData =
          tempMainData.length > 0
            ? tempMainData.map((m, i) => {
              return {
                name: m.name || 'Heading',
                elements: m.elements.filter((v) => v && v.label && v.value),
              };
            })
            : [];
        const tempAuxData =
          (aux &&
            aux.filter(
              (m) =>
                m && m.elements && m.elements.find((v) => v.label && v.value)
            )) ||
          [];
        auxData =
          tempAuxData.length > 0
            ? aux.map((m, i) => {
              return {
                name: m.name || 'Heading',
                elements: m.elements,
              };
            })
            : [];
      }
      if (mainData.length > 0 || auxData.length > 0) {
        return (
          <div className="client_chart_main_div">
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {mainData.map((val, index) => {
                  return (
                    <table
                      className={
                        mainData.length > 2
                          ? 'small-width-grid'
                          : 'full-width-grid'
                      }
                    >
                      {val && val.name && (
                        <thead>
                          <tr>
                            <th className="left">{''}</th>
                            <th className="center-data">{val?.name}</th>
                            <th className="left">{''}</th>
                          </tr>
                        </thead>
                      )}
                      {val.elements &&
                        val.elements.map((ele) => {
                          return (
                            <tbody>
                              <tr>
                                <td className="data-label">{ele.label}</td>
                                <td></td>
                                <td className="data-value">{ele.value}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  );
                })}
              </div>
            </div>
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {auxData.map((val, index) => {
                  return (
                    <table
                      className={
                        auxData.length > 2
                          ? 'small-width-grid'
                          : 'full-width-grid'
                      }
                    >
                      {val && val.name && (
                        <thead>
                          <tr>
                            <th className="left">{''}</th>
                            <th className="center-data">{val?.name}</th>
                            <th className="left">{''}</th>
                          </tr>
                        </thead>
                      )}
                      {val.elements &&
                        val.elements.map((ele) => {
                          return (
                            <tbody>
                              <tr>
                                <td className="data-label">{ele.label}</td>
                                <td></td>
                                <td className="data-value">{ele.value}</td>
                              </tr>
                            </tbody>
                          );
                        })}
                    </table>
                  );
                })}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  renderCustomChart = (renderWhatData) => {
    const { charts } = this.props;
    return <CustomChart charts={charts} renderWhatData={renderWhatData} />;
  };

  renderCustomTable = (renderWhatData) => {
    const { tables } = this.props;
    return <CustomTable tables={tables} renderWhatData={renderWhatData} />;
  };

  renderCustomData = (renderWhatData) => {
    const { data } = this.props;
    return <CustomData data={data} renderWhatData={renderWhatData} />;
  };

  renderCustomNotes = (renderWhatData) => {
    const { notes } = this.props;
    return <CustomNotes notes={notes} renderWhatData={renderWhatData} />;
  }

  renderCustomContentContainer = ({ renderTabType, data }) => {
    switch (renderTabType && renderTabType.toLowerCase()) {
      case 'chart':
        return this.renderCustomChart(data);
      case 'table':
        return this.renderCustomTable(data);
      case 'data':
        return this.renderCustomData(data);
      case 'notes':
        return this.renderCustomNotes(data);
      default:
        break;
    }
  };

  deriveAuxMainAndProfile = () => {
    const { insightsTabs } = this.state;
    const { selectedTab, selectedFaqIndex } = this.state;
    let aux = {};
    let main = {};
    let profile = {};
    if (insightsTabs && insightsTabs.length > 0) {
      const { rows = [] } = insightsTabs[selectedTab];
      const { renderTag = {} } = rows[selectedFaqIndex] || {};
      aux = (renderTag && renderTag.aux) || {};
      main = (renderTag && renderTag.main) || {};
      profile = (renderTag && renderTag.profile) || {};
    }
    const auxMainAndProfile = { aux, main, profile };
    return auxMainAndProfile;
  };

  renderCustomContainer = () => {
    const auxMainAndProfile = this.deriveAuxMainAndProfile();
    const { aux = {}, main = {}, profile = {} } = auxMainAndProfile;
    const { tabType: auxTabType = '' } = aux;
    const { tabType: mainTabType = '' } = main;
    const { tabType: profileTabType = '' } = profile;
    return (
      <div className="client_chart_main_div">
        {['profile', 'main', 'aux'].map((p) => {
          let obj = {};
          if (p === 'main') {
            obj = { renderTabType: mainTabType, data: main };
          } else if (p === 'aux') {
            obj = { renderTabType: auxTabType, data: aux };
          } else if (p === 'profile') {
            obj = { renderTabType: profileTabType, data: profile };
          }
          return (
            <div className="client_chart_inner">
              {this.renderCustomContentContainer(obj)}
            </div>
          );
        })}
      </div>
    );
  };

  renderContentContainer = () => {
    const { renderType } = this.state;
    switch (renderType) {
      case 'Chart':
        return this.renderChartContainer();
      case 'Table':
        return this.renderTableContainer();
      case 'Data':
        return this.renderDataContainer();
      case 'Custom':
        return this.renderCustomContainer();
      default:
        break;
    }
  };

  updateRenderType = (e, renderType) => {
    this.setState({
      renderType,
      parentTabValue: 0,
      subTabValue: 0,
      tabInfo: [],
    });
  };

  getRunCode = (isMC) => {
    const { solutionsParentTab } = this.state;
    let runCode = 0;
    switch (solutionsParentTab) {
      case 0:
        runCode = isMC ? 401 : 400;
        break;
      case 1:
        runCode = isMC ? 501 : 500;
        break;
      case 2:
        runCode = isMC ? 601 : 600;
        break;
      case 3:
        runCode = isMC ? 701 : 700;
        break;
      case 4:
        runCode = isMC ? 801 : 800;
        break;
      default:
        runCode = isMC ? 100 : 0;
        break;
    }
    return runCode;
  };

  handleSimulate = (isMC) => {
    const {
      planDetailsByPlanId,
      triggerSimulatePlanDetailsByPlanId,
      selectedHorizonTerm,
    } = this.props;
    const { localPlanDetailsByPlanId, solutionsParentTab } = this.state;
    let tempPlanDetailsByPlanId =
      Object.keys(localPlanDetailsByPlanId).length > 0
        ? { ...localPlanDetailsByPlanId }
        : { ...planDetailsByPlanId };
    const {
      moduleSettings: { model = {} } = {},
      moduleSettings = {},
      moduleInvestments: { markets = {} } = {},
    } = tempPlanDetailsByPlanId;
    let tempModel = {};
    const runCode = this.getRunCode(isMC);
    tempModel = { ...model, runCode: runCode };
    tempPlanDetailsByPlanId = {
      ...tempPlanDetailsByPlanId,
      moduleSettings: {
        ...moduleSettings,
        model: { ...tempModel },
      },
    };
    triggerSimulatePlanDetailsByPlanId({
      displayTermChanged: false,
      chrono: {},
      plannerFormReq: tempPlanDetailsByPlanId,
      version: 'v2',
    });
  };

  handleSave = () => {
    const { planDetailsByPlanId, triggerSaveAndUpdatePlanDetailsbyPlanId } =
      this.props;
    const version = 'v2';
    triggerSaveAndUpdatePlanDetailsbyPlanId({
      version,
      planDetailsByPlanId,
    });
  };

  handleSubTabChange = (event, value) => {
    const {
      triggerSetSelectedChart,
      triggerSetSelectedTable,
      triggerSetSelectedData,
    } = this.props;
    const { parentTabValue, tabInfo, renderType } = this.state;
    const selectedItem =
      (tabInfo[parentTabValue] &&
        tabInfo[parentTabValue].subMenu &&
        tabInfo[parentTabValue].subMenu.length > 0 &&
        tabInfo[parentTabValue].subMenu[value].subtabName) ||
      '';
    switch (renderType) {
      case 'Chart':
        triggerSetSelectedChart(selectedItem);
        break;
      case 'Table':
        triggerSetSelectedTable(selectedItem);
        break;
      case 'Data':
        triggerSetSelectedData(selectedItem);
        break;
      default:
        break;
    }
    this.setState({
      subTabValue: value,
      isEditable: false,
    });
  };

  renderSubMenuContainer = () => {
    const { subTabValue, parentTabValue, tabInfo } = this.state;
    const subMenus = tabInfo[parentTabValue]
      ? tabInfo[parentTabValue].subMenu
      : [];
    return (
      <div className="chrono-sub-tab">
        <Tabs
          value={subTabValue}
          onChange={this.handleSubTabChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          {subMenus &&
            subMenus.map((item, index) => (
              <Tab
                label={item.subtabName}
                {...a11yProps(index)}
                sx={{ padding: '10px !important' }}
              />
            ))}
        </Tabs>
      </div>
    );
  };

  handleParentTabChange = (event, value) => {
    this.setState({
      parentTabValue: value,
      subTabValue: 0,
    });
  };

  renderInsightsContainer = () => {
    const {
      selectedTab,
      insightsTabs,
      parentTabValue,
      renderType,
      tabInfo,
      selectedFaqIndex,
    } = this.state;
    const { showLeftNav, showRightNav } = this.state;
    return (
      <div className="client_main product_main outer">
        <div className="carousel-container" ref={this.carouselContainerRef}>
          <div key="left" className="carousel-itemm">
            <div className="inner">
              <div className="client_left product_left solution_tab_content insight_action_height_full">
                <div className="chrono-tabs-wrapper">
                  <div className="chrono-parent-tab">
                    <Tabs
                      value={selectedTab}
                      onChange={this.handleTabChange}
                      aria-label="basic tabs example"
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      TabIndicatorProps={{
                        style: {
                          display: 'none',
                        },
                      }}
                    >
                      {insightsTabs &&
                        insightsTabs.map((item, index) => (
                          <Tab
                            iconPosition="start"
                            label={item.heading}
                            {...a11yProps(index)}
                            sx={{ padding: '10px !important' }}
                          />
                        ))}
                    </Tabs>
                  </div>
                </div>
                <div className="product_left_content">
                  {this.renderSelectedTabContainer(insightsTabs)}
                </div>
              </div>
            </div>
          </div>

          <div className="divider_line"></div>

          <div key="right" className="carousel-itemm">
            <div className="inner">
              <div className="client_right solution_tab_content">
                <div className="client_main_right">
                  <div className="client_main_right_left">
                    <TabsComponent
                      renderType={renderType}
                      updateRenderType={this.updateRenderType}
                    />

                    <div className="chrono-tabs-wrapper">
                      {renderType !== 'Custom' ? (
                        <div className="chrono-parent-tab">
                          <Tabs
                            value={parentTabValue}
                            onChange={this.handleParentTabChange}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            TabIndicatorProps={{
                              style: {
                                display: 'none',
                              },
                            }}
                          >
                            {tabInfo &&
                              tabInfo.length > 0 &&
                              tabInfo.map((item, index) => (
                                <Tab
                                  iconPosition="start"
                                  label={item.tabName}
                                  {...a11yProps(index)}
                                  sx={{ padding: '10px !important' }}
                                />
                              ))}
                          </Tabs>
                          {tabInfo &&
                            tabInfo.length > 0 &&
                            this.renderSubMenuContainer()}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div
                      className={
                        renderType !== 'Custom'
                          ? 'result_scroll_div'
                          : 'result_scroll_div result_scroll_div_custom'
                      }
                    >
                      <HorizonDetails
                        isV1={false}
                        isV2={true}
                        renderType={renderType}
                        renderMainType={'insights'}
                        leftParentTabValue={selectedTab}
                        leftSubTabValue={selectedFaqIndex}
                      />
                      {this.renderContentContainer()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Left and Right Arrows */}
        {showLeftNav && (
          <div
            className="arrow left"
            id="prevButton"
            onClick={this.showPreviousItem}
          >
            <DescriptionIcon />
          </div>
        )}

        {showRightNav && (
          <div
            className="arrow right"
            id="nextButton"
            onClick={this.showNextItem}
          >
            <BarChartIcon />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { simulatePlanDetailsLoading } = this.props;
    return (
      <>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={simulatePlanDetailsLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="main-content-wrapper">
          <div className="container-fluid main-content-wrapper-inner">
            <div className="row">{this.renderInsightsContainer()}</div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    insights: outputResponseInsightsSelector(state) || {},
    userDetails: getLoggedInUserDetails(state),
    clients: state.clientInfoReducer.clients || [],
    planDetailsByPlanId: planDetailsByPlanIdSelector(state),
    plannerDetailsLoading: plannerDetailsLoadingSelector(state),
    isOutputResponseLoading: outputResponseLoadingSelector(state),
    planDetailsSimulated: plannerDetailsSimulateStatus(state),
    selectedClientId: selectedClientIdSelector(state),
    selectedPlan: selectedPlanIdForClientIdSelector(state),
    charts: outputResponseChartsByPlanIdSelector(state) || [],
    tables: outputResponseTablesByPlanIdSelector(state) || [],
    data: outputResponseDataByPlanIdSelector(state) || [],
    notes: outputResponseNotesByPlanIdSelector(state) || [],
    selectedChart: selectedChartSelector(state) || '',
    planDetailsError: plannerDetailsErrorStatus(state),
    simulatePlanDetailsLoading: simulatePlanDetailsLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    triggerSaveAndUpdatePlanDetailsbyPlanId: (payload) =>
      dispatch(saveAndUpdatePlanDetailsByPlanId(payload)),
    triggerSetSelectedChart: (payload) =>
      dispatch(setSelectedChartAction(payload)),
    triggerSetSelectedTable: (payload) =>
      dispatch(setSelectedTableAction(payload)),
    triggerSetSelectedData: (payload) =>
      dispatch(setSelectedDataAction(payload)),
    triggerUpdatePlannerDetailsinRedux: (payload) =>
      dispatch(simulatePlanDetailsByPlanIdSuccessAction(payload)),
    triggerSimulatePlanDetailsByPlanId: (payload) =>
      dispatch(simulatePlanDetailsByPlanId(payload)),
    triggerGetPlansByClientId: (payload) =>
      dispatch(getPlansByClientIdAction(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Insights));
