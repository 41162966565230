import React, { useEffect, useState, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Controls from './controls/Controls';
import { isEqual } from 'lodash';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const radioOptions = [
  { title: 'Yes', id: true },
  { title: 'No', id: false },
];
const itemizedTypes = ['cars', 'food', 'healthAndFitness', 'insurancesNonProp', 'phonesAndEnt', 'restaurants', 'shoppingAndMisc', 'travel', 'utils']

const percentageFields = [
  'horizonRentalExpense',
  'horizonFactorPercent',
  'retirementFactor1',
  'retirementFactor2',
  'retirementFactor3',
  'retirementRentalExpense',
  'horPercentValue',
  'retPercentValue',
  'affordabilityFactor',
];

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fiCard: {
    height: 400,
  },
  media: {
    height: 50,
  },
});

const styles = {
  gridItem: {
    marginTop: '10px',
  },
  headerText: {
    fontSize: '14px',
    fontWeight: '600',
    padding: '5px',
  },
  subHeaderText: {
    fontSize: '12px',
    fontWeight: '600',
    padding: '5px',
  },
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
export default function SolutionsJourney(props) {
  const {
    updateExpenseDetailsSolutionsInRedux,
    storeValues,
    from, 
    setIsFormValidInParent
  } = props;
  const [currentStep, setCurrentStep] = useState('lifetime');
  const [isBlurCalled, setIsBlurCalled] = useState(false);
  const [formDetails, setFormDetails] = useState({});
  const [lifestyle, setLifestyle] = useState('');
  const [fieldValueChanged, setFieldValueChanged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const prevFormDetails = usePrevious(formDetails);
  const [itemizedType, setItemizedType] = useState('');
  const [newFieldValue, setNewFieldValue] = useState({ fieldValue: null, from: null, parent: null });
  // const prevIsLastStep = usePrevious(isLastStep);

  const transformValue = (val) => {
    let fieldValue = val;
    if (val !== null && val !== undefined && val !== '') {
      if (val.toString().includes('%')) {
        const percentFieldValue = val.split('%')[0];
        fieldValue =
          percentFieldValue !== '' && !isNaN(percentFieldValue)
            ? parseFloat(percentFieldValue) / 100
            : 0;
      }
      fieldValue = parseFloat(parseFloat(fieldValue) * 100).toFixed(2);
      return `${fieldValue}%`;
    }
    return `0.00%`;
  };
  const formatCurrency = (value = '') => {
    const numFormat = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2, // To always show at least two decimal places
      maximumFractionDigits: 2, // Limit the decimal places to two
    });
    //let newNumformat = numFormat.format(0); // Default formatting for zero
    let currencyFieldValue = '';
    if (value !== null && value !== undefined && value !== '') {
      // Remove dollar sign and commas if present
      if (value.toString().includes('$')) {
        currencyFieldValue = value.split('$')[1];
        currencyFieldValue = currencyFieldValue.replace(/,/g, '');
      } else {
        currencyFieldValue = value.toString().replace(/,/g, '');
      }
      // Check if the value is a valid number and is positive
      const parsedValue = parseFloat(currencyFieldValue);
      if (!isNaN(parsedValue) && parsedValue >= 0) {
        value = parsedValue.toString();
        //newNumformat = numFormat.format(parsedValue);
      } else {
        // If invalid or negative, set to default
        value = '0';
      }
    } else {
      value = '0'; // Default if empty
    }
    // Ensure value is always positive and formatted
    return numFormat.format(parseFloat(value));
  };

  const transformValues = useCallback((obj, type) => {
    const { rental, aggNonHousing, ...rest } = obj;
    const transformedRest = Object.keys(rest).reduce((acc, key) => {
      acc[key] = type ? formatCurrency(rest[key]) : transformValue(rest[key]); // Update each value
      return acc;
    }, {});
    const updatedObj = {
      rental, // Keep the original value for rental
      aggNonHousing, // Keep the original value for aggNonHousing
      ...transformedRest // Add the transformed fields
    };
    return updatedObj
  }, [])

  useEffect(() => {
    if (from) {
      setCurrentStep(from);
    }
  }, [from])

  useEffect(() => {
    if (isFormValid) {
      setIsFormValidInParent(isFormValid);
    }
  }, [isFormValid, setIsFormValidInParent])

  useEffect(() => {
    if (newFieldValue?.field && newFieldValue?.fieldValue !== null) {
      const isItemizedType = itemizedTypes.includes(newFieldValue.field);
      // Make sure newFieldValue is set before updating formDetails
      let newFormDetails = {
        ...formDetails
      }
      if (currentStep === 'discretionary' && isItemizedType && newFieldValue?.parent) {
        newFormDetails = {
          ...newFormDetails,
          [newFieldValue.parent]: {
            ...newFormDetails[newFieldValue.parent],
            [newFieldValue.field]: newFieldValue.fieldValue,
          },
        };
        // Update formDetails after itemizedType has been set
      }
      else {
        newFormDetails = {
          ...newFormDetails,
          [newFieldValue.field]: newFieldValue.fieldValue,
        };
      }
      if (!isEqual(formDetails, newFormDetails)) {
        setFormDetails(newFormDetails);
      }
    }
  }, [newFieldValue, itemizedType, currentStep, formDetails]);

  const updateStepName = useCallback((stepName, discretionaryStep) => {
    let tempStepName = {};
    switch (stepName) {
      case 'horizon':
        tempStepName = { label: 'Horizon', value: 'horizon' };
        break;
      case 'retirement':
        tempStepName = { label: 'Retirement', value: 'retirement' };
        break;
      case 'discretionary':
        tempStepName = { label: 'Discretionary', value: 'discretionary' };
        switch (discretionaryStep) {
          case 1:
            tempStepName = { label: 'Groceries & Staples', value: 'food' };
            break;
          case 2:
            tempStepName = {
              label: 'Phones & Entertainment',
              value: 'phonesAndEnt',
            };
            break;
          case 3:
            tempStepName = { label: 'Travel', value: 'travel' };
            break;
          case 4:
            tempStepName = { label: 'Restaurants', value: 'restaurants' };
            break;
          case 5:
            tempStepName = {
              label: 'Personal / Pet / Other care',
              value: 'insurancesNonProp',
            };
            break;
          case 6:
            tempStepName = {
              label: 'Health & Fitness (excluding what is included in Insurances)',
              value: 'healthAndFitness',
            };
            break;
          case 7:
            tempStepName = { label: 'Utilities', value: 'utils' };
            break;
          case 8:
            tempStepName = {
              label:
                'Transportation (excluding what is included in Vehicles and Insurances)',
              value: 'cars',
            };
            break;
          case 9:
            tempStepName = {
              label: 'Shopping & Miscellaneous',
              value: 'shoppingAndMisc',
            };
            break;
          default:
            break
        }
        break;
      case 'lifetime':
      default:
        tempStepName = {
          label: 'Lifetime',
          value: 'lifetime',
        };
        break;
    }
    return tempStepName;
  }, []);

  const convertBackToApiType = (value) => {
    let convertedValue =
      typeof value !== 'boolean'
        ? value.toString().includes('%')
          ? value.split('%')[0]
          : value.toString().includes('$')
            ? parseFloat(value.split('$')[1].replace(/,/g, ''))
            : value !== ''
              ? value
              : ''
        : value;
    const newFieldValue =
      typeof value !== 'boolean' && value.toString().includes('%')
        ? convertedValue !== '' &&
        !isNaN(convertedValue) &&
        parseFloat(convertedValue) / 100
        : convertedValue;
    return newFieldValue;
  };

  const convertObjectToApiType = useCallback((obj) => {
    if (typeof obj !== 'object' || obj === null) return convertBackToApiType(obj);
    // Base case: if it's not an object, return the value

    // If the object is an array, map over each item recursively
    if (Array.isArray(obj)) {
      return obj.map(convertObjectToApiType);
    }

    // If the object is a plain object, iterate over each key-value pair
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = convertObjectToApiType(obj[key]); // Recursively apply the conversion
      return acc;
    }, {});
  }, []);

  const onReduxUpdate = useCallback(
    (newFormDetails) => {
      const tempFormDetails = newFormDetails ? newFormDetails : formDetails;
      // Convert the entire object to the "API type"
      const convertedData = convertObjectToApiType(tempFormDetails);
      const tempFormDetailsArray = Object.entries(convertedData).map((m) => {
        return { name: m[0], value: convertBackToApiType(m[1]) };
      });
      const tempFormDetailsObject = tempFormDetailsArray.reduce(
        (previousObject, currentObject) => {
          return Object.assign(previousObject, {
            [currentObject.name]: currentObject.value,
          });
        },
        {}
      );
      updateExpenseDetailsSolutionsInRedux(
        tempFormDetailsObject,
        currentStep,
        newFieldValue
      );
      setIsBlurCalled(false);
    },
    [
      formDetails,
      updateExpenseDetailsSolutionsInRedux,
      currentStep,
      convertObjectToApiType,
      newFieldValue
    ]
  );

  useEffect(() => {
    if (
      !isEqual(prevFormDetails, formDetails) &&
      Object.keys(formDetails).length > 0 &&
      fieldValueChanged &&
      isBlurCalled
    ) {
      onReduxUpdate();
      setFieldValueChanged(false);
    }
  }, [
    prevFormDetails,
    formDetails,
    onReduxUpdate,
    fieldValueChanged,
    isBlurCalled,
  ]);

  useEffect(() => {
    if (
      storeValues &&
      Object.keys(storeValues).length > 0 &&
      !fieldValueChanged &&
      !isBlurCalled
    ) {
      const tempFormDetails = {
        affordabilityFactor:
          (storeValues.affordabilityFactor &&
            transformValue(storeValues.affordabilityFactor)) ||
          '100.00%',
        horizonRentalExpense:
          (storeValues.horFactor[`rental`] &&
            transformValue(storeValues.horFactor[`rental`])) ||
          '100.00%',
        horizonFactorPercent:
          (storeValues.discHorFactor &&
            transformValue(storeValues.discHorFactor)) ||
          '100.00%',
        retirementFactor1:
          (storeValues.discRetFactor1 &&
            transformValue(storeValues.discRetFactor1)) ||
          '100.00%',
        retirementFactor2:
          (storeValues.discRetFactor2 &&
            transformValue(storeValues.discRetFactor2)) ||
          '100.00%',
        retirementFactor3:
          (storeValues.discRetFactor3 &&
            transformValue(storeValues.discRetFactor3)) ||
          '100.00%',
        retirementRentalExpense:
          (storeValues.retFactor['rental'] &&
            transformValue(storeValues.retFactor['rental'])) ||
          '100.00%',
        discSameFactorAcross:
          currentStep === 'retirement' ||
            currentStep === 'horizon' ||
            currentStep === 'lifetime'
            ? formDetails.discSameFactorAcross !== undefined &&
              formDetails.discSameFactorAcross !==
              storeValues.useDiscretionaryFlag
              ? formDetails.discSameFactorAcross
              : storeValues.useDiscretionaryFlag
            : formDetails.discSameFactorAcross,
        // horPercentValue:
        //   currentStep === 'discretionary' && itemizedType !== ''
        //     ? (storeValues.horFactor[`${[itemizedType]}`] &&
        //       transformValue(
        //         storeValues.horFactor[`${[itemizedType]}`]
        //       )) ||
        //     '100.00%'
        //     : '100.00%',
        // retPercentValue:
        //   currentStep === 'discretionary'
        //     ? (storeValues.retFactor[`${[itemizedType]}`] &&
        //       transformValue(
        //         storeValues.retFactor[`${[itemizedType]}`]
        //       )) ||
        //     '100.00%'
        //     : '100.00%',
        horFactor: transformValues(storeValues.horFactor),
        retFactor: transformValues(storeValues.retFactor),
        amounts: transformValues(storeValues.amounts, 'currency'),
        disc: {
          ...storeValues.disc,
        },
      };
      const tempLifestyle =
        (storeValues.lifestyle && storeValues.lifestyle) || 'custom';
      setLifestyle(tempLifestyle);
      setFormDetails(tempFormDetails);
    }
  }, [
    storeValues,
    currentStep,
    formDetails?.discSameFactorAcross,
    fieldValueChanged,
    isBlurCalled,
    itemizedType,
    transformValues
  ]);

  const handleDiscFormFieldChange = (e, from) => {
    let fieldValue =
      e.target.checked && e.target.defaultValue === 'true' ? true : false;
    setIsBlurCalled(true);
    setFieldValueChanged(true);
    setNewFieldValue({ fieldValue: fieldValue, field: from, parent: 'disc' });
    // const newFormDetails = {
    //   ...formDetails,
    //   disc: {
    //     ...formDetails.disc,
    //     [from]: fieldValue,
    //   },
    // };
    if (currentStep === 'discretionary') {
      setItemizedType(from)
    }
    //setFormDetails(newFormDetails);
  };

  const handleFormFieldChange = (e, from, type, item) => {
    let fieldValue =
      e && type === 'radio'
        ? e.target.checked && e.target.defaultValue === 'true'
          ? true
          : false
        : e.target.value;
    if (type === 'percent') {
      if (
        fieldValue !== null &&
        fieldValue !== undefined &&
        fieldValue !== ''
      ) {
        if (fieldValue.toString().includes('%')) {
          let percentFieldValue = fieldValue.split('%')[0];
          if (percentFieldValue !== '' && !isNaN(percentFieldValue)) {
            fieldValue = percentFieldValue;
          }
        }
      }
    } else if (type === 'currency') {
      if (
        fieldValue !== null &&
        fieldValue !== undefined &&
        fieldValue !== ''
      ) {
        if (fieldValue.toString().includes('$')) {
          let currencyFieldValue = fieldValue.split('$')[1];
          currencyFieldValue = currencyFieldValue.replace(/,/g, '');
          if (currencyFieldValue !== '' && !isNaN(currencyFieldValue)) {
            fieldValue = currencyFieldValue;
          }
        }
      }
    } else if (type === 'number') {
      if (
        fieldValue !== '' &&
        fieldValue !== null &&
        fieldValue !== undefined
      ) {
        if (isNaN(fieldValue)) {
          fieldValue = 0;
        }
      }
    }
    if (type === 'radio') {
      setIsBlurCalled(true);
    } else {
      setIsBlurCalled(false);
    }
    if (fieldValue === 'NaN') {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
    setFieldValueChanged(true);
    const itemizedType = currentStep === 'discretionary' ? item ? item : from : "";
    setNewFieldValue({ fieldValue: fieldValue, field: item ? item : from, parent: from });
    if (currentStep === 'discretionary') {
      setItemizedType(itemizedType)
    }
  };

  const transformValue1 = (value, field) => {
    if (value) {
      if (percentageFields.includes(field)) {
        let percentFieldValue = '';
        if (isNaN(value) && value.includes('%')) {
          percentFieldValue = parseFloat(
            parseFloat(value.split('%')[0]) % 100
          ).toFixed(1);
        } else {
          percentFieldValue = parseFloat(parseFloat(value) * 100).toFixed(1);
        }
        return `${percentFieldValue}%`;
      } else {
        let numVal = value;
        if (isNaN(numVal) && numVal.includes('$')) {
          numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
        }
        const numFormat = Intl.NumberFormat('en-US');
        const newNumformat = numFormat.format(numVal);
        return `$${newNumformat}`;
      }
    } else if (value === 0) {
      if (percentageFields.includes(field)) {
        return `${0}%`;
      } else {
        return `$${0}`;
      }
    }
  };

  const handleBlur = (event, field, item) => {
    let { value } = event && event.target;
    let percentFieldValue = '';
    if (value !== null && value !== undefined && value !== '') {
      if (value.toString().includes('%')) {
        percentFieldValue = value.split('%')[0];
        if (
          percentFieldValue !== '' &&
          !isNaN(percentFieldValue) &&
          Number(percentFieldValue) >= 0
        ) {
          percentFieldValue = parseFloat(percentFieldValue).toFixed(2);
          value = `${percentFieldValue}%`;
        } else {
          value = `${0}%`;
        }
      } else if (!isNaN(value) && Number(value) >= 0) {
        percentFieldValue = parseFloat(value).toFixed(2);
        value = `${percentFieldValue}%`;
      } else {
        value = `${0}%`;
      }
    } else {
      value = `${0}%`;
    }
    setIsBlurCalled(true);
    const itemizedType = currentStep === 'discretionary' ? item ? item : from : "";
    setNewFieldValue({ fieldValue: value, field: item ? item : field, parent: field });
    if (currentStep === 'discretionary') {
      setItemizedType(itemizedType)
    }
    // const newFormDetails = {
    //   ...formDetails,
    //   [field]: value,
    // };
    // setFormDetails(newFormDetails);
  };

  const handleBlurOnNumbers = (event, field, item) => {
    let value = formatCurrency(event?.target?.value)
    setIsBlurCalled(true);
    const itemizedType = currentStep === 'discretionary' ? item ? item : from : "";
    setNewFieldValue({ fieldValue: value, field: item ? item : field, parent: field });
    if (currentStep === 'discretionary') {
      setItemizedType(itemizedType)
    }
  };

  const renderExpenseInput = (name, item, phase) => (
    <>
      <Grid item xs={9} style={styles.gridItem}>
        What is the most conservative percent of expense you would be willing to consider, if you must reduce {phase}?
      </Grid>
      <Grid item xs={3}>
        <input
          name={currentStep}
          className="planner-input"
          value={formDetails[name][item]}
          type="text"
          onBlur={(event) => handleBlur(event, name, item)}
          onChange={(event) =>
            handleFormFieldChange(event, name, 'percent', item)
          }
        />
      </Grid>
    </>
  );


  const renderDiscretionarySection = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item md={9} sm={8} xs={6} style={styles.gridItem}>
            Would you like to use previously set horizon & retirement adjustments to itemized discretionary expenses?
          </Grid>
          <Grid item md={3} sm={4} xs={6}>
            <Controls.RadioGroup
              name="Disc Same Factor Across"
              value={formDetails.discSameFactorAcross || false}
              rowType={true}
              onChange={(event) =>
                handleFormFieldChange(event, 'discSameFactorAcross', 'radio')
              }
              items={radioOptions}
            />
          </Grid>
        </Grid>
        {!formDetails.discSameFactorAcross && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <hr />
            </Grid>
            {/* <Grid item xs={12} style={styles.subHeaderText}>
              You may adjust down your annual expense for {stepName.label}
            </Grid> */}
            {[...Array(9)].map((item, index) => {
              const currstepName = updateStepName('discretionary', index + 1);
              // const fieldValue = getFormDetailsValue(currstepName);
              return (
                <>
                  <Grid item md={9} sm={8} xs={6} gap={15} className="">
                    <p className="mb-0">{currstepName.label} (annually)</p>
                  </Grid>
                  <Grid item md={3} sm={4} xs={6}>
                    <input
                      className=""
                      value={formDetails.amounts[currstepName.value]}
                      type={'text'}
                      onChange={(event) =>
                        handleFormFieldChange(
                          event,
                          'amounts',
                          'currency',
                          currstepName.value,
                        )
                      }
                      onBlur={(event) =>
                        handleBlurOnNumbers(
                          event,
                          'amounts',
                          currstepName.value,
                        )
                      }
                    />
                  </Grid>
                  <Grid item md={9} sm={8} xs={6} style={styles.gridItem}>
                    Would you be willing to reduce this amount if you were to have a budget deficit?
                  </Grid>
                  <Grid item md={3} sm={4} xs={6}>
                    <Controls.RadioGroup
                      name={currstepName.value}
                      value={formDetails.disc[currstepName.value] || false}
                      rowType={true}
                      onChange={(event) =>
                        handleDiscFormFieldChange(event, currstepName.value)
                      }
                      items={radioOptions}
                    />
                  </Grid>
                  {formDetails.disc[currstepName.value] && renderExpenseInput('horFactor', currstepName.value, 'during planning horizon')}
                  {formDetails.disc[currstepName.value] && renderExpenseInput('retFactor', currstepName.value, 'during phase-1 of retirement')}
                  <Grid item md={12} sm={12} xs={12}>
                    <hr style={{margin: '5px 0px'}} />
                  </Grid>
                </>
              );
            })}
          </Grid>
        )}
      </>
    );
  };

  const renderSteps = () => {
    const expenseTypeMsg =
      lifestyle !== 'custom'
        ? `Expenses are based on Lifestyle. For more granularity, you may change it to Aggregate or Itemized in Build`
        : storeValues.useAggNonHousingExpense
          ? `Expenses are based on Aggregate. For more granularity, you may set expenses to Itemized in Build`
          : 'Expenses are based on Itemized';

          
    return (
      <>
        {lifestyle === 'custom' && (
          <div className="solution_expenses_outer">
            {currentStep === 'lifetime' ? (
              <Grid container spacing={2} className="">
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    padding: '5px',
                  }}
                >
                  Adjust Expenses
                </Grid>
                <Grid
                  item
                  md={9} 
                  sm={8} 
                  xs={6} 
                  className=""
                  style={{fontSize: '14px' }}
                >
                  Affordability Factor
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                  <input
                    name="AffordabilityFactor"
                    className=""
                    value={formDetails.affordabilityFactor}
                    type="text"
                    onBlur={(event) => handleBlur(event, 'affordabilityFactor')}
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'affordabilityFactor',
                        'percent'
                      )
                    }
                  />
                </Grid>
              </Grid>
            ) : currentStep === 'horizon' ? (
              <Grid container spacing={2} className="">
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    padding: '5px',
                  }}
                >
                  Adjust Expenses during Planning Horizon
                </Grid>
                <Grid
                  item
                  xs={12}
                  className=""
                  style={{
                    fontWeight: '600', 
                    padding: '5px',
                  }}
                >
                  You may consider adjusting down your annual expense during
                  your planning horizon if you were to have a budget deficit.
                </Grid>

                <Grid
                  item
                  md={9} 
                  sm={8} 
                  xs={6} 
                  className=""
                >
                  What is the most conservative percent of rental expense you
                  would be willing to consider, if you must reduce? (if you were
                  to liquidate your primary home)
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                  <input
                    name="Horizon Rental Expense"
                    className=""
                    value={formDetails.horizonRentalExpense}
                    type="text"
                    onBlur={(event) =>
                      handleBlur(event, 'horizonRentalExpense')
                    }
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'horizonRentalExpense',
                        'percent'
                      )
                    }
                  />
                </Grid>

                <Grid
                  item
                  md={9} 
                  sm={8} 
                  xs={6} 
                  className=""
                >
                  What is the most conservative percent of discretionary
                  non-housing expense you would be willing to consider, if you
                  must reduce?
                </Grid>

                <Grid item md={3} sm={4} xs={6}>
                  <input
                    name="Horizon Factor Percent"
                    className=""
                    value={formDetails.horizonFactorPercent}
                    type="text"
                    onBlur={(event) =>
                      handleBlur(event, 'horizonFactorPercent')
                    }
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'horizonFactorPercent',
                        'percent'
                      )
                    }
                  />
                </Grid>
              </Grid>
            ) : currentStep === 'retirement' ? (
              <Grid container spacing={2} className="">
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    padding: '5px',
                  }}
                >
                  Adjust Expenses during Retirement
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    fontWeight: '600',
                    padding: '5px',
                  }}
                >
                  You may consider adjusting down your annual expense during
                  your retirement phases: phase-1 is first one-third of
                  retirement, phase-2 is middle one-third of retirement, and
                  phase-3 is last one-third of retirement.
                </Grid>

                <Grid
                  item
                  md={9} 
                  sm={8} 
                  xs={6} 
                  className=""
                >
                  What percent of pre-retirement rental expenses do you expect
                  during phase-1 of retirement? (if you were to liquidate your
                  primary home)
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                  <input
                    name="Retirement Rental Expense"
                    className=""
                    value={formDetails.retirementRentalExpense}
                    type={'text'}
                    onBlur={(event) =>
                      handleBlur(event, 'retirementRentalExpense')
                    }
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'retirementRentalExpense',
                        'percent'
                      )
                    }
                  />
                </Grid>

                <Grid
                  item
                  md={9} 
                  sm={8} 
                  xs={6} 
                  className=""
                >
                  What percent of pre-retirement non-housing discretionary
                  expenses do you expect during phase-1 of retirement?
                </Grid>

                <Grid item md={3} sm={4} xs={6}>
                  <input
                    name="Disc Factor 1"
                    className=""
                    value={formDetails.retirementFactor1}
                    type={'text'}
                    onBlur={(event) => handleBlur(event, 'retirementFactor1')}
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'retirementFactor1',
                        'percent'
                      )
                    }
                  />
                </Grid>

                <Grid
                  item
                  md={9} 
                  sm={8} 
                  xs={6} 
                  className=""
                >
                  What percent of phase-1 expenses do you expect during phase-2
                  of retirement?
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                  <input
                    name="Disc Factor 2"
                    className=""
                    value={formDetails.retirementFactor2}
                    type={'text'}
                    onBlur={(event) => handleBlur(event, 'retirementFactor2')}
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'retirementFactor2',
                        'percent'
                      )
                    }
                  />
                </Grid>

                <Grid
                  item
                  md={9} 
                  sm={8} 
                  xs={6} 
                  className=""
                >
                  What percent of phase-2 expenses do you expect during phase-3
                  of retirement?
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                  <input
                    name="Disc Factor 3"
                    className="planner-input"
                    value={formDetails.retirementFactor3}
                    type={'text'}
                    onBlur={(event) => handleBlur(event, 'retirementFactor3')}
                    onChange={(event) =>
                      handleFormFieldChange(
                        event,
                        'retirementFactor3',
                        'percent'
                      )
                    }
                  />
                </Grid>
              </Grid>
            ) : currentStep === 'discretionary' ?
              !storeValues.useAggNonHousingExpense && renderDiscretionarySection() : <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
                    <Alert severity="info">
                      <p>{expenseTypeMsg} </p>
                    </Alert>
                  </Stack>
                </Grid>
              </Grid>
            }
            {/* <SolutionsJourneyButtons
              currentStep={currentStep}
              isFormValid={isFormValid}
              handleNext={() => onReduxUpdate('next')}
              handlePrevious={() => onReduxUpdate('previous')}
            /> */}
          </div>
        )}

        {
          lifestyle !== 'custom' && 
          <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
              <Alert severity="info">
                <p>Expenses are based on Lifestyle. For more granularity, you may change it to Aggregate or Itemized in Build</p>
              </Alert>
            </Stack>
          </Grid>
        </Grid>
        }

        {
          storeValues.useAggNonHousingExpense && currentStep === 'discretionary' && 
          <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
              <Alert severity="info">
                <p>Expenses are based on Aggregate. For more granularity, you may set expenses to Itemized in Build</p>
              </Alert>
            </Stack>
          </Grid>
        </Grid>
        }
        


      </>
    );
  };

  return renderSteps();
}
