import React, { Component, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Grid, Tooltip } from '@material-ui/core';
import '../../styles/plaiduiV3.scss';
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material';
import Controls from '../controls/Controls';
import RadioGroup from '../controls/RadioGroup';
// import { getAllInstitutions } from '../../services/dashboardSvc';
import { usePlaidLink } from 'react-plaid-link';
import {
  createPlaidLinkToken,
  setPlaidAccessToken,
  getPlaidAccountsBalance,
} from '../../services/dashboardSvc';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {
  investmentFields,
  assetMixList,
  banksListObj,
  newAccountDetailsToBeAdded,
} from '../../constants/dashboard';
import edit_pencil from '../../images/edit_pencil.svg';
import delete_icon from '../../images/delete_icon.svg';
import pdf_page_plus_icon from '../../images/pdf_page_plus_icon.svg';
import { validateNamesWithoutSpaces } from '../../utils/validators';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { USERTYPES } from '../../constants/common';
import { getTickerDetails } from '../../services/dashboardSvc';

const AntSwitch = styled(Switch)(({ theme, dual }) => ({
  width: '28px  !important',
  height: '16px  !important',
  padding: '0px  !important',
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: '12px  !important',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    color: '#fff',
    padding: '2px  !important',
    position: 'absolute !important',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#6ccea6' : '#225ea8',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: '12px  !important',
    height: '12px  !important',
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : dual
          ? '#225ea8'
          : 'grey',
    boxSizing: 'border-box',
  },
}));

const PlaidLinkFn = ({
  // linkToken,
  setPlaidLinkSuccessAction,
  setPlaidLinkAccessToken,
  plaidText = 'Automatic (Plaid)',
  clearProcessedData,
  setToPlaid,
  autoOpenPlaid,
  setSelectedOption,
}) => {
  const [plaidLinkToken, setPlaidLinkToken] = useState('');
  const onSuccess = (public_token) => {
    const exchangePublicTokenForAccessToken = async () => {
      const { status, data } = await setPlaidAccessToken(public_token);
      if (status === 200) {
        setPlaidLinkAccessToken(data);
        setPlaidLinkSuccessAction(true);
      }
    };
    clearProcessedData();
    exchangePublicTokenForAccessToken();
  };

  const onExit = () => {
    // When the Plaid pop-up is closed, update the selected option to "NO"
    setSelectedOption('manual');
  };

  let isOauth = false;

  const config = {
    token: plaidLinkToken,
    onSuccess,
    onExit,
  };

  if (window.location.href.includes('?oauth_state_id=')) {
    // TODO: figure out how to delete this ts-ignore
    // @ts-ignore
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  const createPlaidLinkAccessToken = async () => {
    const { status, data } = await createPlaidLinkToken();
    if (status === 200) {
      setPlaidLinkToken(data.linkToken);
    }
  };

  useEffect(() => {
    setPlaidLinkSuccessAction(false);
    createPlaidLinkAccessToken(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Automatically open Plaid if the autoOpenPlaid prop is true and the link is ready
  useEffect(() => {
    if (autoOpenPlaid && ready) {
      open();
      setToPlaid(); // Any additional action you want to perform
    }
  }, [autoOpenPlaid, ready, open, setToPlaid]);

  return (
    <Button
      onClick={() => {
        open();
        setToPlaid();
      }}
      style={{
        fontWeight: 700,
        fontSize: '12px',
        marginRight: '10px',
      }}
      disabled={!ready}
    >
      {plaidText}
    </Button>
  );
};

const valueAsofNowList = [
  {
    title: 'Now',
    id: 'now',
  },
  {
    title: 'Begining of the year',
    id: 'boy',
  },
];

const invReturnOptions = [
  {
    title: 'Generic',
    id: 'generic',
  },
  {
    title: 'Custom',
    id: 'custom',
  },
  {
    title: 'Products',
    id: 'products',
  },
];

const radioOptions = [
  { id: true, title: 'Yes' },
  { id: false, title: 'No' },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class BuildInvestmentsMarketsComponent extends Component {
  state = {
    stepIndex: 0,
    selectedBankDetails: {},
    userName: '',
    password: '',
    stepNoIndex: 1,
    selectedYesEvent: false,
    selectedInvestmentDetails: [...investmentFields],
    assetMixListForInv: [...assetMixList],
    ...this.getNewMemberInitialState(),
    selectedMember: '',
    accountDetails: {},
    tempAccountDetails: {},
    plaidAccTabValue: 0,
    banksList: [...banksListObj],
    searchInst: {},
    plaidLinkToken: '',
    plaidAccountDetails: {},
    investmentTypeName: '',
    newNickName: '',
    isButtonDisabled: true,
    isAssetMixPercentNotEqualTo100: false,
    plaidAccountProcessedInfo: [],
    selectedAccount: '',
    accBalance: 0,
    isEditable: false,
    isAddNew: false,
    selectedProductType: '',
    tickers: [],
    selectedTicker: '',
    tickersAddedToPlan: [],
    tickersAvailableInPlan: [],
    investmentAccounts: [],
    remainingBalance: '',
    inValidAmount: false,
    amountUsed: 0,
    buttonDisabledMsg: '',
    selectedInvestmentAccount: {},
    oldSelectedInvestmentAccount: {},
    availableInvestmentAccounts: [],
    selectedInvestmentBucket: '',
    oldInvestmentBucket: '',
    selectedOption: 'manual',
    isProductsSelected: true,
    isCustomSelected: true,
    localProdInfo: [],
  };

  handleOptionChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  getSelectedMemberState = (updatedMember) => {
    let { selectedMember, memberValues } = this.state;
    selectedMember = updatedMember ? updatedMember : selectedMember;
    const tempMemberValues = { ...memberValues };
    if (selectedMember === 'first') {
      return tempMemberValues.first;
    } else {
      return tempMemberValues.second;
    }
  };

  componentDidMount() {
    const {
      storeValues,
      setLastStepInParent,
      members,
      prodInfo = [],
    } = this.props;
    if (
      storeValues &&
      Object.keys(storeValues).length > 0 &&
      members &&
      members.length > 0
    ) {
      const memberValues = { ...storeValues };
      const uniqueProdInfo = prodInfo.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.ticker.toLowerCase() === value.ticker.toLowerCase()
          )
      );
      this.setState({
        memberValues,
        selectedMember: members[0].key,
        localProdInfo: uniqueProdInfo,
      });
    }
    setLastStepInParent(true); // this was false before, see how it works for plaid flow - Ram
  }

  checkForButtonStatus = (accs, accTabValue) => {
    const isSelectedAccNickNameEmpty =
      accs &&
        accs.length > 0 &&
        accs[accTabValue] &&
        accs[accTabValue].nickName === ''
        ? true
        : false;
    return isSelectedAccNickNameEmpty;
  };

  updateAccountDetails = () => {
    const {
      plaidAccountDetails: { accounts = [] },
      plaidAccTabValue,
      selectedYesEvent,
      availableInvestmentAccounts,
    } = this.state;
    let tempAvailableInvestmentAccounts = [...availableInvestmentAccounts];
    let existingPlaidAccountIndex = -1;
    const defaultInvestmentBucket = investmentFields[0].id || '';
    if (selectedYesEvent && accounts && accounts.length > 0) {
      const selectedPlaidAccount = accounts[plaidAccTabValue] || {};
      if (Object.keys(selectedPlaidAccount).length > 0) {
        existingPlaidAccountIndex = tempAvailableInvestmentAccounts.indexOf(
          tempAvailableInvestmentAccounts.filter(
            (f) =>
              f.nickName.toLowerCase() ===
              selectedPlaidAccount.name.toLowerCase()
          )[0]
        );
        if (existingPlaidAccountIndex > -1) {
          tempAvailableInvestmentAccounts[
            existingPlaidAccountIndex
          ].invBalance = selectedPlaidAccount.balances.current;
        } else {
          let newAccountDetails = {
            ...newAccountDetailsToBeAdded,
            nickName: selectedPlaidAccount.name,
            invBalance: selectedPlaidAccount.balances.current,
            id: `${selectedPlaidAccount.name}-${defaultInvestmentBucket}`,
            investmentBucket: defaultInvestmentBucket,
          };
          tempAvailableInvestmentAccounts = [
            ...[newAccountDetails],
            ...tempAvailableInvestmentAccounts,
          ];
        }
        const selectedInvestmentAccount =
          tempAvailableInvestmentAccounts.length > 0
            ? tempAvailableInvestmentAccounts.filter(
              (t) =>
                t.nickName.toLowerCase() ===
                selectedPlaidAccount?.name.toLowerCase()
            )[0] || tempAvailableInvestmentAccounts[0]
            : {};
        const tempAccTabValue =
          existingPlaidAccountIndex > -1 ? existingPlaidAccountIndex : 0;
        const isButtonDisabled = this.checkForButtonStatus(
          tempAvailableInvestmentAccounts,
          tempAccTabValue
        );
        const newAssetMixList = assetMixList.map((listItem) => {
          const value = selectedInvestmentAccount[listItem.name] || 0;
          let newPercentValue = this.getPercentValue(value);
          return {
            ...listItem,
            value: newPercentValue,
          };
        });
        const sumOfAssetMixValue = newAssetMixList.reduce(function (acc, val) {
          return acc + parseFloat(val.value);
        }, 0);
        const {
          invBalance = '$0',
          investmentBucket = '',
          nickName = '',
          assetProfile = 'products',
          products = [],
        } = selectedInvestmentAccount;
        this.setState((prevState) => ({
          availableInvestmentAccounts: tempAvailableInvestmentAccounts,
          selectedInvestmentAccount,
          selectedInvestmentBucket: investmentBucket || defaultInvestmentBucket,
          isButtonDisabled,
          accBalance: invBalance || '$0',
          assetMixListForInv: newAssetMixList,
          isAssetMixPercentNotEqualTo100:
            sumOfAssetMixValue === 100 ? false : true,
          sumOfAssetMixValue,
          newNickName: nickName || '',
          tickersAvailableInPlan: products,
          tickersAddedToPlan: [],
        }));
      }
    }
  };

  formatCurrency = (value = '') => {
    const numFormat = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2, // To always show at least two decimal places
      maximumFractionDigits: 3, // Limit the decimal places to two
    });
    //let newNumformat = numFormat.format(0); // Default formatting for zero
    let currencyFieldValue = '';
    if (value !== null && value !== undefined && value !== '') {
      // Remove dollar sign and commas if present
      if (value.toString().includes('$')) {
        currencyFieldValue = value.split('$')[1];
        currencyFieldValue = currencyFieldValue.replace(/,/g, '');
      } else {
        currencyFieldValue = value.toString().replace(/,/g, '');
      }
      // Check if the value is a valid number and is positive
      const parsedValue = parseFloat(currencyFieldValue);
      if (!isNaN(parsedValue) && parsedValue >= 0) {
        value = parsedValue.toString();
        //newNumformat = numFormat.format(parsedValue);
      } else {
        // If invalid or negative, set to default
        value = '0';
      }
    } else {
      value = '0'; // Default if empty
    }
    // Ensure value is always positive and formatted
    return numFormat.format(parseFloat(value));
  };

  getConvertedValue = (value) => {
    let usedBalance = value;
    if (
      usedBalance &&
      isNaN(usedBalance) &&
      usedBalance !== '$' &&
      usedBalance.includes('$')
    ) {
      const tempUsedBalance = usedBalance.split('$')[1].replace(/,/g, '');
      usedBalance =
        tempUsedBalance && !isNaN(tempUsedBalance)
          ? parseFloat(tempUsedBalance)
          : 0;
    } else {
      usedBalance = usedBalance && !isNaN(usedBalance) ? usedBalance : 0;
    }
    return usedBalance;
  };

  updateBalance = (tickersAdded) => {
    const amounts = tickersAdded
      .map(
        (m) => this.getConvertedValue(m.balance) //to remove empty values
      )
      .filter((a) => a);
    //taking only amounts property
    const amountUsed = amounts.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    ); //now adding them (a + b is nthg but actual values like 10 + 20)
    return amountUsed;
  };

  loadTickersAddedInPlan = () => {
    const {
      tickersAvailableInPlan,
      selectedInvestmentAccount,
      isProductsSelected,
    } = this.state;
    const { invBalance = '$0' } = selectedInvestmentAccount;
    const { investmentProducts } = this.props;
    let tickersAdded = [];
    let accBalance = invBalance;
    // Build a map of unique tickers from the investment products
    if (isProductsSelected) {
      const productMap = new Map();
      investmentProducts.forEach((product) => {
        product.tickers.forEach((ticker) => {
          if (!productMap.has(ticker.ticker)) {
            productMap.set(ticker.ticker, ticker);
          }
        });
      });
      // Map tickersAvailableInPlan to new array with details
      const cashInfo = tickersAvailableInPlan.find(
        (t) => t.ticker.toLowerCase() === 'cash'
      );
      if (!cashInfo) {
        const cashInfo = {
          ticker: 'CASH',
          balance: 0,
          desc: 'CASH',
          quantity: 0,
          price: '$1',
          type: 'Cash',
        };
        tickersAvailableInPlan.push(cashInfo);
      }
      tickersAdded = tickersAvailableInPlan.map((m) => {
        const match = productMap.get(m.ticker);
        return {
          ticker: m.ticker.toUpperCase(),
          balance: this.formatCurrency(m.balance),
          desc: match ? match.desc : m.ticker.toUpperCase(), // If no match, use ticker as desc
          quantity: !isNaN(m.quantity) ? parseFloat(m.quantity) : 0,
          price: this.formatCurrency(m.price),
          type: m.type,
        };
      });
      accBalance =
        tickersAvailableInPlan.length === 1
          ? invBalance
          : this.updateBalance(tickersAdded); // add all tickers value to get final balance or if no tickers are present, set the balance to default plaid balance or account balance of the selected account
    }
    // Update state
    this.setState({
      tickersAddedToPlan: tickersAdded,
      accBalance: this.formatCurrency(accBalance),
    });
  };

  getPlaidAccountsBalance = async () => {
    const { plaidLinkAccessToken } = this.props;
    const { status, data: accountsResp } = await getPlaidAccountsBalance(
      plaidLinkAccessToken
    );
    if (status === 200) {
      this.setState({
        plaidAccountDetails: { ...accountsResp },
        selectedYesEvent: true,
        stepIndex: 1,
      });
    }
  };

  getAccounts = (selectedMemberState) => {
    let accounts = [];
    accounts = Object.entries(selectedMemberState).reduce(
      (acc, [key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            const index = acc.indexOf(
              acc.filter(
                (a) =>
                  a.id.toLowerCase() === `${item.nickName}-${key}`.toLowerCase()
              )[0]
            );
            if (index === -1) {
              acc.push({
                ...item,
                investmentBucket: key,
                invBalance: this.formatCurrency(item.invBalance),
                id: `${item.nickName}-${key}`,
              });
            }
          });
        }
        return acc;
      },
      []
    );
    return accounts;
  };

  getAllAccountsFromBuckets = (memberState) => {
    const { selectedInvestmentAccount } = this.state;
    const { nickName = '' } = selectedInvestmentAccount || {};
    const selectedMemberState = memberState
      ? memberState
      : this.getSelectedMemberState();
    const availableInvestmentAccounts =
      this.getAccounts(selectedMemberState) || [];
    const currentSelectedInvestmentAccount =
      availableInvestmentAccounts.filter((a) => a.nickName === nickName)[0] ||
      {};
    const investmentAccountSelected =
      currentSelectedInvestmentAccount &&
        Object.keys(currentSelectedInvestmentAccount).length > 0
        ? currentSelectedInvestmentAccount
        : availableInvestmentAccounts.length > 0
          ? availableInvestmentAccounts[0]
          : undefined; // this is set to undefined when no accounts are present in all the buckets
    this.setState({
      availableInvestmentAccounts,
      selectedInvestmentAccount: investmentAccountSelected,
      selectedInvestmentBucket:
        investmentAccountSelected?.investmentBucket || '',
      accBalance: investmentAccountSelected?.invBalance || '$0',
      tickersAvailableInPlan: investmentAccountSelected?.products || [],
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { plaidLinkSuccess, members, investmentProducts } = this.props;
    const { plaidLinkSuccess: prevPlaidLinkSuccess } = prevProps;
    const {
      selectedMember,
      selectedYesEvent,
      plaidAccountDetails: { accounts = [] },
      stepIndex,
      selectedInvestmentAccount,
      isProductsSelected,
    } = this.state;
    const {
      selectedMember: prevSelectedMember,
      stepIndex: prevStepIndex,
      selectedInvestmentAccount: prevSelectedInvestmentAccount,
      isProductsSelected: prevIsProductsSelected,
    } = prevState;
    if (
      (investmentProducts?.length > 0 &&
        selectedMember &&
        selectedMember !== prevSelectedMember) || selectedInvestmentAccount &&
      Object.keys(selectedInvestmentAccount).length === 0
    ) {
      this.getAllAccountsFromBuckets();
    }
    if (
      selectedYesEvent &&
      accounts.length > 0 &&
      stepIndex > 1 &&
      stepIndex !== prevStepIndex
    ) {
      this.updateAccountDetails();
    }
    if (members && members.length > 0 && selectedMember === '') {
      this.setState({
        selectedMember: members[0].key,
      });
    }
    if (plaidLinkSuccess !== prevPlaidLinkSuccess && plaidLinkSuccess) {
      this.getPlaidAccountsBalance();
    }
    if (
      selectedInvestmentAccount && Object.keys(selectedInvestmentAccount).length > 0 &&
      (!isEqual(
        selectedInvestmentAccount?.nickName,
        prevSelectedInvestmentAccount?.nickName
      ) ||
        isProductsSelected !== prevIsProductsSelected)
    ) {
      this.loadTickersAddedInPlan();
    }
  }

  getNewMemberInitialState() {
    const { storeValues } = this.props;
    return {
      memberValues: storeValues,
    };
  }

  resetAllAccountsState = (from) => {
    const { investmentTypeName, selectedMember } = this.state;
    const { members, setLastStepInParent } = this.props;
    if (from) {
      setLastStepInParent(true); // for member change and simulate, consider it as last page and show buttons
    } else {
      setLastStepInParent(false);
    }
    this.setState({
      userName: '',
      password: '',
      assetMixListForInv: [...assetMixList],
      investmentTypeName: from ? investmentFields[0].id : investmentTypeName,
      selectedMember: from ? members[0].key : selectedMember,
      tickersAddedToPlan: [],
      tickersAvailableInPlan: [],
    });
  };

  // renderCredentialsForm = () => {
  //   const { selectedBankDetails, userName, password } = this.state;
  //   return (
  //     <Grid container className="user-credentials-form">
  //       <Grid item xs={8}>
  //         <p className="enter-cred-text">Enter your credentials</p>
  //         <p className="terms-cond-text">
  //           By Providing your credentials for <b>{selectedBankDetails.title}</b>{' '}
  //           you are enabling Green'r to fetch your information at{' '}
  //           <b>{selectedBankDetails.title}</b> through Plaid, a 3rd party
  //           integrator.
  //         </p>
  //         <p className="terms-cond-text">
  //           Enter your credentials for <b>{selectedBankDetails.title}</b> :
  //         </p>
  //         <Controls.Input
  //           name="userName"
  //           label="User Name"
  //           value={userName}
  //           onChange={this.onInputChange}
  //           required
  //           isValid
  //         />
  //         <Controls.Input
  //           name="password"
  //           label="Password (min 6 characters)"
  //           value={password}
  //           type="password"
  //           onChange={this.onInputChange}
  //           minlength="8"
  //           required
  //           errorText="Invalid Password"
  //           isValid
  //         />
  //         {/* <div className="simulate-buttons">
  //           <Button
  //             variant="contained"
  //             startIcon={<PublishIcon />}
  //             onClick={this.submitPlaidCred}
  //             // disabled={!this.validateForm('login')}
  //           >
  //             Submit{' '}
  //           </Button>
  //         </div> */}
  //       </Grid>
  //     </Grid>
  //   );
  // };

  // submitPlaidCred = () => {
  //   //call plaid api and get response
  //   this.setState({
  //     stepIndex: 3,
  //   });
  // };

  setStepLevelToTwo = () => {
    this.setState({
      stepIndex: 2,
      stepNoIndex: '',
    });
  };

  // setSelectedBankDetails = (event) => {
  //   const { banksList } = this.state;
  //   const selectedItem = banksList.find(
  //     (list) => list.id === event.target.value
  //   );
  //   this.setState({
  //     selectedBankDetails: selectedItem,
  //   });
  // };

  searchInstitutionCb = (options) => {
    return options.map((option) => ({
      value: option.institutionId,
      label: option.name,
    }));
  };

  // setSearchInstitution = (selectedOption) => {
  //   const newSelectedBankDetails = {
  //     id: selectedOption.value,
  //     title: selectedOption.label,
  //   };
  //   this.setState({
  //     selectedBankDetails: newSelectedBankDetails,
  //   });
  // };

  // searchInstitution = async (inputValue, callback) => {
  //   const { data = [] } = await getAllInstitutions(inputValue);
  //   if (data.length > 0) callback(this.searchInstitutionCb(data));
  //   else callback([]);
  // };

  // setStepBack = () => {
  //   const { stepIndex } = this.state;
  //   this.setState({
  //     stepIndex: stepIndex - 1,
  //   });
  // };

  selectedYesEvent = (selectedYesEvent) => {
    this.setState({
      selectedYesEvent,
      stepIndex: 1,
      banksList: [...banksListObj],
    });
  };

  selectedNoEvent = () => {
    this.resetAllAccountsState();
    this.setState({
      stepNoIndex: 1,
      selectedYesEvent: false,
      stepIndex: 0,
    });
  };

  // enterCredentialsEvent = () => {
  //   this.setState({
  //     stepIndex: 2,
  //   });
  // };

  setNextStepIndex1 = () => {
    this.setState({
      stepIndex: 1,
      selectedYesEvent: true,
      selectedInvestmentAccount: {},
    });
  };

  setNextStepNoIndex2 = (e, from) => {
    const {
      selectedMember,
      oldSelectedInvestmentAccount,
      selectedInvestmentAccount,
      isAddNew,
    } = this.state;
    const { setLastStepInParent, members } = this.props;
    let tempSelectedMember = selectedMember;
    if (!from && members && members.length > 0) {
      tempSelectedMember = members[0].key;
    }
    setLastStepInParent(true);
    this.setState((prevState) => ({
      stepNoIndex: 1,
      isAddNew: false,
      isEditable: false,
      selectedYesEvent: false,
      selectedTicker: '',
      selectedProductType: '',
      tickersAddedToPlan: [],
      tickersAvailableInPlan: [],
      selectedMember: tempSelectedMember,
      isProductsSelected: true,
      isCustomSelected: true,
      selectedInvestmentAccount: {},
      // selectedInvestmentAccount:
      //   isAddNew || from === 'cancel'
      //     ? {}
      //     : selectedInvestmentAccount,
    }));
  };

  setPreviousStepNoIndex = () => {
    const { setLastStepInParent } = this.props;
    this.setState({ stepNoIndex: 1 });
    setLastStepInParent(false);
  };

  getPercentValue = (value) => {
    if (!value.toString().includes('%')) {
      const percentValue = parseFloat(parseFloat(value) * 100).toFixed(2);
      return `${percentValue}%`;
    }
    return value;
  };

  getCurrencyValue = (value) => {
    if (value !== '' && !value.toString().includes('$')) {
      const currencyValue = !isNaN(value) ? value : '';
      const numFormat = Intl.NumberFormat('en-US');
      const newNumformat = numFormat.format(currencyValue);
      return `$${newNumformat}`;
    }
    return value;
  };

  setNextStepNoIndex = (accountDetails) => {
    const { setLastStepInParent } = this.props;
    const { customReturn, customVol, customDivRate, invBalance } =
      accountDetails;
    let newCustRet = this.getPercentValue(customReturn);
    let newCustVol = this.getPercentValue(customVol);
    let newCustomDivRate = this.getPercentValue(customDivRate);
    //let newInvBalance = this.formatCurrency(invBalance);
    const newAssetMixList = assetMixList.map((listItem) => {
      const value = accountDetails[listItem.name] || 0;
      let newPercentValue = this.getPercentValue(value);
      return {
        ...listItem,
        value: newPercentValue,
      };
    });
    const sumOfAssetMixValue = newAssetMixList.reduce(function (acc, val) {
      return acc + parseFloat(val.value);
    }, 0);
    setLastStepInParent(false);
    this.setState({
      stepNoIndex: 3,
      selectedYesEvent: false,
      assetMixListForInv: newAssetMixList,
      isAssetMixPercentNotEqualTo100: sumOfAssetMixValue === 100 ? false : true,
      sumOfAssetMixValue,
      selectedInvestmentAccount: {
        ...accountDetails,
        customReturn: newCustRet,
        customVol: newCustVol,
        customDivRate: newCustomDivRate,
        // invBalance: newInvBalance,
      },
    });
  };

  handleMemberChange = (e, type) => {
    let fieldValue = e.target.checked ? e.target.defaultValue : false;
    this.resetAllAccountsState('memberChange');
    this.setState({
      selectedMember: fieldValue,
      selectedInvestmentAccount: {},
    });
  };

  renderMemberTabs = () => {
    const { members } = this.props;
    const { selectedMember } = this.state;
    return (
      <>
        <div className="marketing_select_member">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-6">
              <p className="mb-0">
                <strong>Select Family Member</strong>
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <Controls.RadioGroup
                name="member-radio-buttons"
                value={selectedMember}
                rowType={true}
                onChange={(event) => this.handleMemberChange(event, 'radio')}
                items={members}
              ></Controls.RadioGroup>
            </div>
          </div>
          <hr style={{ margin: '0px' }} />
        </div>
      </>
    );
  };

  handlePlaidAccChange = (event) => {
    const { value } = event.target;
    this.setState({
      plaidAccTabValue: parseInt(value, 10),
    });
  };

  updateInvBalance = (event) => {
    let { value } = event && event.target;
    this.setState({
      accBalance: value,
    });
  };

  updateInvAccNickName = (event) => {
    let { value } = (event && event.target) || '';
    const {
      availableInvestmentAccounts,
      selectedInvestmentAccount = {},
      selectedInvestmentBucket,
    } = this.state;
    const isNameValid = validateNamesWithoutSpaces(value);
    let index = -1;
    if (isNameValid) {
      index = availableInvestmentAccounts.indexOf(
        availableInvestmentAccounts.filter(
          (a) => a.nickName.toLowerCase() === value.toLowerCase()
        )[0]
      );
    }
    let isButtonDisabled = index === -1 ? false : true;
    this.setState({
      newNickName: value,
      // selectedInvestmentAccount: {
      //   ...selectedInvestmentAccount,
      //   nickName: value,
      //   id:
      //     selectedInvestmentBucket !== ''
      //       ? `${value}-${selectedInvestmentBucket}`
      //       : '',
      // },
      isButtonDisabled,
      buttonDisabledMsg: !isNameValid
        ? 'Invalid Name'
        : isButtonDisabled
          ? 'Name already exists'
          : '',
    });
  };

  setToPlaid = () => {
    const { updateInvValueAsOfInStore, invValueAsOf, setLastStepInParent } =
      this.props;
    if (invValueAsOf !== 'now') {
      updateInvValueAsOfInStore('', 'plaid');
    }
    setLastStepInParent(false);
  };

  renderPlaidAccountsInfo = () => {
    const {
      plaidAccTabValue,
      plaidAccountDetails: { accounts = [] },
      plaidLinkToken,
      plaidAccountProcessedInfo,
    } = this.state;
    const { current: avlAccBalance } =
      (accounts &&
        accounts[plaidAccTabValue] &&
        accounts[plaidAccTabValue].balances) ||
      0;
    const { setPlaidLinkSuccessAction, setPlaidLinkAccessToken } = this.props;
    const formattedBalance = parseFloat(avlAccBalance).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });
    const isAddToInvestmentsDisabled =
      plaidAccountProcessedInfo.filter((p) => p.isPlaidAccountProcessed)
        .length === accounts.length
        ? true
        : false;
    return (
      <>
        <p>Accounts fetched from #Institution through Plaid</p>
        <Grid container className="acc-list-container" spacing={4}>
          <Grid item xs={4} className="acc-list-items">
            <RadioGroup
              name="controlled-radio-buttons-group"
              value={plaidAccTabValue}
              rowType={false}
              onChange={(event) => this.handlePlaidAccChange(event)}
              items={
                accounts &&
                accounts.map((item, index) => ({
                  disabled:
                    plaidAccountProcessedInfo.filter(
                      (p) => p.plaidIndex === index && p.isPlaidAccountProcessed
                    ).length > 0
                      ? true
                      : false,
                  showProcessed:
                    plaidAccountProcessedInfo.filter(
                      (p) => p.plaidIndex === index && p.isPlaidAccountProcessed
                    ).length > 0
                      ? true
                      : false,
                  title: item.name,
                  id: index,
                }))
              }
            />
          </Grid>
          <Grid item xs={7}>
            <div>
              {plaidAccTabValue !== '' && accounts.length > 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <label>Account Type</label>
                    <input
                      id={`${plaidAccTabValue}-plaidAccountType`}
                      value={
                        (accounts[plaidAccTabValue] &&
                          accounts[plaidAccTabValue].type) ||
                        ''
                      }
                      label="Account Type"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <label>Balance</label>
                    <input
                      id={`${plaidAccTabValue}-plaidAccountBalance`}
                      value={`${formattedBalance}`}
                      label="Balance"
                      disabled
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container className="automatic_button_group">
          <Grid item xs={4} className="simulate-buttons">
            <Button
              onClick={this.setStepLevelToTwo}
              variant="contained"
              disabled={isAddToInvestmentsDisabled}
              style={{
                color: '#fff',
                fontWeight: 700,
                fontSize: '12px',
                marginRight: '10px',
                backgroundColor: isAddToInvestmentsDisabled
                  ? 'gray'
                  : '#009fe3',
              }}
            >
              Add to Investments{' '}
            </Button>
          </Grid>
          <Grid item xs={4} className="simulate-buttons">
            <PlaidLinkFn
              setPlaidLinkSuccessAction={setPlaidLinkSuccessAction}
              setPlaidLinkAccessToken={setPlaidLinkAccessToken}
              clearProcessedData={this.clearProcessedData}
              plaidText="Next Institution"
              setToPlaid={this.setToPlaid}
              setSelectedOption={(option) =>
                this.setState({ selectedOption: option })
              }
            />
          </Grid>
          <Grid item xs={4} className="simulate-buttons">
            <Button
              onClick={(e) => this.setNextStepNoIndex2(e)}
              variant="contained"
              style={{
                color: '#fff',
                fontWeight: 700,
                fontSize: '12px',
                marginRight: '10px',
                backgroundColor: '#009fe3',
              }}
            >
              Done with Automatic
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  updateCountOnDelete = (member, availableInvestmentAccounts) => {
    let tempMember = JSON.parse(JSON.stringify(member)) || {};
    const { selectedInvestmentAccount: { investmentBucket = '' } = {} } =
      this.state;
    const availableInvestmentBucketAccounts =
      availableInvestmentAccounts.filter(
        (f) => f.investmentBucket === investmentBucket
      );
    const availableAccounts = availableInvestmentBucketAccounts || [];
    tempMember = {
      ...tempMember,
      [`num${this.capitalizeFirstLetter(investmentBucket)}`]:
        availableAccounts.length,
      [investmentBucket]: availableAccounts.map((acc) => {
        const {
          customReturn,
          customVol,
          customDivRate,
          invBalance,
          assetProfile,
        } = acc;
        return {
          ...acc,
          products: acc.products?.map((m) => {
            return {
              ticker: m.ticker,
              balance: this.transformBackToAPIType(m.balance),
              price: this.transformBackToAPIType(m.price),
              quantity: !isNaN(m.quantity) ? parseFloat(m.quantity) : 0,
              type: m.type,
            }
          }) || [],
          altPercent: this.percentageConversion(acc.altPercent),
          bondPercent: this.percentageConversion(acc.bondPercent),
          cashPercent: this.percentageConversion(acc.cashPercent),
          reitPercent: this.percentageConversion(acc.reitPercent),
          shortTermPercent: this.percentageConversion(acc.shortTermPercent),
          stockPercent: this.percentageConversion(acc.stockPercent),
          cryptoPercent: this.percentageConversion(acc.cryptoPercent),
          commoditiesPercent: this.percentageConversion(acc.commoditiesPercent),
          emktBondPercent: this.percentageConversion(acc.emktBondPercent),
          emktStockPercent: this.percentageConversion(acc.emktStockPercent),
          intlBondPercent: this.percentageConversion(acc.intlBondPercent),
          intlStockPercent: this.percentageConversion(acc.intlStockPercent),
          customVol:
            assetProfile === 'products' || assetProfile === 'generic'
              ? 0
              : this.percentageConversion(customVol),
          customReturn:
            assetProfile === 'products' || assetProfile === 'generic'
              ? 0
              : this.percentageConversion(customReturn),
          customDivRate:
            assetProfile === 'products' || assetProfile === 'generic'
              ? 0
              : this.percentageConversion(customDivRate),
          invBalance: this.transformBackToAPIType(invBalance),
        };
      }),
    };
    return tempMember;
  };

  updateAccountsOnDelete = (availableInvestmentAccounts) => {
    const { memberValues, selectedMember, localProdInfo } = this.state;
    const { updateAssetMixInRedux, invValueAsOf } = this.props;
    const { first, second } = memberValues;
    const newMemberValues = {
      ...memberValues,
    };
    let tempFirst = JSON.parse(JSON.stringify(first)) || {};
    tempFirst = this.convertToAPIType(tempFirst);
    let tempSecond = JSON.parse(JSON.stringify(second)) || {};
    tempSecond = this.convertToAPIType(tempSecond);
    if (selectedMember === 'first') {
      newMemberValues.first = this.updateCountOnDelete(
        first,
        availableInvestmentAccounts
      );
      newMemberValues.second = tempSecond;
      this.getAllAccountsFromBuckets(newMemberValues.first);
    } else if (selectedMember === 'second') {
      newMemberValues.second = this.updateCountOnDelete(
        second,
        availableInvestmentAccounts
      );
      newMemberValues.first = tempFirst;
      this.getAllAccountsFromBuckets(newMemberValues.second);
    }
    this.setState({ memberValues: newMemberValues });
    updateAssetMixInRedux({
      invbalanceReq: newMemberValues,
      invValueAsOf,
      localProdInfo,
    });
  };

  handleOnBlur = (event) => {
    const value = this.formatCurrency(event?.target?.value);
    this.setState({
      accBalance: value,
    });
  };

  // transformValue = (value) => {
  //   if (value) {
  //     let numVal = value;
  //     if (isNaN(numVal) && numVal.toString().includes('$')) {
  //       numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
  //     }
  //     const numFormat = Intl.NumberFormat('en-US');
  //     const newNumformat = numFormat.format(numVal);
  //     return `$${newNumformat}`;
  //   } else if (value === 0) {
  //     return `$0`;
  //   } else {
  //     return '';
  //   }
  // };

  onInvAccountChange = (event) => {
    const { availableInvestmentAccounts } = this.state;
    let { value: selectedAccount } = event.target;
    const selectedAccountInfo = availableInvestmentAccounts.filter(
      (f) => f.id.toLowerCase() === selectedAccount.toLowerCase()
    );
    let selectedInvestmentAccount = {};
    if (selectedAccountInfo && selectedAccountInfo.length > 0) {
      selectedInvestmentAccount = selectedAccountInfo[0];
    }
    this.setState({
      selectedAccount,
      selectedInvestmentAccount,
      tickersAvailableInPlan: selectedInvestmentAccount?.products,
      accBalance: selectedInvestmentAccount?.invBalance,
    });
  };

  handleEdit = (isEditable = true, isNewRec = false, name = '') => {
    const { selectedInvestmentAccount = {}, accBalance } = this.state;
    const {
      products = [],
      nickName = '',
      assetProfile = '',
      investmentBucket = '',
    } = selectedInvestmentAccount;
    let tickersAvailableInPlan = isNewRec ? [] : products;
    let newNickName = isNewRec ? '' : nickName;
    let accountBalance = isNewRec ? '$0' : accBalance;
    let accountDetails = isNewRec
      ? {
        ...newAccountDetailsToBeAdded,
        nickName: newNickName,
        invBalance: `${accountBalance}`,
      }
      : { ...selectedInvestmentAccount };

    this.setState({
      isEditable,
      isAddNew: isNewRec,
      accBalance: accountBalance,
      newNickName,
      isButtonDisabled: false,
      tickersAvailableInPlan,
      oldSelectedInvestmentAccount: selectedInvestmentAccount,
      selectedInvestmentBucket: isNewRec ? '' : investmentBucket,
      isProductsSelected: isNewRec ? true : assetProfile === 'products',
      isCustomSelected: isNewRec ? false : assetProfile === 'custom',
    });
    this.setNextStepNoIndex(accountDetails);
  };

  openDeleteConfirmation = () => {
    this.setState({ isDeleteConfirmationOpen: true });
  };

  renderInputForm = (from, currentAcc) => {
    const { newNickName, isAddNew } = this.state;
    const { nickName } = currentAcc;
    return (
      <div className="row align-items-center">
        <div className="market_data_row">
          <label className="required">Account Name</label>
          <input
            type={'text'}
            id={'accountName'}
            name={'accountName'}
            value={isAddNew ? newNickName || '' : nickName || ''}
            disabled={!isAddNew || from}
            className={'custom-input'} // Apply the appropriate class
            onChange={(e) => this.updateInvAccNickName(e)}
            placeholder={'Enter Account Name'}
          />
        </div>
      </div>
    );
  };

  renderInputFormOnBlur = () => {
    const { accBalance, isProductsSelected } = this.state;
    return (
      <div className="row align-items-center">
        <div className="market_data_row">
          <label className="required">Account Balance</label>
          <input
            type={'text'}
            id={'accountBalance'}
            name={'accountBalance'}
            disabled={isProductsSelected}
            value={accBalance || '$0'}
            className={'custom-input'} // Apply the appropriate class
            onChange={(e) => this.updateInvBalance(e)}
            onBlur={(event) => this.handleOnBlur(event)}
            placeholder={'$'}
          />
        </div>
      </div>
    );
  };

  getInvestmentBucket = (investmentBucket = '') => {
    const selectedAccountInfo = investmentFields.filter(
      (f) => f.id.toLowerCase() === investmentBucket.toLowerCase()
    );
    let selectedInvestmentBucket = '';
    if (selectedAccountInfo && selectedAccountInfo.length > 0) {
      selectedInvestmentBucket = selectedAccountInfo[0].title;
    }
    return selectedInvestmentBucket;
  };

  renderInputFormBalance = () => {
    const { selectedInvestmentAccount, accBalance = '$0' } = this.state;
    const { invBalance = '$0', investmentBucket = '' } =
      selectedInvestmentAccount || {};
    //const formattedBalance = this.formatCurrency(invBalance);
    const formattedBalance = this.formatCurrency(accBalance);
    return (
      <div className="row align-items-center">
        <div className="col-sm-6">
          <div className="market_data_row">
            <label>Account Balance</label>
            <input
              type={'text'}
              id={`${invBalance}-accountBalance`}
              name={'accountBalance'}
              value={formattedBalance}
              disabled
              className={'custom-input'} // Apply the appropriate class
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="market_data_row">
            <label>Investment Type</label>
            <input
              type={'text'}
              id={investmentBucket}
              name={investmentBucket}
              value={this.getInvestmentBucket(investmentBucket)}
              disabled
              className={'custom-input'} // Apply the appropriate class
            />
          </div>
        </div>
      </div>
    );
  };

  renderSelectAccountDropdown = () => {
    const { availableInvestmentAccounts, selectedInvestmentAccount } =
      this.state;
    return (
      <>
        <div className="Select_account_main_outer">
          <div className="row align-items-center">
            <div className="col-lg-9 col-md-9 col-8">
              <div className='controls-select-main-outer'>
                <h5 className="account_dropdown_label">Select Account </h5>
                <Controls.Select
                    className="js-example-basic-single"
                    name="Account"
                    maxMenuHeight={150}
                    id="Account"
                    value={selectedInvestmentAccount?.id}
                    onChange={(e) => this.onInvAccountChange(e)}
                    options={availableInvestmentAccounts.map((p, index) => {
                      return { label: p.nickName, value: p.id, index: index };
                    })}
                  />
              </div>
            </div>

            <div
                  className="col-lg-3 col-md-3 col-3 button-group"
                  style={{
                    width: 'max-content',
                  }}
                >
                  {availableInvestmentAccounts &&
                    availableInvestmentAccounts.length > 0 && (
                      <>
                        <button
                          className="action-button"
                          onClick={(e) => {
                            this.handleEdit(true);
                          }}
                        >
                          <img src={edit_pencil} alt="" title="" />
                        </button>
                        <button
                          className="action-button"
                          onClick={(e) => this.openDeleteConfirmation('')}
                        >
                          <img src={delete_icon} alt="" title="" />
                        </button>
                      </>
                    )}
                  <button
                    className="action-button"
                    onClick={(event) => {
                      this.handleEdit(true, true);
                    }}
                  >
                    <img src={pdf_page_plus_icon} alt="" title="" />
                  </button>
                </div>
          </div>
        </div>
      </>
    );
  };

  handleDeleteConfirm = () => {
    const { availableInvestmentAccounts, selectedInvestmentAccount } =
      this.state;
    const selectedIndex = availableInvestmentAccounts.indexOf(
      availableInvestmentAccounts.filter(
        (f) =>
          f.nickName.toLowerCase() ===
          selectedInvestmentAccount?.nickName.toLowerCase()
      )[0]
    );
    availableInvestmentAccounts.splice(selectedIndex, 1);
    this.setState(
      {
        isDeleteConfirmationOpen: false,
        tickersAddedToPlan: [],
        tickersAvailableInPlan: [],
        availableInvestmentAccounts,
      },
      () => {
        this.updateAccountsOnDelete(availableInvestmentAccounts);
      }
    );
  };

  closeDeleteConfirmation = () => {
    this.setState({ isDeleteConfirmationOpen: false });
  };

  deleteConfirmation = () => {
    const {
      isDeleteConfirmationOpen,
      selectedInvestmentAccount: { nickName = '' } = {},
    } = this.state;
    return (
      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={this.closeDeleteConfirmation}
        className="delete_popup_outer"
      >
        <DialogTitle>Are you sure you want to delete "{nickName}"?</DialogTitle>
        <DialogActions>
          <Button
            onClick={this.closeDeleteConfirmation}
            color="primary"
            style={{
              padding: '7.5px 15px',
              color: '#fff',
              fontWeight: 700,
              fontSize: '12px',
              marginRight: '10px',
              backgroundColor: 'gray !important',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleDeleteConfirm}
            color="primary"
            style={{
              padding: '7.5px 15px',
              color: '#fff',
              fontWeight: 700,
              fontSize: '12px',
              marginRight: '10px',
              backgroundColor: '#009fe3',
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // renderInvestmentForm = () => {
  //   const { selectedInvestmentAccount } = this.state;
  //   return (
  //     <>
  //       {this.renderMemberTabs()}
  //       {this.renderSelectAccountDropdown()}
  //       {this.deleteConfirmation()}
  //       <div className="financial-instn-container">
  //         <p className="select-bank-text">
  //           Select the investment that you want to manually enter or update what
  //           you automatically fetched from your financial institutions:
  //         </p>
  //       </div>

  //       {selectedInvestmentAccount &&
  //         Object.keys(selectedInvestmentAccount).length > 0 && (
  //           <div className="insured_main_outer">
  //             {this.renderInputFormBalance()}
  //           </div>
  //         )}
  //     </>
  //   );
  // };

  setAssetMixValue = (event, name) => {
    let { value } = event.target;
    // if (value.toString().includes('%')) {
    //   const percentFieldValue = value.split('%')[0];
    //   value =
    //     percentFieldValue !== '' && !isNaN(percentFieldValue)
    //       ? percentFieldValue
    //       : '';
    // } else {
    //   value = value !== '' && !isNaN(value) ? value : '';
    // }
    const { assetMixListForInv } = this.state;
    const newAssetMixList = assetMixListForInv.map((list) => {
      if (list.name === name) {
        return {
          ...list,
          value,
        };
      }
      return {
        ...list,
      };
    });
    this.setState({
      assetMixListForInv: [...newAssetMixList],
    });
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  percentageConversion = (field) => {
    if (field.toString().includes('%')) {
      let percentField = field.split('%')[0];
      percentField =
        percentField !== '' && !isNaN(percentField)
          ? parseFloat(percentField) / 100
          : 0;
      return percentField;
    }
    return field !== '' && !isNaN(field) ? field : 0;
  };

  convertToAPIType = (obj) => {
    const arrayKeys = Object.keys(obj).filter(key => Array.isArray(obj[key]));
    const updatedObj = { ...obj };
    arrayKeys.forEach((selectedInvestmentBucket) => {
      const availableAccounts = obj[selectedInvestmentBucket] || [];
      updatedObj[selectedInvestmentBucket] =
        availableAccounts.map((acc) => {
          const { customReturn, customVol, customDivRate, invBalance } = acc;
          return {
            ...acc,
            products: acc.products?.map((m) => {
              return {
                ticker: m.ticker,
                balance: this.transformBackToAPIType(m.balance),
                price: this.transformBackToAPIType(m.price),
                quantity: !isNaN(m.quantity) ? parseFloat(m.quantity) : 0,
                type: m.type,
              }
            }) || [],
            altPercent: this.percentageConversion(acc.altPercent),
            bondPercent: this.percentageConversion(acc.bondPercent),
            cashPercent: this.percentageConversion(acc.cashPercent),
            reitPercent: this.percentageConversion(acc.reitPercent),
            shortTermPercent: this.percentageConversion(acc.shortTermPercent),
            stockPercent: this.percentageConversion(acc.stockPercent),
            cryptoPercent: this.percentageConversion(acc.cryptoPercent),
            commoditiesPercent: this.percentageConversion(
              acc.commoditiesPercent
            ),
            emktBondPercent: this.percentageConversion(acc.emktBondPercent),
            emktStockPercent: this.percentageConversion(acc.emktStockPercent),
            intlBondPercent: this.percentageConversion(acc.intlBondPercent),
            intlStockPercent: this.percentageConversion(acc.intlStockPercent),
            customVol: this.percentageConversion(customVol),
            customReturn: this.percentageConversion(customReturn),
            customDivRate: this.percentageConversion(customDivRate),
            invBalance: this.transformBackToAPIType(invBalance)
          }
        })
    })
    return updatedObj;
  }

  convertedNewMemberObject = (member, from) => {
    let tempMember = JSON.parse(JSON.stringify(member)) || {};
    const {
      assetMixListForInv,
      tickersAddedToPlan,
      selectedInvestmentAccount = {},
      selectedInvestmentBucket = "",
      oldInvestmentBucket,
      isProductsSelected,
      isCustomSelected,
      accBalance: updatedInvBalance,
      newNickName = "",
      isAddNew
    } = this.state;
    const { nickName = "", products: availableProducts = [] } = selectedInvestmentAccount;
    const tempNickName = isAddNew ? newNickName : nickName;
    const tempSelectedInvestmentAccount = {
      ...selectedInvestmentAccount,
      nickName: tempNickName,
      investmentBucket: selectedInvestmentBucket,
      id:
        selectedInvestmentBucket !== ''
          ? `${tempNickName}-${selectedInvestmentBucket}`
          : '',
    };
    const newAssetMixList = assetMixListForInv.map((list) => {
      const percentFieldValue = list.value.toString().split('%')[0];
      const newFieldValue =
        isProductsSelected && tickersAddedToPlan.length > 0
          ? 0
          : percentFieldValue !== '' &&
          !isNaN(percentFieldValue) &&
          parseFloat(percentFieldValue) / 100;
      return {
        ...list,
        value: newFieldValue,
      };
    });
    let products = isProductsSelected && tickersAddedToPlan?.length > 0 ? tickersAddedToPlan : availableProducts; // retaining products to make sure they are not lost
    products = products.map((m) => {
      return {
        ticker: m.ticker,
        balance: this.transformBackToAPIType(m.balance),
        price: this.transformBackToAPIType(m.price),
        quantity: !isNaN(m.quantity) ? parseFloat(m.quantity) : 0,
        type: m.type,
      };
    });
    const { id, investmentBucket, ...objectToMove } = tempSelectedInvestmentAccount;
    const index =
      oldInvestmentBucket !== ''
        ? tempMember[oldInvestmentBucket].findIndex(
          (item) =>
            item.nickName.toLowerCase() ===
            objectToMove.nickName.toLowerCase()
        )
        : -1;
    if (
      index !== -1 &&
      oldInvestmentBucket.toLowerCase() !==
      selectedInvestmentBucket.toLowerCase()
    ) {
      // Remove the object from trad401k array
      tempMember[oldInvestmentBucket].splice(index, 1);
      // Add the removed object to the rsu array
      tempMember[selectedInvestmentBucket].push(objectToMove);
    } else if (index === -1) {
      const accounts = tempMember[selectedInvestmentBucket];
      if (accounts) {
        const newIndex = accounts.indexOf(
          accounts.filter(
            (f) =>
              f.nickName.toLowerCase() === objectToMove.nickName.toLowerCase()
          )[0]
        );
        if (newIndex === -1) {
          tempMember[selectedInvestmentBucket].push(objectToMove);
        }
      }
    }
    const availableAccounts = tempMember[selectedInvestmentBucket] || [];
    const availableAccountsAfterMoving = tempMember[oldInvestmentBucket] || [];
    tempMember = {
      ...tempMember,
      [`num${this.capitalizeFirstLetter(selectedInvestmentBucket)}`]:
        availableAccounts.length,
      // [`num${this.capitalizeFirstLetter(oldInvestmentBucket)}`]:
      //   availableAccountsAfterMoving.length,
      [selectedInvestmentBucket]: availableAccounts.map((acc, index) => {
        const { customReturn, customVol, customDivRate, invBalance } = acc;
        if (
          acc.nickName.toLowerCase() === objectToMove.nickName.toLowerCase()
        ) {
          const {
            customReturn: updatedCustomReturn,
            customVol: updatedCustomVol,
            customDivRate: updatedCustomDivRate,
            //invBalance: updatedInvBalance,
          } = objectToMove;
          return {
            ...acc,
            assetProfile: isProductsSelected
              ? 'products'
              : isCustomSelected
                ? 'custom'
                : 'generic',
            altPercent: newAssetMixList.find((inv) => inv.name === 'altPercent')
              .value,
            bondPercent: newAssetMixList.find(
              (inv) => inv.name === 'bondPercent'
            ).value,
            cashPercent: newAssetMixList.find(
              (inv) => inv.name === 'cashPercent'
            ).value,
            reitPercent: newAssetMixList.find(
              (inv) => inv.name === 'reitPercent'
            ).value,
            shortTermPercent: newAssetMixList.find(
              (inv) => inv.name === 'shortTermPercent'
            ).value,
            stockPercent: newAssetMixList.find(
              (inv) => inv.name === 'stockPercent'
            ).value,
            cryptoPercent: newAssetMixList.find(
              (inv) => inv.name === 'cryptoPercent'
            ).value,
            commoditiesPercent: newAssetMixList.find(
              (inv) => inv.name === 'commoditiesPercent'
            ).value,
            emktBondPercent: newAssetMixList.find(
              (inv) => inv.name === 'emktBondPercent'
            ).value,
            emktStockPercent: newAssetMixList.find(
              (inv) => inv.name === 'emktStockPercent'
            ).value,
            intlBondPercent: newAssetMixList.find(
              (inv) => inv.name === 'intlBondPercent'
            ).value,
            intlStockPercent: newAssetMixList.find(
              (inv) => inv.name === 'intlStockPercent'
            ).value,
            customVol: !isCustomSelected
              ? 0
              : this.percentageConversion(updatedCustomVol),
            customReturn: !isCustomSelected
              ? 0
              : this.percentageConversion(updatedCustomReturn),
            customDivRate: !isCustomSelected
              ? 0
              : this.percentageConversion(updatedCustomDivRate),
            invBalance: this.transformBackToAPIType(updatedInvBalance),
            products: products,
          };
        } else {
          return {
            ...acc,
            products: acc.products?.map((m) => {
              return {
                ticker: m.ticker,
                balance: this.transformBackToAPIType(m.balance),
                price: this.transformBackToAPIType(m.price),
                quantity: !isNaN(m.quantity) ? parseFloat(m.quantity) : 0,
                type: m.type,
              }
            }) || [],
            altPercent: this.percentageConversion(acc.altPercent),
            bondPercent: this.percentageConversion(acc.bondPercent),
            cashPercent: this.percentageConversion(acc.cashPercent),
            reitPercent: this.percentageConversion(acc.reitPercent),
            shortTermPercent: this.percentageConversion(acc.shortTermPercent),
            stockPercent: this.percentageConversion(acc.stockPercent),
            cryptoPercent: this.percentageConversion(acc.cryptoPercent),
            commoditiesPercent: this.percentageConversion(
              acc.commoditiesPercent
            ),
            emktBondPercent: this.percentageConversion(acc.emktBondPercent),
            emktStockPercent: this.percentageConversion(acc.emktStockPercent),
            intlBondPercent: this.percentageConversion(acc.intlBondPercent),
            intlStockPercent: this.percentageConversion(acc.intlStockPercent),
            customVol: !isCustomSelected
              ? 0
              : this.percentageConversion(customVol),
            customReturn: !isCustomSelected
              ? 0
              : this.percentageConversion(customReturn),
            customDivRate: !isCustomSelected
              ? 0
              : this.percentageConversion(customDivRate),
            invBalance: this.transformBackToAPIType(invBalance),
          };
        }
      }),
    };
    if (oldInvestmentBucket !== "") {
      tempMember = {
        ...tempMember,
        [`num${this.capitalizeFirstLetter(oldInvestmentBucket)}`]: availableAccountsAfterMoving.length
      };
    } else {
      // If oldInvestmentBucket is empty, remove the `num<oldInvestmentBucket>` field
      const { [`num${this.capitalizeFirstLetter(oldInvestmentBucket)}`]: removed, ...rest } = tempMember;
      tempMember = rest;
    }
    return tempMember;
  };

  getNextPlaidAccTabValue = (plaidAccountProcessedInfo) => {
    const {
      plaidAccountDetails: { accounts = [] },
    } = this.state;
    const remainingIndexes = accounts
      .map((a, idx) => {
        return { index: idx };
      })
      .filter((f) => {
        return !plaidAccountProcessedInfo.find((p) => {
          return p.plaidIndex === f.index;
        });
      });
    return remainingIndexes.length > 0
      ? parseInt(remainingIndexes[0].index)
      : 0;
  };

  updateAssetMix = () => {
    const {
      memberValues,
      selectedMember,
      plaidAccTabValue,
      plaidAccountDetails: { accounts = [] },
      plaidAccountProcessedInfo,
      selectedYesEvent,
      localProdInfo,
    } = this.state;
    const { updateAssetMixInRedux, invValueAsOf, setLastStepInParent } =
      this.props;
    const { first, second } = memberValues;
    const newMemberValues = {
      ...memberValues,
    };
    let tempFirst = JSON.parse(JSON.stringify(first)) || {};
    tempFirst = this.convertToAPIType(tempFirst);
    let tempSecond = JSON.parse(JSON.stringify(second)) || {};
    tempSecond = this.convertToAPIType(tempSecond);
    if (selectedMember === 'first') {
      newMemberValues.first = this.convertedNewMemberObject(tempFirst);
      newMemberValues.second = tempSecond;
      this.getAllAccountsFromBuckets(newMemberValues.first);
    } else if (selectedMember === 'second') {
      newMemberValues.second = this.convertedNewMemberObject(tempSecond);
      newMemberValues.first = tempFirst;
      this.getAllAccountsFromBuckets(newMemberValues.second);
    }
    if (selectedYesEvent && accounts.length > 0) {
      let tempPlaidAccountProcessedInfo = [...plaidAccountProcessedInfo];
      if (
        tempPlaidAccountProcessedInfo.indexOf(
          tempPlaidAccountProcessedInfo.filter(
            (f) =>
              f.plaidIndex === plaidAccTabValue && f.isPlaidAccountProcessed
          )[0]
        ) === -1
      ) {
        tempPlaidAccountProcessedInfo.push({
          plaidIndex: plaidAccTabValue,
          isPlaidAccountProcessed: true,
        });
      }
      this.setState({
        selectedYesEvent: true,
        stepIndex: 1,
        newNickName: '',
        plaidAccTabValue:
          accounts.length === tempPlaidAccountProcessedInfo.length
            ? 0
            : this.getNextPlaidAccTabValue(tempPlaidAccountProcessedInfo),
        plaidAccountProcessedInfo: [...tempPlaidAccountProcessedInfo],
        memberValues: newMemberValues,
        tickersAddedToPlan: [],
        tickersAvailableInPlan: [],
        selectedTicker: '',
        selectedProductType: '',
      });
    } else {
      this.setState({
        stepNoIndex: 1,
        isEditable: false,
        isAddNew: false,
        selectedYesEvent: false,
        newNickName: '',
        tickersAddedToPlan: [],
        tickersAvailableInPlan: [],
        memberValues: newMemberValues,
        selectedInvestmentAccount: {},
        selectedTicker: '',
        selectedProductType: '',
      });
    }
    setLastStepInParent(true);
    updateAssetMixInRedux({
      invbalanceReq: newMemberValues,
      invValueAsOf,
      localProdInfo,
    });
  };

  // setSelectedInvReturnOption = (tab) => {
  //   this.setState({
  //     selectedInvReturnOption: tab.id,
  //   });
  // };

  handleBlur = (event, field) => {
    const { value } = event && event.target;
    const { selectedInvestmentAccount, assetMixListForInv } = this.state;
    let newCustValue = value;
    if (!value.toString().includes('%')) {
      const percentCustVal =
        value !== '' && !isNaN(value) ? parseFloat(value).toFixed(2) : 0;
      newCustValue = `${percentCustVal}%`;
    }
    const newAssetMixList = assetMixListForInv.map((list) => {
      if (list.name === field) {
        return {
          ...list,
          value: newCustValue,
        };
      }
      return {
        ...list,
      };
    });
    const sumOfAssetMixValue = newAssetMixList.reduce(function (acc, val) {
      return acc + parseFloat(val.value);
    }, 0);

    const tempSelectedInvestmentAccount = {
      ...selectedInvestmentAccount,
      [field]: newCustValue,
    };
    this.setState({
      assetMixListForInv: [...newAssetMixList],
      isAssetMixPercentNotEqualTo100: sumOfAssetMixValue === 100 ? false : true,
      sumOfAssetMixValue,
      selectedInvestmentAccount: tempSelectedInvestmentAccount,
    });
  };

  setSelectedInvReturnValue = (event, fieldKey) => {
    let { value } = event.target;
    const { selectedInvestmentAccount } = this.state;
    // if (value.toString().includes('%')) {
    //   const percentFieldValue = value.split('%')[0];
    //   value =
    //     percentFieldValue !== '' && !isNaN(percentFieldValue)
    //       ? percentFieldValue
    //       : '';
    // } else {
    //   value = value !== '' && !isNaN(value) ? value : '';
    // }
    const tempSelectedInvestmentAccount = {
      ...selectedInvestmentAccount,
      [fieldKey]: value,
    };
    this.setState({
      selectedInvestmentAccount: tempSelectedInvestmentAccount,
    });
  };

  calculateQuantity = (ticker) => {
    let { balance = '', price = '' } = ticker || {};
    balance = this.getConvertedValue(balance); // Convert "$10,000" to float
    price = this.getConvertedValue(price); // Convert "$100" to float
    return price ? balance / price : 0; // Prevent division by zero
  };

  calculateBalance = (ticker) => {
    let { quantity = 0, price = 0 } = ticker || {};
    price = this.getConvertedValue(price); // Convert "$100" to float
    quantity = !isNaN(quantity) ? parseFloat(quantity) : 0; // Convert "$100" to float
    return this.formatCurrency(price * quantity);
  };

  handleOnBlurTickers = (event, index, type) => {
    const value =
      type !== 'quantity'
        ? this.formatCurrency(event?.target?.value)
        : event?.target?.value;

    const { tickersAddedToPlan } = this.state;

    // Update the tickers in a single map operation
    const tempTickersAddedToPlan = tickersAddedToPlan.map((ticker, i) => {
      // Create a copy of the ticker
      const updatedTicker = { ...ticker };

      // If the index matches, update the appropriate field
      if (i === index) {
        updatedTicker[type] = value;
      }

      // Always update the balance for each ticker
      updatedTicker.balance = this.calculateBalance(updatedTicker);

      return updatedTicker;
    });

    // Calculate the account balance
    const accBalance = this.formatCurrency(
      this.updateBalance(tempTickersAddedToPlan)
    );

    // Update the state with the new tickers and account balance
    this.setState({ tickersAddedToPlan: tempTickersAddedToPlan, accBalance });
  };

  // updateRemainingBalance = (tempTickersAddedToPlan, balance) => {
  //   const amounts = tempTickersAddedToPlan
  //     .map(
  //       (m) => this.getConvertedValue(m.balance) //to remove empty values
  //     )
  //     .filter((a) => a);
  //   //taking only amounts property
  //   const amountUsed = amounts.reduce(
  //     (a, b) => parseFloat(a) + parseFloat(b),
  //     0
  //   ); //now adding them (a + b is nthg but actual values like 10 + 20)
  //   let tempBalance = this.getConvertedValue(balance);
  //   const remainingBalance =
  //     amountUsed !== '' && !isNaN(amountUsed)
  //       ? parseFloat(tempBalance) - parseFloat(amountUsed)
  //       : 0;
  //   let inValidAmount = false;
  //   if (amountUsed > tempBalance) {
  //     inValidAmount = true;
  //   }
  //   let amountUsedFormatted = '$0';
  //   if (amountUsed !== '' && !isNaN(amountUsed)) {
  //     const numFormat = Intl.NumberFormat('en-US');
  //     amountUsedFormatted = numFormat.format(amountUsed);
  //   }
  //   this.setState({ inValidAmount, amountUsed: `$${amountUsedFormatted}` });
  //   return remainingBalance;
  // };

  // purchaseTicker = (event, i, balance) => {
  //   const { selectedInvReturnOption } = this.state;
  //   const usedBalance = this.formatCurrency(event?.target?.value);
  //   const { tickersAddedToPlan } = this.state;
  //   let tempTickersAddedToPlan = [...tickersAddedToPlan];
  //   tempTickersAddedToPlan.forEach((f, index) => {
  //     if (i === index) {
  //       f.balance = usedBalance;
  //     }
  //   });
  //   const remainingBalance = this.updateRemainingBalance(
  //     tempTickersAddedToPlan,
  //     balance
  //   );
  //   this.setState({
  //     remainingBalance,
  //     tickersAddedToPlan: tempTickersAddedToPlan,
  //     selectedTicker: '',
  //     selectedProductType: '',
  //     selectedInvReturnOption:
  //       tempTickersAddedToPlan.length > 0
  //         ? 'products'
  //         : selectedInvReturnOption,
  //   });
  // };

  onTickerChange = async (event) => {
    const { value: selectedTicker } = event.target;
    const { localProdInfo, tickersAddedToPlan } = this.state;
    // Fetch ticker details
    const { status, data } = await getTickerDetails(selectedTicker);
    if (status === 200) {
      const tickerDetails = data;
      // Find the ticker info
      const tickerInfo = tickerDetails.find((t) => t.ticker === selectedTicker);
      if (!tickerInfo) return; // If no ticker info found, early exit
      // Update tickersAddedToPlan
      const existingTicker = tickersAddedToPlan.find(
        (t) => t.ticker === selectedTicker
      );
      if (!existingTicker) {
        tickersAddedToPlan.unshift({
          ticker: tickerInfo.ticker,
          desc: tickerInfo.desc,
          balance: 0,
          quantity: 0,
          price: this.formatCurrency(tickerInfo.currentPrice),
          type: tickerInfo.type,
        });
      }
      // Update localProdInfo
      if (tickerInfo.prodInfo && Object.keys(tickerInfo.prodInfo).length > 0) {
        const existingProdInfo = localProdInfo.find(
          (p) => p.ticker === tickerInfo.prodInfo.ticker
        );
        if (!existingProdInfo) {
          this.setState((prevState) => ({
            localProdInfo: [...prevState.localProdInfo, tickerInfo.prodInfo],
          }));
        }
      }
      // Set state with selectedTicker and updated tickersAddedToPlan
      this.setState({
        selectedTicker,
        tickersAddedToPlan: [...tickersAddedToPlan], // Make sure to create a new array
      });
    }
  };

  onProductTypeChange = (event) => {
    let { value: selectedProductType } = event.target;
    const { investmentProducts } = this.props;
    const selectedProductTickersInfo = investmentProducts.filter(
      (i) => i.productType === selectedProductType
    );
    let tickers = [];
    if (selectedProductTickersInfo && selectedProductTickersInfo.length > 0) {
      tickers = selectedProductTickersInfo[0].tickers;
    }
    this.setState({ selectedProductType, tickers });
  };

  deleteTicker = (e, index, ticker) => {
    const { tickersAddedToPlan, localProdInfo } = this.state;
    // Remove the ticker at the specified index
    const updatedTickers = tickersAddedToPlan.filter((_, i) => i !== index);
    // Update account balance after ticker removal
    const accBalance = this.formatCurrency(this.updateBalance(updatedTickers));
    // Update product info by filtering out the ticker
    // const updatedProdInfo = localProdInfo.filter(
    //   (item) => item.ticker.toLowerCase() !== ticker?.ticker?.toLowerCase()
    // );
    // Update state
    this.setState({
      tickersAddedToPlan: updatedTickers,
      selectedTicker: '',
      selectedProductType: '',
      accBalance,
      //localProdInfo: updatedProdInfo,
    });
  };

  transformBackToAPIType = (value) => {
    if (value) {
      let numVal = value;
      if (isNaN(numVal) && numVal.toString().includes('$')) {
        numVal = parseFloat(numVal.split('$')[1].replace(/,/g, ''));
      }
      return numVal;
    } else if (value === 0 || value === '') {
      return 0;
    }
  };

  fnToRemoveCommaAndParse = (value) => {
    let valueWithComma = '';
    if (isNaN(value) && value.toString().includes('$')) {
      valueWithComma = value.split('$')[1];
    } else if (!isNaN(value)) {
      valueWithComma = value;
    } else {
      valueWithComma = '';
    }
    const valueWithoutComma = valueWithComma.replace(/,/g, '');
    return !isNaN(valueWithoutComma) && parseFloat(valueWithoutComma);
  };

  onInvBucketChange = (event) => {
    let { value } = event.target;
    const { selectedInvestmentAccount = {}, newNickName = '' } = this.state;
    const selectedAccountInfo = investmentFields.filter(
      (f) => f.id.toLowerCase() === value.toLowerCase()
    );
    let selectedInvestmentBucket = '';
    if (selectedAccountInfo && selectedAccountInfo.length > 0) {
      selectedInvestmentBucket = selectedAccountInfo[0].id;
    }
    this.setState({
      selectedInvestmentBucket,
      oldInvestmentBucket: selectedInvestmentAccount?.investmentBucket || '',
      // selectedInvestmentAccount: {
      //   ...selectedInvestmentAccount,
      //   investmentBucket: selectedInvestmentBucket,
      //   id:
      //     newNickName !== ''
      //       ? `${newNickName}-${selectedInvestmentBucket}`
      //       : '',
      // },
    });
  };

  renderInvestmentBuckets = () => {
    const { selectedInvestmentBucket } = this.state;
    return (
      <div className="row align-items-center">
        <div className="market_data_row">
          <label  className="required">Investment Type</label>
          <Controls.Select
            className="js-example-basic-single"
            name="Account"
            maxMenuHeight={150}
            id="Account"
            value={selectedInvestmentBucket || ''}
            onChange={(e) => this.onInvBucketChange(e)}
            options={investmentFields.map((p, index) => {
              return { label: p.title, value: p.id, index: index };
            })}
          />
        </div>
      </div>
    );
  };

  updateTickerPrice = (event, index, balance) => {
    //const price = this.formatCurrency(event?.target?.value);
    const price = event?.target?.value;
    const { tickersAddedToPlan } = this.state;
    const tempTickersAddedToPlan = tickersAddedToPlan.map((ticker, i) => {
      // Initialize the updated ticker object
      const updatedTicker = {
        ...ticker,
        price: i === index ? price : ticker.price, // Update price conditionally
      };
      // Calculate balance using the possibly updated price
      //updatedTicker.balance = this.calculateBalance(updatedTicker);
      return updatedTicker;
    });
    // const remainingBalance = this.updateRemainingBalance(
    //   tempTickersAddedToPlan,
    //   balance
    // );
    this.setState({
      tickersAddedToPlan: tempTickersAddedToPlan,
    });
  };

  updateTickerQuantity = (e, index, balance) => {
    let { value: quantity } = e.target;
    const { tickersAddedToPlan } = this.state;
    const tempTickersAddedToPlan = tickersAddedToPlan.map((ticker, i) => {
      // Initialize the updated ticker object
      const updatedTicker = {
        ...ticker,
        quantity: i === index ? parseFloat(quantity) : ticker.quantity, // Update price conditionally
      };
      // Calculate balance using the possibly updated price
      //updatedTicker.balance = this.calculateBalance(updatedTicker);
      return updatedTicker;
    });
    // const remainingBalance = this.updateRemainingBalance(
    //   tempTickersAddedToPlan,
    //   balance
    // );
    this.setState({
      tickersAddedToPlan: tempTickersAddedToPlan,
      // remainingBalance,
    });
  };

  // toggleQuantityToValue = (e, index) => {
  //   const { checked: isQuantitySelected } = e.target;
  //   const { tickersAddedToPlan } = this.state;
  //   let tempTickersAddedToPlan = [...tickersAddedToPlan];
  //   tempTickersAddedToPlan.forEach((f, i) => {
  //     if (i === index) {
  //       f.isQuantitySelected = isQuantitySelected
  //     }
  //   });
  //   this.setState({
  //     tickersAddedToPlan: tempTickersAddedToPlan,
  //   });
  // };

  handleProductsSelection = (e) => {
    let fieldValue =
      e.target.checked && e.target.defaultValue === 'true' ? true : false;
    this.setState({ isProductsSelected: fieldValue });
  };

  handleCustomSelection = (e) => {
    let fieldValue =
      e.target.checked && e.target.defaultValue === 'true' ? true : false;
    this.setState({ isCustomSelected: fieldValue });
  };

  renderAssetMixForm = (from) => {
    const {
      assetMixListForInv,
      selectedMember,
      selectedYesEvent,
      isAssetMixPercentNotEqualTo100,
      selectedProductType,
      selectedTicker,
      tickers,
      tickersAddedToPlan,
      remainingBalance,
      sumOfAssetMixValue,
      accBalance,
      inValidAmount,
      amountUsed,
      isButtonDisabled,
      buttonDisabledMsg,
      newNickName,
      selectedInvestmentAccount,
      availableInvestmentAccounts,
      selectedInvestmentBucket,
      isProductsSelected,
      isCustomSelected,
    } = this.state;
    const {
      customVol = '0.00%',
      customReturn = '0.00%',
      customDivRate = '0.00%',
    } = selectedInvestmentAccount || {};
    const { investmentProducts } = this.props;
    // const memberInfo =
    //   selectedMember &&
    //   selectedMemberObj.length > 0 &&
    //   selectedMemberObj.filter(
    //     (f) => f.key.toLowerCase() === selectedMember.toLowerCase()
    //   )[0];
    // const { title = '' } = memberInfo || {};
    // const selectedInvDetailLabel = investmentFields.find(
    //   (invDetail) =>
    //     invDetail.id.toLowerCase() === selectedInvestmentBucket.toLowerCase()
    // );
    const currentAcc = selectedInvestmentAccount;
    // const formattedBalance = isNaN(accBalance)
    //   ? accBalance
    //   : parseFloat(accBalance).toLocaleString('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    //     minimumFractionDigits: 0,
    //   });
    // let availableBalance = accBalance;
    // if (tickersAddedToPlan && tickersAddedToPlan.length > 0) {
    //   availableBalance = remainingBalance;
    // }
    // availableBalance =
    //   availableBalance !== '' && isNaN(availableBalance)
    //     ? this.fnToRemoveCommaAndParse(availableBalance)
    //     : availableBalance;
    // const investmentLabelText = (
    //   <>
    //     Enter Asset Profile and Asset Mix for (Risk/Return characteristics){' '}
    //     <b>{(title && title.toUpperCase()) || ''}</b>'s{' '}
    //     {selectedInvDetailLabel?.title} Account{' '}
    //     <b> {(currentAcc && currentAcc.nickName) || ''}</b> with balance{' '}
    //     <b> {formattedBalance}</b> of type <b>{selectedInvestmentBucket}</b>:
    //   </>
    // );
    return (
      <>
        <div className="markets_grid_full">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="insured_main_outer">
                {this.renderInputForm(from, currentAcc)}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="insured_main_outer">
                {this.renderInvestmentBuckets()}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="insured_main_outer">
                {this.renderInputFormOnBlur()}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="markets_grid_full">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-12">
              {inValidAmount && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
                      <Alert severity="warning">
                        <p>
                          Account has Products with {amountUsed}, if you need to
                          reduce the account value to below {amountUsed}, then
                          please adjust the below details{' '}
                        </p>
                      </Alert>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </div> */}

        <div className="markets_grid_full">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-12">
              {isButtonDisabled && buttonDisabledMsg && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
                      <Alert severity="error">
                        <p>{buttonDisabledMsg}</p>
                      </Alert>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </div>

        <div className="expenses_grid_main_outer">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-8 col-sm-8">
              <p className="mb-0">Do you have product details?</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <Controls.RadioGroup
                name="member-radio-buttons"
                value={isProductsSelected}
                rowType={true}
                onChange={this.handleProductsSelection}
                items={radioOptions}
              ></Controls.RadioGroup>
            </div>
          </div>
        </div>

        {!isProductsSelected && (
          <>
            <div className="GenericExpences_no">
              <div className="row align-items-center">
                <div className="col-lg-8 col-md-6 col-sm-4">
                  <p className="mb-0">
                    Does this account have customized risk/return?
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-8">
                  <Controls.RadioGroup
                    name="member-radio-buttons"
                    value={isCustomSelected}
                    rowType={true}
                    onChange={this.handleCustomSelection}
                    items={radioOptions}
                  ></Controls.RadioGroup>
                </div>
              </div>
            </div>
            {/* <div className="expenses_grid_main_outer">
              {expenseTab === 'aggregate' && renderAggregateFields()}
              {expenseTab === 'itemized' && renderItemizedFields()}
            </div> */}
          </>
        )}

        {/* <div className="markets_grid_full">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-12">
              <p className="select-bank-text">{investmentLabelText}</p>
            </div>
          </div>
        </div> */}

        {/* <div className="markets_grid_full">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-12">
              <div className="chart_filter">
                <ul className="tabs">
                  {invReturnOptions.map((m) => {
                    return (
                      <li
                        className={
                          selectedInvReturnOption === m.id
                            ? 'tab active'
                            : 'tab'
                        }
                        disabled={
                          m.id !== 'products' && tickersAddedToPlan.length > 0
                        }
                      >
                        <button
                          onClick={() => this.setSelectedInvReturnOption(m)}
                          disabled={
                            m.id !== 'products' && tickersAddedToPlan.length > 0
                          }
                        >
                          {m.title}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div> */}

        <div className="markets_grid_full">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-12">
              <Grid container>
                {isProductsSelected && (
                  <Grid item xs={12}>
                    <div className="select_product_main_outer">
                      <div className="select_product_dropdown_outer">
                        <div className="select_product_title">
                          <h5 className="mb-0 title-div">Select Product</h5>
                        </div>
                        <Controls.Select
                          className="js-example-basic-single"
                          name="select_product"
                          id="select_product"
                          value={selectedProductType}
                          onChange={this.onProductTypeChange}
                          options={investmentProducts.map((t) => {
                            return {
                              label: t.productType,
                              value: t.productType,
                            };
                          })}
                        />
                      </div>
                      <div className="select_product_dropdown_outer">
                        <div className="select_product_title">
                          <h5 className="mb-0 title-div">Add Ticker</h5>
                        </div>
                        <Controls.Select
                          className="js-example-basic-single"
                          name="select_ticker"
                          id="select_ticker"
                          value={selectedTicker}
                          options={tickers.map((t) => {
                            return { label: t.ticker, value: t.ticker };
                          })}
                          onChange={(e) => this.onTickerChange(e)}
                          allowAddNewItem
                        />
                      </div>
                    </div>
                    <div className="select_product_table_main_outer">
                      {tickersAddedToPlan &&
                        tickersAddedToPlan.length > 0 &&
                        tickersAddedToPlan.map((ticker, index) => {
                          return (
                            <div className="select_product_table_inner">
                              <div className="select_product_table_column">
                                <div className="select_product_table_content">
                                  <Tooltip
                                    title={ticker.desc}
                                    placement="bottom-end"
                                  >
                                    <p className="value">{ticker.ticker}</p>
                                  </Tooltip>
                                </div>
                                <div className="select_product_table_amount">
                                  <input
                                    id={`tickerQuantity-${index}`}
                                    placeholder="Quantity"
                                    value={ticker.quantity || ''}
                                    type="number"
                                    onChange={(e) => {
                                      this.updateTickerQuantity(
                                        e,
                                        index,
                                        accBalance
                                      );
                                    }}
                                    onBlur={(event) => {
                                      this.handleOnBlurTickers(
                                        event,
                                        index,
                                        'quantity'
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="select_product_table_column">
                                <div className="select_product_table_amount">
                                  <input
                                    id={`tickerPrice-${index}`}
                                    placeholder="Price"
                                    disabled={
                                      index === tickersAddedToPlan.length - 1
                                    }
                                    value={ticker.price || ''}
                                    onChange={(e) => {
                                      this.updateTickerPrice(
                                        e,
                                        index,
                                        accBalance
                                      );
                                    }}
                                    onBlur={(event) => {
                                      this.handleOnBlurTickers(
                                        event,
                                        index,
                                        'price'
                                      );
                                    }}
                                  />
                                </div>
                                <div className="select_product_table_amount">
                                  <input
                                    id={`tickerAmount-${index}`}
                                    placeholder="Amount $"
                                    disabled
                                    value={ticker.balance || ''}
                                  />
                                </div>
                                <div className="select_product_table_delete">
                                  {index !== tickersAddedToPlan.length - 1 && (
                                    <button>
                                      <img
                                        src={delete_icon}
                                        title=""
                                        alt=""
                                        onClick={(e) =>
                                          this.deleteTicker(e, index, ticker)
                                        }
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {/* <div className="select_product_table_inner">
                          <div className="select_product_table_content">
                            <p>Cash Left</p>
                          </div>
                          <div className="select_product_table_amount">
                            <input
                              type="text"
                              id="remaining_Balance"
                              name="Remaining Balance"
                              value={this.transformValue(availableBalance)}
                              disabled
                            />
                          </div>
                        </div> */}
                    </div>
                  </Grid>
                )}
                <div className="asset-mix-input-outer">
                  {!isProductsSelected && isCustomSelected && (
                    <Grid item>
                      <>
                        <Grid container xs={12} md={6}>
                          <Grid
                            item
                            xs={6}
                            className="planner-label"
                            style={{ padding: '10px 0' }}
                          >
                            Custom Return(%)
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: '10px' }}>
                            <input
                              label="Custom Return"
                              className="planner-input"
                              type="text"
                              value={customReturn}
                              onChange={(event) =>
                                this.setSelectedInvReturnValue(
                                  event,
                                  'customReturn'
                                )
                              }
                              onBlur={(event) =>
                                this.handleBlur(event, 'customReturn')
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            className="planner-label"
                            style={{ padding: '10px 0' }}
                          >
                            Custom Volatality(%)
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: '10px' }}>
                            <input
                              label="Custom Volatality"
                              className="planner-input"
                              type="text"
                              value={customVol}
                              onChange={(event) =>
                                this.setSelectedInvReturnValue(
                                  event,
                                  'customVol'
                                )
                              }
                              onBlur={(event) =>
                                this.handleBlur(event, 'customVol')
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            className="planner-label"
                            style={{ padding: '10px 0' }}
                          >
                            Custom Dividend(%)
                          </Grid>
                          <Grid item xs={6} style={{ marginTop: '10px' }}>
                            <input
                              label="Custom Dividend"
                              className="planner-input"
                              type="text"
                              value={customDivRate}
                              onChange={(event) =>
                                this.setSelectedInvReturnValue(
                                  event,
                                  'customDivRate'
                                )
                              }
                              onBlur={(event) =>
                                this.handleBlur(event, 'customDivRate')
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          spacing={2}
                          style={{ marginTop: '10px' }}
                        >
                          <Grid item xs={12}>
                            <Alert severity="warning">
                              <p className="select-bank-text">
                                Please make sure to enter Custom Return, Custom
                                Volatality and Custom Dividend Rate
                              </p>
                            </Alert>
                          </Grid>
                        </Grid>
                        <hr />
                      </>
                    </Grid>
                  )}
                  {!isProductsSelected && (
                    <Grid item xs={12}>
                      <div className="asset-mix-input">
                        {assetMixListForInv.map((list) => (
                          <Grid container>
                            <Grid
                              item
                              xs={6}
                              className="planner-label"
                              style={{ padding: '10px 0' }}
                            >
                              {list.label}
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: '10px' }}>
                              <input
                                className="planner-input"
                                name={list.name}
                                value={list.value || ''}
                                label={list.label}
                                onChange={(event) =>
                                  this.setAssetMixValue(event, list.name)
                                }
                                onBlur={(event) =>
                                  this.handleBlur(event, list.name)
                                }
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </div>
                    </Grid>
                  )}

                  {!isProductsSelected && isAssetMixPercentNotEqualTo100 && (
                    <Grid item xs={12}>
                      <Stack sx={{ width: '100%', padding: '2px' }}>
                        <Alert severity="error">
                          <p>
                            Asset mix should add upto 100%, currently it is at{' '}
                            {sumOfAssetMixValue}%{' '}
                          </p>
                        </Alert>
                      </Stack>
                    </Grid>
                  )}
                </div>
              </Grid>
            </div>
          </div>
        </div>

        <div className="markets_grid_full">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-12">
              <Grid container spacing={2}>
                <Grid item xs={12} className={`${'save_button'}`}>
                  <div className="plaid_save_button_group">
                    <button
                      onClick={(e) => {
                        selectedYesEvent
                          ? this.setNextStepIndex1(e)
                          : this.setNextStepNoIndex2(e, 'cancel');
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className={
                        (isAssetMixPercentNotEqualTo100 &&
                          !isProductsSelected) ||
                          !newNickName ||
                          !selectedInvestmentBucket
                          ? 'secondary-button'
                          : ''
                      }
                      onClick={this.updateAssetMix}
                      disabled={
                        (isAssetMixPercentNotEqualTo100 &&
                          !isProductsSelected) ||
                        !newNickName ||
                        !selectedInvestmentBucket
                      }
                    >
                      Update
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  };

  renderBalanceOptions = () => {
    const { updateInvValueAsOfInStore, invValueAsOf } = this.props;
    const { selectedInvestmentAccount, selectedOption } = this.state;
    const { stepIndex, selectedYesEvent, stepNoIndex, plaidLinkToken } =
      this.state;

    const {
      setPlaidLinkSuccessAction,
      setPlaidLinkAccessToken,
      plaidLinkSuccess,
      userDetails: { userType = '' },
    } = this.props;
    return (
      <>
        <div className="action-content">
          {selectedOption === 'manual' ? (
            <>
              {this.renderMemberTabs()}

              {(stepNoIndex === 1 || stepIndex === 1) && (
                <>
                  {userType !== USERTYPES.ADVISOR && (
                    <div className="row Select-Update-row ">
                      <div className="col-lg-6 col-md-6 col-6">
                        <p class="mb-0">
                          Would you like to get current investments using Plaid
                          ?
                        </p>
                      </div>
                      <div className="col-lg-6 col-md-6 col-6">
                        <div className="chart_filter">
                          <div className="markets-radio-buttons">
                            <p>
                              <input
                                type="radio"
                                value="manual"
                                id="manual"
                                checked={selectedOption === 'manual'}
                                onChange={this.handleOptionChange}
                              />
                              <label for="manual">No</label>
                            </p>
                            <p>
                              <input
                                type="radio"
                                value="plaid"
                                id="plaid"
                                checked={selectedOption === 'plaid'}
                                onChange={this.handleOptionChange}
                              />
                              <label for="plaid">Yes</label>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {this.renderSelectAccountDropdown()}
              {this.deleteConfirmation()}

              <div className="financial-instn-container">
                <p className="select-bank-text">
                  Select the investment that you want to manually enter or
                  update what you automatically fetched from your financial
                  institutions:
                </p>
              </div>

              {selectedInvestmentAccount &&
                Object.keys(selectedInvestmentAccount).length > 0 && (
                  <div className="insured_main_outer">
                    {this.renderInputFormBalance()}
                  </div>
                )}
            </>
          ) : (
            <>
              {(stepNoIndex === 1 || stepIndex === 1) && (
                <>
                  <div className="row Select-Update-row ">
                    <div className="col-lg-6 col-md-6 col-sm-4">
                      <p class="mb-0">
                        Would you like to get current investments using Plaid ?
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-4">
                      <div className="chart_filter">
                        <div className="markets-radio-buttons">
                          <p>
                            <input
                              type="radio"
                              value="manual"
                              id="manual"
                              checked={selectedOption === 'manual'}
                              onChange={this.handleOptionChange}
                            />
                            <label for="manual">No</label>
                          </p>
                          <p>
                            <input
                              type="radio"
                              value="plaid"
                              id="plaid"
                              checked={selectedOption === 'plaid'}
                              onChange={this.handleOptionChange}
                            />
                            <label for="plaid">Yes</label>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <PlaidLinkFn
                linkToken={plaidLinkToken}
                setPlaidLinkSuccessAction={setPlaidLinkSuccessAction}
                setPlaidLinkAccessToken={setPlaidLinkAccessToken}
                clearProcessedData={this.clearProcessedData}
                setToPlaid={this.setToPlaid}
                autoOpenPlaid={selectedOption === 'plaid'}
                setSelectedOption={(option) =>
                  this.setState({ selectedOption: option })
                }
              />
            </>
          )}
        </div>
      </>
    );
  };

  clearProcessedData = () => {
    this.setState({ plaidAccountProcessedInfo: [], plaidAccTabValue: 0 });
  };

  render() {
    const {
      stepIndex,
      selectedYesEvent,
      stepNoIndex,
      plaidLinkToken,
      selectedOption,
      isEditable,
      isAddNew,
    } = this.state;

    const {
      setPlaidLinkSuccessAction,
      setPlaidLinkAccessToken,
      plaidLinkSuccess,
      userDetails: { userType = '' },
      renderPreviousAndNextButtons,
    } = this.props;
    return (
      <>
        <div className="plaid-container">
          <>
            {/* {(stepNoIndex === 1 || stepIndex === 1) && (
            <div className="chart_filter">
              <ul className="tabs">
                <li className={selectedYesEvent ? 'tab active' : 'tab'}>
                  {userType !== USERTYPES.ADVISOR && (
                    <PlaidLinkFn
                      linkToken={plaidLinkToken}
                      setPlaidLinkSuccessAction={setPlaidLinkSuccessAction}
                      setPlaidLinkAccessToken={setPlaidLinkAccessToken}
                      clearProcessedData={this.clearProcessedData}
                      setToPlaid={this.setToPlaid}
                      setSelectedOption={(option) =>
                        this.setState({ selectedOption: option })
                      }
                    />
                  )}
                </li>
                <li className={!selectedYesEvent ? 'tab active' : 'tab'}>
                  <Button
                    onClick={this.selectedNoEvent}
                    style={{
                      fontWeight: 700,
                      fontSize: '12px',
                    }}
                  >
                    MANUAL
                  </Button>
                </li>
              </ul>
            </div>
          )} */}
          </>
          {selectedYesEvent && (
            <>
              {stepIndex === 1 && this.renderPlaidAccountsInfo()}
              {stepIndex === 2 && this.renderAssetMixForm('plaid')}
            </>
          )}

          {!selectedYesEvent && (
            <>
              {stepNoIndex === 1 && this.renderBalanceOptions()}
              {stepNoIndex === 3 && this.renderAssetMixForm()}
            </>
          )}
        </div>
        {!isEditable && !isAddNew && renderPreviousAndNextButtons()}
      </>
    );
  }
}

export default BuildInvestmentsMarketsComponent;
