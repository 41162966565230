import React from 'react';

export const CustomNotes = (props) => {
  const { notes, renderWhatData } = props;

  const renderCustomNotes = () => {
    const { tabName, subtabName } = renderWhatData;
    if (notes && notes.length > 0) {
      const selectedNotesData = notes.filter(
        (t) => t && tabName && t.tabName.toLowerCase() === tabName.toLowerCase()
      )[0];
      const selectedSubNotesData =
        (selectedNotesData &&
          selectedNotesData.subtabs &&
          selectedNotesData.subtabs.length > 0 &&
          selectedNotesData.subtabs.filter(
            (s) =>
              s &&
              subtabName &&
              s.subtabName.toLowerCase() === subtabName.toLowerCase()
          )[0]) ||
        {};
      if (
        selectedSubNotesData &&
        Object.keys(selectedSubNotesData).length > 0
      ) {
        const { notes: contentNotes } = selectedSubNotesData;
        return (
          <div className="client_chart_main_div Table">
            <div className="client_chart_inner">
              <div className="Table_main_outer d-lg-flex">
                {contentNotes}
              </div>
            </div>
          </div>
        )
      }
      else {
        return null;
      }
    }
  }
  return <>{renderCustomNotes()}</>;
}
export default CustomNotes;
