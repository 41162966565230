import { GET, POST, CUSTOMGET, CUSTOMPOST } from '../api/fetch_wrapper';
import { convertObjToQueryStr } from '../utils/apirequestutil';
import { API_HOST_URL } from './apiHost';

export async function getAllUserInformation() {
  return GET(`${API_HOST_URL()}/selectAllUser`);
}

export async function updateUserPrivacyConsent(reqPayload) {
  const reqQueryStr = convertObjToQueryStr(reqPayload);
  return POST(`${API_HOST_URL()}/updateUserPrivacyConsent?${reqQueryStr}`);
}

export async function updateUserTermsConsent(reqPayload) {
  const reqQueryStr = convertObjToQueryStr(reqPayload);
  return POST(`${API_HOST_URL()}/updateUserTermsConsent?${reqQueryStr}`);
}

export async function createUserInformation(reqPayload) {
  // const reqQueryStr = convertObjToQueryStr(reqPayload);
  //return POST(`http://107.20.29.93:9090/public/createUser?${reqQueryStr}`);
  return POST(`${API_HOST_URL()}/createUser`, reqPayload);
}

export async function getWhoAmI1(reqPayload) {
  const reqQueryStr = convertObjToQueryStr(reqPayload);
  return GET(`${API_HOST_URL()}/login?${reqQueryStr}`);
}

export async function getWhoAmI(reqPayload) {
  //return CUSTOMGET(`${API_HOST_URL('public')}/authenticate`, reqPayload);
  const reqQueryStr = convertObjToQueryStr(reqPayload); // uncomment above for secured apis
  return GET(`${API_HOST_URL()}/login?${reqQueryStr}`);
}

export async function approveUserInfo(reqPayload) {
  const reqQueryStr = convertObjToQueryStr(reqPayload);
  return GET(`${API_HOST_URL()}/approveUser?${reqQueryStr}`);
}

export async function getClientsByAdvisorId(reqPayload) {
  const reqQueryStr = convertObjToQueryStr(reqPayload.payload);
  return GET(`${API_HOST_URL()}/selectClientInfoByAdvisorId?${reqQueryStr}`);
}

export async function getAdvisorAssetsUnderManagement(advisorId) {
  return GET(
    `${API_HOST_URL()}/selectAdvisorAumByAdvisorId?advisorId=${advisorId}`
  );
}

export async function getCompanyAumByAdvisorId(advisorId) {
  return GET(
    `${API_HOST_URL()}/selectCompanyAumByAdvisorId?advisorId=${advisorId}`
  );
}

export async function getAdvisorStatsByClientType(advisorId) {
  return GET(
    `${API_HOST_URL()}/advisorStatsByClientType?advisorId=${advisorId}`
  );
}

export async function getPlansByClientId(reqPayload) {
  const reqQueryStr = convertObjToQueryStr(reqPayload.payload);
  return GET(`${API_HOST_URL()}/selectPlansInfoByClientId?${reqQueryStr}`);
}

export async function getMeetingsByMeetingId(meetingId) {
  return GET(
    `${API_HOST_URL()}/selectMeetingByMeetingId?meetingId=${meetingId}`
  );
}

export async function getCompaniesInfo() {
  return GET(`${API_HOST_URL()}/getAllCompanies`);
}

export async function selectSavedPlanByPlanId(planId) {
  return GET(`${API_HOST_URL()}/selectSavedPlanByPlanId?palnId=${planId}`);
}

export async function selectSavedPlanByPlanIdV2(planId) {
  return GET(`${API_HOST_URL()}/v2SelectSavedPlanByPlanId?planId=${planId}`);
}

export async function saveAndUpdateMeetingDetailsByMeetingId(apiPayload) {
  return POST(`${API_HOST_URL()}/updateMeeting`, apiPayload);
}

export async function deleteMeeting(confirmDelete, meetingID) {
  return POST(
    `${API_HOST_URL()}/deleteMeeting?confirmDelete=${confirmDelete}&meetingID=${meetingID}`
  );
}

export async function saveAndUpdatePlanDetailsByPlanId(apiPayload) {
  return POST(`${API_HOST_URL()}/saveandupdate`, apiPayload);
}

export async function saveAndUpdatePlanDetailsByPlanIdV2(apiPayload) {
  return POST(`${API_HOST_URL()}/v2SaveAndUpdate`, apiPayload);
}

export async function simulatePlanDetailsByPlanId(apiPayload) {
  return POST(`${API_HOST_URL()}/simulate`, apiPayload);
}

export async function simulatePlanDetailsByPlanIdV2(apiPayload) {
  return POST(`${API_HOST_URL()}/V2simulate`, apiPayload);
}

export async function getOutputResponseByPlanId(planId) {
  return GET(`${API_HOST_URL()}/fetchResponseDataByPlanID?palnId=${planId}`);
}

export async function getAllPlannerMenuItems() {
  //return GET(`${API_HOST_URL()}/private/getAllMenuItem`);
  return GET(`${API_HOST_URL('public')}/getAllMenuItem`, true); //true for public methods
}

export async function getExpensesByLifestyle(lifeStyle, location = 'all') {
  return GET(`${API_HOST_URL()}/getExpenses/${lifeStyle}/${location}`);
}

export async function getAllTaxSlabs() {
  // return GET(`${API_HOST_URL()}/selectAllTaxSlab`);
  return GET(`${API_HOST_URL('public')}/selectAllTaxSlab`, true); //true for public methods
}

export async function getAllInstitutions(searchStr) {
  return GET(`${API_HOST_URL()}/institutions?searchQuery=${searchStr}`);
}

export async function getExpenseFactorDetails() {
  return GET(`${API_HOST_URL()}/expensesFactor`);
}

export async function createNewPlan(reqPayload) {
  const { clientId = '', fromPlanId = '', toPlanName = '' } = reqPayload;
  const encodedPlanName = encodeURIComponent(toPlanName);
  const encodedClientId = encodeURIComponent(clientId);
  return POST(
    `${API_HOST_URL()}/duplicatePlanForClient?clientId=${encodedClientId}&fromPlanId=${fromPlanId}&toPlanName=${encodedPlanName}`
  );
}

export async function createNewClient(reqPayload) {
  const {
    advisorId = '',
    clientId = '',
    clientType = '',
    firstPersonFirstName = '',
    planName = '',
    secondPersonFirstName = '',
    stateCode = '',
    mobileNumber = '',
  } = reqPayload;
  const encodedClientId = encodeURIComponent(clientId);
  const encodedAdvisorId = encodeURIComponent(advisorId);
  const encodedPlanName = encodeURIComponent(planName);
  // return POST(`${API_HOST_URL()}/createClientAndAddToAdvisor`, reqPayload);
  return POST(
    `${API_HOST_URL()}/createClientAndAddToAdvisor?advisorId=${encodedAdvisorId}&clientId=${encodedClientId}&clientType=${clientType}&firstPersonFirstName=${firstPersonFirstName}&planName=${encodedPlanName}&secondPersonFirstName=${secondPersonFirstName}&stateCode=${stateCode}&mobileNumber=${mobileNumber}`
  );
}

export async function copyDefaultPlan(reqPayload) {
  return POST(`${API_HOST_URL()}/copyPlan`, reqPayload);
}

export async function sendEmail(reqPayload) {
  return POST(`${API_HOST_URL()}/sendNotification`, reqPayload);
}

export async function sendPassCode(emailId) {
  const encodedEmailId = encodeURIComponent(emailId);
  return POST(`${API_HOST_URL()}/forgotPassword?emailId=${encodedEmailId}`);
}

export async function verifyPassCode(emailId, passcode) {
  const encodedEmailId = encodeURIComponent(emailId);
  return POST(
    `${API_HOST_URL()}/verifyPassCode?emailId=${encodedEmailId}&passcode=${passcode}`
  );
}

export async function changePassword(emailId, password) {
  const encodedEmailId = encodeURIComponent(emailId);
  return POST(
    `${API_HOST_URL()}/changePassword?emailId=${encodedEmailId}&password=${password}`
  );
}

export async function getTermLifeInsuranceProductsByClientId(clientId) {
  return GET(
    `${API_HOST_URL()}/selectInsTermLifeProductsByClientId?clientId=${clientId}`
  );
}
export async function getWholeLifeInsuranceProductsByClientId(clientId) {
  return GET(
    `${API_HOST_URL()}/selectInsWholeLifeProductsByClientId?clientId=${clientId}`
  );
}
export async function getUmbrellaInsuranceProductsByClientId(clientId) {
  return GET(
    `${API_HOST_URL()}/selectInsUmbrellaProductsByClientId?clientId=${clientId}`
  );
}

export async function getPropertyInsuranceProductsByClientId(clientId) {
  return GET(
    `${API_HOST_URL()}/selectInsPropertyProductsByClientId?clientId=$${clientId}`
  );
  //return GET(`${API_HOST_URL()}/selectAllInsPropertyProducts`);
}

export async function getAutoInsuranceProductsByClientId(clientId) {
  return GET(
    `${API_HOST_URL()}/selectInsAutoProductsByClientId?clientId=${clientId}`
  );
  //return GET(`${API_HOST_URL()}/selectAllInsPropertyProducts`);
}
export async function getInsHealthProductsByClientId(clientId) {
  return GET(
    `${API_HOST_URL()}/selectInsHealthProductsByClientId?clientId=${clientId}`
  );
  //return GET(`${API_HOST_URL()}/selectAllInsPropertyProducts`);
}

export async function getLongTermCareInsuranceProductsByClientId(clientId) {
  return GET(
    `${API_HOST_URL()}/selectInsLtcProductsByClientId?clientId=${clientId}`
  );
}
export async function getInvMarketExpectationsByAdvisorId(advisorId) {
  return GET(
    `${API_HOST_URL()}/selectInvMarketExpectationsByAdvisorId?advisorId=${advisorId}`
  );
}
export async function updateInvMarketExpectationsByAdvisorId(apiPayload) {
  return POST(
    `${API_HOST_URL()}/updateInvMarketExpectationsByAdvisorId`,
    apiPayload
  );
}
export async function updateInvMarketCorrelationsByAdvisorId(apiPayload) {
  return POST(
    `${API_HOST_URL()}/updateInvMarketCorrelationsByAdvisorId`,
    apiPayload
  );
}

export async function getClientsNotUpdatedForNumDays({ advisorId, numOfDays }) {
  return GET(
    `${API_HOST_URL()}/selectClientsNotUpdatedForNumDays?advisorId=${advisorId}&Num Days=${numOfDays}`
  );
}

export async function getClientsMeetingsForNumDays({ advisorId, numOfDays }) {
  return GET(
    `${API_HOST_URL()}/selectMtgInfoForAdvisor?advisorId=${advisorId}&numDays=${numOfDays}`
  );
}

export async function createPlaidLinkToken() {
  return GET(`${API_HOST_URL()}/createLinkToken`);
}

export async function setPlaidAccessToken(public_token) {
  return POST(
    `${API_HOST_URL()}/set_access_token?public_token=${public_token}`
  );
}

export async function getPlaidAccountsBalance(access_token) {
  return POST(`${API_HOST_URL()}/getBalance?accessToken=${access_token}`);
}

export async function getForecastInvMktExpListByAdvisorId(advisorId) {
  return GET(
    `${API_HOST_URL()}/selectInvMktExpListByAdvisorId?advisorId=${advisorId}`
  );
}

export async function getInvAssetAllocationsByAdvisorId(advisorId) {
  return GET(
    `${API_HOST_URL()}/selectInvAssetAllocationsByAdvisorId?advisorId=${advisorId}`
  );
}

export async function getInvMarketCorrelationsByAdvisorId(advisorId) {
  return GET(
    `${API_HOST_URL()}/selectInvMarketCorrelationsByAdvisorId?advisorId=${advisorId}`
  );
}

export async function updateInvAssetAllocationsByRiskLevelByAdvisorId(
  reqPayload
) {
  return POST(
    `${API_HOST_URL()}/updateInvAssetAllocationsByRiskLevelByAdvisorId`,
    reqPayload
  );
}

export async function getAssetAllocListByClientId({
  clientid,
  recommendedRiskLevel,
}) {
  return GET(
    `${API_HOST_URL(
      'public'
    )}/selectAssetAllocListByClientId?clientId=${clientid}&riskLevel=${recommendedRiskLevel}`,
    true
  );
}

export async function getClientReportsByAdvisorId(advisorId) {
  return GET(
    `${API_HOST_URL()}/fetchClientReportsByAdvisorId?Advisor%20Id=${advisorId}`
  );
}

export async function getClientReportDataByReportId(displayTerm, reportId) {
  return GET(
    `${API_HOST_URL()}/fetchClientReportByReportId?Display%20Term=${displayTerm}&Report%20Id=${reportId}`
  );
}

export async function selectAssetAllocationByProductId(allocationId) {
  return GET(
    `${API_HOST_URL(
      'public'
    )}/selectAssetAllocationByProductId?productId=${allocationId}`,
    true
  );
}

export async function selectRecommendedAssetAllocationByClientId(clientId) {
  return GET(
    `${API_HOST_URL(
      'public'
    )}/selectRecommendedAssetAllocListByClientId?clientId=${clientId}`,
    true
  );
}
export async function selectInvestmentProductsByClientId(clientId) {
  return GET(
    `${API_HOST_URL()}/selectInvestmentProductsByClientId?clientId=${clientId}`
  );
}

export async function getUserInformationByEmailId(emailId) {
  return GET(`${API_HOST_URL()}/selectUserByEmailId?emailId=${emailId}`);
}

export async function updateMobileNumber(apiPayload) {
  return POST(
    `${API_HOST_URL()}/updateUserMobile?emailId=${apiPayload.emailId
    }&mobileNumber=${apiPayload.mobileNumber}`
  );
}

export async function getTickerDetails(ticker) {
  return GET(`${API_HOST_URL()}/getTickerDetails?tickers=${ticker}`);
}
