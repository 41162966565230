import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Controls from '../../components/controls/Controls';
import { getLoggedInUserDetails } from '../../selectors/loginUserSelector';
import {
  getPlansDetailsAction,
  saveAndUpdatePlanDetailsByPlanId,
} from '../../redux/actions/plannerDetailsAction';
import {
  plannerDetailsLoadingSelector,
  plannerDetailsSaveStatus,
  planDetailsByPlanIdSelector,
  selectedPlanIdForClientIdSelector,
  selectedDisplayTermForClientIdSelector,
  selectedHorizonTermForClientIdSelector,
  allPlannerMenuItemsSelector,
  plannerDetailsSimulateStatus,
  getNumberOfRuns,
  simulatePlanDetailsLoadingSelector,
} from '../../selectors/plannerDetailsSelector';
import {
  outputResponseLoadingSelector,
  outputResponseDashboardSummaryDetailsByPlanIdSelector,
  outputResponsePlannerDetailsByPlanIdSelector,
  outputResponseChartsByPlanIdSelector,
  outputResponseBuildByPlanIdSelector,
  outputResponseTablesByPlanIdSelector,
  outputResponseDataByPlanIdSelector,
  outputResponseGoalsSelector,
  outputResponseInsightsSelector,
  outputResponseSolutionsByPlanIdSelector,
  isOutputResponseDataAvailable,
  outputResponseNotesByPlanIdSelector
} from '../../selectors/outputResponseDetailsSelector';
import {
  setSelectedPlanForClientId,
  setSelectedDisplayTermAction,
  setSelectedHorizonTermAction,
  getPlansByClientIdSuccessAction,
  setSelectedChronoTabItem,
  getPlansByClientIdAction,
  setSelectedNumberOfRuns,
} from '../../redux/actions/plansAction';
import { selectedClientIdSelector } from '../../selectors/clientsInfoSelector';
import { getOutputResponseByPlanIdAction } from '../../redux/actions/outputResponseDetailsAction';
import ConfirmModal from '../shared/ConfirmModal';
import { isEqual } from 'lodash';
import {
  simulatePlanDetailsByPlanId,
  setPlanDetailsSimulatedAction,
} from '../../redux/actions/plannerDetailsAction';
import { getCurrentDateTime } from '../../utils/apirequestutil';
import { createNewClient, copyDefaultPlan } from '../../services/dashboardSvc';
import { DEFAULT_PLAN_ID } from '../../services/CONSTANTS';
import { renderTypes } from '../../constants/dashboard';
import {
  getClientsByAdvisorIdAction,
  setSelectedClientIdAction,
} from '../../redux/actions/clientsInfoAction';
import { USERTYPES } from '../../constants/common';
import CustomChart from '../../components/shared/CustomChartComponent';
import CustomTable from '../../components/shared/CustomTableComponent';
import CustomData from '../../components/shared/CustomDataComponent';
import CustomNotes from '../../components/shared/CustomNotesComponent';
class HorizonDetails extends Component {
  state = {
    openAddNewPlanModal: false,
    newPlanName: '',
    mobileNumber: '',
    isApiFailed: false,
    isLoading: false,
    snapData: {},
  };

  componentDidMount() {
    const {
      selectedClientId,
      selectedPlan,
      userDetails = {},
      plans,
      triggerGetPlansByClientId,
    } = this.props;
    const { emailId, userType } = userDetails;
    if (selectedClientId && selectedPlan) {
      this.getSelectedPlanIdDetails();
    } else if (userType === 'client' && plans.length <= 0) {
      triggerGetPlansByClientId({ clientId: emailId });
    }
  }

  deriveOtherData = () => {
    const {
      build,
      goals,
      insights,
      solutions,
      renderMainType,
      leftParentTabValue,
      leftSubTabValue,
      expandedPanel,
    } = this.props;
    let snap = {};
    let health = {};
    let alert = {};
    switch (renderMainType.toLowerCase()) {
      case 'build':
        switch (leftParentTabValue) {
          case 'family':
            const { family: { personal = {}, kids = {} } = {} } = build;
            switch (leftSubTabValue) {
              case 'personal':
                snap = personal?.snap || {};
                health = personal?.health || {};
                alert = personal?.alert || {};
                break;
              case 'kids':
                snap = kids?.snap || {};
                health = kids?.health || {};
                alert = kids?.alert || {};
                break;
              default:
                break;
            }
            break;
          case 'assets':
            const {
              assets: {
                markets = {},
                other = {},
                primaryHome = {},
                secondHomes = {},
                rentals: investmentProps = {},
              } = {},
            } = build;
            switch (leftSubTabValue) {
              case 'markets':
                snap = markets?.snap || {};
                health = markets?.health || {};
                alert = markets?.alert || {};
                break;
              case 'primary home':
                snap = primaryHome?.snap || {};
                health = primaryHome?.health || {};
                alert = primaryHome?.alert || {};
                break;
              case 'second homes':
                snap = secondHomes?.snap || {};
                health = secondHomes?.health || {};
                alert = secondHomes?.alert || {};
                break;
              case 'rentals':
                snap = investmentProps?.snap || {};
                health = investmentProps?.health || {};
                alert = investmentProps?.alert || {};
                break;
              case 'others':
                snap = other?.snap || {};
                health = other?.health || {};
                alert = other?.alert || {};
                break;
              default:
                break;
            }
            break;
          case 'income':
            const {
              income: {
                deferredCompensation = {},
                other: incomeOther = {},
                pension = {},
                socialSecurity = {},
                wages = {},
                dividends = {},
                rentals = {},
                withdrawalChoices = {},
              } = {},
            } = build;
            switch (leftSubTabValue) {
              case 'wages':
                snap = wages?.snap || {};
                health = wages?.health || {};
                alert = wages?.alert || {};
                break;
              case 'social security':
                snap = socialSecurity?.snap || {};
                health = socialSecurity?.health || {};
                alert = socialSecurity?.alert || {};
                break;
              case 'pension':
                snap = pension?.snap || {};
                health = pension?.health || {};
                alert = pension?.alert || {};
                break;
              case 'deferred compensation':
                snap = deferredCompensation?.snap || {};
                health = deferredCompensation?.health || {};
                alert = deferredCompensation?.alert || {};
                break;
              case 'dividends':
                snap = dividends?.snap || {};
                health = dividends?.health || {};
                alert = dividends?.alert || {};
                break;
              case 'rentals':
                snap = rentals?.snap || {};
                health = rentals?.health || {};
                alert = rentals?.alert || {};
                break;
              case 'withdrawal choices':
                snap = withdrawalChoices?.snap || {};
                health = withdrawalChoices?.health || {};
                alert = withdrawalChoices?.alert || {};
                break;
              case 'other':
                snap = incomeOther?.snap || {};
                health = incomeOther?.health || {};
                alert = incomeOther?.alert || {};
                break;
              default:
                break;
            }
            break;
          case 'savings':
            const {
              savings: {
                deferredCompensation: savingsDeferredCompensation = {},
                emergencyFunds: savingsEmergencyFunds = {},
                ret401k = {},
                other: savingsOther = {},
                pension: savingsPension = {},
                plans529 = {},
                ira = {},
              } = {},
            } = build;
            switch (leftSubTabValue) {
              case 'pension':
                snap = savingsPension?.snap || {};
                health = savingsPension?.health || {};
                alert = savingsPension?.alert || {};
                break;
              case '401k':
                snap = ret401k?.snap || {};
                health = ret401k?.health || {};
                alert = ret401k?.alert || {};
                break;
              case 'ira':
                snap = ira?.snap || {};
                health = ira?.health || {};
                alert = ira?.alert || {};
                break;
              case 'emergency funds':
                snap = savingsEmergencyFunds?.snap || {};
                health = savingsEmergencyFunds?.health || {};
                alert = savingsEmergencyFunds?.alert || {};
                break;
              case 'deferred compensation':
                snap = savingsDeferredCompensation?.snap || {};
                health = savingsDeferredCompensation?.health || {};
                alert = savingsDeferredCompensation?.alert || {};
                break;
              case '529 plan':
                snap = plans529?.snap || {};
                health = plans529?.health || {};
                alert = plans529?.alert || {};
                break;
              case 'other':
                snap = savingsOther?.snap || {};
                health = savingsOther?.health || {};
                alert = savingsOther?.alert || {};
                break;
              default:
                break;
            }
            break;
          case 'expenses':
            const {
              expenses: {
                insurance = {},
                kids: expensesKids = {},
                living = {},
                vacations = {},
                vehicles = {},
                debt = {},
              } = {},
            } = build;
            switch (leftSubTabValue) {
              case 'kids':
                snap = expensesKids?.snap || {};
                health = expensesKids?.health || {};
                alert = expensesKids?.alert || {};
                break;
              case 'vehicles':
                snap = vehicles?.snap || {};
                health = vehicles?.health || {};
                alert = vehicles?.alert || {};
                break;
              case 'insurance':
                snap = insurance?.snap || {};
                health = insurance?.health || {};
                alert = insurance?.alert || {};
                break;
              // switch (expandedPanel) {
              //   case 'termlife':
              //     snap = termLife?.snap || {};
              //     health = termLife?.health || {};
              //     alert = termLife?.alert || {};
              //     break;
              //   case 'wholelife':
              //     snap = wholeLife?.snap || {};
              //     health = wholeLife?.health || {};
              //     alert = wholeLife?.alert || {};
              //     break;
              //   case 'umbrella':
              //     snap = umbrella?.snap || {};
              //     health = umbrella?.health || {};
              //     alert = umbrella?.alert || {};
              //     break;
              //   case 'propertyInsurance':
              //     snap = property?.snap || {};
              //     health = property?.health || {};
              //     alert = property?.alert || {};
              //     break;
              //   case 'health':
              //     snap = expensesHealth?.snap || {};
              //     health = expensesHealth?.health || {};
              //     alert = expensesHealth?.alert || {};
              //     break;
              //   case 'longtermcare':
              //     snap = longTermCare?.snap || {};
              //     health = longTermCare?.health || {};
              //     alert = longTermCare?.alert || {};
              //     break;
              //   case 'autoIns':
              //     snap = auto?.snap || {};
              //     health = auto?.health || {};
              //     alert = auto?.alert || {};
              //     break;
              //   default:
              //     break;
              // }
              // break;
              case 'debt':
                snap = debt?.snap || {};
                health = debt?.health || {};
                alert = debt?.alert || {};
                break;
              // switch (expandedPanel) {
              //   case 'creditCards':
              //     snap = creditCards?.snap || {};
              //     health = creditCards?.health || {};
              //     alert = creditCards?.alert || {};
              //     break;
              //   case 'studentLoans':
              //     snap = studentLoans?.snap || {};
              //     health = studentLoans?.health || {};
              //     alert = studentLoans?.alert || {};
              //     break;
              //   case 'otherLoans':
              //     snap = otherLoans?.snap || {};
              //     health = otherLoans?.health || {};
              //     alert = otherLoans?.alert || {};
              //     break;
              //   default:
              //     break;
              // }
              // break;
              case 'vacations':
                snap = vacations?.snap || {};
                health = vacations?.health || {};
                alert = vacations?.alert || {};
                break;
              case 'living':
                snap = living?.snap || {};
                health = living?.health || {};
                alert = living?.alert || {};
                break;
              default:
                break;
            }
            break;
          case 'estate':
            const { estate: { heirs = {}, roth = {}, taxes = {} } = {} } =
              build;
            switch (leftSubTabValue) {
              case 'heirs':
                snap = heirs?.snap || {};
                health = heirs?.health || {};
                alert = heirs?.alert || {};
                break;
              case 'roth':
                snap = roth?.snap || {};
                health = roth?.health || {};
                alert = roth?.alert || {};
                break;
              case 'taxes':
                snap = taxes?.snap || {};
                health = taxes?.health || {};
                alert = taxes?.alert || {};
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
        break;
      case 'insights':
        const { insightsTabs = [] } = insights;
        const { rows: insightRows = [] } =
          insightsTabs.length > 0 ? insightsTabs[leftParentTabValue] : [];
        const { renderTag: insightsRenderTag = {} } =
          insightRows.length > 0 ? insightRows[leftSubTabValue] : {};
        if (insightsRenderTag) {
          let {
            snap: snapData = {},
            health: healthData = {},
            alert: alertData = {},
          } = insightsRenderTag;
          snap = snapData;
          health = healthData;
          alert = alertData;
        }
        break;
      case 'goals':
        const { goalsTabs = [] } = goals;
        const { rows: goalsRows = [] } =
          goalsTabs.length > 0 ? goalsTabs[leftParentTabValue] : [];
        const { renderTag: goalsRenderTag = {} } =
          goalsRows.length > 0 ? goalsRows[leftSubTabValue] : {};
        if (goalsRenderTag) {
          let {
            snap: snapData = {},
            health: healthData = {},
            alert: alertData = {},
          } = goalsRenderTag;
          snap = snapData;
          health = healthData;
          alert = alertData;
        }
        break;
      case 'compare':
        break;
      case 'solutions':
        const tempSubTabValue =
          typeof leftSubTabValue === 'string'
            ? leftSubTabValue.toLowerCase()
            : leftSubTabValue;
        switch (leftParentTabValue) {
          case 'investments':
          case 0:
            const { investments: { rebalance = {} } = {} } = solutions;
            snap = rebalance.snap || {};
            health = rebalance.health || {};
            alert = rebalance.alert || {};
            break;
          case 1:
          case 'expenses':
            const { expenses: { optimize = {} } = {} } = solutions;
            snap = optimize.snap || {};
            health = optimize.health || {};
            alert = optimize.alert || {};
            break;
          case 2:
          case 'goals':
            const { goals: { optimize: goalsOptimize = {} } = {} } = solutions;
            snap = goalsOptimize.snap || {};
            health = goalsOptimize.health || {};
            alert = goalsOptimize.alert || {};
            break;
          case 3:
          case 'insurance':
            const {
              insurance: {
                termLife = {},
                wholeLife = {},
                longTermCare = {},
                umbrella = {},
                property = {},
                auto = {},
                health: healthInsurance = {},
              } = {},
            } = solutions;
            switch (tempSubTabValue) {
              case 'term life':
              case 0:
                snap = termLife.snap || {};
                health = termLife.health || {};
                alert = termLife.alert || {};
                break;
              case 'whole life':
              case 1:
                snap = wholeLife.snap || {};
                health = wholeLife.health || {};
                alert = wholeLife.alert || {};
                break;
              case 'long term care':
              case 2:
                snap = longTermCare.snap || {};
                health = longTermCare.health || {};
                alert = longTermCare.alert || {};
                break;
              case 'umbrella':
              case 3:
                snap = umbrella.snap || {};
                health = umbrella.health || {};
                alert = umbrella.alert || {};
                break;
              case 'property':
              case 4:
                snap = property.snap || {};
                health = property.health || {};
                alert = property.alert || {};
                break;
              case 'vehicle':
              case 5:
                snap = auto.snap || {};
                health = auto.health || {};
                alert = auto.alert || {};
                break;
              case 'health':
              case 6:
                snap = healthInsurance.snap || {};
                health = healthInsurance.health || {};
                alert = healthInsurance.alert || {};
                break;
              default:
                break;
            }
            break;
          case 4:
            break;
          case 5:
            break;
          case 'roth':
            const { roth: { conversions = {}, withdrawals = {} } = {} } =
              solutions;
            switch (tempSubTabValue) {
              case 'withdrawals':
                snap = withdrawals.snap || {};
                health = withdrawals.health || {};
                alert = withdrawals.alert || {};
                break;
              case 'conversions':
                snap = conversions.snap || {};
                health = conversions.health || {};
                alert = conversions.alert || {};
                break;

              default:
                break;
            }

            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    const otherData = { snap, health, alert };
    return otherData;
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedPlan,
      selectedHorizonTerm,
      selectedDisplayTerm,
      planDetailsByPlanId,
      triggersetSelectedHorizonTermForClientId,
      triggersetSelectedDisplayTermForClientId,
      planDetailsSimulated,
      plans,
      triggersetCurrentPlansforClientId,
      isPlanDetailsSaved,
    } = this.props;
    const {
      selectedPlan: prevSelectedPlan,
      selectedHorizonTerm: prevSelectedHorizonTerm,
      selectedDisplayTerm: prevSelectedDisplayTerm,
      planDetailsSimulated: prevPlanDetailsSimulated,
      plans: prevPlans,
      isPlanDetailsSaved: prevIsPlanDetailsSaved,
    } = prevProps;
    if (
      planDetailsByPlanId &&
      Object.keys(planDetailsByPlanId).length > 0 &&
      !planDetailsSimulated
    ) {
      if (
        selectedDisplayTerm &&
        selectedHorizonTerm &&
        (prevSelectedDisplayTerm !== selectedDisplayTerm ||
          prevSelectedHorizonTerm !== selectedHorizonTerm)
      ) {
        triggersetSelectedHorizonTermForClientId(selectedHorizonTerm);
        triggersetSelectedDisplayTermForClientId(selectedDisplayTerm);
      }
    }
    if (isPlanDetailsSaved && isPlanDetailsSaved !== prevIsPlanDetailsSaved) {
      this.handleDisplayToast('Plan details saved successfully', 'success');
    }
    if (
      planDetailsSimulated &&
      planDetailsSimulated !== prevPlanDetailsSimulated
    ) {
      this.handleDisplayToast('Plan details simulated successfully', 'success');
    }
    if (!isEqual(plans, prevPlans) && plans.length > 1) {
      triggersetCurrentPlansforClientId(plans);
    }
    if (selectedPlan !== prevSelectedPlan) {
      this.getSelectedPlanIdDetails();
    }
  }

  handleDisplayToast = (toasterMsg, type) => {
    const { enqueueSnackbar } = this.props;
    this.key = enqueueSnackbar(toasterMsg, {
      variant: type,
      autoHideDuration: 2000,
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      action: (key) => <CloseIcon onClick={this.onClickDismiss(key)} />,
    });
  };

  getSelectedPlanIdDetails = () => {
    const {
      selectedPlan: planId,
      getSavedSelectedPlanIdDetails,
      isV1,
      planDetailsByPlanId,
    } = this.props;
    const { moduleLifeStyle } = planDetailsByPlanId;
    if (moduleLifeStyle && !isV1 && planId && planId !== '') {
      const version = 'v2';
      getSavedSelectedPlanIdDetails({ planId, version });
    }
  };

  onClickDismiss = (key) => () => {
    const { closeSnackbar } = this.props;
    closeSnackbar(key);
  };

  handleOpen = (val) => {
    this.setState({ openAddNewPlanModal: val, newPlanName: '' });
  };

  onNumberOfRunsChange = (event) => {
    const { triggersetSelectedNumberofRuns } = this.props;
    triggersetSelectedNumberofRuns(event.target.value);
  };

  onHorizonTermChange = (event) => {
    const {
      triggersetSelectedHorizonTermForClientId,
      triggerSetPlanDetailsSimulated,
    } = this.props;
    const { value: horizon } = event.target;
    triggersetSelectedHorizonTermForClientId(horizon);
    triggerSetPlanDetailsSimulated(false);
  };

  onDisplayTermChange = (event) => {
    const {
      triggersetSelectedDisplayTermForClientId,
      triggerSetPlanDetailsSimulated,
    } = this.props;
    const { value: displayTerm } = event.target;
    triggersetSelectedDisplayTermForClientId(displayTerm);
    triggerSetPlanDetailsSimulated(false);
  };

  onPlanNameInputChange = (event) => {
    if (event) {
      this.setState({ newPlanName: event.target.value });
    }
  };

  renderNewPlanDetails = () => {
    const { newPlanName } = this.state;
    return (
      <>
        <div className="client-dashboard-title">
          <div></div>
          <div className="logo-class">Add New Plan</div>
          <div></div>
        </div>
        <div className="add-new-plan-fields">
          <div></div>
          <div className="plan-name-field">
            <Controls.Input
              label="Plan Name"
              value={newPlanName}
              type="text"
              onChange={(event) => {
                this.onPlanNameInputChange(event);
              }}
              errorText="Plan name cannot be empty"
            />
          </div>
          <div></div>
        </div>
      </>
    );
  };

  copyDefaultPlan = async (planId, clientId) => {
    const { userDetails = {}, triggerGetPlansByClientId } = this.props;
    const { emailId } = userDetails;
    const reqObj = {
      clientId: clientId !== '' ? clientId.toLowerCase() : '',
      advisorId: emailId !== '' ? emailId.toLowerCase() : '',
      sourcePlanId: DEFAULT_PLAN_ID,
      targetPlanId: planId,
    };
    try {
      const [response] = await Promise.all([copyDefaultPlan(reqObj)]);
      if (response && response.status === 200) {
        this.setState(
          {
            isApiFailed: false,
            isLoading: false,
          },
          () => {
            const payload = {
              clientId: clientId !== '' ? clientId.toLowerCase() : '',
            };
            triggerGetPlansByClientId(payload);
          }
        );
      } else {
        this.setState({
          isApiFailed: true,
          isLoading: false,
        });
      }
    } catch (e) {
      this.setState({
        isApiFailed: true,
        isLoading: false,
      });
    }
  };

  handleNewPlan = async () => {
    const { userDetails = {}, selectedClientId, clients } = this.props;
    const { emailId, name } = userDetails;
    const { newPlanName, mobileNumber } = this.state;
    let selectedClientName = '';
    if (clients.length > 0) {
      const clientObj = clients.find(
        (client) => client.value === selectedClientId
      );
      selectedClientName =
        clientObj && Object.keys(clientObj).length > 0 ? clientObj.label : '';
    }
    const emailExtension = emailId.split('@')[1];
    let tempEmailId =
      selectedClientId !== ''
        ? selectedClientId
        : selectedClientName !== ''
          ? selectedClientName.trim().split(' ').join('') + '@' + emailExtension
          : '';
    this.setState({ isLoading: true });
    const reqObj = {
      createdBy: name,
      createdDate: getCurrentDateTime(),
      emailId: tempEmailId.toLowerCase(),
      mobileNumber: mobileNumber,
      clientName: selectedClientName,
      planName: newPlanName,
      modifiedBy: name,
      modifiedDate: getCurrentDateTime(),
      advisorId: emailId,
      planId: '',
    };
    try {
      const [firstResponse] = await Promise.all([createNewClient(reqObj)]);
      if (firstResponse && firstResponse.status === 200) {
        this.setState({ isLoading: false }, () => {
          this.copyDefaultPlan(firstResponse.data, tempEmailId);
          this.handleDisplayToast('New plan created successfully', 'success');
        });
      } else {
        this.handleDisplayToast(
          'Something went wrong, please try again',
          'error'
        );
        this.setState({
          isApiFailed: true,
          isLoading: false,
        });
      }
    } catch (e) {
      this.handleDisplayToast(
        'Something went wrong, please try again',
        'error'
      );
      this.setState({
        isApiFailed: true,
        isLoading: false,
      });
    }
  };

  onPlanChange = (event, id) => {
    let planId = event?.target?.value || '';
    const {
      triggersetSelectedPlanIdForClientId,
      //triggerClearPlanDetailsFromStore, // bring it back when u rewrite whole logic of changing plans and simulate
    } = this.props;
    //triggerClearPlanDetailsFromStore();
    triggersetSelectedPlanIdForClientId(planId);
  };

  renderCustomTable = (renderWhatData) => {
    const { tables } = this.props;
    return <CustomTable tables={tables} renderWhatData={renderWhatData} snap />;
  };

  renderCustomData = (renderWhatData) => {
    const { data } = this.props;
    return <CustomData data={data} renderWhatData={renderWhatData} />;
  };

  renderCustomChart = (renderWhatData) => {
    const { charts } = this.props;
    return <CustomChart charts={charts} renderWhatData={renderWhatData} />;
  };

  renderCustomNotes = (renderWhatData) => {
    const { notes } = this.props;
    return <CustomNotes notes={notes} renderWhatData={renderWhatData} />;
  }

  renderCustomContentContainer = ({ renderTabType, data }) => {
    switch (renderTabType && renderTabType.toLowerCase()) {
      case 'chart':
        return this.renderCustomChart(data);
      case 'table':
        return this.renderCustomTable(data);
      case 'data':
        return this.renderCustomData(data);
      case 'notes':
        return this.renderCustomNotes(data);
      default:
        break;
    }
  };

  renderCustomContainer = (type) => {
    const otherData = this.deriveOtherData();
    const data = otherData[type];
    const { tabType } = data;
    const obj = { renderTabType: tabType, data: data };
    return this.renderCustomContentContainer(obj);
  };

  render() {
    const {
      userDetails = {},
      plans,
      isPlanner,
      planner,
      clients,
      selectedClientId,
      renderType,
    } = this.props;
    let selectedClientName = '';
    if (clients.length > 0) {
      const clientObj = clients.find(
        (client) => client.value === selectedClientId
      );
      selectedClientName =
        clientObj && Object.keys(clientObj).length > 0 ? clientObj.label : '';
    } else if (userDetails.userType === USERTYPES.CLIENT) {
      selectedClientName = userDetails?.name;
    }
    const { name, companyName, shortLocation } = userDetails;
    const { main = [] } = planner || {};
    const elements = main.length > 0 && main[0].elements;
    const { openAddNewPlanModal } = this.state;
    const addPlanItem = { label: 'Add New Plan', value: 'newPlanId' };
    const plansWithDefault = [...plans];
    if (
      isPlanner &&
      plans.filter((p) => p.value === addPlanItem.value).length === 0
    ) {
      plansWithDefault.unshift(addPlanItem);
    }
    return (
      <>
        {renderType?.toLowerCase() === 'custom' && (
          <div className="plan_results_main_outer">
            <div className="button_meter_outer-alert">
              {this.renderCustomContainer('alert')}
            </div>
            <div className="button_meter_outer">
              {this.renderCustomContainer('snap')}
            </div>
            <div className="client_main_right_2">
              {this.renderCustomContainer('health')}
            </div>
          </div>
        )}
        <ConfirmModal
          content={this.renderNewPlanDetails()}
          open={openAddNewPlanModal}
          setOpen={this.handleOpen}
          onConfirm={this.handleNewPlan}
          okLabel="Add"
          from="horizon"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: getLoggedInUserDetails(state),
    clients: state.clientInfoReducer.clients || [],
    plans: state.plansReducer.plans || [],
    planDetailsByPlanId: planDetailsByPlanIdSelector(state),
    plannerDetailsLoading: plannerDetailsLoadingSelector(state),
    simulatePlanDetailsLoading: simulatePlanDetailsLoadingSelector(state),
    isPlanDetailsSaved: plannerDetailsSaveStatus(state),
    selectedPlan: selectedPlanIdForClientIdSelector(state),
    selectedDisplayTerm: selectedDisplayTermForClientIdSelector(state),
    selectedHorizonTerm: selectedHorizonTermForClientIdSelector(state),
    dashboard: outputResponseDashboardSummaryDetailsByPlanIdSelector(state),
    charts: outputResponseChartsByPlanIdSelector(state) || {},
    notes: outputResponseNotesByPlanIdSelector(state) || [],
    isOutputResponseLoading: outputResponseLoadingSelector(state),
    allMenuItems: allPlannerMenuItemsSelector(state),
    planner: outputResponsePlannerDetailsByPlanIdSelector(state),
    selectedClientId: selectedClientIdSelector(state),
    isoutputResponseAvailable: isOutputResponseDataAvailable(state),
    planDetailsSimulated: plannerDetailsSimulateStatus(state),
    selectedNumberOfRuns: getNumberOfRuns(state),
    build: outputResponseBuildByPlanIdSelector(state) || {},
    tables: outputResponseTablesByPlanIdSelector(state) || [],
    data: outputResponseDataByPlanIdSelector(state) || [],
    goals: outputResponseGoalsSelector(state) || {},
    insights: outputResponseInsightsSelector(state) || {},
    solutions: outputResponseSolutionsByPlanIdSelector(state) || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    triggersetSelectedDisplayTermForClientId: (payload) =>
      dispatch(setSelectedDisplayTermAction(payload)),
    triggersetSelectedHorizonTermForClientId: (payload) =>
      dispatch(setSelectedHorizonTermAction(payload)),
    triggersetSelectedPlanIdForClientId: (payload) =>
      dispatch(setSelectedPlanForClientId(payload)),
    triggersetSelectedNumberofRuns: (payload) =>
      dispatch(setSelectedNumberOfRuns(payload)),
    triggerSetSelectedClientIdAction: (payload) =>
      dispatch(setSelectedClientIdAction(payload)),
    getSavedSelectedPlanIdDetails: (payload) =>
      dispatch(getPlansDetailsAction(payload)),
    saveAndUpdatePlanDetailsbyPlanId: (payload) =>
      dispatch(saveAndUpdatePlanDetailsByPlanId(payload)),
    triggersetCurrentPlansforClientId: (payload) =>
      dispatch(getPlansByClientIdSuccessAction(payload)),
    triggerGetOutputReponseByPlanId: (payload) =>
      dispatch(getOutputResponseByPlanIdAction(payload)),
    triggerSetSelectedChronoTabItem: (payload) =>
      dispatch(setSelectedChronoTabItem(payload)),
    triggerGetClientsByAdvisorId: (payload) =>
      dispatch(getClientsByAdvisorIdAction(payload)),
    triggerSimulatePlanDetailsByPlanId: (payload) =>
      dispatch(simulatePlanDetailsByPlanId(payload)),
    triggerGetPlansByClientId: (payload) =>
      dispatch(getPlansByClientIdAction(payload)),
    triggerSetPlanDetailsSimulated: (payload) =>
      dispatch(setPlanDetailsSimulatedAction(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(HorizonDetails));
