import React, { useState, useEffect } from 'react';
import {
  renderMembersInputRow,
  renderLabelRow,
  renderSelectOption,
  transformInfoCoreBackToUIType,
  validateForm,
  renderMemberLabelRowWithoutEdits,
  getAndUpdatePensionContribYrs,
} from '../../helpers/BuildHelperComponentV3';
import { infoCoreValidatableFields } from '../../constants/BuildTabComponentV3';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const BuildSavingsPensionComponent = (props) => {
  const {
    state,
    localPlanDetailsByPlanId,
    setLocalPlanDetailsByPlanId,
    setLastStepInParent,
    setValidationError,
  } = props;
  const { retiredState, membersCount } = state;
  const { moduleFamily: { infoCore = {} } = {} } = localPlanDetailsByPlanId;
  const userSavingRetOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  const [formErrors, setFormErrors] = useState([]);
  const [infoCoreDetails, setinfoCoreDetails] = useState(infoCore);

  useEffect(() => {
    if (infoCore && Object.keys(infoCore).length > 0) {
      //let tempInfoCore = getAndUpdatePensionContribYrs(infoCore, membersCount);
      let tempInfoCore = transformInfoCoreBackToUIType(infoCore);
      let validationErrors = {};
      validationErrors = validateForm(
        tempInfoCore,
        membersCount,
        infoCoreValidatableFields
      );
      if (Object.values(validationErrors).length > 0) {
        setFormErrors(validationErrors);
      }
      const { validationObj1 = [], validationObj2 = [] } = validationErrors;
      if (
        Object.values(validationObj1).length > 0 ||
        Object.values(validationObj2).length > 0
      ) {
        setValidationError(false);
      } else {
        setValidationError(true);
      }
      setinfoCoreDetails(tempInfoCore);
    }
  }, [localPlanDetailsByPlanId, infoCore, membersCount, setValidationError]);

  const [alertMessageOnAgeChange, setAlertMessageOnAgeChange] = useState('');

  useEffect(() => {
    setLastStepInParent(true);
  }, [setLastStepInParent]);

  useEffect(() => {
    if (alertMessageOnAgeChange) {
      const timer = setTimeout(() => {
        setAlertMessageOnAgeChange('');
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [alertMessageOnAgeChange]);

  return (
    <>
      <div className="insured_main_outer">
        {/* <Stack sx={{ width: '100%', padding: '2px' }} spacing={2}>
            <Alert severity="info">
              <p>{'No savings to be captured since the family is retired'}</p>
            </Alert>
          </Stack> */}
        {renderMemberLabelRowWithoutEdits(
          infoCoreDetails,
          'name',
          'basicsFirst',
          'basicsSecond',
          state
        )}
      </div>
      {retiredState ? (
        <></>
      ) : (
        <>
          <div className="insured_main_outer">

            {renderMembersInputRow(
              'text',
              'tlMemberOnePercentPensionGrant',
              'percentPensionGrant',
              'tlMemberSecPercentPensionGrant',
              'Pension employer contribution as % of wages',
              'percentage',
              infoCoreDetails,
              'infoCore',
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              'moduleFamily',
              'defCompFirst',
              'defCompSecond',
              state,
              setinfoCoreDetails,
              formErrors,
              'isRetirementAgeSpecificField'
            )}
            {renderMembersInputRow(
              'text',
              'tlMemberOnePercentPensionSelfContrib',
              'percentPensionSelfContrib',
              'tlMemberSecPercentPensionSelfContrib',
              'Pension self contribution as % of wages',
              'percentage',
              infoCoreDetails,
              'infoCore',
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              'moduleFamily',
              'defCompFirst',
              'defCompSecond',
              state,
              setinfoCoreDetails,
              formErrors,
              'isRetirementAgeSpecificField'
            )}
            {renderMembersInputRow(
              'text',
              'tlMemberOnePensionContribYrs',
              'pensionContribYrs',
              'tlMemberSecPensionContribYrs',
              'Number of years of pension contribution from now',
              'number',
              infoCoreDetails,
              'infoCore',
              localPlanDetailsByPlanId,
              setLocalPlanDetailsByPlanId,
              'moduleFamily',
              'defCompFirst',
              'defCompSecond',
              state,
              setinfoCoreDetails,
              formErrors,
              'isRetirementAgeSpecificField'
            )}
          </div>
          <div className='tab_main_nav'>
            <div className='tab_left_nav'>
              <button>
                <ArrowBackIcon /> Previous
              </button>
            </div>
            <div className='tab_right_nav'>
              <button>
                Next <ArrowForwardIcon />
              </button>
            </div>
          </div>

        </>
      )}
    </>
  );
};
export default BuildSavingsPensionComponent;
